<div *ngIf="options && options.active" class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
  <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>

  <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10">
          <span *ngIf="isBrowser && options.severity == 'I'" class="text-blue-600 text-3xl icon"><fa-icon [icon]="faInfoCircle"></fa-icon></span>
          <span *ngIf="isBrowser && options.severity == 'W'" class="text-yellow-400 text-3xl icon"><fa-icon [icon]="faExclamationCircle"></fa-icon></span>
          <span *ngIf="isBrowser && options.severity == 'E'" class="text-red-600 text-3xl icon"><fa-icon [icon]="faExclamationCircle"></fa-icon></span>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            {{options.title}}
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500">
              {{options.message}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button type="button" (click)="pressOK()"
            class="button-jf button-red inline-flex justify-center w-full shadow-sm">
          {{options.okText}}
        </button>
      </span>
      <span class="flex-1"></span>
      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
        <button type="button"  (click)="pressCancel()"
          class="button-jf button-white inline-flex justify-center w-full shadow-sm">
          {{options.cancelText}}
        </button>
      </span>
    </div>
  </div>
</div>
