import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';

import { UserEntity, UserSelfGQL } from '../../../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private isBrowser: boolean;
  private user: UserEntity = null;
  private userSubject$ = new BehaviorSubject<UserEntity>(this.user);
  public userChanged$ = this.userSubject$.asObservable();

  private readonly ACCESS_TOKEN_KEY = 'access_token';
  private readonly EXPIRATION_KEY = 'expiration_timestamp';
  private readonly USER_DETAILS_KEY = 'user_details';

  constructor(
    private httpClient: HttpClient,
    private apollo: Apollo,
    private userSelfGQL: UserSelfGQL,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      const expirationTimestamp = Number(localStorage.getItem(this.EXPIRATION_KEY));

      const accessToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);
      const userDetails = localStorage.getItem(this.USER_DETAILS_KEY);

      if (expirationTimestamp && accessToken) {
        // if the user details haven't expired AND the timestamp isn't too far in the future to be valid
        if (Date.now() < expirationTimestamp && Date.now() > expirationTimestamp - 24 * 60 * 60 * 1000) {
          if (userDetails) {
            this.user = JSON.parse(userDetails) as UserEntity;
            this.userSubject$.next(this.user);
          } else {
            this.userSelf();
          }
        }
      }
    }
  }

  public register(firstName: string, lastName: string, username: string, password: string, turnstileResponse: string): Observable<LoginResponse> {
    if (this.isBrowser) {
      const headers = new HttpHeaders({ 'X-Turnstile-Response': turnstileResponse });
      const data = this.httpClient
        .post<LoginResponse>(
          '/register',
          {
            firstName,
            lastName,
            username,
            password
          },
          { headers }
        )
        .pipe(shareReplay(1));
      data.subscribe((response) => {
        // Javascript date format is in milliseconds since epoch UTC, expires_in is in seconds
        const expirationTimestamp = Date.now() + response.expires_in * 1000;
        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.access_token);
        localStorage.setItem(this.EXPIRATION_KEY, String(expirationTimestamp));
        this.userSelf();
      });
      return data;
    }
  }

  public login(username: string, password: string, turnstileResponse: string): Observable<LoginResponse> {
    if (this.isBrowser) {
      localStorage.removeItem(this.ACCESS_TOKEN_KEY);
      localStorage.removeItem(this.EXPIRATION_KEY);

      this.apollo.getClient().resetStore();

      const headers = new HttpHeaders({ 'X-Turnstile-Response': turnstileResponse });
      const data = this.httpClient.post<LoginResponse>('/login', { username, password }, { headers }).pipe(shareReplay(1));
      data.subscribe((response) => {
        // Javascript date format is in milliseconds since epoch UTC, expires_in is in seconds
        const expirationTimestamp = Date.now() + response.expires_in * 1000;
        localStorage.setItem(this.ACCESS_TOKEN_KEY, response.access_token);
        localStorage.setItem(this.EXPIRATION_KEY, String(expirationTimestamp));
        this.userSelf();
      });
      return data;
    }
  }

  public isAuthenticated(): boolean {
    if (this.isBrowser) {
      const expirationTimestamp = Number(localStorage.getItem(this.EXPIRATION_KEY));
      const accessToken = localStorage.getItem(this.ACCESS_TOKEN_KEY);

      // if the user details haven't expired AND the timestamp isn't too far in the future to be valid
      return !!expirationTimestamp && !!accessToken && Date.now() < expirationTimestamp && Date.now() > expirationTimestamp - 24 * 60 * 60 * 1000;
    } else {
      return false;
    }
  }

  public isAdmin(): boolean {
    if (this.isBrowser) {
      const accessToken = window.localStorage.getItem(this.ACCESS_TOKEN_KEY);
      if (accessToken) {
        const parts = accessToken.split('.');
        if (parts.length === 3) {
          const payload = parts[1];
          const data = JSON.parse(atob(payload));
          return data?.roles?.length > 0 && data.roles.includes('ROLE_ADMIN');
        }
      }
    }
    return false;
  }

  private userSelf(): void {
    if (this.isBrowser) {
      this.userSelfGQL
        .fetch()
        .pipe(map((result) => result.data.userSelf))
        .subscribe(
          (result) => {
            this.user = result as UserEntity;
            localStorage.setItem(this.USER_DETAILS_KEY, JSON.stringify(this.user));
            this.userSubject$.next(this.user);
          },
          (error) => {
            console.error(error);
            this.user = undefined;
            this.userSubject$.next(this.user);
          }
        );
    }
  }

  public logout(): void {
    if (this.isBrowser) {
      this.apollo.getClient().resetStore();
      localStorage.removeItem(this.ACCESS_TOKEN_KEY);
      localStorage.removeItem(this.EXPIRATION_KEY);
      localStorage.removeItem(this.USER_DETAILS_KEY);
      this.user = undefined;
      this.userSubject$.next(this.user);
    }
  }
}

// tslint:disable:variable-name
export class LoginResponse {
  username: string;
  roles: [string];
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}
