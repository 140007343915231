import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styles: []
})
export class MessageBoxComponent implements OnInit {
  isBrowser = false;

  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;

  @Input() options = new MessageBoxOptions();
  @Output() okPressed = new EventEmitter<void>();
  @Output() cancelPressed = new EventEmitter<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  pressOK() {
    this.okPressed.emit();
  }

  pressCancel() {
    this.cancelPressed.emit();
  }
}

export class MessageBoxOptions {
  active = true;
  severity = 'I';
  title = '';
  message = '';
  okText = 'OK';
  cancelText = 'Cancel';
}
