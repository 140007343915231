import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import {
  CategoryEntity, CategorySetImageGQL, CategoryUploadImageGQL,
  ConfigurableProductAddImageGQL, ConfigurableProductUploadImageGQL, KitProductAddImageGQL, KitProductUploadImageGQL,
  MediaEntity, MediaUploadImageGQL,
  SimpleProductAddImageGQL,
  SimpleProductUploadImageGQL
} from '../../../../../../../generated/graphql';
import { DialogBoxOptions, DialogComponent } from '../../../../../shared/components/dialog/dialog.component';
import { ImageFile } from '../../../../../shared/components/image-uploader/image-uploader.component';
import { Product } from '../../../../../shared/constants/constants';
import { DialogService } from '../../../../../shared/services/dialog.service';
import { MediaSelectComponent } from '../../../../shared/components/media-select/media-select.component';

@Component({
  selector: 'app-add-media',
  templateUrl: './add-media.component.html',
  styles: []
})
export class AddMediaComponent implements OnInit {
  @Input() category: CategoryEntity;
  @Input() product: Product;
  @Input() showExisting = true;
  @Input() callback: (entity: Product | CategoryEntity | MediaEntity) => void;
  @Input() parentRef: DialogComponent<AddMediaComponent>;

  submitting: string;
  form: FormGroup;
  error: Error;
  mediaError: Error;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private categoryUploadImageGQL: CategoryUploadImageGQL,
    private categorySetImageGQL: CategorySetImageGQL,
    private configurableProductAddImageGQL: ConfigurableProductAddImageGQL,
    private configurableProductUploadImageGQL: ConfigurableProductUploadImageGQL,
    private kitProductAddImageGQL: KitProductAddImageGQL,
    private kitProductUploadImageGQL: KitProductUploadImageGQL,
    private mediaUploadImageGQL: MediaUploadImageGQL,
    private simpleProductAddImageGQL: SimpleProductAddImageGQL,
    private simpleProductUploadImageGQL: SimpleProductUploadImageGQL
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [{ value: undefined, disabled: false }],
      keywords: [{ value: undefined, disabled: false }],
      file: [{ value: undefined, disabled: false }, [Validators.required]]
    });
  }

  showMediaSelectDialog() {
    const options = new DialogBoxOptions();
    options.component = MediaSelectComponent;
    options.title = `Media Select`;
    options.cancelText = 'Cancel';
    options.okText = '';
    options.inputs = {
      selectLimit: 1,
      submitText: this.category ? 'Set Thumbnail' : 'Add Media',
      callback: (medias: Map<string, MediaEntity>) => {
        if (medias.size !== 1) {
          alert(`Please select one item.`);
        } else {
          medias.forEach((media) => {
            this.addMedia(media);
          });
        }
      }
    };

    this.dialogService.showDialog(options);
  }

  addMedia(media: MediaEntity) {
    this.submitting = 'add';
    this.form.disable();
    delete this.error;
    if (this.category) {
      this.categoryAddMedia(media);
    } else if (this.product) {
      switch (this.product.cls) {
        case 'SimpleProductEntity':
          this.simpleProductAddMedia(media);
          break;
        case 'KitProductEntity':
          this.kitProductAddMedia(media);
          break;
        case 'ConfigurableProductEntity':
          this.configurableProductAddMedia(media);
          break;
        default:
          this.error = new Error(`Products of type ${this.product.cls} are not currently supported.`);
          this.form.enable();
          delete this.submitting;
      }
    } else {
      this.handleSuccess(media);
    }
  }

  categoryAddMedia(media: MediaEntity) {
    this.categorySetImageGQL
      .mutate({ id: this.category.id, mediaId: media.id })
      .pipe(map((result) => result.data.categorySetImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  configurableProductAddMedia(media: MediaEntity) {
    this.configurableProductAddImageGQL
      .mutate({ id: this.product.id, mediaId: media.id })
      .pipe(map((result) => result.data.configurableProductAddImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  kitProductAddMedia(media: MediaEntity) {
    this.kitProductAddImageGQL
      .mutate({ id: this.product.id, mediaId: media.id })
      .pipe(map((result) => result.data.kitProductAddImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  simpleProductAddMedia(media: MediaEntity) {
    this.simpleProductAddImageGQL
      .mutate({ id: this.product.id, mediaId: media.id })
      .pipe(map((result) => result.data.simpleProductAddImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  uploadMedia() {
    if (this.form.valid) {
      this.submitting = 'upload';
      this.form.disable();
      delete this.error;
      const data = {
        id: undefined,
        title: this.form.controls.title.value,
        keywords: this.form.controls.keywords.value,
        filename: this.form.controls.file.value.name,
        dataUrl: `data:${this.form.controls.file.value.type};base64,${this.form.controls.file.value.base64}`
      };
      if (this.category) {
        data.id = this.category.id;
        this.categoryUploadMedia(data);
      } else if (this.product) {
        data.id = this.product.id;
        switch (this.product.cls) {
          case 'SimpleProductEntity':
            this.simpleProductUploadMedia(data);
            break;
          case 'KitProductEntity':
            this.kitProductUploadMedia(data);
            break;
          case 'ConfigurableProductEntity':
            this.configurableProductUploadMedia(data);
            break;
          default:
            this.error = new Error(`Products of type ${this.product.cls} are not currently supported.`);
            this.form.enable();
            delete this.submitting;
        }
      } else {
        this.mediaUploadImage(data);
      }
    } else {
      if (!this.form.controls.file.value) {
        this.mediaError = new Error('No file selected.');
      }
      this.form.markAllAsTouched();
    }
  }

  mediaUploadImage(data) {
    this.mediaUploadImageGQL
      .mutate(data)
      .pipe(map((result) => result.data.mediaUploadImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  categoryUploadMedia(data) {
    this.categoryUploadImageGQL
      .mutate(data)
      .pipe(map((result) => result.data.categoryUploadImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  configurableProductUploadMedia(data) {
    this.configurableProductUploadImageGQL
      .mutate(data)
      .pipe(map((result) => result.data.configurableProductUploadImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  kitProductUploadMedia(data) {
    this.kitProductUploadImageGQL
      .mutate(data)
      .pipe(map((result) => result.data.kitProductUploadImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  simpleProductUploadMedia(data) {
    this.simpleProductUploadImageGQL
      .mutate(data)
      .pipe(map((result) => result.data.simpleProductUploadImage))
      .subscribe(
        (result) => this.handleSuccess(result),
        (error) => this.handleError(error)
      );
  }

  imageCallback = (file: ImageFile) => {
    this.form.controls.file.setValue(file);
  };

  handleError(error: Error) {
    this.error = error;
    this.form.enable();
    delete this.submitting;
  }

  handleSuccess(entity: Product | CategoryEntity | MediaEntity) {
    this.parentRef.pressOK();
    this.callback?.(entity);
  }
}
