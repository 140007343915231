<div *ngIf="options && options.active" class="fixed bottom-0 inset-x-0 p-4 sm:inset-0 flex sm:items-center sm:justify-center max-h-full"
     [ngClass]="{'h-full' : options.fullHeight}">
  <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div *ngIf="dialogService.componentRef[dialogService.componentRef.length - 1].instance === this" class="fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-gray-500 opacity-75" (click)="hideDialog()"></div>
  </div>

  <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
  <div class="bg-white rounded-lg transform transition-all sm:max-w-6xl w-full max-h-full flex flex-col overflow-auto" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
       [ngClass]="{'h-full' : options.fullHeight}">
    <div class="bg-jfgreen-600 p-4 sm:p-6" style="flex: 0 0 auto">
      <div class="text-center sm:text-left">
        <h3 class="text-lg leading-6 font-medium text-white break-words" id="modal-headline">
          {{options.title}}
        </h3>
        <div *ngIf="options.message" class="mt-2">
          <p class="text-sm leading-5 text-jfgreen-100">
            {{options.message}}
          </p>
        </div>
      </div>
    </div>

    <div class="bg-white p-4 sm:p-6 relative overflow-auto text-center sm:text-left" #scrollContainer [ngClass]="{'h-full' : options.fullHeight}">
        <template #content></template>
    </div>

    <div class="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between" style="flex: 0 0 auto">
      <span *ngIf="options.okText" class="flex w-full rounded-md shadow-sm sm:w-auto" [ngClass]="{'sm:ml-3': options.cancelText}">
        <button type="button" (click)="pressOK()"
                class="button-jf inline-flex justify-center w-full shadow-sm">
          {{options.okText}}
        </button>
      </span>
      <span class="flex-1"></span>
      <span *ngIf="options.cancelText" class="flex w-full rounded-md shadow-sm sm:w-auto" [ngClass]="{'mt-3 sm:mt-0': options.okText}">
        <button type="button" (click)="pressCancel()"
                class="button-jf button-gray inline-flex justify-center w-full shadow-sm">
          {{options.cancelText}}
        </button>
      </span>
    </div>
  </div>
</div>
