import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** Built-in scalar representing an instant in time */
  Instant: any;
  /** Built-in scalar representing a local date */
  LocalDate: any;
  /** Long type */
  Long: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
  /** UUID String */
  UUID: any;
};









export type AddonEntity = {
  __typename?: 'AddonEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Addon's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Addon's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
};

export type AddonSetDestinationRequirementEntity = {
  __typename?: 'AddonSetDestinationRequirementEntity';
  /** Addon rule's addon set */
  addonSet?: Maybe<AddonSetEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon rule's comparison */
  comparison?: Maybe<Comparison>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon rule's temperature */
  temp?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddonSetEntity = {
  __typename?: 'AddonSetEntity';
  /** Addon set's addons */
  addons?: Maybe<Array<Maybe<AddonEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Addon set's name */
  name?: Maybe<Scalars['String']>;
  /** Addon set's priority */
  priority?: Maybe<Scalars['Long']>;
  /** Addon set's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddonSetSourceRequirementEntity = {
  __typename?: 'AddonSetSourceRequirementEntity';
  /** Addon rule's addon set */
  addonSet?: Maybe<AddonSetEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon rule's comparison */
  comparison?: Maybe<Comparison>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon rule's temperature */
  temp?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  /** Address' validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Address' city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Address' company */
  company?: Maybe<Scalars['String']>;
  /** Address' ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Address' first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Address' last name */
  lastName?: Maybe<Scalars['String']>;
  /** Address' latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Address' first line */
  line1?: Maybe<Scalars['String']>;
  /** Address' last line */
  line2?: Maybe<Scalars['String']>;
  /** Address' longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Address' Magento Id */
  magentoId?: Maybe<Scalars['Long']>;
  /** Address' postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Address' residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Address' state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AffiliateProductListEntity = {
  __typename?: 'AffiliateProductListEntity';
  /** Affiliate list's items */
  affiliateProductListItems?: Maybe<Array<Maybe<AffiliateProductListItemEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Affiliate list's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Affiliate list's name */
  name?: Maybe<Scalars['String']>;
  /** Affiliate list's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Affiliate list's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AffiliateProductListItemEntity = {
  __typename?: 'AffiliateProductListItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Kit item's position */
  position?: Maybe<Scalars['Long']>;
  /** Affiliate product list item's product */
  product?: Maybe<ProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AmazonProfileEntity = {
  __typename?: 'AmazonProfileEntity';
  /** Amazon profile's ASIN */
  asin?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Amazon profile's FBA Seller SKU */
  fbaSellerSku?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Amazon profile's MFN Seller SKU */
  sellerSku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AmazonSalesOrderEntity = {
  __typename?: 'AmazonSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Amazon created at */
  amazonCreatedAt?: Maybe<Scalars['Instant']>;
  /** Amazon updated at */
  amazonUpdatedAt?: Maybe<Scalars['Instant']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type AnimalEntity = {
  __typename?: 'AnimalEntity';
  /** Animal's children */
  children?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Animal's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Animal's name */
  name?: Maybe<Scalars['String']>;
  /** Animal's parent */
  parent?: Maybe<AnimalEntity>;
  /** Animal's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Animal's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Animal's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ApplicationEntity = {
  __typename?: 'ApplicationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BinEntity = {
  __typename?: 'BinEntity';
  /** Bin's human-readable ID */
  binId?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Bin's zone membership */
  zone?: Maybe<ZoneEntity>;
};

export type BlockEntity = {
  __typename?: 'BlockEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Block's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Block's name */
  name?: Maybe<Scalars['String']>;
  /** Block's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BlogEntity = {
  __typename?: 'BlogEntity';
  /** Blog's active */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Blog's topics */
  blogTopics?: Maybe<Array<Maybe<BlogTopicEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Blog's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Blog's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Blog's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Blog's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BlogTopicEntity = {
  __typename?: 'BlogTopicEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Blog topic's name */
  name?: Maybe<Scalars['String']>;
  /** Blog topic's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BomItemEntity = {
  __typename?: 'BomItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** BOM item's component */
  component?: Maybe<SimpleProductEntity>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** BOM item's position */
  position?: Maybe<Scalars['Long']>;
  /** BOM item's quantity */
  quantity?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Brand's name */
  name?: Maybe<Scalars['String']>;
  /** Brand's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Brand's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CareSheetEntity = {
  __typename?: 'CareSheetEntity';
  /** Content's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Content's description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Content's keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Care sheet's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Content's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CarrierRestrictionEntity = {
  __typename?: 'CarrierRestrictionEntity';
  /** Shipping rule's carriers */
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's packagings */
  packagings?: Maybe<Array<Maybe<Packaging>>>;
  /** Shipping rule's services */
  services?: Maybe<Array<Maybe<Service>>>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartCustomItemEntity = {
  __typename?: 'CartCustomItemEntity';
  /** Cart item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item's product price */
  productPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Cart item's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cart item's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
};

export type CartDiscount = {
  __typename?: 'CartDiscount';
  /** Cart discount's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Cart discount's name */
  name?: Maybe<Scalars['String']>;
};

export type CartEntity = {
  __typename?: 'CartEntity';
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Cart's batch item count */
  cartBatchItemsCount?: Maybe<Scalars['Long']>;
  /** Cart's custom items */
  cartCustomItems?: Maybe<Array<Maybe<CartCustomItemEntity>>>;
  /** Cart's discounts */
  cartDiscounts?: Maybe<Array<Maybe<CartDiscount>>>;
  /** Cart's item groups */
  cartItemGroups?: Maybe<Array<Maybe<CartItemGroupEntity>>>;
  /** Cart's items */
  cartItems?: Maybe<Array<Maybe<CartItemEntity>>>;
  /** Cart's item count */
  cartItemsCount?: Maybe<Scalars['Long']>;
  /** Cart's listed items */
  cartListedItems?: Maybe<Array<Maybe<CartListedItemEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Cart's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Cart's email */
  email?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart's phone */
  phone?: Maybe<Scalars['String']>;
  /** Cart's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Cart's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Cart's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Cart's subtotal */
  subtotal?: Maybe<Scalars['BigDecimal']>;
  /** Cart's tax-exempt flag */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Cart's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartItemEntity = {
  __typename?: 'CartItemEntity';
  /** Cart item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Cart item's group */
  cartItemGroup?: Maybe<CartItemGroupEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart item's discount price */
  discountPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Cart item's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item's overridden shipping needs type */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Cart item's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item's regular price */
  regularPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's service type preference */
  serviceTypePreference?: Maybe<ServiceType>;
  /** Cart item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartItemGroupEntity = {
  __typename?: 'CartItemGroupEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item group's discount price */
  discountPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item group's kit product */
  kitProduct?: Maybe<KitProductEntity>;
  /** Cart item group's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item group's regular price */
  regularPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartListedItemEntity = {
  __typename?: 'CartListedItemEntity';
  /** Cart listed item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart listed item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart listed item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Cart listed item's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart listed item's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Cart listed item's listing */
  listing?: Maybe<ListingEntity>;
  /** Cart listed item's overridden shipping needs type */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Cart listed item's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart listed item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart listed item's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart listed item's service type preference */
  serviceTypePreference?: Maybe<ServiceType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  /** Category's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Category's ancestors */
  ancestors?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Category's children */
  children?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Category's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Category's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Category's Magento Id */
  magentoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Category's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Category's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Category's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Category's name */
  name?: Maybe<Scalars['String']>;
  /** Category's parent */
  parent?: Maybe<CategoryEntity>;
  /** Category's path */
  path?: Maybe<Scalars['String']>;
  /** Category's published revision */
  publishedRevision?: Maybe<CategoryRevisionEntity>;
  /** Category's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Category's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Category's slug */
  slug?: Maybe<Scalars['String']>;
  /** Category's thumbnail media */
  thumbnailMedia?: Maybe<MediaEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CategoryResults = {
  __typename?: 'CategoryResults';
  /** Result's category */
  category?: Maybe<CategoryEntity>;
  /** Result's products */
  products?: Maybe<GraphQlPage_ProductEntity>;
};

export type CategoryRevisionEntity = {
  __typename?: 'CategoryRevisionEntity';
  /** Category revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type ChewyProfileEntity = {
  __typename?: 'ChewyProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Chewy profile's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Chewy profile's description */
  description?: Maybe<Scalars['String']>;
  /** Chewy profile's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Chewy profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ChewySalesOrderEntity = {
  __typename?: 'ChewySalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type ClientTokenDetail = {
  __typename?: 'ClientTokenDetail';
  /** Client token */
  clientToken?: Maybe<Scalars['String']>;
};

export type ConfigurableItemEntity = {
  __typename?: 'ConfigurableItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Configurable item's position */
  position?: Maybe<Scalars['Long']>;
  /** Configurable item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ConfigurableProductEntity = {
  __typename?: 'ConfigurableProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Configurable's items */
  configurableItems?: Maybe<Array<Maybe<ConfigurableItemEntity>>>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sells */
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type ContentEntity = {
  __typename?: 'ContentEntity';
  /** Content's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Content's description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Content's keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Content's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ContentMediaEntity = {
  __typename?: 'ContentMediaEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Media's content */
  content?: Maybe<ContentEntity>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Media's type */
  mediaType?: Maybe<MediaType>;
  /** Media's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Media's url */
  url?: Maybe<Scalars['String']>;
};

export type ContentRevisionEntity = {
  __typename?: 'ContentRevisionEntity';
  /** Product revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  /** Dashboard's stats, last week */
  lastWeek?: Maybe<Stats>;
  /** Dashboard's late count */
  lateCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs scheduling count */
  needsSchedulingCount?: Maybe<Scalars['Long']>;
  /** Dashboard's next open date */
  nextOpenDate?: Maybe<Scalars['String']>;
  /** Dashboard's on-hold count */
  onHoldCount?: Maybe<Scalars['Long']>;
  /** Dashboard's pending count */
  pendingCount?: Maybe<Scalars['Long']>;
  /** Dashboard's summaries */
  summaries?: Maybe<Array<Maybe<Summary>>>;
  /** Dashboard's stats, this week */
  thisWeek?: Maybe<Stats>;
  /** Dashboard's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Dashboard's today */
  today?: Maybe<Scalars['String']>;
  /** Dashboard's warehouse */
  warehouse?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = {
  __typename?: 'DepartmentEntity';
  /** Department's children */
  children?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Department's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Department's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Department's name */
  name?: Maybe<Scalars['String']>;
  /** Department's parent */
  parent?: Maybe<DepartmentEntity>;
  /** Department's promotions */
  promotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Department's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Department's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Department's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DestinationRestrictionEntity = {
  __typename?: 'DestinationRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Destination restriction's domestic only */
  domesticOnly?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Destination restriction's prohibited states */
  prohibitedStates?: Maybe<Array<Maybe<State>>>;
  /** Destination restriction's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DiscountEntity = {
  __typename?: 'DiscountEntity';
  /** Discount's active status */
  active?: Maybe<Scalars['Boolean']>;
  /** Discount's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Discount's departments */
  departments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Discount's amount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Discount's type */
  discountType?: Maybe<DiscountType>;
  /** Discount's eligible cart value */
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  /** Discount's eligible coupon code */
  eligibleCouponCode?: Maybe<Scalars['String']>;
  /** Discount's eligible products */
  eligibleProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's eligible trade show */
  eligibleTradeShow?: Maybe<TradeShowEntity>;
  /** Discount's eligible user type */
  eligibleUserType?: Maybe<UserType>;
  /** Discount's end date */
  endDate?: Maybe<Scalars['Instant']>;
  /** Discount's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Discount's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's name */
  name?: Maybe<Scalars['String']>;
  /** Discount's permit stacking status */
  permitStacking?: Maybe<Scalars['Boolean']>;
  /** Discount's slug */
  slug?: Maybe<Scalars['String']>;
  /** Discount's start date */
  startDate?: Maybe<Scalars['Instant']>;
  /** Discount's teaser */
  teaser?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DropshipInventoryDetails = {
  __typename?: 'DropshipInventoryDetails';
  /** Result's dropshipper profile */
  dropshipperProfile?: Maybe<DropshipperProfileEntity>;
  /** Result's warehouse quantity available */
  warehouseQuantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  warehouseQuantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity unshipped */
  warehouseQuantityUnshipped?: Maybe<Scalars['Long']>;
};

export type DropshipperProfileEntity = {
  __typename?: 'DropshipperProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Dropshipper's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Profile's dropshipper */
  dropshipper?: Maybe<PartnerEntity>;
  /** Dropshipper's SKU */
  dropshipperSku?: Maybe<Scalars['String']>;
  /** Dropshipper's enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Dropshipper's title */
  title?: Maybe<Scalars['String']>;
  /** Dropshipper's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GeolocationType = {
  __typename?: 'GeolocationType';
  /** Geolocation's Cit */
  city?: Maybe<Scalars['String']>;
  /** Geolocation's Country */
  country?: Maybe<Scalars['String']>;
  /** Geolocation's IP Address */
  ip?: Maybe<Scalars['String']>;
  /** Geolocation's Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Geolocation's Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Geolocation's State */
  state?: Maybe<Scalars['String']>;
  /** Geolocation's Zip */
  zip?: Maybe<Scalars['String']>;
};

export type GiftCardEntity = {
  __typename?: 'GiftCardEntity';
  /** Gift card's activator */
  activatedBy?: Maybe<Scalars['String']>;
  /** Gift card's balance */
  balance?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Gift card's code */
  code?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Gift card's pin */
  pin?: Maybe<Scalars['String']>;
  /** Gift card's transactions */
  transactions?: Maybe<Array<Maybe<GiftCardTransactionEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GiftCardTransactionEntity = {
  __typename?: 'GiftCardTransactionEntity';
  /** Gift card transaction's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Gift card transaction's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Gift card transaction's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Gift card transaction's type */
  transactionType?: Maybe<GiftCardTransactionType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GraphQlPage_AnimalEntity = {
  __typename?: 'GraphQLPage_AnimalEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BlockEntity = {
  __typename?: 'GraphQLPage_BlockEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BlockEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BlogEntity = {
  __typename?: 'GraphQLPage_BlogEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BlogEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BrandEntity = {
  __typename?: 'GraphQLPage_BrandEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BrandEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_CareSheetEntity = {
  __typename?: 'GraphQLPage_CareSheetEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_CategoryEntity = {
  __typename?: 'GraphQLPage_CategoryEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ConfigurableProductEntity = {
  __typename?: 'GraphQLPage_ConfigurableProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ConfigurableProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DepartmentEntity = {
  __typename?: 'GraphQLPage_DepartmentEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DiscountEntity = {
  __typename?: 'GraphQLPage_DiscountEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DropshipInventoryDetails = {
  __typename?: 'GraphQLPage_DropshipInventoryDetails';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DropshipInventoryDetails>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DropshipperProfileEntity = {
  __typename?: 'GraphQLPage_DropshipperProfileEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DropshipperProfileEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_GiftCardEntity = {
  __typename?: 'GraphQLPage_GiftCardEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<GiftCardEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_KitProductEntity = {
  __typename?: 'GraphQLPage_KitProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<KitProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ListedProductEntity = {
  __typename?: 'GraphQLPage_ListedProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ListedProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_MediaEntity = {
  __typename?: 'GraphQLPage_MediaEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<MediaEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PartnerEntity = {
  __typename?: 'GraphQLPage_PartnerEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ProductEntity = {
  __typename?: 'GraphQLPage_ProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ProfitabilitySummary = {
  __typename?: 'GraphQLPage_ProfitabilitySummary';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ProfitabilitySummary>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PromotionEntity = {
  __typename?: 'GraphQLPage_PromotionEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PurchaseOrderEntity = {
  __typename?: 'GraphQLPage_PurchaseOrderEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PurchaseOrderEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PurchaseRequestEntity = {
  __typename?: 'GraphQLPage_PurchaseRequestEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PurchaseRequestEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ReviewEntity = {
  __typename?: 'GraphQLPage_ReviewEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ReviewEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SalesOrderEntity = {
  __typename?: 'GraphQLPage_SalesOrderEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SalesOrderEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ShipmentEntity = {
  __typename?: 'GraphQLPage_ShipmentEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ShippingRuleSetEntity = {
  __typename?: 'GraphQLPage_ShippingRuleSetEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ShippingRuleSetEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SimpleProductEntity = {
  __typename?: 'GraphQLPage_SimpleProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SubscriptionEntity = {
  __typename?: 'GraphQLPage_SubscriptionEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SubscriptionEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SupplierEntity = {
  __typename?: 'GraphQLPage_SupplierEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SupplierEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_TeamEntity = {
  __typename?: 'GraphQLPage_TeamEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<TeamEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_TradeShowEntity = {
  __typename?: 'GraphQLPage_TradeShowEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_UserEntity = {
  __typename?: 'GraphQLPage_UserEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<UserEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_WarehouseEntity = {
  __typename?: 'GraphQLPage_WarehouseEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type HoldLocation = {
  __typename?: 'HoldLocation';
  /** Hold location's city */
  city?: Maybe<Scalars['String']>;
  /** Hold location's company */
  company?: Maybe<Scalars['String']>;
  /** Hold location's country */
  country?: Maybe<Scalars['String']>;
  /** Hold location's line 1 */
  line1?: Maybe<Scalars['String']>;
  /** Hold location's line 2 */
  line2?: Maybe<Scalars['String']>;
  /** Hold location's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Hold location's state */
  state?: Maybe<Scalars['String']>;
};

export type InventoryConsistency = {
  __typename?: 'InventoryConsistency';
  /** Result's lost sales */
  lostSales?: Maybe<Scalars['BigDecimal']>;
  /** Result's product */
  product?: Maybe<SimpleProductEntity>;
  /** Result's warehouse quantity available */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's stock-out rate */
  stockOutRate?: Maybe<Scalars['Float']>;
  /** Result's consumption rate */
  weeklyConsumptionRate?: Maybe<Scalars['Float']>;
};

export type InventoryDetails = {
  __typename?: 'InventoryDetails';
  /** Result's product */
  product?: Maybe<SimpleProductEntity>;
  /** Result's warehouse quantity available */
  warehouseQuantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  warehouseQuantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity unshipped */
  warehouseQuantityUnshipped?: Maybe<Scalars['Long']>;
  /** Result's warehouse run out days, 50% confidence */
  warehouseRunOutDays50?: Maybe<Scalars['Float']>;
  /** Result's warehouse run out days, 85% confidence */
  warehouseRunOutDays85?: Maybe<Scalars['Float']>;
  /** Result's warehouse run out days, 97% confidence */
  warehouseRunOutDays97?: Maybe<Scalars['Float']>;
  /** Result's warehouse weekly consumption rate */
  warehouseWeeklyConsumptionRate?: Maybe<Scalars['Float']>;
  /** Result's warehouse weekly consumption variance */
  warehouseWeeklyConsumptionVariance?: Maybe<Scalars['Float']>;
};

export type InventoryHistory = {
  __typename?: 'InventoryHistory';
  /** Result's quantity changed */
  changeQuantity?: Maybe<Scalars['Long']>;
  /** Result's change reason */
  changeType?: Maybe<InventoryChangeType>;
  /** Result's quantity available for sale */
  quantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
};

export type InventoryQuantityCacheEntity = {
  __typename?: 'InventoryQuantityCacheEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cache's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Cache's quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Cache's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cache's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type InventoryStatsCacheEntity = {
  __typename?: 'InventoryStatsCacheEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cache's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cache's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Cache's weekly consumption rate */
  weeklyConsumptionRate?: Maybe<Scalars['Float']>;
  /** Cache's weekly consumption variance */
  weeklyConsumptionVariance?: Maybe<Scalars['Float']>;
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  /** Journal entry's accounts receivable */
  accountsReceivable?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Journal entry's product refunds */
  productRefunds?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's product sales */
  productSales?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's sales tax expenses */
  salesTaxExpense?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's sales tax liability */
  salesTaxLiability?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's shipping refunds */
  shippingRefunds?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's shipping sales */
  shippingSales?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
};

export type KitItemEntity = {
  __typename?: 'KitItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Kit item's position */
  position?: Maybe<Scalars['Long']>;
  /** Kit item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Kit item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type KitProductEntity = {
  __typename?: 'KitProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sells */
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Kit's items */
  kitItems?: Maybe<Array<Maybe<KitItemEntity>>>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  /** Line item's line amount */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Line item's name */
  name?: Maybe<Scalars['String']>;
  /** Line item's order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Line item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Line item's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Line item's unit amount */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
};

export type ListedProductEntity = {
  __typename?: 'ListedProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Listed product's bins */
  bins?: Maybe<Array<Maybe<BinEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sells */
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Listed product's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Listed product's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's listings */
  listings?: Maybe<Array<Maybe<ListingEntity>>>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Listed product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Listed product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Listed product's warehouses */
  warehouses?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Listed product's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's width */
  width?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's zones */
  zones?: Maybe<Array<Maybe<ZoneEntity>>>;
};

export type ListingEntity = {
  __typename?: 'ListingEntity';
  /** Listing's birth date */
  birthDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Listing's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Listing's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Listing's identifier */
  identifier?: Maybe<Scalars['String']>;
  /** Listing's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Listing's sex */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Listing's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Listing's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Listing's sex */
  sex?: Maybe<SexType>;
  /** Listing's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Listing's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Listing's weight date */
  weightDate?: Maybe<Scalars['LocalDate']>;
  /** Listing's width */
  width?: Maybe<Scalars['BigDecimal']>;
};

export type LiveArrivalGuaranteeEntity = {
  __typename?: 'LiveArrivalGuaranteeEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Live arrival rule's max temp */
  maxTemp?: Maybe<Scalars['Int']>;
  /** Live arrival rule's min temp */
  minTemp?: Maybe<Scalars['Int']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Live arrival rule's transit time type */
  transitTimeType?: Maybe<TransitTimeType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type MagentoSalesOrderEntity = {
  __typename?: 'MagentoSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Magento created at */
  magentoCreatedAt?: Maybe<Scalars['Instant']>;
  /** Magento order status */
  magentoStatus?: Maybe<Scalars['String']>;
  /** Magento updated at */
  magentoUpdatedAt?: Maybe<Scalars['Instant']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type MediaEntity = {
  __typename?: 'MediaEntity';
  /** Media's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Media's keywords */
  keywords?: Maybe<Scalars['String']>;
  /** Media's product medias */
  productMedias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Media's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Media's url */
  url?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  /** Removes an listed product */
  listedProductRemove?: Maybe<Scalars['Boolean']>;
  /** Refreshes a sales order (with Amazon) by entity id */
  amazonSalesOrderRefresh?: Maybe<AmazonSalesOrderEntity>;
  /** Handles an enrollment request */
  partnerJoin?: Maybe<Scalars['Boolean']>;
  /** Uploads an image and adds it to the kit */
  kitProductUploadImage?: Maybe<KitProductEntity>;
  /** Clears the cart's tax exempt flag */
  assistedCartClearTaxExempt?: Maybe<CartEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderOnlineRefund?: Maybe<SalesOrderEntity>;
  /** Triggers a re-sync of updated orders with Chewy */
  maintenanceChewySyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Add cross sells to a kit product */
  kitProductAddCrossSellProducts?: Maybe<KitProductEntity>;
  /** Filters to shipments with a simple product */
  shipmentFilterUnshippedBySimpleProduct?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Validates the address, updates it if necessary */
  shipmentValidateAddress?: Maybe<ShipmentEntity>;
  /** Adds user to marketing list */
  marketingSubscribe: Scalars['Boolean'];
  /** Updates Five Points Inventory */
  maintenanceFivePointsInventory?: Maybe<Scalars['Boolean']>;
  /** Clears the service level */
  assistedSubscriptionClearServiceLevel?: Maybe<SubscriptionEntity>;
  /** Sets a configurable product's visible flag */
  configurableProductSetVisible?: Maybe<ConfigurableProductEntity>;
  /** Add a price tier to a kit product */
  kitProductAddPriceTier?: Maybe<KitProductEntity>;
  /** Sets the payment token */
  subscriptionSetPaymentToken?: Maybe<SubscriptionEntity>;
  /** Resends a cancel notification */
  shipmentResendCancelNotification?: Maybe<ShipmentEntity>;
  /** Vaults a payment method for the specified user. */
  assistedPaymentMethodCreate?: Maybe<PaymentMethodDetail>;
  /** Gets inventory details for a simple product */
  inventoryGetDetails?: Maybe<InventoryDetails>;
  /** Adds an existing image to the blog */
  blogAddImage?: Maybe<BlogEntity>;
  /** Looks up weather for shipment */
  shipmentLookupWeather?: Maybe<Array<Maybe<Weather>>>;
  /** Sets the cart's tradeshow */
  assistedCartSetTradeshow?: Maybe<CartEntity>;
  /** Sets a simple product's slug */
  simpleProductSetSlug?: Maybe<SimpleProductEntity>;
  /** Set the position of a product list item */
  affiliateSetProductListItemPosition?: Maybe<AffiliateProductListEntity>;
  /** Splits a shipment into two smaller shipments */
  shipmentSplit?: Maybe<ShipmentEntity>;
  /** Sets active flag */
  blogSetActive?: Maybe<BlogEntity>;
  /** Generates a packing list PDF */
  shipmentGeneratePackingList?: Maybe<Scalars['String']>;
  /** Adds inventory for a simple product */
  inventoryAddDetails?: Maybe<InventoryDetails>;
  /** Changes quantity of selected simple product in user's cart */
  assistedCartChangeQuantitySimpleProduct?: Maybe<CartEntity>;
  /** Triggers a re-sync of updated orders with Walmart */
  maintenanceWalmartSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Create */
  amazonProfileCreate?: Maybe<AmazonProfileEntity>;
  /** Provides cart info for current user */
  assistedCartInfo?: Maybe<CartEntity>;
  /** Updates an item's quantity */
  assistedSubscriptionSetItemQuantity?: Maybe<SubscriptionEntity>;
  /** Removes a vaulted payment method for the specified user. */
  assistedPaymentMethodDelete: Scalars['Boolean'];
  /** Cancels a shipment within an order */
  shipmentCancel?: Maybe<ShipmentEntity>;
  /** Syncs purchase orders */
  maintenanceSalesOrderIdentifyHouseholds?: Maybe<Scalars['Boolean']>;
  /** Closes a purchase order */
  purchaseOrderClose?: Maybe<PurchaseOrderEntity>;
  /** Uploads an image */
  mediaUploadImage?: Maybe<MediaEntity>;
  /** Sets a kit product's featured flag */
  kitProductSetFeatured?: Maybe<KitProductEntity>;
  /** Create */
  chewyProfileCreate?: Maybe<ChewyProfileEntity>;
  /** Sets a category's image */
  categorySetImage?: Maybe<CategoryEntity>;
  /** Update an existing discount */
  discountUpdate?: Maybe<DiscountEntity>;
  /** Tracks a shipment */
  shipmentTrackByHash?: Maybe<Array<Maybe<TrackingEvent>>>;
  /** Returns an authorization code */
  authorizationAuthCode?: Maybe<Scalars['String']>;
  /** Updates an existing block */
  blockUpdate?: Maybe<BlockEntity>;
  /** Sets an listed product's special price */
  listedProductSetSpecialPrice?: Maybe<ListedProductEntity>;
  /** Reschedule a shipment */
  shipmentReschedule?: Maybe<ShipmentEntity>;
  /** Adds whatever portion of a kit product is actually available to user's cart */
  cartAddPartialKitProduct?: Maybe<CartEntity>;
  /** Summarizes a product's description */
  productSummarize?: Maybe<Scalars['String']>;
  /** Removes a vaulted payment method for the current user. */
  checkoutDeletePaymentMethod: Scalars['Boolean'];
  /** Schedule a shipment for today or a future date */
  assistedScheduledShipmentSchedule?: Maybe<ShipmentEntity>;
  /** Approves a purchase request */
  purchaseRequestApprove?: Maybe<PurchaseRequestEntity>;
  /** Custom refunds an order. */
  salesOrderCustomRefundSummarize?: Maybe<RefundEntity>;
  /** Sets a partner's reseller shipping rate mode */
  resellerSetResellerShippingRateMode?: Maybe<PartnerEntity>;
  /** Assigns a department to a product */
  productSetDepartment?: Maybe<ProductEntity>;
  /** Sets a configurable product's slug */
  configurableProductSetSlug?: Maybe<ConfigurableProductEntity>;
  /** Updates an existing tradeshow */
  tradeShowUpdateShow?: Maybe<TradeShowEntity>;
  /** Removes a warehouse from a simple product */
  simpleProductRemoveWarehouse?: Maybe<SimpleProductEntity>;
  /** Creates a ticket with customer service */
  scheduledShipmentContactCustomerService?: Maybe<Scalars['Boolean']>;
  /** Add products to a blog */
  blogAddProducts?: Maybe<BlogEntity>;
  /** Removes eligible products from the discount */
  discountRemoveEligibleProducts?: Maybe<DiscountEntity>;
  /** Sends the tradeshow report */
  tradeShowSendReport?: Maybe<Scalars['Boolean']>;
  /** Creates a new tradeshow */
  tradeShowCreateShow?: Maybe<TradeShowEntity>;
  /** Clears the service level for a cart item */
  assistedCartClearServiceTypePreference?: Maybe<CartEntity>;
  /** Triggers a re-sync of all kits with Magento */
  maintenanceMagentoSyncAllKits?: Maybe<Scalars['Boolean']>;
  /** Generates a client token for specified user. */
  assistedPaymentMethodGetClientToken?: Maybe<ClientTokenDetail>;
  /** Refreshes a sales order (with Magento) by entity id */
  magentoSalesOrderRefresh?: Maybe<MagentoSalesOrderEntity>;
  /** Sets the active flag */
  promotionSetActive?: Maybe<PromotionEntity>;
  /** Reorders off of a past sales order */
  cartReorder?: Maybe<CartEntity>;
  /** Refreshes a sales order (with Magento) by entity id */
  magentoSalesOrderRefreshAsync: Scalars['Boolean'];
  /** Create a subscription for a new item */
  assistedSubscriptionCreate?: Maybe<SubscriptionEntity>;
  /** Triggers a re-sync of MFN inventory with Amazon */
  maintenanceAmazonUpdateInventory?: Maybe<Scalars['Boolean']>;
  /** Gets items eligible for refund. */
  salesOrderGetRefundableAmounts?: Maybe<RefundableAmounts>;
  /** Generates a commercial invoice PDF */
  shipmentGenerateCommercialInvoice?: Maybe<Scalars['String']>;
  /** Creates a web flow for Josh's Frogs Pay checkout */
  cartAuthorizeUrl?: Maybe<Scalars['String']>;
  /** Removes all quantity of selected simple product from user's cart */
  assistedCartRemoveAllSimpleProduct?: Maybe<CartEntity>;
  /** Removes all quantity of selected simple product from user's cart */
  cartRemoveAllSimpleProduct?: Maybe<CartEntity>;
  /** Sets a simple product's length */
  simpleProductSetLength?: Maybe<SimpleProductEntity>;
  /** Sets the sales order's email */
  salesOrderSetEmail?: Maybe<SalesOrderEntity>;
  /** Sets the sequence of a product media */
  simpleProductSetProductMediaSequence?: Maybe<SimpleProductEntity>;
  /** Clears the carrier preference for a cart item */
  assistedCartClearCarrierPreference?: Maybe<CartEntity>;
  /** Sets a partner's dropshipper flag */
  partnerSetDropshipper?: Maybe<PartnerEntity>;
  /** Clears the cart's email */
  assistedCartClearEmail?: Maybe<CartEntity>;
  /** Sanitize a care sheet */
  careSheetSanitize?: Maybe<CareSheetEntity>;
  /** Sets the service level */
  subscriptionSetServiceLevel?: Maybe<SubscriptionEntity>;
  /** Clears the service level */
  subscriptionClearServiceLevel?: Maybe<SubscriptionEntity>;
  /** Creates a partner */
  partnerCreate?: Maybe<PartnerEntity>;
  /** Sets active flag */
  categorySetActive?: Maybe<CategoryEntity>;
  /** Triggers sync of products with Poynt */
  maintenancePoyntSyncProducts?: Maybe<Scalars['Boolean']>;
  /** Deletes a brand */
  brandDelete: Scalars['Boolean'];
  /** Recomputes shipping estimates for an listed product */
  listedProductRecomputeShippingEstimate?: Maybe<ListedProductEntity>;
  /** Sets a delivery date preference for a cart item */
  cartClearDeliveryDatePreference?: Maybe<CartEntity>;
  /** Updates Drop Shipper Inventory */
  maintenanceDropshipperInventory?: Maybe<Scalars['Boolean']>;
  /** Creates a brand */
  brandCreate?: Maybe<BrandEntity>;
  /** Clears the shipping address */
  assistedCartClearShippingAddress?: Maybe<CartEntity>;
  /** Clears the carrier preference */
  assistedSubscriptionClearCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Updates a scheduled shipment's address */
  scheduledShipmentUpdateAddressByHash?: Maybe<ShipmentEntity>;
  /** Generates a packing list PDF */
  dropshipShipmentGeneratePackingList?: Maybe<Scalars['String']>;
  /** Creates a simple product */
  simpleProductCreate?: Maybe<SimpleProductEntity>;
  /** Remove a product from a care sheet */
  careSheetRemoveProduct?: Maybe<CareSheetEntity>;
  /** Sets the carrier preference */
  assistedSubscriptionSetCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Sets the supplier's email */
  supplierClearEmail?: Maybe<SupplierEntity>;
  /** Triggers a re-sync of updated orders with Amazon */
  maintenanceAmazonSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Remove a price tier from a simple product */
  simpleProductRemovePriceTier?: Maybe<SimpleProductEntity>;
  /** Update an existing promotion */
  promotionUpdate?: Maybe<PromotionEntity>;
  /** Sends a password reset email */
  userSendPasswordReset?: Maybe<Scalars['Boolean']>;
  /** Sets an affiliate's slug */
  affiliateSetSlug?: Maybe<PartnerEntity>;
  /** Sets a care sheet's published revision */
  careSheetSetPublishedRevision?: Maybe<CareSheetEntity>;
  /** Pushes a simple product to Poynt */
  simpleProductPush: Scalars['Boolean'];
  /** Triggers a re-sync of packaging orders with Magento */
  maintenanceMagentoSyncPackaging?: Maybe<Scalars['Boolean']>;
  /** Provides cart info for the user's ID */
  assistedCartLookup?: Maybe<CartEntity>;
  /** Sets the shipping zip code, as a shortcut for shipping rates */
  cartSetShippingZip?: Maybe<CartEntity>;
  /** Create */
  petcoSdProfileCreate?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Remove a cross sell from an listed product */
  listedProductRemoveCrossSellProduct?: Maybe<ListedProductEntity>;
  /** Changes quantity of selected simple product in user's cart */
  cartChangeQuantitySimpleProduct?: Maybe<CartEntity>;
  /** Removes a component item from the simple product */
  simpleProductRemoveComponentItem?: Maybe<SimpleProductEntity>;
  /** Adds a payment terms payment to an order */
  salesOrderAddPaymentTermsPayment?: Maybe<SalesOrderEntity>;
  /** Sets an listed product's price */
  listedProductSetPrice?: Maybe<ListedProductEntity>;
  /** Clears the carrier preference */
  subscriptionClearCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Schedule a shipment for today or a future date */
  scheduledShipmentSchedule?: Maybe<ShipmentEntity>;
  /** Sets the billing address to one of the user's addresses */
  assistedCartSetBillingAddressById?: Maybe<CartEntity>;
  /** Sets a simple product's cost */
  simpleProductSetCost?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the configurable product */
  configurableProductRemoveProductMedia?: Maybe<ConfigurableProductEntity>;
  /** Creates a listing for a listed product */
  listedProductCreateListing?: Maybe<ListedProductEntity>;
  /** Triggers a fix to assign the default payment method to subscriptions */
  maintenanceFixSubscriptionDefaultPayments?: Maybe<Scalars['Boolean']>;
  /** Update's a user's email address */
  userUpdateEmail?: Maybe<UserEntity>;
  /** Sets a simple product's vendor ref */
  simpleProductSetVendorRef?: Maybe<SimpleProductEntity>;
  /** Summarizes the cart's totals */
  assistedCartSummarize?: Maybe<SalesOrderEntity>;
  /** Sets the carrier preference */
  subscriptionSetCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Clears the cart's phone */
  cartClearPhone?: Maybe<CartEntity>;
  /** Remove a price tier from an listed product */
  listedProductRemovePriceTier?: Maybe<ListedProductEntity>;
  /** Sets a delivery date preference for a cart item */
  assistedCartClearDeliveryDatePreference?: Maybe<CartEntity>;
  /** Sets a kit product's published revision */
  kitProductSetPublishedRevision?: Maybe<KitProductEntity>;
  /** Subscribe to a new item */
  assistedSubscriptionAddItem?: Maybe<SubscriptionEntity>;
  /** Generates a shipment dashboard */
  shipmentDashboard?: Maybe<Dashboard>;
  /** Sets a partner's affiliate flag */
  partnerSetAffiliate?: Maybe<PartnerEntity>;
  /** Sets a partner's email */
  partnerSetEmail?: Maybe<PartnerEntity>;
  /** Sets an listed product's SKU */
  listedProductSetSku?: Maybe<ListedProductEntity>;
  /** Calculates the implicit products a discount */
  discountEvaluateImplicitProducts?: Maybe<DiscountEntity>;
  /** Triggers a re-index of hibernate cache */
  maintenanceCacheFlushAll?: Maybe<Scalars['Boolean']>;
  /** Denies a purchase request */
  purchaseRequestDeny?: Maybe<PurchaseRequestEntity>;
  /** Returns shipping estimates for an listed product */
  listedProductShippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Sets the sequence of a product media */
  configurableProductSetProductMediaSequence?: Maybe<ConfigurableProductEntity>;
  /** Adds explicit products to the discount */
  discountAddExplicitProducts?: Maybe<DiscountEntity>;
  /** Ships a multi-piece shipment */
  shipmentShipMultiPiece?: Maybe<ShipmentEntity>;
  /** Enrolls a workstation for use with AutoPrint */
  autoprintEnrollWorkstation?: Maybe<WorkstationEntity>;
  /** Refreshes a sales order (with Walmart) by purchase order id */
  walmartSalesOrderRefresh?: Maybe<WalmartSalesOrderEntity>;
  /** Generates an invoice PDF */
  salesOrderGenerateInvoice?: Maybe<Scalars['String']>;
  /** Reprocess a shipment */
  shipmentReprocess?: Maybe<ShipmentEntity>;
  /** Remove a price tier from a kit product */
  kitProductRemovePriceTier?: Maybe<KitProductEntity>;
  /** Redirects based on a path */
  redirectFindByPath?: Maybe<Scalars['String']>;
  /** Removes a preparation from a product */
  preppedProductsRemovePreparation?: Maybe<PreparationSummary>;
  /** Creates a ticket with customer service */
  scheduledShipmentContactCustomerServiceByHash?: Maybe<Scalars['Boolean']>;
  /** Sets an listed product's UPC */
  listedProductSetUpc?: Maybe<ListedProductEntity>;
  /** Sets an listed product's title */
  listedProductSetTitle?: Maybe<ListedProductEntity>;
  /** Reroutes shipments from a queue */
  autoprintReroute: Scalars['Boolean'];
  /** Calculates the implicit products a promotion */
  promotionEvaluateImplicitProducts?: Maybe<PromotionEntity>;
  /** Create a subscription for a new item */
  subscriptionCreate?: Maybe<SubscriptionEntity>;
  /** Mocks a partial refund of an order. */
  salesOrderPartialRefundSummarize?: Maybe<RefundEntity>;
  /** Clears the cart's phone */
  assistedCartClearPhone?: Maybe<CartEntity>;
  /** Sets an listed product's weight */
  listedProductSetWeight?: Maybe<ListedProductEntity>;
  /** Set a simple product's map price */
  simpleProductSetMapPrice?: Maybe<SimpleProductEntity>;
  /** Clears a supplier from a simple product */
  simpleProductClearSupplier?: Maybe<SimpleProductEntity>;
  /** Updates an item's frequency */
  assistedSubscriptionSetItemFrequency?: Maybe<SubscriptionEntity>;
  /** Sets the cart's email */
  cartSetEmail?: Maybe<CartEntity>;
  /** Sets the sequence of a listed product media */
  listedProductSetProductMediaSequence?: Maybe<ListedProductEntity>;
  /** Assigns a pos terminal to a tradeshow */
  tradeShowAssignTerminal?: Maybe<TradeShowEntity>;
  /** Sets a carrier preference for a cart item */
  cartSetCarrierPreference?: Maybe<CartEntity>;
  /** Sets a product as requiring preparation */
  simpleProductSetRequiresPrep?: Maybe<SimpleProductEntity>;
  /** Create */
  petcoDsProfileCreate?: Maybe<PetcoDropshipProfileEntity>;
  /** Custom refunds an order. */
  salesOrderCustomRefund?: Maybe<SalesOrderEntity>;
  /** Sets a simple product's featured flag */
  simpleProductSetFeatured?: Maybe<SimpleProductEntity>;
  /** Confirm user's email */
  userConfirmEmail?: Maybe<Scalars['Boolean']>;
  /** Deletes a care sheet */
  careSheetDelete: Scalars['Boolean'];
  /** Adds a check payment to an order */
  salesOrderAddCheckPayment?: Maybe<SalesOrderEntity>;
  /** Change a user's password */
  userChangePasswordByHash?: Maybe<Scalars['Boolean']>;
  /** Removes all quantity of selected listed product from user's cart */
  cartRemoveAllListedProduct?: Maybe<CartEntity>;
  /** Sets the shipping zip code, as a shortcut for shipping rates */
  assistedCartSetShippingZip?: Maybe<CartEntity>;
  /** Set the html content of a promotion */
  promotionSetHtmlContent?: Maybe<PromotionEntity>;
  /** Add an item to this kit */
  kitProductAddKitItem?: Maybe<KitProductEntity>;
  /** Sets the supplier's email */
  supplierSetEmail?: Maybe<SupplierEntity>;
  /** Triggers a re-sync of updated orders with Magento */
  maintenanceMagentoSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Tests a workstation for use with AutoPrint */
  autoprintTestWorkstation?: Maybe<WorkstationEntity>;
  /** Adds a purchase request item */
  purchaseRequestAddItem?: Maybe<PurchaseRequestEntity>;
  /** Adds explicit products to the promotion */
  promotionAddExplicitProducts?: Maybe<PromotionEntity>;
  /** Completes checkout and processes payment. */
  assistedCartCheckout?: Maybe<SalesOrderEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderCancelByHash?: Maybe<SalesOrderEntity>;
  /** Sets the cart's tax exempt flag */
  cartSetTaxExempt?: Maybe<CartEntity>;
  /** Sets the cart's contact information */
  cartSetContact?: Maybe<CartEntity>;
  /** Sets a carrier preference for a cart item */
  cartClearCarrierPreference?: Maybe<CartEntity>;
  /** Assigns a brand to an listed product */
  listedProductSetBrand?: Maybe<ListedProductEntity>;
  /** Replenishes a new gift card, with amount */
  giftCardReload?: Maybe<GiftCardEntity>;
  /** Reindexes all products */
  maintenanceReindexProducts?: Maybe<Scalars['Boolean']>;
  /** Sets the cart's tradeshow */
  cartSetTradeshow?: Maybe<CartEntity>;
  /** Sets the cart's contact information */
  assistedCartSetContact?: Maybe<CartEntity>;
  /** Sets a listing's birth date */
  listedProductSetListingBirthDate?: Maybe<ListingEntity>;
  /** Update's a user's admin status */
  userUpdateAdmin?: Maybe<UserEntity>;
  /** Verifies an existing address */
  addressVerifyRaw?: Maybe<AddressEntity>;
  /** Sets the sequence of a product media */
  kitProductSetProductMediaSequence?: Maybe<KitProductEntity>;
  /** Set the html content of a discount */
  discountSetHtmlContent?: Maybe<DiscountEntity>;
  /** Returns the tradeshow report */
  tradeShowReport?: Maybe<ShowReport>;
  /** Generates an invoice PDF from a hash */
  salesOrderGenerateInvoiceByHash?: Maybe<Scalars['String']>;
  /** Uploads an image and adds it to the configurable product */
  configurableProductUploadImage?: Maybe<ConfigurableProductEntity>;
  /** Refreshes a sales order (with Petco SD) by entity id */
  petcoSdSalesOrderRefresh?: Maybe<PetcoStoreDirectSalesOrderEntity>;
  /** Clears the shipping address */
  cartClearShippingAddress?: Maybe<CartEntity>;
  /** Sets a delivery date preference for a cart item */
  cartSetDeliveryDatePreference?: Maybe<CartEntity>;
  /** Assigns dimensions to an listed product */
  listedProductSetDimensions?: Maybe<ListedProductEntity>;
  /** Marks the purchase request as received */
  purchaseRequestReceive?: Maybe<PurchaseRequestEntity>;
  /** Sets the category's name */
  categorySetName?: Maybe<CategoryEntity>;
  /** Sets an listed product's published revision */
  listedProductSetPublishedRevision?: Maybe<ListedProductEntity>;
  /** Gets status if customer is already signed up */
  marketingStatus: Scalars['Boolean'];
  /** Reindexes all blogs */
  maintenanceSearchReindexBlog?: Maybe<Scalars['Boolean']>;
  /** Creates a supplier */
  supplierCreate?: Maybe<SupplierEntity>;
  /** Clears the cart's tradeshow */
  cartClearTradeshow?: Maybe<CartEntity>;
  /** Sets a listing's comments */
  listedProductSetListingComments?: Maybe<ListingEntity>;
  /** Sets a simple product's height */
  simpleProductSetHeight?: Maybe<SimpleProductEntity>;
  /** Verifies an existing address */
  addressVerify?: Maybe<AddressEntity>;
  /** Sets the supplier's order cycle */
  supplierSetOrderCycle?: Maybe<SupplierEntity>;
  /** Sets an affiliate's attribution period */
  affiliateSetAttributionPeriod?: Maybe<PartnerEntity>;
  /** Gets inventory history for a simple product */
  inventoryReportGenerate?: Maybe<Array<Maybe<InventoryConsistency>>>;
  /** Sets the cart's phone */
  assistedCartSetPhone?: Maybe<CartEntity>;
  /** Creates a new category */
  categoryCreate?: Maybe<CategoryEntity>;
  /** Refreshes a simple product (with Magento) by sku */
  simpleProductRefreshBySku?: Maybe<SimpleProductEntity>;
  /** Sets the cart's affiliate */
  cartSetAffiliate?: Maybe<CartEntity>;
  /** Removes a kit product */
  kitProductRemove?: Maybe<Scalars['Boolean']>;
  /** Updates a shipment's address */
  shipmentUpdateAddress?: Maybe<ShipmentEntity>;
  /** Sets a component item's position */
  simpleProductSetComponentItemPosition?: Maybe<SimpleProductEntity>;
  /** Creates a purchase request */
  purchaseRequestCreate?: Maybe<PurchaseRequestEntity>;
  /** Subscribe to a new item */
  subscriptionAddItem?: Maybe<SubscriptionEntity>;
  /** Sets an affiliate's commission */
  affiliateSetCommission?: Maybe<PartnerEntity>;
  /** Assigns a supplier to a simple product */
  simpleProductSetSupplier?: Maybe<SimpleProductEntity>;
  /** Add an animal to a kit product */
  kitProductAddAnimal?: Maybe<KitProductEntity>;
  /** Summarizes the cart's totals */
  cartSummarize?: Maybe<SalesOrderEntity>;
  /** Sets a department's parent */
  departmentSetParent?: Maybe<DepartmentEntity>;
  /** Sets a listing's weight */
  listedProductSetListingWeight?: Maybe<ListingEntity>;
  /** Add blog topics to a blog */
  blogAddBlogTopics?: Maybe<BlogEntity>;
  /** Gets inventory history for a simple product */
  inventoryGetHistory?: Maybe<Array<Maybe<InventoryHistory>>>;
  /** Remove a cross sell from a kit product */
  kitProductRemoveCrossSellProduct?: Maybe<KitProductEntity>;
  /** Deletes a discount */
  discountDelete?: Maybe<Scalars['Boolean']>;
  /** Sets an listed product's slug */
  listedProductSetSlug?: Maybe<ListedProductEntity>;
  /** Clears a brand from an listed product */
  listedProductClearBrand?: Maybe<ListedProductEntity>;
  /** Refreshes a sales order (with Chewy) by entity id */
  chewySalesOrderRefresh?: Maybe<ChewySalesOrderEntity>;
  /** Sets inventory for a simple product */
  inventorySetDetails?: Maybe<InventoryDetails>;
  /** Set the html content for an affiliate's product list */
  affiliateSetProductListHtmlContent?: Maybe<AffiliateProductListEntity>;
  /** Deletes a blog */
  blogDelete: Scalars['Boolean'];
  /** Sets a listing's price */
  listedProductSetListingPrice?: Maybe<ListingEntity>;
  /** Removes explicit products from the discount */
  discountRemoveExplicitProducts?: Maybe<DiscountEntity>;
  /** Sets the cart's attribution token */
  cartSetAttributionToken?: Maybe<CartEntity>;
  /** Adds items to a product list */
  affiliateAddProductListItems?: Maybe<AffiliateProductListEntity>;
  /** Adds quantity of selected kit product to user's cart */
  cartAddKitProduct?: Maybe<CartEntity>;
  /** Clears the cart's tax exempt flag */
  cartClearTaxExempt?: Maybe<CartEntity>;
  /** Requests a notification when a product is placed back into stock */
  productRequestBackInStockNotification?: Maybe<Scalars['Boolean']>;
  /** Records an off-line delivery for an order */
  shipmentDeliverOffline?: Maybe<ShipmentEntity>;
  /** Sets a delivery date preference for a cart item */
  assistedCartSetDeliveryDatePreference?: Maybe<CartEntity>;
  /** Sets a vaulted payment method as the default for the current user. */
  checkoutSetDefaultPaymentMethod: Scalars['Boolean'];
  /** Remove a blog topic from a blog */
  blogRemoveBlogTopics?: Maybe<BlogEntity>;
  /** Sets a kit product's cost */
  kitProductSetCost?: Maybe<KitProductEntity>;
  /** Sets the shipping rule set on a product */
  listedProductSetShippingRuleSet?: Maybe<ListedProductEntity>;
  /** Records an off-line shipment for a shipment */
  dropshipShipmentShipOffline?: Maybe<ShipmentEntity>;
  /** Clears the billing address */
  assistedCartClearBillingAddress?: Maybe<CartEntity>;
  /** Sets a simple product's visible flag */
  simpleProductSetVisible?: Maybe<SimpleProductEntity>;
  /** Sets the sequence of a content media */
  careSheetSetProductMediaSequence?: Maybe<CareSheetEntity>;
  /** Removes a product media from the listed product */
  listedProductRemoveProductMedia?: Maybe<ListedProductEntity>;
  /** Creates a reseller profile */
  resellerCreateProfile?: Maybe<ResellerProfileEntity>;
  /** Generates a CSV */
  purchaseOrderGenerateCSV?: Maybe<Scalars['String']>;
  /** Clears the shipping rule set from an listed product */
  listedProductClearShippingRuleSet?: Maybe<ListedProductEntity>;
  /** Removes all quantity of selected kit product from user's cart */
  assistedCartRemoveAllKitProduct?: Maybe<CartEntity>;
  /** Sets the cart's pickup warehouse */
  assistedCartSetPickupWarehouse?: Maybe<CartEntity>;
  /** Sets the brands's name */
  brandSetName?: Maybe<BrandEntity>;
  /** Remove a product from a blog */
  blogRemoveProduct?: Maybe<BlogEntity>;
  /** Sets the cart's tax exempt flag */
  assistedCartSetTaxExempt?: Maybe<CartEntity>;
  /** Records an off-line shipment for a shipment */
  dropshipShipmentCancelAndRefund?: Maybe<ShipmentEntity>;
  /** Removes a simple product */
  simpleProductRemove?: Maybe<Scalars['Boolean']>;
  /** Triggers a re-sync of all orders with Magento */
  maintenanceMagentoReSyncOrders?: Maybe<Scalars['Boolean']>;
  /** Reindexes a configurable product */
  configurableProductReindex?: Maybe<ConfigurableProductEntity>;
  /** Sets a simple product's minimum order quantity */
  simpleProductSetMinimumOrderQuantity?: Maybe<SimpleProductEntity>;
  /** Sets the cart's phone */
  cartSetPhone?: Maybe<CartEntity>;
  /** Partially reships an order. */
  salesOrderPartialReship?: Maybe<SalesOrderEntity>;
  /** Refreshes a simple product (with Magento) by entity id */
  simpleProductRefresh?: Maybe<SimpleProductEntity>;
  /** Uploads an image and adds it to the listed product */
  listedProductUploadImage?: Maybe<ListedProductEntity>;
  /** Vaults a payment method for the current user. */
  checkoutCreatePaymentMethod?: Maybe<PaymentMethodDetail>;
  /** Adds an existing image to the care sheet */
  careSheetAddImage?: Maybe<CareSheetEntity>;
  /** Removes explicit products from the promotion */
  promotionRemoveExplicitProducts?: Maybe<PromotionEntity>;
  /** Add a price tier to an listed product */
  listedProductAddPriceTier?: Maybe<ListedProductEntity>;
  /** Changes quantity of selected listed product in user's cart */
  assistedCartChangeQuantityListedProduct?: Maybe<CartEntity>;
  /** Sets active flag */
  careSheetSetActive?: Maybe<CareSheetEntity>;
  /** Gets the next shipment to print at this workstation's printer */
  autoprintGetNextForPrinter?: Maybe<Array<Maybe<PrintJob>>>;
  /** Sends a shipper performance report */
  maintenanceSendShipperPerformance?: Maybe<Scalars['Boolean']>;
  /** Sets the billing address to one of the user's addresses */
  cartSetBillingAddressById?: Maybe<CartEntity>;
  /** Sets a carrier preference for a cart item */
  assistedCartSetCarrierPreference?: Maybe<CartEntity>;
  /** Re-opens a purchase order */
  purchaseOrderReOpen?: Maybe<PurchaseOrderEntity>;
  /** Adds an existing image to the listed product */
  listedProductAddImage?: Maybe<ListedProductEntity>;
  /** Lists all POS terminals */
  tradeShowListTerminals?: Maybe<Array<Maybe<PosTerminalEntity>>>;
  /** Creates a new blog */
  blogCreate?: Maybe<BlogEntity>;
  /** Recomputes shipping estimates for all products */
  maintenanceRecomputeShippingEstimates?: Maybe<Scalars['Boolean']>;
  /** Processes a subscription */
  assistedSubscriptionProcess?: Maybe<SubscriptionEntity>;
  /** Adds quantity of selected simple product to user's cart */
  cartAddSimpleProduct?: Maybe<CartEntity>;
  /** Sets the sequence of a content media */
  blogSetProductMediaSequence?: Maybe<BlogEntity>;
  /** Sets the service level */
  assistedSubscriptionSetServiceLevel?: Maybe<SubscriptionEntity>;
  /** Deletes a promotion */
  promotionDelete?: Maybe<Scalars['Boolean']>;
  /** Sends a Petco DS inventory report */
  maintenanceSendPetcoDsInventory?: Maybe<Scalars['Boolean']>;
  /** Sets a kit product's wholesale price */
  kitProductSetWholesalePrice?: Maybe<KitProductEntity>;
  /** Sets a kit product's visible flag */
  kitProductSetVisible?: Maybe<KitProductEntity>;
  /** Creates a new kit product */
  kitProductCreate?: Maybe<KitProductEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderCancel?: Maybe<SalesOrderEntity>;
  /** Sets the shipping address to one of the user's addresses */
  cartSetShippingAddressById?: Maybe<CartEntity>;
  /** Adds eligible products to the discount */
  discountAddEligibleProducts?: Maybe<DiscountEntity>;
  /** Sets the payment token */
  assistedSubscriptionSetPaymentToken?: Maybe<SubscriptionEntity>;
  /** Triggers a re-sync of products quantities available for sale */
  maintenanceInventoryUpdateQuantitiesAvailableForSale?: Maybe<Scalars['Boolean']>;
  /** Exports simple products */
  simpleProductExport?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** Sets a simple product's case quantity */
  simpleProductSetCaseQuantity?: Maybe<SimpleProductEntity>;
  /** Sets the billing address */
  assistedCartSetBillingAddress?: Maybe<CartEntity>;
  /** Sets a service level for a cart item */
  assistedCartSetServiceTypePreference?: Maybe<CartEntity>;
  /** Update's a user's name */
  userUpdateName?: Maybe<UserEntity>;
  /** Clears the cart's tradeshow */
  assistedCartClearTradeshow?: Maybe<CartEntity>;
  /** Updates a shipment's address */
  scheduledShipmentUpdateAddress?: Maybe<ShipmentEntity>;
  /** Sets a simple product's special price */
  simpleProductSetSpecialPrice?: Maybe<SimpleProductEntity>;
  /** Add cross sells to a simple product */
  simpleProductAddCrossSellProducts?: Maybe<SimpleProductEntity>;
  /** Clears the cart's email */
  cartClearEmail?: Maybe<CartEntity>;
  /** Updates a partner's address */
  partnerUpdateAddress?: Maybe<PartnerEntity>;
  /** Rejects a scheduled shipment for departure today */
  assistedScheduledShipmentClearApproval?: Maybe<ShipmentEntity>;
  /** Uploads an image and adds it to the blog */
  blogUploadImage?: Maybe<BlogEntity>;
  /** Lists available ship dates */
  preppedProductsCalculateShipDates?: Maybe<PreparationShipDates>;
  /** Adds a check payment to an order */
  salesOrderAddGiftCardPayment?: Maybe<SalesOrderEntity>;
  /** Triggers a re-sync of FBA inventory with Amazon */
  maintenanceAmazonUpdateFbaInventory?: Maybe<Scalars['Boolean']>;
  /** Generates a packing list as a label */
  dropshipShipmentGeneratePackingListZpl?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Creates a new address */
  addressCreate?: Maybe<AddressEntity>;
  /** Removes a purchase request item */
  purchaseRequestRemoveItem?: Maybe<PurchaseRequestEntity>;
  /** Indexes a product */
  productIndex?: Maybe<ProductEntity>;
  /** Sets a category's published revision */
  categorySetPublishedRevision?: Maybe<CategoryEntity>;
  /** Generates the assisted scheduled shipment dashboard */
  assistedScheduledShipmentDashboard?: Maybe<SchedulingDashboard>;
  /** Create */
  dropshipperProfileCreate?: Maybe<DropshipperProfileEntity>;
  /** Sets a simple product's width */
  simpleProductSetWidth?: Maybe<SimpleProductEntity>;
  /** Remove an animal from a simple product */
  simpleProductRemoveAnimal?: Maybe<SimpleProductEntity>;
  /** Create a new promotion */
  promotionCreate?: Maybe<PromotionEntity>;
  /** Add an animal to a simple product */
  simpleProductAddAnimal?: Maybe<SimpleProductEntity>;
  /** Sets a shipment to be reprinted */
  autoprintReprint: Scalars['Boolean'];
  /** Tracks a shipment */
  shipmentTrack?: Maybe<Array<Maybe<TrackingEvent>>>;
  /** Sets an listed product's wholesale price */
  listedProductSetWholesalePrice?: Maybe<ListedProductEntity>;
  /** Sets the billing address */
  cartSetBillingAddress?: Maybe<CartEntity>;
  /** Recomputes shipping estimates for an listed product */
  listedProductRecomputeShippingEstimatesAsync?: Maybe<ListedProductEntity>;
  /** Adds a printer to an existing workstation */
  autoprintAddPrinter?: Maybe<PrinterEntity>;
  /** Sets the shipping address */
  assistedCartSetShippingAddress?: Maybe<CartEntity>;
  /** Adds a warehouse to a simple product */
  simpleProductAddWarehouse?: Maybe<SimpleProductEntity>;
  /** Set the position of a kit item */
  kitProductSetKitItemPosition?: Maybe<KitProductEntity>;
  /** Acknowledges a shipment's print job has downloaded */
  autoprintDownloadAcknowledgeForPrinter: Scalars['Boolean'];
  /** Removes a content media from the blog */
  blogRemoveProductMedia?: Maybe<BlogEntity>;
  /** Refreshes a configurable product (with Magento) by entity id */
  configurableProductRefresh?: Maybe<ConfigurableProductEntity>;
  /** Set the slug for an affiliate's product list */
  affiliateSetProductListSlug?: Maybe<AffiliateProductListEntity>;
  /** Clears the cart's coupon code */
  assistedCartClearCouponCode?: Maybe<CartEntity>;
  /** Add a preparation to a product by shipment item */
  preppedProductsAddPreparationLineItem?: Maybe<PreparationSummary>;
  /** Generates a shipping label image */
  shipmentGenerateLabelImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Adds a custom product to user's cart */
  assistedCartAddCustomProduct?: Maybe<CartEntity>;
  /** Generates a packing list label */
  shipmentGeneratePackingListZpl?: Maybe<Scalars['String']>;
  /** Removes a content media from the care sheet */
  careSheetRemoveProductMedia?: Maybe<CareSheetEntity>;
  /** Sets a simple product's price */
  simpleProductSetPrice?: Maybe<SimpleProductEntity>;
  /** Returns shipping estimates for a simple product */
  simpleProductShippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Sets a listing's title */
  listedProductSetListingTitle?: Maybe<ListingEntity>;
  /** Sets a configurable product's SKU */
  configurableProductSetSku?: Maybe<ConfigurableProductEntity>;
  /** Updates a shipment's address */
  supplierUpdateAddress?: Maybe<SupplierEntity>;
  /** Sets a configurable product's published revision */
  configurableProductSetPublishedRevision?: Maybe<ConfigurableProductEntity>;
  /** Registers a new user, with raw password */
  userRegister?: Maybe<UserEntity>;
  /** Sets a configurable product's UPC */
  configurableProductSetUpc?: Maybe<ConfigurableProductEntity>;
  /** Update's a user's type */
  userUpdateType?: Maybe<UserEntity>;
  /** Changes quantity of selected kit product in user's cart */
  assistedCartChangeQuantityKitProduct?: Maybe<CartEntity>;
  /** Add an animal to a configurable product */
  configurableProductAddAnimal?: Maybe<ConfigurableProductEntity>;
  /** Sets a vaulted payment method as the default for the specified user. */
  assistedPaymentMethodSetDefault: Scalars['Boolean'];
  /** Add cross sells to an listed product */
  listedProductAddCrossSellProducts?: Maybe<ListedProductEntity>;
  /** Set the name for an affiliate's product list */
  affiliateSetProductListName?: Maybe<AffiliateProductListEntity>;
  /** Creates a new block */
  blockCreate?: Maybe<BlockEntity>;
  /** Filter inventory history */
  dropshipInventoryFilter?: Maybe<GraphQlPage_DropshipInventoryDetails>;
  /** Sets the product's BOM */
  simpleProductSetBom?: Maybe<SimpleProductEntity>;
  /** Adds quantity of selected simple product to user's cart */
  assistedCartAddSimpleProduct?: Maybe<CartEntity>;
  /** Refreshes a kit product (with Magento) by sku */
  kitProductRefreshBySku?: Maybe<KitProductEntity>;
  /** Sets the cart's coupon code */
  assistedCartSetCouponCode?: Maybe<CartEntity>;
  /** Set the author of a blog */
  blogSetAuthor?: Maybe<BlogEntity>;
  /** Recomputes shipping estimates for a simple product */
  simpleProductRecomputeShippingEstimate?: Maybe<SimpleProductEntity>;
  /** Gets earliest delivery date option */
  cartGetEarliestDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Triggers sync of transactions with Poynt */
  maintenancePoyntSyncTransactions?: Maybe<Scalars['Boolean']>;
  /** Schedule a shipment for today or a future date */
  scheduledShipmentScheduleByHash?: Maybe<ShipmentEntity>;
  /** Removes a configurable product */
  configurableProductRemove?: Maybe<Scalars['Boolean']>;
  /** Release subscriptions */
  maintenanceReleaseSubscriptions?: Maybe<Scalars['Boolean']>;
  /** Clears a product's discontinuation reason */
  simpleProductClearDiscontinuationReason?: Maybe<SimpleProductEntity>;
  /** Adds an existing image to the kit */
  kitProductAddImage?: Maybe<KitProductEntity>;
  /** Reindexes an listed product */
  listedProductReindex?: Maybe<ListedProductEntity>;
  /** Partially refunds an order. */
  salesOrderPartialRefund?: Maybe<SalesOrderEntity>;
  /** Creates an listed product */
  listedProductCreate?: Maybe<ListedProductEntity>;
  /** Sets a kit product's title */
  kitProductSetTitle?: Maybe<KitProductEntity>;
  /** Clears the shipping rule set from a simple product */
  simpleProductClearShippingRuleSet?: Maybe<SimpleProductEntity>;
  /** Updates a user's password */
  userUpdatePassword?: Maybe<UserEntity>;
  /** Uploads an image and adds it to the care sheet */
  careSheetUploadImage?: Maybe<CareSheetEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderOfflineRefund?: Maybe<SalesOrderEntity>;
  /** Clones a simple product */
  simpleProductClone?: Maybe<SimpleProductEntity>;
  /** Refreshes a configurable product (with Magento) by sku */
  configurableProductRefreshBySku?: Maybe<ConfigurableProductEntity>;
  /** Remove a product list for an affiliate */
  affiliateDeleteProductList?: Maybe<PartnerEntity>;
  /** Clears the billing address */
  cartClearBillingAddress?: Maybe<CartEntity>;
  /** Looks up weather for a zip code */
  shipmentLookupWeatherByZip?: Maybe<Array<Maybe<Weather>>>;
  /** Updates an existing address */
  addressUpdate?: Maybe<AddressEntity>;
  /** Creates a new blog topic */
  blogTopicCreate?: Maybe<BlogTopicEntity>;
  /** Creates a new team */
  teamCreate?: Maybe<TeamEntity>;
  /** Sets the shipping address to one of the user's addresses */
  assistedCartSetShippingAddressById?: Maybe<CartEntity>;
  /** Deletes an existing block */
  blockDelete: Scalars['Boolean'];
  /** Validates the address, updates it if necessary */
  salesOrderValidateAddress?: Maybe<SalesOrderEntity>;
  /** Clears a product from shipping alone */
  simpleProductClearShipsAlone?: Maybe<SimpleProductEntity>;
  /** Recomputes shipping estimates for a simple product */
  simpleProductRecomputeShippingEstimatesAsync?: Maybe<SimpleProductEntity>;
  /** Add a price tier to a simple product */
  simpleProductAddPriceTier?: Maybe<SimpleProductEntity>;
  /** Uploads an image and sets it on the category */
  categoryUploadImage?: Maybe<CategoryEntity>;
  /** Adds a comment to a sales order */
  salesOrderAddComment?: Maybe<SalesOrderEntity>;
  /** Gets printers for an existing workstation */
  autoprintListPrinters?: Maybe<Array<Maybe<PrinterEntity>>>;
  /** Sanitize a blog */
  blogSanitize?: Maybe<BlogEntity>;
  /** Remove an item from this kit */
  kitProductRemoveKitItem?: Maybe<KitProductEntity>;
  /** Sets the shipping rule set on a product */
  simpleProductSetShippingRuleSet?: Maybe<SimpleProductEntity>;
  /** Adds a Braintree payment to an order */
  salesOrderAddBraintreePayment?: Maybe<SalesOrderEntity>;
  /** Generates a client token for current user. */
  checkoutGetClientToken?: Maybe<ClientTokenDetail>;
  /** Triggers a re-sync of updated orders with Petco SD */
  maintenancePetcoSdSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Removes all quantity of selected kit product from user's cart */
  cartRemoveAllKitProduct?: Maybe<CartEntity>;
  /** Process subscriptions */
  maintenanceProcessSubscriptions?: Maybe<Scalars['Boolean']>;
  /** Updates payments with processor */
  salesOrderUpdatePayments?: Maybe<SalesOrderEntity>;
  /** Sets a simple product's weight */
  simpleProductSetWeight?: Maybe<SimpleProductEntity>;
  /** Gets items eligible for refund. */
  salesOrderGetRefundableItems?: Maybe<Array<Maybe<SalesOrderLineItem>>>;
  /** Edits an item quantity on a purchase order */
  purchaseOrderEditItemQty?: Maybe<PurchaseOrderEntity>;
  /** Removes a listing from a listed product */
  listedProductRemoveListing?: Maybe<ListedProductEntity>;
  /** Sets a blog's published revision */
  blogSetPublishedRevision?: Maybe<BlogEntity>;
  /** Unsubscribe from an existing item */
  assistedSubscriptionRemoveItem?: Maybe<SubscriptionEntity>;
  /** Creates a new department */
  departmentCreate?: Maybe<DepartmentEntity>;
  /** Refreshes a sales order (with Petco DS) by entity id */
  petcoDsSalesOrderRefresh?: Maybe<PetcoDropshipSalesOrderEntity>;
  /** Sets an listed product's cost */
  listedProductSetCost?: Maybe<ListedProductEntity>;
  /** Sets the cart's pickup warehouse */
  cartSetPickupWarehouse?: Maybe<CartEntity>;
  /** Sets the supplier's name */
  supplierSetName?: Maybe<SupplierEntity>;
  /** Clears the cart's coupon code */
  cartClearCouponCode?: Maybe<CartEntity>;
  /** Create a new discount */
  discountCreate?: Maybe<DiscountEntity>;
  /** Completes checkout and processes payment. */
  cartCheckout?: Maybe<SalesOrderEntity>;
  /** Clears the cart's pickup warehouse */
  cartClearPickupWarehouse?: Maybe<CartEntity>;
  /** Marks the purchase request as ordered */
  purchaseRequestOrder?: Maybe<PurchaseRequestEntity>;
  /** Removes a user account from a partner */
  partnerRemoveUser?: Maybe<PartnerEntity>;
  /** Resends a shipment notification */
  shipmentResendShipmentNotification?: Maybe<ShipmentEntity>;
  /** Sets a partner's affiliate biography */
  affiliateSetAffiliateBiography?: Maybe<PartnerEntity>;
  /** Sets a simple product's wholesale price */
  simpleProductSetWholesalePrice?: Maybe<SimpleProductEntity>;
  /** Triggers an update to all inventory caches */
  maintenanceUpdateInventoryCaches?: Maybe<Scalars['Boolean']>;
  /** Sets a simple product's SKU */
  simpleProductSetSku?: Maybe<SimpleProductEntity>;
  /** Validates the address, updates it if necessary */
  partnerValidateAddress?: Maybe<PartnerEntity>;
  /** Adds a cash payment to an order */
  salesOrderAddCashPayment?: Maybe<SalesOrderEntity>;
  /** Updates bin on a simple product */
  simpleProductSetBin?: Maybe<SimpleProductEntity>;
  /** Calculates a sales tax rate based on zip code */
  salesTaxRate?: Maybe<TaxDetail>;
  /** Validates the address, updates it if necessary */
  supplierValidateAddress?: Maybe<SupplierEntity>;
  /** Sets a partner's reseller tax mode */
  resellerSetResellerTaxMode?: Maybe<PartnerEntity>;
  /** Remove a cross sell from a simple product */
  simpleProductRemoveCrossSellProduct?: Maybe<SimpleProductEntity>;
  /** Sets a listing's dimensions */
  listedProductSetListingDimensions?: Maybe<ListingEntity>;
  /** Add a category to a product */
  productAddCategory?: Maybe<ProductEntity>;
  /** Deletes an existing tradeshow */
  tradeShowDeleteShow: Scalars['Boolean'];
  /** Adds a user account to a partner */
  partnerAddUser?: Maybe<PartnerEntity>;
  /** Changes quantity of selected kit product in user's cart */
  cartChangeQuantityKitProduct?: Maybe<CartEntity>;
  /** Adds quantity of selected kit product to user's cart */
  assistedCartAddKitProduct?: Maybe<CartEntity>;
  /** Creates a new care sheet */
  careSheetCreate?: Maybe<CareSheetEntity>;
  /** Sets an listed product's visible flag */
  listedProductSetVisible?: Maybe<ListedProductEntity>;
  /** Adds an existing image to the simple product */
  simpleProductAddImage?: Maybe<SimpleProductEntity>;
  /** Sets a listing's identifier */
  listedProductSetListingIdentifier?: Maybe<ListingEntity>;
  /** Triggers a re-sync of all products with Magento */
  maintenanceMagentoSyncAllProducts?: Maybe<Scalars['Boolean']>;
  /** Sets a simple product's UPC */
  simpleProductSetUpc?: Maybe<SimpleProductEntity>;
  /** Sets a configurable product's active flag */
  configurableProductSetActive?: Maybe<ConfigurableProductEntity>;
  /** Removes a role from a user */
  userRemoveRole?: Maybe<UserEntity>;
  /** Sets the active flag */
  discountSetActive?: Maybe<DiscountEntity>;
  /** Remove an animal from a configurable product */
  configurableProductRemoveAnimal?: Maybe<ConfigurableProductEntity>;
  /** Triggers a fix to clean up inventory issues */
  maintenanceFixInventoryIssues?: Maybe<Scalars['Boolean']>;
  /** Adds a component item to the simple product */
  simpleProductAddComponentItem?: Maybe<SimpleProductEntity>;
  /** Gets earliest delivery date option */
  assistedCartGetEarliestDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Sets a simple product's published revision */
  simpleProductSetPublishedRevision?: Maybe<SimpleProductEntity>;
  /** Gets rates for a multi-piece shipment */
  shipmentRateMultiPiece?: Maybe<Array<Maybe<RateQuote>>>;
  /** Lists payment methods for the current user. */
  checkoutListPaymentMethods?: Maybe<Array<Maybe<PaymentMethodDetail>>>;
  /** Sets a configurable product's featured flag */
  configurableProductSetFeatured?: Maybe<ConfigurableProductEntity>;
  /** Refreshes a kit product (with Magento) by entity id */
  kitProductRefresh?: Maybe<KitProductEntity>;
  /** Reindexes a kit product */
  kitProductReindex?: Maybe<KitProductEntity>;
  /** Approves a scheduled shipment for departure today */
  assistedScheduledShipmentSetApproval?: Maybe<ShipmentEntity>;
  /** Cancels a shipment within an order */
  shipmentCancelAndRefund?: Maybe<ShipmentEntity>;
  /** Deletes a blog topic */
  blogTopicDelete: Scalars['Boolean'];
  /** Set the sequence for an affiliate's product list */
  affiliateSetProductListSequence?: Maybe<PartnerEntity>;
  /** Generates a PDF */
  purchaseOrderGeneratePDF?: Maybe<Scalars['String']>;
  /** Deletes an item on a purchase order */
  purchaseOrderDeleteItem?: Maybe<PurchaseOrderEntity>;
  /** Adds quantity of selected listed product to user's cart */
  cartAddListedProduct?: Maybe<CartEntity>;
  /** Sets a configurable product's title */
  configurableProductSetTitle?: Maybe<ConfigurableProductEntity>;
  /** Sets a kit product's slug */
  kitProductSetSlug?: Maybe<KitProductEntity>;
  /** Triggers a re-sync of updated orders with Amazon */
  maintenanceAmazonReSyncOrders?: Maybe<Scalars['Boolean']>;
  /** Removes bin from a simple product */
  simpleProductClearBin?: Maybe<SimpleProductEntity>;
  /** Remove an animal from a kit product */
  kitProductRemoveAnimal?: Maybe<KitProductEntity>;
  /** Records an off-line shipment for an order */
  shipmentShipOffline?: Maybe<ShipmentEntity>;
  /** Create */
  resellerProfileCreate?: Maybe<ResellerProfileEntity>;
  /** Remove an item from a product list */
  affiliateRemoveProductListItem?: Maybe<AffiliateProductListEntity>;
  /** Uploads an image and adds it to the simple product */
  simpleProductUploadImage?: Maybe<SimpleProductEntity>;
  /** Deletes a category */
  categoryDelete: Scalars['Boolean'];
  /** Triggers a re-sync of processing orders with Magento */
  maintenanceMagentoSyncProcessing?: Maybe<Scalars['Boolean']>;
  /** Unassigns a pos terminal to a tradeshow */
  tradeShowUnassignTerminal?: Maybe<TradeShowEntity>;
  /** Triggers a re-sync of MFN inventory with Walmart */
  maintenanceWalmartUpdateInventory?: Maybe<Scalars['Boolean']>;
  /** Sets a listing's weight date */
  listedProductSetListingWeightDate?: Maybe<ListingEntity>;
  /** Reroute a shipment */
  shipmentReroute?: Maybe<ShipmentEntity>;
  /** Sets a product as shipping alone */
  simpleProductSetShipsAlone?: Maybe<SimpleProductEntity>;
  /** Acknowledges a shipment's print job has been cancelled */
  autoprintCancelAcknowledgeForPrinter: Scalars['Boolean'];
  /** Sends an email confirmation email */
  userSendEmailConfirmation?: Maybe<Scalars['Boolean']>;
  /** Assigns a brand to a simple product */
  simpleProductSetBrand?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's SKU */
  kitProductSetSku?: Maybe<KitProductEntity>;
  /** Sets the cart's coupon code */
  cartSetCouponCode?: Maybe<CartEntity>;
  /** Syncs purchase orders */
  maintenanceSyncPurchaseOrders?: Maybe<Scalars['Boolean']>;
  /** Removes a product media from the kit */
  kitProductRemoveProductMedia?: Maybe<KitProductEntity>;
  /** Adds an item to a sales order */
  salesOrderAddItem?: Maybe<SalesOrderEntity>;
  /** Sets a kit product's UPC */
  kitProductSetUpc?: Maybe<KitProductEntity>;
  /** Clears the supplier's phone */
  supplierClearPhone?: Maybe<SupplierEntity>;
  /** Changes quantity of selected listed product in user's cart */
  cartChangeQuantityListedProduct?: Maybe<CartEntity>;
  /** Acknowledges a shipment's print job has printed */
  autoprintPrintAcknowledgeForPrinter: Scalars['Boolean'];
  /** Adds the listed products to user's cart */
  assistedCartAddProducts?: Maybe<CartEntity>;
  /** Sets the sales order's hold status */
  salesOrderSetHold?: Maybe<SalesOrderEntity>;
  /** Converts a simple product to a kit */
  simpleProductConvertToKit?: Maybe<KitProductEntity>;
  /** Unsubscribe from an existing item */
  subscriptionRemoveItem?: Maybe<SubscriptionEntity>;
  /** Sets the supplier's phone */
  supplierSetPhone?: Maybe<SupplierEntity>;
  /** Reindexes a simple product */
  simpleProductReindex?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the simple product */
  simpleProductRemoveProductMedia?: Maybe<SimpleProductEntity>;
  /** Sends a Petco SD inventory email */
  maintenanceSendPetcoSdInventory?: Maybe<Scalars['Boolean']>;
  /** Retrieves cross-sells for the given cart */
  cartCrossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Adds zone to a simple product */
  simpleProductAddZone?: Maybe<SimpleProductEntity>;
  /** Splits a shipment into two smaller shipments */
  shipmentSplitAfterShip?: Maybe<ShipmentEntity>;
  /** Refunds the order's sales tax */
  salesOrderSalesTaxRefund?: Maybe<SalesOrderEntity>;
  /** Creates a new POS terminal */
  tradeShowCreateTerminal?: Maybe<PosTerminalEntity>;
  /** Assigns dimensions to a simple product */
  simpleProductSetDimensions?: Maybe<SimpleProductEntity>;
  /** Sets a product's discontinuation reason */
  simpleProductSetDiscontinuationReason?: Maybe<SimpleProductEntity>;
  /** Sets a simple product's title */
  simpleProductSetTitle?: Maybe<SimpleProductEntity>;
  /** Provides cart info for current user */
  cartInfo?: Maybe<CartEntity>;
  /** Sets a kit product's active flag */
  kitProductSetActive?: Maybe<KitProductEntity>;
  /** Updates rate quotes */
  maintenanceUpdateRateQuotes?: Maybe<Scalars['Boolean']>;
  /** Add products to a care sheet */
  careSheetAddProducts?: Maybe<CareSheetEntity>;
  /** Sets a listing's quantity */
  listedProductSetListingQuantity?: Maybe<ListingEntity>;
  /** Update's a user's tax exempt status */
  userUpdateTaxExempt?: Maybe<UserEntity>;
  /** Retrieves cross-sells for the given cart */
  assistedCartCrossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Clears a product from requiring preparation */
  simpleProductClearRequiresPrep?: Maybe<SimpleProductEntity>;
  /** Sets a listing's sex */
  listedProductSetListingSex?: Maybe<ListingEntity>;
  /** Sets an listed product's active flag */
  listedProductSetActive?: Maybe<ListedProductEntity>;
  /** Releases a subscription order */
  subscriptionSalesOrderRelease?: Maybe<SubscriptionSalesOrderEntity>;
  /** Update's a user's payment terms */
  userUpdateNetTerms?: Maybe<UserEntity>;
  /** Removes a custom product to user's cart */
  assistedCartRemoveCustomProduct?: Maybe<CartEntity>;
  /** Changes a shipment's shipping needs */
  shipmentSetShippingNeeds?: Maybe<ShipmentEntity>;
  /** Deletes an existing address */
  addressDelete: Scalars['Boolean'];
  /** Sets a partner's reseller flag */
  partnerSetReseller?: Maybe<PartnerEntity>;
  /** Looks up weather for shipment */
  shipmentLookupWeatherByHash?: Maybe<Array<Maybe<Weather>>>;
  /** Creates a new user, with password hash */
  userCreate?: Maybe<UserEntity>;
  /** Assigns case dimensions to a simple product */
  simpleProductSetCaseDimensions?: Maybe<SimpleProductEntity>;
  /** Clears the cart's pickup warehouse */
  assistedCartClearPickupWarehouse?: Maybe<CartEntity>;
  /** Sets the cart's email */
  assistedCartSetEmail?: Maybe<CartEntity>;
  /** Sets a category's parent */
  categorySetParent?: Maybe<CategoryEntity>;
  /** Add a preparation to a product */
  preppedProductsAddPreparation?: Maybe<PreparationSummary>;
  /** Removes a zone from a simple product */
  simpleProductRemoveZone?: Maybe<SimpleProductEntity>;
  /** Updates an existing tradeshow */
  tradeShowSetPreOrderDates?: Maybe<TradeShowEntity>;
  /** Provides making stock status */
  makingStockStatus?: Maybe<Array<Maybe<InventoryDetails>>>;
  /** Triggers a re-sync of updated orders with Petco DS */
  maintenancePetcoDsSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Update's rate quotes for a shipment */
  shipmentUpdateRateQuotes?: Maybe<ShipmentEntity>;
  /** Sends a Chewy inventory report */
  maintenanceSendChewyInventory?: Maybe<Scalars['Boolean']>;
  /** Removes a category from a product */
  productRemoveCategory?: Maybe<ProductEntity>;
  /** Sets a partner's name */
  partnerSetName?: Maybe<PartnerEntity>;
  /** Lists payment methods for the specified user. */
  assistedPaymentMethodList?: Maybe<Array<Maybe<PaymentMethodDetail>>>;
  /** Creates a new application */
  applicationCreate?: Maybe<ApplicationEntity>;
  /** Triggers sync a transaction with Poynt */
  maintenancePoyntSyncTransaction?: Maybe<Scalars['Boolean']>;
  /** Sets a simple product's active flag */
  simpleProductSetActive?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's special price */
  kitProductSetSpecialPrice?: Maybe<KitProductEntity>;
  /** Adds a role to a user */
  userAddRole?: Maybe<UserEntity>;
  /** Deletes a brand */
  supplierDelete: Scalars['Boolean'];
  /** Sets the shipping address */
  cartSetShippingAddress?: Maybe<CartEntity>;
  /** Generates a shipping manifest */
  shipmentManifest?: Maybe<Scalars['String']>;
  /** Adds an existing image to the configurable product */
  configurableProductAddImage?: Maybe<ConfigurableProductEntity>;
  /** Sets the supplier's lead time */
  supplierSetLeadTime?: Maybe<SupplierEntity>;
  /** Sets an listed product's featured flag */
  listedProductSetFeatured?: Maybe<ListedProductEntity>;
  /** Creates a new gift card, with amount */
  giftCardCreate?: Maybe<GiftCardEntity>;
  /** Voids a shipment */
  shipmentVoid?: Maybe<ShipmentEntity>;
  /** Triggers a re-index of all items in the catalog */
  maintenanceSearchReindexCatalog?: Maybe<Scalars['Boolean']>;
  /** Clears a brand from a simple product */
  simpleProductClearBrand?: Maybe<SimpleProductEntity>;
  /** Sets a component item's quantity */
  simpleProductSetComponentItemQuantity?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's price */
  kitProductSetPrice?: Maybe<KitProductEntity>;
  /** Add a product list for an affiliate */
  affiliateAddProductList?: Maybe<PartnerEntity>;
  /** Triggers a fix to clean up inventory issues */
  maintenanceFixInventoryIssue?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationListedProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAmazonSalesOrderRefreshArgs = {
  amazonId: Scalars['String'];
};


/** Mutation root */
export type MutationPartnerJoinArgs = {
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartClearTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderOnlineRefundArgs = {
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentFilterUnshippedBySimpleProductArgs = {
  warehouseId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMarketingSubscribeArgs = {
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionClearServiceLevelArgs = {
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSetPaymentTokenArgs = {
  paymentToken: Scalars['String'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentResendCancelNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodCreateArgs = {
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryGetDetailsArgs = {
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationBlogAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentLookupWeatherArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetTradeshowArgs = {
  tradeshowId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateSetProductListItemPositionArgs = {
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
  position: Scalars['Int'];
};


/** Mutation root */
export type MutationShipmentSplitArgs = {
  lineItems: Array<Maybe<ShipmentLineItemInput>>;
  id: Scalars['UUID'];
  reroute: Scalars['Boolean'];
};


/** Mutation root */
export type MutationBlogSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentGeneratePackingListArgs = {
  template?: Maybe<Scalars['String']>;
  htmlOnly?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryAddDetailsArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
  purchaseOrderItemId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartChangeQuantitySimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAmazonProfileCreateArgs = {
  productId: Scalars['UUID'];
  fbaSellerSku?: Maybe<Scalars['String']>;
  asin: Scalars['String'];
  sellerSku?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionSetItemQuantityArgs = {
  quantity: Scalars['Long'];
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodDeleteArgs = {
  id: Scalars['UUID'];
  token: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentCancelArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  sendNotification?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderCloseArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMediaUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationKitProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationChewyProfileCreateArgs = {
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationCategorySetImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountUpdateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Instant']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  discountType: DiscountType;
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentTrackByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationAuthorizationAuthCodeArgs = {
  redirectUri: Scalars['String'];
  clientId: Scalars['String'];
  scope: AuthorizationScopeType;
  codeChallengeMethod: Scalars['String'];
  codeChallenge: Scalars['String'];
};


/** Mutation root */
export type MutationBlockUpdateArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationShipmentRescheduleArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAddPartialKitProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationProductSummarizeArgs = {
  maxCharacters: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCheckoutDeletePaymentMethodArgs = {
  token: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentScheduleArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseRequestApproveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderCustomRefundSummarizeArgs = {
  reason?: Maybe<RefundReason>;
  shipping: Scalars['BigDecimal'];
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerSetResellerShippingRateModeArgs = {
  resellerShippingRateMode: ResellerShippingRateMode;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationProductSetDepartmentArgs = {
  productId: Scalars['UUID'];
  departmentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationTradeShowUpdateShowArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  endDate: Scalars['LocalDate'];
  city: Scalars['String'];
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  postalCode: Scalars['String'];
  name: Scalars['String'];
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRemoveWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationScheduledShipmentContactCustomerServiceArgs = {
  id: Scalars['UUID'];
  message: Scalars['String'];
};


/** Mutation root */
export type MutationBlogAddProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountRemoveEligibleProductsArgs = {
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationTradeShowSendReportArgs = {
  emails: Array<Maybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowCreateShowArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  endDate: Scalars['LocalDate'];
  city: Scalars['String'];
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  postalCode: Scalars['String'];
  name: Scalars['String'];
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearServiceTypePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodGetClientTokenArgs = {
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMagentoSalesOrderRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartReorderArgs = {
  orderId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationMagentoSalesOrderRefreshAsyncArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedSubscriptionCreateArgs = {
  shippingCity: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<DayOfWeek>;
  shippingPostalCode: Scalars['String'];
  frequency: Scalars['Long'];
  paymentToken?: Maybe<Scalars['String']>;
  billingCompany?: Maybe<Scalars['String']>;
  shippingCountry: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
  shippingLine2?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  taxExempt?: Maybe<Scalars['Boolean']>;
  productId: Scalars['UUID'];
  shippingLastName: Scalars['String'];
  billingState: Scalars['String'];
  userId: Scalars['UUID'];
  billingLastName: Scalars['String'];
  phone: Scalars['String'];
  billingPostalCode: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingState: Scalars['String'];
  shippingLine1: Scalars['String'];
  billingCity: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderGetRefundableAmountsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentGenerateCommercialInvoiceArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAuthorizeUrlArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  giftCardPin?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationAssistedCartRemoveAllSimpleProductArgs = {
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartRemoveAllSimpleProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetLengthArgs = {
  length?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderSetEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearCarrierPreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetDropshipperArgs = {
  dropshipper: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearEmailArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSanitizeArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSubscriptionSetServiceLevelArgs = {
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionClearServiceLevelArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerCreateArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationCategorySetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBrandDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRecomputeShippingEstimateArgs = {
  state: State;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearDeliveryDatePreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBrandCreateArgs = {
  name: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearShippingAddressArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionClearCarrierPreferenceArgs = {
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentUpdateAddressByHashArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipShipmentGeneratePackingListArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductCreateArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  price: Scalars['BigDecimal'];
  upc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCareSheetRemoveProductArgs = {
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetCarrierPreferenceArgs = {
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierClearEmailArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionUpdateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  promotionType: PromotionType;
  endDate?: Maybe<Scalars['Instant']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUserSendPasswordResetArgs = {
  email: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductPushArgs = {
  price?: Maybe<Scalars['BigDecimal']>;
  name?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  shortCode?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartLookupArgs = {
  lookup?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartSetShippingZipArgs = {
  zip: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPetcoSdProfileCreateArgs = {
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartChangeQuantitySimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductRemoveComponentItemArgs = {
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddPaymentTermsPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionClearCarrierPreferenceArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentScheduleArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetBillingAddressByIdArgs = {
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductCreateListingArgs = {
  identifier: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  price: Scalars['BigDecimal'];
  sex?: Maybe<SexType>;
  width?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  weight?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  title: Scalars['String'];
  weightDate?: Maybe<Scalars['LocalDate']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationUserUpdateEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetVendorRefArgs = {
  id: Scalars['UUID'];
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartSummarizeArgs = {
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  cartId: Scalars['UUID'];
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationSubscriptionSetCarrierPreferenceArgs = {
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearPhoneArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearDeliveryDatePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionAddItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationShipmentDashboardArgs = {
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationPartnerSetAffiliateArgs = {
  id: Scalars['UUID'];
  affiliate: Scalars['Boolean'];
};


/** Mutation root */
export type MutationPartnerSetEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountEvaluateImplicitProductsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseRequestDenyArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductShippingEstimatesArgs = {
  productId: Scalars['UUID'];
  warehouseId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationConfigurableProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountAddExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentShipMultiPieceArgs = {
  carrier: Carrier;
  service: Service;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  reseller?: Maybe<Reseller>;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAutoprintEnrollWorkstationArgs = {
  machineKey: Scalars['String'];
  name: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationWalmartSalesOrderRefreshArgs = {
  purchaseOrderId: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderGenerateInvoiceArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentReprocessArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationRedirectFindByPathArgs = {
  path: Scalars['String'];
};


/** Mutation root */
export type MutationPreppedProductsRemovePreparationArgs = {
  productId: Scalars['UUID'];
  preparationId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationScheduledShipmentContactCustomerServiceByHashArgs = {
  message: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionEvaluateImplicitProductsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionCreateArgs = {
  shippingCity: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<DayOfWeek>;
  shippingPostalCode: Scalars['String'];
  frequency: Scalars['Long'];
  paymentToken?: Maybe<Scalars['String']>;
  billingCompany?: Maybe<Scalars['String']>;
  shippingCountry: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
  shippingLine2?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  taxExempt?: Maybe<Scalars['Boolean']>;
  productId: Scalars['UUID'];
  shippingLastName: Scalars['String'];
  billingState: Scalars['String'];
  billingLastName: Scalars['String'];
  phone: Scalars['String'];
  billingPostalCode: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingState: Scalars['String'];
  shippingLine1: Scalars['String'];
  billingCity: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderPartialRefundSummarizeArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearPhoneArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetWeightArgs = {
  weight?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMapPriceArgs = {
  mapPrice?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearSupplierArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetItemFrequencyArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationCartSetEmailArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowAssignTerminalArgs = {
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetCarrierPreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  carrierPreference: Carrier;
};


/** Mutation root */
export type MutationSimpleProductSetRequiresPrepArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPetcoDsProfileCreateArgs = {
  surcharge: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderCustomRefundArgs = {
  shipping: Scalars['BigDecimal'];
  refundReason?: Maybe<RefundReason>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserConfirmEmailArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddCheckPaymentArgs = {
  checkNumber?: Maybe<Scalars['String']>;
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserChangePasswordByHashArgs = {
  password: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationCartRemoveAllListedProductArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingZipArgs = {
  zip: Scalars['String'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetHtmlContentArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationKitProductAddKitItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetEmailArgs = {
  id?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationAutoprintTestWorkstationArgs = {
  machineKey: Scalars['String'];
};


/** Mutation root */
export type MutationPurchaseRequestAddItemArgs = {
  amount?: Maybe<Scalars['BigDecimal']>;
  quantity?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPromotionAddExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartCheckoutArgs = {
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  skipAvs?: Maybe<Scalars['Boolean']>;
  method: Scalars['String'];
  cartId: Scalars['UUID'];
  deviceData?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  nonce?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSalesOrderCancelByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetContactArgs = {
  phone?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartClearCarrierPreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetBrandArgs = {
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
};


/** Mutation root */
export type MutationGiftCardReloadArgs = {
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetTradeshowArgs = {
  tradeshowId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartSetContactArgs = {
  phone?: Maybe<Scalars['String']>;
  cartId: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationListedProductSetListingBirthDateArgs = {
  id: Scalars['UUID'];
  birthDate?: Maybe<Scalars['LocalDate']>;
};


/** Mutation root */
export type MutationUserUpdateAdminArgs = {
  admin: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressVerifyRawArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetHtmlContentArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationTradeShowReportArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderGenerateInvoiceByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPetcoSdSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationCartClearShippingAddressArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartSetDeliveryDatePreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
};


/** Mutation root */
export type MutationListedProductSetDimensionsArgs = {
  width: Scalars['BigDecimal'];
  length: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  height: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationPurchaseRequestReceiveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSupplierCreateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationCartClearTradeshowArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetListingCommentsArgs = {
  comments?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetHeightArgs = {
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationAddressVerifyArgs = {
  commit: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetOrderCycleArgs = {
  orderCycle: Scalars['Int'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAffiliateSetAttributionPeriodArgs = {
  period: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryReportGenerateArgs = {
  departmentId: Scalars['UUID'];
  days: Scalars['Long'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetPhoneArgs = {
  phone: Scalars['String'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategoryCreateArgs = {
  name: Scalars['String'];
  magentoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetAffiliateArgs = {
  affiliateSlug: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationKitProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetComponentItemPositionArgs = {
  position: Scalars['Int'];
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseRequestCreateArgs = {
  comments?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  items?: Maybe<Array<Maybe<PurchaseRequestItemInput>>>;
};


/** Mutation root */
export type MutationSubscriptionAddItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationAffiliateSetCommissionArgs = {
  commission: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetSupplierArgs = {
  supplierId: Scalars['UUID'];
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSummarizeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationDepartmentSetParentArgs = {
  parent: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingWeightArgs = {
  weight?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogAddBlogTopicsArgs = {
  blogTopicIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryGetHistoryArgs = {
  days: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductClearBrandArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationChewySalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationInventorySetDetailsArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateSetProductListHtmlContentArgs = {
  listId: Scalars['UUID'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationBlogDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountRemoveExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetAttributionTokenArgs = {
  attributionToken: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAffiliateAddProductListItemsArgs = {
  listId: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationCartAddKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartClearTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationProductRequestBackInStockNotificationArgs = {
  phone?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentDeliverOfflineArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetDeliveryDatePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
};


/** Mutation root */
export type MutationCheckoutSetDefaultPaymentMethodArgs = {
  token: Scalars['String'];
};


/** Mutation root */
export type MutationBlogRemoveBlogTopicsArgs = {
  blogTopicId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetShippingRuleSetArgs = {
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentShipOfflineArgs = {
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  service?: Maybe<Service>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearBillingAddressArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerCreateProfileArgs = {
  productId: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderGenerateCsvArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductClearShippingRuleSetArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartRemoveAllKitProductArgs = {
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetPickupWarehouseArgs = {
  warehouseId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBrandSetNameArgs = {
  name: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationBlogRemoveProductArgs = {
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentCancelAndRefundArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMinimumOrderQuantityArgs = {
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetPhoneArgs = {
  phone: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSalesOrderPartialReshipArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  serviceType?: Maybe<ServiceType>;
  reason?: Maybe<RefundReason>;
  carrierPreference?: Maybe<Carrier>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCheckoutCreatePaymentMethodArgs = {
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionRemoveExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartChangeQuantityListedProductArgs = {
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintGetNextForPrinterArgs = {
  machineKey: Scalars['String'];
  printerName: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetBillingAddressByIdArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetCarrierPreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  carrierPreference: Carrier;
};


/** Mutation root */
export type MutationPurchaseOrderReOpenArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogCreateArgs = {
  title: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenanceRecomputeShippingEstimatesArgs = {
  state: State;
};


/** Mutation root */
export type MutationAssistedSubscriptionProcessArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAddSimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationBlogSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetServiceLevelArgs = {
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationKitProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductCreateArgs = {
  price: Scalars['BigDecimal'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderCancelArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetShippingAddressByIdArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountAddEligibleProductsArgs = {
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssistedSubscriptionSetPaymentTokenArgs = {
  paymentToken: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductExportArgs = {
  supplier?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductSetCaseQuantityArgs = {
  caseQuantity?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId: Scalars['UUID'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetServiceTypePreferenceArgs = {
  serviceType: ServiceType;
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateNameArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearTradeshowArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationSimpleProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearEmailArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPartnerUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentClearApprovalArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPreppedProductsCalculateShipDatesArgs = {
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderAddGiftCardPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  giftCardCode?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDropshipShipmentGeneratePackingListZplArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressCreateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationPurchaseRequestRemoveItemArgs = {
  itemId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationProductIndexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedScheduledShipmentDashboardArgs = {
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  warehouse?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDropshipperProfileCreateArgs = {
  cost: Scalars['BigDecimal'];
  simpleProductId: Scalars['UUID'];
  dropshipperId: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetWidthArgs = {
  width?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionCreateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  promotionType: PromotionType;
  endDate?: Maybe<Scalars['Instant']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintReprintArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentTrackArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationCartSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductRecomputeShippingEstimatesAsyncArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintAddPrinterArgs = {
  machineKey: Scalars['String'];
  printerName: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId: Scalars['UUID'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductAddWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetKitItemPositionArgs = {
  kitItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintDownloadAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateSetProductListSlugArgs = {
  listId: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearCouponCodeArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPreppedProductsAddPreparationLineItemArgs = {
  productId: Scalars['UUID'];
  shipmentItemId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentGenerateLabelImagesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartAddCustomProductArgs = {
  unitPrice: Scalars['BigDecimal'];
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  weight: Scalars['BigDecimal'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentGeneratePackingListZplArgs = {
  template?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductShippingEstimatesArgs = {
  productId: Scalars['UUID'];
  warehouseId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetListingTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSupplierUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationUserRegisterArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateTypeArgs = {
  id: Scalars['UUID'];
  type: UserType;
};


/** Mutation root */
export type MutationAssistedCartChangeQuantityKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodSetDefaultArgs = {
  userId: Scalars['UUID'];
  token: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListNameArgs = {
  listId: Scalars['UUID'];
  name: Scalars['String'];
};


/** Mutation root */
export type MutationBlockCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipInventoryFilterArgs = {
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  warehouse: GraphQlSingleValueFilter_StringInput;
};


/** Mutation root */
export type MutationSimpleProductSetBomArgs = {
  bomItems: Array<Maybe<BomItemInput>>;
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartAddSimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetCouponCodeArgs = {
  cartId: Scalars['UUID'];
  couponCode: Scalars['String'];
};


/** Mutation root */
export type MutationBlogSetAuthorArgs = {
  author?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRecomputeShippingEstimateArgs = {
  state: State;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartGetEarliestDeliveryDateArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentScheduleByHashArgs = {
  date: Scalars['LocalDate'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearDiscontinuationReasonArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderPartialRefundArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductCreateArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  upc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationKitProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductClearShippingRuleSetArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdatePasswordArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSalesOrderOfflineRefundArgs = {
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductCloneArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  price: Scalars['BigDecimal'];
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationConfigurableProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateDeleteProductListArgs = {
  listId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearBillingAddressArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationShipmentLookupWeatherByZipArgs = {
  zip: Scalars['String'];
};


/** Mutation root */
export type MutationAddressUpdateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationBlogTopicCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationTeamCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingAddressByIdArgs = {
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlockDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearShipsAloneArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRecomputeShippingEstimatesAsyncArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategoryUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSalesOrderAddCommentArgs = {
  comment: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintListPrintersArgs = {
  machineKey: Scalars['String'];
};


/** Mutation root */
export type MutationBlogSanitizeArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationKitProductRemoveKitItemArgs = {
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetShippingRuleSetArgs = {
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddBraintreePaymentArgs = {
  orderId: Scalars['UUID'];
  transactionId: Scalars['String'];
};


/** Mutation root */
export type MutationCheckoutGetClientTokenArgs = {
  anonymous?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCartRemoveAllKitProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSalesOrderUpdatePaymentsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetWeightArgs = {
  weight?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderGetRefundableItemsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderEditItemQtyArgs = {
  quantity: Scalars['Long'];
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveListingArgs = {
  productId: Scalars['UUID'];
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionRemoveItemArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDepartmentCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPetcoDsSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetPickupWarehouseArgs = {
  warehouseId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSupplierSetNameArgs = {
  name: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartClearCouponCodeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationDiscountCreateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Instant']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  discountType: DiscountType;
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartCheckoutArgs = {
  method: Scalars['String'];
  cartId: Scalars['UUID'];
  deviceData?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  nonce?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartClearPickupWarehouseArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPurchaseRequestOrderArgs = {
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerRemoveUserArgs = {
  partnerId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentResendShipmentNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetAffiliateBiographyArgs = {
  affiliateBiography: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationSimpleProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationPartnerValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddCashPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetBinArgs = {
  bin: Scalars['String'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSalesTaxRateArgs = {
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSupplierValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerSetResellerTaxModeArgs = {
  resellerTaxMode: ResellerTaxMode;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingDimensionsArgs = {
  width?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationProductAddCategoryArgs = {
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowDeleteShowArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerAddUserArgs = {
  partnerId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartChangeQuantityKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartAddKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetCreateArgs = {
  title: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingIdentifierArgs = {
  identifier: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMaintenanceMagentoSyncAllProductsArgs = {
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Mutation root */
export type MutationSimpleProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserRemoveRoleArgs = {
  role: RoleType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddComponentItemArgs = {
  quantity: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartGetEarliestDeliveryDateArgs = {
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentRateMultiPieceArgs = {
  applyRestrictions?: Maybe<Scalars['Boolean']>;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  shipDate?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentSetApprovalArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentCancelAndRefundArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  sendNotification: Scalars['Boolean'];
  id: Scalars['UUID'];
  refund: Scalars['Boolean'];
};


/** Mutation root */
export type MutationBlogTopicDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListSequenceArgs = {
  listId: Scalars['UUID'];
  sequence: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderGeneratePdfArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderDeleteItemArgs = {
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAddListedProductArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductClearBinArgs = {
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentShipOfflineArgs = {
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  service?: Maybe<Service>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerProfileCreateArgs = {
  resellerId: Scalars['UUID'];
  productId: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  title: Scalars['String'];
  sku: Scalars['String'];
  retail?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationAffiliateRemoveProductListItemArgs = {
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCategoryDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowUnassignTerminalArgs = {
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingWeightDateArgs = {
  id: Scalars['UUID'];
  weightDate?: Maybe<Scalars['LocalDate']>;
};


/** Mutation root */
export type MutationShipmentRerouteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetShipsAloneArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintCancelAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetBrandArgs = {
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetCouponCodeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  couponCode: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddItemArgs = {
  salesOrderId: Scalars['UUID'];
  quantity: Scalars['Long'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierClearPhoneArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartChangeQuantityListedProductArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintPrintAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartAddProductsArgs = {
  kitProductIds: Array<Maybe<Scalars['UUID']>>;
  cartId: Scalars['UUID'];
  simpleProductIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationSalesOrderSetHoldArgs = {
  id: Scalars['UUID'];
  hold: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSimpleProductConvertToKitArgs = {
  kitItems: Array<Maybe<KitItemInput>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionRemoveItemArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetPhoneArgs = {
  phone: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartCrossSellsArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentSplitAfterShipArgs = {
  lineItems: Array<Maybe<ShipmentLineItemInput>>;
  id: Scalars['UUID'];
  reroute: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSalesOrderSalesTaxRefundArgs = {
  id: Scalars['UUID'];
  type: SalesOrderType;
};


/** Mutation root */
export type MutationTradeShowCreateTerminalArgs = {
  urn: Scalars['String'];
  assetTag: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetDimensionsArgs = {
  width: Scalars['BigDecimal'];
  length: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  height: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationSimpleProductSetDiscontinuationReasonArgs = {
  reason: DiscontinuationReasonType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationKitProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetAddProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingQuantityArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateTaxExemptArgs = {
  taxExempt: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartCrossSellsArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearRequiresPrepArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingSexArgs = {
  sex?: Maybe<SexType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSalesOrderReleaseArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateNetTermsArgs = {
  netTerms: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartRemoveCustomProductArgs = {
  cartId: Scalars['UUID'];
  cartCustomItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentSetShippingNeedsArgs = {
  shippingNeeds: ShippingNeedsType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetResellerArgs = {
  reseller: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentLookupWeatherByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationUserCreateArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetCaseDimensionsArgs = {
  caseLength: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  caseHeight: Scalars['BigDecimal'];
  caseWidth: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationAssistedCartClearPickupWarehouseArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetEmailArgs = {
  cartId: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationCategorySetParentArgs = {
  parent: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPreppedProductsAddPreparationArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRemoveZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowSetPreOrderDatesArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMakingStockStatusArgs = {
  department?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentUpdateRateQuotesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationProductRemoveCategoryArgs = {
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodListArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationApplicationCreateArgs = {
  redirectUri: Scalars['String'];
  clientId: Scalars['String'];
  name: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenancePoyntSyncTransactionArgs = {
  orderNumber?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationUserAddRoleArgs = {
  role: RoleType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentManifestArgs = {
  date: Scalars['String'];
  reseller: Reseller;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetLeadTimeArgs = {
  leadTime: Scalars['Int'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationGiftCardCreateArgs = {
  amount: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationShipmentVoidArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearBrandArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetComponentItemQuantityArgs = {
  quantity: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateAddProductListArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenanceFixInventoryIssueArgs = {
  productId?: Maybe<Scalars['UUID']>;
};

export type PartnerEntity = {
  __typename?: 'PartnerEntity';
  /** Partner's validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Partner's affiliate flag */
  affiliate?: Maybe<Scalars['Boolean']>;
  /** Affiliate's attribution period */
  affiliateAttributionPeriod?: Maybe<Scalars['Long']>;
  /** Partner's attribution token */
  affiliateAttributionToken?: Maybe<Scalars['String']>;
  /** Affiliate's biography */
  affiliateBiography?: Maybe<Scalars['String']>;
  /** Affiliate's commission */
  affiliateCommissionRate?: Maybe<Scalars['BigDecimal']>;
  /** Affiliate's product list */
  affiliateProductLists?: Maybe<Array<Maybe<AffiliateProductListEntity>>>;
  /** Partner's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Partner's company */
  company?: Maybe<Scalars['String']>;
  /** Partner's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Partner's dropshipper flag */
  dropshipper?: Maybe<Scalars['Boolean']>;
  /** Partner's email */
  email?: Maybe<Scalars['String']>;
  /** Partner's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Partner's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Partner's latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Partner's first line */
  line1?: Maybe<Scalars['String']>;
  /** Partner's last line */
  line2?: Maybe<Scalars['String']>;
  /** Partner's longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Partner's name */
  name?: Maybe<Scalars['String']>;
  /** Partner's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Partner's reseller flag */
  reseller?: Maybe<Scalars['Boolean']>;
  /** Reseller's shipping rate mode */
  resellerShippingRateMode?: Maybe<ResellerShippingRateMode>;
  /** Reseller's tax mode */
  resellerTaxMode?: Maybe<ResellerTaxMode>;
  /** Partner's residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Affiliate's slug */
  slug?: Maybe<Scalars['String']>;
  /** Partner's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Partner's enrolled users */
  users?: Maybe<Array<Maybe<UserEntity>>>;
};

export type PaymentEntity = {
  __typename?: 'PaymentEntity';
  /** Payment's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Payment's description */
  description?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Payment's status */
  paymentStatus?: Maybe<PaymentStatus>;
  /** Payment's placed timestamp */
  placedAt?: Maybe<Scalars['Instant']>;
  /** Payment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Payment's transaction id */
  transactionId?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PaymentMethodDetail = {
  __typename?: 'PaymentMethodDetail';
  /** Payment method comment */
  comment?: Maybe<Scalars['String']>;
  /** Payment method's default method flag */
  defaultMethod?: Maybe<Scalars['Boolean']>;
  /** Payment method's image */
  image?: Maybe<Scalars['String']>;
  /** Payment method token */
  token?: Maybe<Scalars['String']>;
  /** Payment method type */
  type?: Maybe<Scalars['String']>;
};

export type PdfContents = {
  __typename?: 'PdfContents';
  /** PDF's data */
  data?: Maybe<Scalars['String']>;
  /** PDF's filename */
  fileName?: Maybe<Scalars['String']>;
  /** PDF's HTML content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type PetcoDropshipProfileEntity = {
  __typename?: 'PetcoDropshipProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Petco dropship profile's description */
  description?: Maybe<Scalars['String']>;
  /** Petco dropship profile's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Petco dropship profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Petco dropship profile's surcharge */
  surcharge?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PetcoDropshipSalesOrderEntity = {
  __typename?: 'PetcoDropshipSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PetcoStoreDirectProfileEntity = {
  __typename?: 'PetcoStoreDirectProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Petco store direct profile's description */
  description?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Petco store direct profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PetcoStoreDirectSalesOrderEntity = {
  __typename?: 'PetcoStoreDirectSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PosTerminalEntity = {
  __typename?: 'PosTerminalEntity';
  /** POS terminal's asset tag */
  assetTag?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  tradeShowEntities?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** POS terminal's urn */
  urn?: Maybe<Scalars['String']>;
};

export type PreparationEntity = {
  __typename?: 'PreparationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Preparation's preparer */
  preparer?: Maybe<Scalars['String']>;
  /** Preparation's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Preparation's ship date */
  shipDate?: Maybe<Scalars['LocalDate']>;
  /** Preparation's shipment item */
  shipmentItem?: Maybe<ShipmentItemEntity>;
  /** Preparation's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Preparation's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type PreparationShipDates = {
  __typename?: 'PreparationShipDates';
  /** Preparation tool's current ship date */
  currentShipDate?: Maybe<Scalars['LocalDate']>;
  /** Preparation tool's possible ship dates */
  possibleShipDates?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
};

export type PreparationSummary = {
  __typename?: 'PreparationSummary';
  /** Preparation summary's quantity needed */
  quantityNeeded?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity ordered */
  quantityOrdered?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity prepped */
  quantityPrepped?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity shipped */
  quantityShipped?: Maybe<Scalars['Long']>;
  /** Preparation summary's shipment items */
  shipmentItems?: Maybe<Array<Maybe<ShipmentItemEntity>>>;
  /** Preparation summary's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Preparation summary's preparations */
  todaysPreparations?: Maybe<Array<Maybe<PreparationEntity>>>;
};

export type PriceTierEntity = {
  __typename?: 'PriceTierEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Tiered price's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Tiered price's quantity */
  quantity?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Tiered price's user type */
  userType?: Maybe<UserType>;
};

export type PrintJob = {
  __typename?: 'PrintJob';
  /** Print job's data */
  dataBase64?: Maybe<Scalars['String']>;
  /** Print job's name */
  name?: Maybe<Scalars['String']>;
  /** Print job's printer */
  printer?: Maybe<Scalars['String']>;
  /** Print job's rotation */
  rotate?: Maybe<Scalars['Boolean']>;
  /** Print job's tray */
  tray?: Maybe<Scalars['String']>;
};

export type PrinterEntity = {
  __typename?: 'PrinterEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Printer's name */
  name?: Maybe<Scalars['String']>;
  /** Printer's rotation */
  rotate?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sells */
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type ProductMediaEntity = {
  __typename?: 'ProductMediaEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product media's media */
  media?: Maybe<MediaEntity>;
  /** Product media's type */
  mediaType?: Maybe<MediaType>;
  /** Product media's product */
  product?: Maybe<ProductEntity>;
  /** Product media's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product media's url */
  url?: Maybe<Scalars['String']>;
};

export type ProductRevisionEntity = {
  __typename?: 'ProductRevisionEntity';
  /** Product revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type ProfitabilitySummary = {
  __typename?: 'ProfitabilitySummary';
  /** Summary's average cost */
  averageCost?: Maybe<Scalars['BigDecimal']>;
  /** Summary's average price */
  averagePrice?: Maybe<Scalars['BigDecimal']>;
  /** Summary's gross margin */
  grossMargin?: Maybe<Scalars['Float']>;
  /** Summary's product */
  product?: Maybe<SimpleProductEntity>;
  /** Summary's profit */
  profit?: Maybe<Scalars['BigDecimal']>;
  /** Summary's number sold */
  sold?: Maybe<Scalars['Long']>;
  /** Summary's stock out */
  stockOut?: Maybe<Scalars['Float']>;
  /** Summary's velocity */
  velocity?: Maybe<Scalars['Float']>;
};

export type PromotionEntity = {
  __typename?: 'PromotionEntity';
  /** Promotion's active status */
  active?: Maybe<Scalars['Boolean']>;
  /** Promotion's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Promotion's departments */
  departments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Promotion's eligible discount amount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible cart value */
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible coupon code */
  eligibleCouponCode?: Maybe<Scalars['String']>;
  /** Promotion's eligible item count */
  eligibleItemCount?: Maybe<Scalars['Long']>;
  /** Promotion's eligible item value */
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible user type */
  eligibleUserType?: Maybe<UserType>;
  /** Promotion's end date */
  endDate?: Maybe<Scalars['Instant']>;
  /** Promotion's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Promotion's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Promotion's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Promotion's name */
  name?: Maybe<Scalars['String']>;
  /** Promotion's permit stacking status */
  permitStacking?: Maybe<Scalars['Boolean']>;
  /** Promotion's type */
  promotionType?: Maybe<PromotionType>;
  /** Promotion's slug */
  slug?: Maybe<Scalars['String']>;
  /** Promotion's start date */
  startDate?: Maybe<Scalars['Instant']>;
  /** Promotion's teaser */
  teaser?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PurchaseOrderEntity = {
  __typename?: 'PurchaseOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's expected time */
  expectedTime?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's items */
  purchaseOrderItems?: Maybe<Array<Maybe<PurchaseOrderItemEntity>>>;
  /** Order's status */
  status?: Maybe<PurchaseOrderStatus>;
  /** Order's supplier */
  supplier?: Maybe<SupplierEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type PurchaseOrderItemEntity = {
  __typename?: 'PurchaseOrderItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order's item's name */
  name?: Maybe<Scalars['String']>;
  /** Order's item's order */
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  /** Order's quantity received */
  received?: Maybe<Scalars['Long']>;
  /** Order's items simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Order's item's sku */
  sku?: Maybe<Scalars['String']>;
  /** Order's quantity total */
  total?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's item's vendor reference */
  vendorRef?: Maybe<Scalars['String']>;
};

export type PurchaseRequestEntity = {
  __typename?: 'PurchaseRequestEntity';
  /** Request's approver */
  approver?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Request's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Request's estimated amount */
  estimatedAmount?: Maybe<Scalars['BigDecimal']>;
  /** Request's final amount */
  finalAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Request's ordered date */
  orderedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's orderer */
  orderer?: Maybe<Scalars['String']>;
  /** Request's items */
  purchaseRequestItems?: Maybe<Array<Maybe<PurchaseRequestItemEntity>>>;
  /** Request's status */
  purchaseRequestStatus?: Maybe<PurchaseRequestStatus>;
  /** Request's received date */
  receivedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's receiver */
  receiver?: Maybe<Scalars['String']>;
  /** Request's number */
  requestNumber?: Maybe<Scalars['String']>;
  /** Request's requested date */
  requestedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's requester */
  requester?: Maybe<Scalars['String']>;
  /** Request's supplier */
  supplier?: Maybe<Scalars['String']>;
  /** Request's team */
  team?: Maybe<TeamEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PurchaseRequestItemEntity = {
  __typename?: 'PurchaseRequestItemEntity';
  /** Requests item's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Requests item's name */
  name?: Maybe<Scalars['String']>;
  /** Requests item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  /** Provides user info a user */
  userInfo?: Maybe<UserEntity>;
  /** Locates a discount by slug */
  discountBySlug?: Maybe<DiscountEntity>;
  /** Filter dropship profiles */
  dropshipDropshipperProfileFilter?: Maybe<GraphQlPage_DropshipperProfileEntity>;
  /** Creates transient shipments for sales order items, then rolls back */
  salesOrderComputeBestShipping?: Maybe<SalesOrderEntity>;
  /** Filters care sheets */
  careSheetFilter?: Maybe<GraphQlPage_CareSheetEntity>;
  /** Deletes and recomputes shipments for a sales order */
  salesOrderNukeAndComputeBestShipping?: Maybe<SalesOrderEntity>;
  /** Generate a PDF for the sales order */
  salesOrderGeneratePdf?: Maybe<PdfContents>;
  /** Returns the user's reward points balance */
  rewardPointsBalance?: Maybe<Scalars['Long']>;
  /** Locates the root department */
  departmentRoot?: Maybe<DepartmentEntity>;
  /** Retrieves product reviews */
  reviewListForProduct?: Maybe<ReviewResults>;
  /** Fetches brand info */
  supplierInfo?: Maybe<SupplierEntity>;
  /** Lists listed products */
  listedProductList?: Maybe<GraphQlPage_ListedProductEntity>;
  /** Filters blogs */
  blogFilter?: Maybe<GraphQlPage_BlogEntity>;
  /** Locates a product by slug */
  productBySlug?: Maybe<ProductEntity>;
  /** Locates a product list by id */
  affiliateGetProductListById?: Maybe<AffiliateProductListEntity>;
  /** Lists kit products */
  kitProductList?: Maybe<GraphQlPage_KitProductEntity>;
  /** Lists teams */
  teamList?: Maybe<GraphQlPage_TeamEntity>;
  /** Lists suppliers */
  supplierList?: Maybe<GraphQlPage_SupplierEntity>;
  /** Locates a block by id */
  blockInfo?: Maybe<BlockEntity>;
  /** Calculates a secure hash */
  salesOrderSecureHash?: Maybe<Scalars['String']>;
  /** Search products */
  productSearch?: Maybe<SearchResults>;
  /** Filters products */
  productFilter?: Maybe<GraphQlPage_ProductEntity>;
  /** Locates a partner by slug */
  affiliateBySlug?: Maybe<PartnerEntity>;
  /** Provides geolocation info for an IP address */
  geolocationByIpAddress?: Maybe<GeolocationType>;
  /** Locates a configurable product by id */
  configurableProductInfo?: Maybe<ConfigurableProductEntity>;
  /** Locates a department by slug */
  departmentBySlug?: Maybe<DepartmentEntity>;
  /** Calculates a secure hash */
  userSecureHash?: Maybe<Scalars['String']>;
  /** Filters simple products */
  simpleProductFilter?: Maybe<GraphQlPage_SimpleProductEntity>;
  /** Locates a product by id */
  productInfo?: Maybe<ProductEntity>;
  /** Locates a discount by id */
  discountInfo?: Maybe<DiscountEntity>;
  /** Filters users */
  userFilter?: Maybe<GraphQlPage_UserEntity>;
  /** Filter shipments */
  shipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Locates a media by id */
  mediaInfo?: Maybe<MediaEntity>;
  /** Filters sales orders */
  salesOrderFilter?: Maybe<GraphQlPage_SalesOrderEntity>;
  /** Lists products */
  productList?: Maybe<GraphQlPage_ProductEntity>;
  /** Locates a kit product by slug */
  kitProductBySlug?: Maybe<KitProductEntity>;
  /** Lists upcoming tradeshows */
  tradeShowListUpcoming?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Locates a simple product by slug */
  simpleProductBySlug?: Maybe<SimpleProductEntity>;
  /** Locates a simple product by bin */
  simpleProductFindByBin?: Maybe<SimpleProductEntity>;
  /** Returns a purchase request by id */
  purchaseRequestInfo?: Maybe<PurchaseRequestEntity>;
  /** Retrieves a reship sales order */
  reshipSalesOrderInfo?: Maybe<ReshipSalesOrderEntity>;
  /** Lists warehouses */
  dropshipWarehouseList?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Lists departments */
  departmentList?: Maybe<GraphQlPage_DepartmentEntity>;
  /** Returns the gift card's balance */
  giftCardBalance?: Maybe<Scalars['BigDecimal']>;
  /** Locates a team by slug */
  teamBySlug?: Maybe<TeamEntity>;
  /** Provides user info by email */
  userFindByEmail?: Maybe<UserEntity>;
  /** Locates a blog by slug */
  blogBySlug?: Maybe<BlogEntity>;
  /** Lists prepped products */
  preppedProductsList?: Maybe<Array<Maybe<PreparationSummary>>>;
  /** Lists dropshipper partners */
  partnerListDropshippers?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Locates a configurable product by slug */
  configurableProductBySlug?: Maybe<ConfigurableProductEntity>;
  /** Lists address for current user */
  addressList?: Maybe<Array<Maybe<AddressEntity>>>;
  /** Retrieves a subscription */
  subscriptionInfo?: Maybe<SubscriptionEntity>;
  /** Locates a category by path */
  categoryByPath?: Maybe<CategoryResults>;
  /** Queries a preparation summary for a product */
  preppedProductsInfo?: Maybe<PreparationSummary>;
  /** Lists configurable products */
  configurableProductList?: Maybe<GraphQlPage_ConfigurableProductEntity>;
  /** Locates a simple product by id */
  simpleProductInfo?: Maybe<SimpleProductEntity>;
  /** Locates an listed product by id */
  listedProductInfo?: Maybe<ListedProductEntity>;
  /** Filter shipments */
  assistedScheduledShipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Filters configurable products */
  configurableProductFilter?: Maybe<GraphQlPage_ConfigurableProductEntity>;
  /** Lists subscriptions */
  subscriptionList?: Maybe<GraphQlPage_SubscriptionEntity>;
  /** Filters media */
  mediaFilter?: Maybe<GraphQlPage_MediaEntity>;
  /** Locates a simple product by UPC */
  simpleProductFindByUpc?: Maybe<SimpleProductEntity>;
  /** Locates the root category */
  categoryRoot?: Maybe<CategoryEntity>;
  /** Filters kit products */
  kitProductFilter?: Maybe<GraphQlPage_KitProductEntity>;
  /** Lists simple products */
  simpleProductList?: Maybe<GraphQlPage_SimpleProductEntity>;
  /** Locates a promotion by id */
  promotionInfo?: Maybe<PromotionEntity>;
  /** Provides geolocation info for current user */
  geolocationSelf?: Maybe<GeolocationType>;
  /** Locates a simple product by SKU */
  simpleProductFindBySku?: Maybe<SimpleProductEntity>;
  /** Lists simples product by sku list */
  productListBySkus?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Search blogs */
  blogSearch?: Maybe<GraphQlPage_BlogEntity>;
  /** Gets a journal entry for the reseller */
  financialResellerRevenueJournal?: Maybe<JournalEntry>;
  /** Lists promotions */
  promotionList?: Maybe<GraphQlPage_PromotionEntity>;
  /** Filters tradeshows */
  tradeShowFilter?: Maybe<GraphQlPage_TradeShowEntity>;
  simpleProductProfitabilityReport?: Maybe<GraphQlPage_ProfitabilitySummary>;
  /** Filter shipments */
  dropshipShipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Lists animals */
  animalList?: Maybe<GraphQlPage_AnimalEntity>;
  /** Locates a partner by id */
  partnerInfo?: Maybe<PartnerEntity>;
  /** Gets health of Rabbit MQ */
  healthRabbit?: Maybe<Scalars['Int']>;
  /** Lists all blog topics */
  blogTopicList?: Maybe<Array<Maybe<BlogTopicEntity>>>;
  /** Lists partners which user has access to */
  partnerList?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Filters partners */
  partnerFilter?: Maybe<GraphQlPage_PartnerEntity>;
  /** Filters purchase orders */
  purchaseOrderFilter?: Maybe<GraphQlPage_PurchaseOrderEntity>;
  /** Filters blocks */
  blockFilter?: Maybe<GraphQlPage_BlockEntity>;
  /** Search categories */
  categorySearch?: Maybe<GraphQlPage_CategoryEntity>;
  /** Calculates a secure hash */
  shipmentSecureHash?: Maybe<Scalars['String']>;
  scheduledShipmentHoldLocations?: Maybe<Array<Maybe<HoldLocation>>>;
  /** Filters purchase requests */
  purchaseRequestFilter?: Maybe<GraphQlPage_PurchaseRequestEntity>;
  /** Provides warehouse info */
  warehouseFindByName?: Maybe<WarehouseEntity>;
  /** Lists reseller partners */
  partnerListResellers?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Searches for a shipment */
  shipmentSearch?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Locates a promotion by slug */
  promotionBySlug?: Maybe<PromotionEntity>;
  /** Lists care sheets */
  careSheetList?: Maybe<GraphQlPage_CareSheetEntity>;
  /** Locates a configurable product by SKU */
  configurableProductFindBySku?: Maybe<ConfigurableProductEntity>;
  /** Provides warehouse info */
  warehouseList?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Pulls information for a shipment */
  dropshipShipmentFind?: Maybe<ShipmentEntity>;
  /** Locates a category by slug */
  categoryBySlug?: Maybe<CategoryResults>;
  /** Locates a product list by slug */
  affiliateGetProductListBySlug?: Maybe<AffiliateProductListEntity>;
  /** Provides user info for current user */
  userSelf?: Maybe<UserEntity>;
  /** Pulls information for a shipment */
  dropshipShipmentInfo?: Maybe<ShipmentEntity>;
  /** Locates a category by id */
  categoryInfo?: Maybe<CategoryEntity>;
  /** Provides cart info for current user */
  cartInfo?: Maybe<CartEntity>;
  /** Looks up a shipment by secure hash */
  shipmentByHash?: Maybe<ShipmentEntity>;
  /** Locates a care sheet by id */
  careSheetInfo?: Maybe<CareSheetEntity>;
  /** Retrieves a refund */
  refundInfo?: Maybe<RefundEntity>;
  /** Locates an listed product by SKU */
  listedProductFindBySku?: Maybe<ListedProductEntity>;
  /** Locates content by slug */
  contentBySlug?: Maybe<ContentEntity>;
  /** Returns a tradeshow */
  tradeShowInfo?: Maybe<TradeShowEntity>;
  /** Product Info */
  productViewBySlug?: Maybe<IGenericProductEntity>;
  /** Lists discounts */
  discountList?: Maybe<GraphQlPage_DiscountEntity>;
  /** Lists subscriptions */
  assistedSubscriptionList?: Maybe<GraphQlPage_SubscriptionEntity>;
  /** Looks up an order by secure hash */
  salesOrderByHash?: Maybe<SalesOrderEntity>;
  /** Locates an listed product by UPC */
  listedProductFindByUpc?: Maybe<ListedProductEntity>;
  /** Retrieves a shipment */
  shipmentFind?: Maybe<ShipmentEntity>;
  /** Lists brands */
  brandList?: Maybe<GraphQlPage_BrandEntity>;
  /** Provides user info a user */
  giftCardInfo?: Maybe<GiftCardEntity>;
  /** Locates a kit product by UPC */
  kitProductFindByUpc?: Maybe<KitProductEntity>;
  /** Retrieves a subscription */
  assistedSubscriptionInfo?: Maybe<SubscriptionEntity>;
  /** Filters gift cards */
  giftCardFilter?: Maybe<GraphQlPage_GiftCardEntity>;
  /** Returns open purchase order lines for a simple product */
  purchaseOrderOpenItems?: Maybe<Array<Maybe<PurchaseOrderItemEntity>>>;
  /** Returns a purchase order by id */
  purchaseOrderInfo?: Maybe<PurchaseOrderEntity>;
  /** Lists categories */
  categoryList?: Maybe<GraphQlPage_CategoryEntity>;
  /** Fetches brand info */
  brandInfo?: Maybe<BrandEntity>;
  /** Locates a kit product by SKU */
  kitProductFindBySku?: Maybe<KitProductEntity>;
  /** Retrieves a shipment */
  shipmentInfo?: Maybe<ShipmentEntity>;
  /** Filters listed products */
  listedProductFilter?: Maybe<GraphQlPage_ListedProductEntity>;
  /** Provides zone info */
  zoneList?: Maybe<Array<Maybe<ZoneEntity>>>;
  /** Lists shipping rules */
  shippingRuleSetList?: Maybe<GraphQlPage_ShippingRuleSetEntity>;
  /** Locates an listed product by slug */
  listedProductBySlug?: Maybe<ListedProductEntity>;
  /** Lists sales orders */
  salesOrderList?: Maybe<GraphQlPage_SalesOrderEntity>;
  /** Locates a kit product by id */
  kitProductInfo?: Maybe<KitProductEntity>;
  /** Lists blogs */
  blogList?: Maybe<GraphQlPage_BlogEntity>;
  /** Returns the user's rewards account */
  rewardPointsGetRewardsAccount?: Maybe<RewardsAccountEntity>;
  /** Lists tradeshows in pre-order window */
  tradeShowListInPreOrder?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Filters warehouse info */
  warehouseFilter?: Maybe<GraphQlPage_WarehouseEntity>;
  /** Lists categories */
  categoryFilter?: Maybe<GraphQlPage_CategoryEntity>;
  /** Retrieves a sales order */
  salesOrderInfo?: Maybe<SalesOrderEntity>;
  /** Locates a care sheet by slug */
  careSheetBySlug?: Maybe<CareSheetEntity>;
  /** Gets a journal entry for the website */
  financialWebsiteRevenueJournal?: Maybe<JournalEntry>;
  /** Locates a blog by id */
  blogInfo?: Maybe<BlogEntity>;
};


/** Query root */
export type QueryUserInfoArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryDiscountBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryDropshipDropshipperProfileFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySalesOrderComputeBestShippingArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCareSheetFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySalesOrderNukeAndComputeBestShippingArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderGeneratePdfArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryReviewListForProductArgs = {
  productId: Scalars['UUID'];
  rating?: Maybe<Scalars['Long']>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QuerySupplierInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryBlogFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryAffiliateGetProductListByIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryKitProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryTeamListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QuerySupplierListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryBlockInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryProductSearchArgs = {
  featured?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  price?: Maybe<GraphQlValueRangeQueryFilter_BigDecimalInput>;
  query?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlValueRangeQueryFilter_DoubleInput>;
  animal?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  subscription?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  category?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  promotion?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryProductFilterArgs = {
  visible?: Maybe<Scalars['Boolean']>;
  upc?: Maybe<Scalars['String']>;
  minRating?: Maybe<Scalars['Long']>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['Boolean']>;
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  minStock?: Maybe<Scalars['Long']>;
};


/** Query root */
export type QueryAffiliateBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryGeolocationByIpAddressArgs = {
  ipAddress?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryConfigurableProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDepartmentBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryUserSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySimpleProductFilterArgs = {
  supplier?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDiscountInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryUserFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  admin?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  late?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouse?: Maybe<GraphQlSingleValueFilter_StringInput>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  shipmentStatus?: Maybe<GraphQlSingleValueFilter_ShipmentStatusInput>;
};


/** Query root */
export type QueryMediaInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderFilterArgs = {
  orderType?: Maybe<GraphQlSingleValueFilter_StringInput>;
  billingLastName?: Maybe<GraphQlLikeQueryFilterInput>;
  orderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  alternateOrderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  billingFirstName?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  hold?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  status?: Maybe<GraphQlSingleValueFilter_SalesOrderStatusInput>;
};


/** Query root */
export type QueryProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryKitProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySimpleProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySimpleProductFindByBinArgs = {
  binId: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryPurchaseRequestInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryReshipSalesOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipWarehouseListArgs = {
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryDepartmentListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryGiftCardBalanceArgs = {
  code: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryTeamBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryUserFindByEmailArgs = {
  email: Scalars['String'];
};


/** Query root */
export type QueryBlogBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryPreppedProductsListArgs = {
  shipDate?: Maybe<Scalars['LocalDate']>;
  department: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryConfigurableProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySubscriptionInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryByPathArgs = {
  path: Scalars['String'];
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPreppedProductsInfoArgs = {
  productId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryConfigurableProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QuerySimpleProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAssistedScheduledShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouse?: Maybe<Scalars['String']>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  schedulingStatus?: Maybe<GraphQlSingleValueFilter_SchedulingStatusTypeInput>;
};


/** Query root */
export type QueryConfigurableProductFilterArgs = {
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySubscriptionListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryMediaFilterArgs = {
  keywords?: Maybe<Scalars['String']>;
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
};


/** Query root */
export type QuerySimpleProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryKitProductFilterArgs = {
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySimpleProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryPromotionInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySimpleProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryProductListBySkusArgs = {
  skus: Array<Maybe<Scalars['String']>>;
};


/** Query root */
export type QueryBlogSearchArgs = {
  query: Scalars['String'];
  animal?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryFinancialResellerRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryPromotionListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryTradeShowFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QuerySimpleProductProfitabilityReportArgs = {
  endDate: Scalars['LocalDate'];
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryDropshipShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  late?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  warehouse?: Maybe<GraphQlSingleValueFilter_StringInput>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  shipmentStatus?: Maybe<GraphQlSingleValueFilter_ShipmentStatusInput>;
};


/** Query root */
export type QueryAnimalListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPartnerInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPartnerFilterArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryPurchaseOrderFilterArgs = {
  orderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  supplier?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  status?: Maybe<PurchaseOrderStatus>;
};


/** Query root */
export type QueryBlockFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryCategorySearchArgs = {
  query: Scalars['String'];
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryShipmentSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryScheduledShipmentHoldLocationsArgs = {
  postalCode: Scalars['String'];
  miles?: Maybe<Scalars['BigDecimal']>;
};


/** Query root */
export type QueryPurchaseRequestFilterArgs = {
  requestNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  team?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseRequestStatus>;
};


/** Query root */
export type QueryWarehouseFindByNameArgs = {
  name: Scalars['String'];
};


/** Query root */
export type QueryShipmentSearchArgs = {
  query: Scalars['String'];
};


/** Query root */
export type QueryPromotionBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryCareSheetListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryConfigurableProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryDropshipShipmentFindArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryBySlugArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  slug: Scalars['String'];
};


/** Query root */
export type QueryAffiliateGetProductListBySlugArgs = {
  affiliateSlug: Scalars['String'];
  slug: Scalars['String'];
};


/** Query root */
export type QueryDropshipShipmentInfoArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryShipmentByHashArgs = {
  hash: Scalars['String'];
};


/** Query root */
export type QueryCareSheetInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRefundInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryContentBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryTradeShowInfoArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryProductViewBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryDiscountListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryAssistedSubscriptionListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  userId: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderByHashArgs = {
  hash: Scalars['String'];
};


/** Query root */
export type QueryListedProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryShipmentFindArgs = {
  shipmentNumber: Scalars['String'];
};


/** Query root */
export type QueryBrandListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryGiftCardInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryKitProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryAssistedSubscriptionInfoArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryGiftCardFilterArgs = {
  code?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPurchaseOrderOpenItemsArgs = {
  simpleProductId: Scalars['UUID'];
};


/** Query root */
export type QueryPurchaseOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryBrandInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryKitProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryShipmentInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductFilterArgs = {
  supplier?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryShippingRuleSetListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryListedProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySalesOrderListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryKitProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryBlogListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryWarehouseFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouseType?: Maybe<GraphQlSingleValueFilter_WarehouseTypeInput>;
  enabled?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
};


/** Query root */
export type QueryCategoryFilterArgs = {
  active?: Maybe<Scalars['Boolean']>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QuerySalesOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCareSheetBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryFinancialWebsiteRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryBlogInfoArgs = {
  id: Scalars['UUID'];
};

export type QueueEntity = {
  __typename?: 'QueueEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Queue's combos only */
  combosOnly?: Maybe<Scalars['Boolean']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Queue's enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Queue's name */
  name?: Maybe<Scalars['String']>;
  /** Queue's priority */
  priority?: Maybe<Scalars['Long']>;
  /** Queue's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Queue's shipping service */
  shippingService?: Maybe<Scalars['String']>;
  /** Queue's singles only */
  singlesOnly?: Maybe<Scalars['Boolean']>;
  /** Queue's slug */
  slug?: Maybe<Scalars['String']>;
  /** Queue's templates */
  template?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Queue's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Queue's zone */
  zone?: Maybe<ZoneEntity>;
};

export type RateQuote = {
  __typename?: 'RateQuote';
  /** Rate quote's carrier */
  carrier?: Maybe<Carrier>;
  /** Rate quote's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Rate quote's delivery date */
  deliveryDate?: Maybe<Scalars['String']>;
  /** Rate quote's domestic service type */
  domesticServiceType?: Maybe<ServiceType>;
  /** Rate quote's fitness */
  fitness?: Maybe<RateQuoteFitness>;
  /** Rate quote's options */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Rate quote's packaging */
  packaging?: Maybe<Packaging>;
  /** Rate quote's postage reseller */
  reseller?: Maybe<Reseller>;
  /** Rate quote's service */
  service?: Maybe<Service>;
  /** Rate quote's ship date */
  shipDate?: Maybe<Scalars['String']>;
};

export type RefundEntity = {
  __typename?: 'RefundEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Refund's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Refund's placed at time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Refund's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's items */
  refundItems?: Maybe<Array<Maybe<RefundItemEntity>>>;
  /** Refund's refund number */
  refundNumber?: Maybe<Scalars['String']>;
  /** Refund's refund reason */
  refundReason?: Maybe<RefundReason>;
  /** Refund's representative first name */
  repFirstName?: Maybe<Scalars['String']>;
  /** Refund's representative last name */
  repLastName?: Maybe<Scalars['String']>;
  /** Refund's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Refund's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RefundItemEntity = {
  __typename?: 'RefundItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Refund item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Refund item's refund */
  refund?: Maybe<RefundEntity>;
  /** Refund item's sales order item */
  salesOrderItem?: Maybe<SalesOrderItemEntity>;
  /** Refund item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RefundableAmounts = {
  __typename?: 'RefundableAmounts';
  /** Handling amount eligible for refund */
  handling?: Maybe<Scalars['BigDecimal']>;
  /** Product discount amount eligible for refund */
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  /** Shipping amount eligible for refund */
  shipping?: Maybe<Scalars['BigDecimal']>;
  /** Shipping discount amount eligible for refund */
  shippingDiscount?: Maybe<Scalars['BigDecimal']>;
  /** Product sub total eligible for refund */
  subTotal?: Maybe<Scalars['BigDecimal']>;
};

export type ResellerProfileEntity = {
  __typename?: 'ResellerProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Reseller's content */
  content?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Reseller's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Profile's reseller */
  reseller?: Maybe<PartnerEntity>;
  /** Reseller's SKU */
  resellerSku?: Maybe<Scalars['String']>;
  /** Reseller's retail */
  retail?: Maybe<Scalars['BigDecimal']>;
  /** Reseller's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ReshipSalesOrderEntity = {
  __typename?: 'ReshipSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Reship sales order's parent order */
  parentOrder?: Maybe<SalesOrderEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Reship sales order's refund reason */
  refundReason?: Maybe<RefundReason>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Reship sales order's representative first name */
  repFirstName?: Maybe<Scalars['String']>;
  /** Reship sales order's representative last name */
  repLastName?: Maybe<Scalars['String']>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Review's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Review's rating */
  rating?: Maybe<Scalars['Long']>;
  /** Review's reviewer */
  reviewer?: Maybe<Scalars['String']>;
  /** Review's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ReviewResults = {
  __typename?: 'ReviewResults';
  /** Result's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Result's count 0 to 20 */
  count0To20?: Maybe<Scalars['Long']>;
  /** Result's count 21 to 40 */
  count21To40?: Maybe<Scalars['Long']>;
  /** Result's count 41 to 60 */
  count41To60?: Maybe<Scalars['Long']>;
  /** Result's count 61 to 80 */
  count61To80?: Maybe<Scalars['Long']>;
  /** Result's count 81 to 100 */
  count81To100?: Maybe<Scalars['Long']>;
  /** Result's reviews */
  reviews?: Maybe<GraphQlPage_ReviewEntity>;
};

export type RewardsAccountEntity = {
  __typename?: 'RewardsAccountEntity';
  /** Rewards account's balance */
  balance?: Maybe<Scalars['Long']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Rewards account's transactions */
  transactions?: Maybe<Array<Maybe<RewardsTransactionEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RewardsTransactionEntity = {
  __typename?: 'RewardsTransactionEntity';
  /** Rewards transaction's amount */
  amount?: Maybe<Scalars['Long']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Rewards transaction's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Rewards transaction's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Rewards transaction's type */
  transactionType?: Maybe<RewardsTransactionType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderCommentsEntity = {
  __typename?: 'SalesOrderCommentsEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Comment's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Comment's name */
  name?: Maybe<Scalars['String']>;
  /** Comment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Comment's time */
  time?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderDiscountEntity = {
  __typename?: 'SalesOrderDiscountEntity';
  /** Sales order discount's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order discount's name */
  name?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderEntity = {
  __typename?: 'SalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Order type */
  orderType?: Maybe<OrderType>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type SalesOrderItemEntity = {
  __typename?: 'SalesOrderItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order item's discount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's expected delivery */
  expectedDelivery?: Maybe<Scalars['LocalDate']>;
  /** Order item's expected ship */
  expectedShip?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order item's line cost */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Order item's name */
  name?: Maybe<Scalars['String']>;
  /** Order item's overridden carrier preference */
  overriddenCarrierPreference?: Maybe<Carrier>;
  /** Order item's overridden service type */
  overriddenDomesticServiceType?: Maybe<ServiceType>;
  /** Order item's overridden shipping needs */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Order item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Order item's group */
  salesOrderItemGroup?: Maybe<SalesOrderItemGroupEntity>;
  /** Order item's shipping amount */
  shippingAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Order item's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Order item's tax amount */
  taxAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order item's unit cost */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's unit weight */
  unitWeight?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderItemGroupEntity = {
  __typename?: 'SalesOrderItemGroupEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order item group's discount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order item group's kit product */
  kitProduct?: Maybe<KitProductEntity>;
  /** Order item group's line cost */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item group's name */
  name?: Maybe<Scalars['String']>;
  /** Order item group's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Order item group's order items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order item group's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Order item group's unit cost */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderLineItem = {
  __typename?: 'SalesOrderLineItem';
  id?: Maybe<Scalars['UUID']>;
  quantity?: Maybe<Scalars['Long']>;
};

export type SchedulingDashboard = {
  __typename?: 'SchedulingDashboard';
  /** Dashboard's needs approval count */
  needsApprovalCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs picked count */
  needsPickedCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs shipped count */
  needsShippedCount?: Maybe<Scalars['Long']>;
  /** Dashboard's next open date */
  nextOpenDate?: Maybe<Scalars['String']>;
  /** Dashboard's scheduled in future count */
  scheduledInFutureCount?: Maybe<Scalars['Long']>;
  /** Dashboard's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Dashboard's to reschedule count */
  toRescheduleCount?: Maybe<Scalars['Long']>;
  /** Dashboard's today */
  today?: Maybe<Scalars['String']>;
  /** Dashboard's unscheduled count */
  unscheduledCount?: Maybe<Scalars['Long']>;
  /** Dashboard's warehouse */
  warehouse?: Maybe<Scalars['String']>;
};

export type SearchResults = {
  __typename?: 'SearchResults';
  /** Result's animal */
  animal?: Maybe<AnimalEntity>;
  /** Result's brand */
  brand?: Maybe<BrandEntity>;
  /** Result's category */
  category?: Maybe<CategoryEntity>;
  /** Result's products */
  products?: Maybe<GraphQlPage_ProductEntity>;
};

export type ShipmentAddonEntity = {
  __typename?: 'ShipmentAddonEntity';
  /** Shipment addon's addon */
  addon?: Maybe<AddonEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment addon's name */
  name?: Maybe<Scalars['String']>;
  /** Shipment addon's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Shipment addon's shipment */
  shipment?: Maybe<ShipmentEntity>;
  /** Shipment addon's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShipmentEntity = {
  __typename?: 'ShipmentEntity';
  /** Shipment's actual height */
  actualHeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual length */
  actualLength?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual shipping cost */
  actualShippingCost?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual weight */
  actualWeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual width */
  actualWidth?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's address validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipment's cancellation reason */
  cancellationReason?: Maybe<CancellationReason>;
  /** Shipment's cancelled at */
  cancelledAt?: Maybe<Scalars['Instant']>;
  /** Shipment's cancelled by */
  cancelledBy?: Maybe<Scalars['String']>;
  /** Shipment's carrier */
  carrier?: Maybe<Carrier>;
  /** Shipment's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Shipment's city */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Shipment's company */
  company?: Maybe<Scalars['String']>;
  /** Shipment's content hash */
  contentsMd5?: Maybe<Scalars['String']>;
  /** Shipment's country */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipment's departing warehouse */
  departingWarehouse?: Maybe<WarehouseEntity>;
  /** Shipment's download start */
  downloadStart?: Maybe<Scalars['Instant']>;
  /** Shipment's dropshipper */
  dropshipper?: Maybe<PartnerEntity>;
  /** Shipment's email */
  email?: Maybe<Scalars['String']>;
  /** Shipment's estimated delivery date */
  estimatedDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated height */
  estimatedHeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated length */
  estimatedLength?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated ship date */
  estimatedShipDate?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated shipping cost */
  estimatedShippingCost?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated weight */
  estimatedWeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated width */
  estimatedWidth?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's final approval */
  finalApproval?: Maybe<Scalars['Boolean']>;
  /** Shipment's final approval time */
  finalApprovalBy?: Maybe<Scalars['String']>;
  /** Shipment's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Shipment's handling cost */
  handlingCost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Shipment's line 1 */
  line1?: Maybe<Scalars['String']>;
  /** Shipment's line 2 */
  line2?: Maybe<Scalars['String']>;
  /** Shipment's options */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's origin warehouse */
  originWarehouse?: Maybe<WarehouseEntity>;
  /** Shipment's packaging */
  packaging?: Maybe<Packaging>;
  /** Shipment's placed at time */
  placedAt?: Maybe<Scalars['Instant']>;
  /** Shipment's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Shipment's printed end */
  printedEnd?: Maybe<Scalars['Instant']>;
  /** Shipment's printed start */
  printedStart?: Maybe<Scalars['Instant']>;
  /** Shipment's queue */
  queue?: Maybe<QueueEntity>;
  /** Shipment's reprint status */
  reprint?: Maybe<Scalars['Boolean']>;
  /** Shipment's postage reseller */
  reseller?: Maybe<Reseller>;
  /** Shipment's residential flag */
  residential?: Maybe<Scalars['Boolean']>;
  /** Shipment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Shipment's scheduled at time */
  scheduledAt?: Maybe<Scalars['Instant']>;
  /** Shipment's scheduler */
  scheduledBy?: Maybe<Scalars['String']>;
  schedulingStatus?: Maybe<SchedulingStatusType>;
  /** Shipment's service */
  service?: Maybe<Service>;
  /** Shipment's addons */
  shipmentAddons?: Maybe<Array<Maybe<ShipmentAddonEntity>>>;
  /** Shipment's items */
  shipmentItems?: Maybe<Array<Maybe<ShipmentItemEntity>>>;
  /** Shipment's shipment number */
  shipmentNumber?: Maybe<Scalars['String']>;
  /** Shipment's status */
  shipmentStatus?: Maybe<ShipmentStatus>;
  /** Shipment's shipped at time */
  shippedAt?: Maybe<Scalars['Instant']>;
  /** Shipment's shipper */
  shippedBy?: Maybe<Scalars['String']>;
  /** Shipment's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Shipment's state */
  state?: Maybe<Scalars['String']>;
  /** Shipment's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Shipment's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Shipment's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Shipment's tracking number (deprecated) */
  trackingNo?: Maybe<Scalars['String']>;
  /** Shipment's tracking number */
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's tracking number (deprecated) */
  trackingUrl?: Maybe<Scalars['String']>;
  /** Shipment's tracking urls */
  trackingUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipment's label ZPL content (deprecated) */
  zplContent?: Maybe<Scalars['String']>;
  /** Shipment's label ZPL contents */
  zplContents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ShipmentItemEntity = {
  __typename?: 'ShipmentItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Shipment item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Shipment item's sales order item */
  salesOrderItem?: Maybe<SalesOrderItemEntity>;
  /** Shipment item's shipment */
  shipment?: Maybe<ShipmentEntity>;
  /** Shipment item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipment item's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ShippingEstimateEntity = {
  __typename?: 'ShippingEstimateEntity';
  /** Shipping estimates carrier */
  carrier?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Shipping estimates cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates country */
  country?: Maybe<Country>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipping estimates height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping estimates length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates service */
  service?: Maybe<Service>;
  /** Shipping estimates service type */
  serviceType?: Maybe<ServiceType>;
  /** Shipping estimates state */
  state?: Maybe<State>;
  /** Shipping estimates transit days */
  transitDays?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipping estimates weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates width */
  width?: Maybe<Scalars['BigDecimal']>;
};

export type ShippingRuleSetEntity = {
  __typename?: 'ShippingRuleSetEntity';
  /** Shipping rule set's addon destination requirements */
  addonSetDestinationRequirements?: Maybe<Array<Maybe<AddonSetDestinationRequirementEntity>>>;
  /** Shipping rule set's addon source requirements */
  addonSetSourceRequirements?: Maybe<Array<Maybe<AddonSetSourceRequirementEntity>>>;
  /** Shipping rule set's carrier restriction */
  carrierRestriction?: Maybe<CarrierRestrictionEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipping rule set's live destination restriction */
  destinationRestriction?: Maybe<DestinationRestrictionEntity>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule set's live arrival guarantees */
  liveArrivalGuarantees?: Maybe<Array<Maybe<LiveArrivalGuaranteeEntity>>>;
  /** Shipping rule set's name */
  name?: Maybe<Scalars['String']>;
  /** Shipping rule set's ships alone restriction */
  shipsAloneRestriction?: Maybe<ShipsAloneRestrictionEntity>;
  /** Shipping rule set's slug */
  slug?: Maybe<Scalars['String']>;
  /** Shipping rule set's transit time restriction */
  transitTimeRestriction?: Maybe<TransitTimeRestrictionEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShipsAloneRestrictionEntity = {
  __typename?: 'ShipsAloneRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShowReport = {
  __typename?: 'ShowReport';
  /** Show report's city */
  city?: Maybe<Scalars['String']>;
  /** Show report's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Show report's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's line items */
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  /** Show report's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's show name */
  showName?: Maybe<Scalars['String']>;
  /** Show report's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
  /** Show report's state */
  state?: Maybe<Scalars['String']>;
  /** Show report's subtotal */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
};

export type SimpleProductEntity = {
  __typename?: 'SimpleProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Simple product's bins */
  bins?: Maybe<Array<Maybe<BinEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Simple product's case height */
  caseHeight?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's case length */
  caseLength?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's case quantity */
  caseQuantity?: Maybe<Scalars['Long']>;
  /** Simple product's case width */
  caseWidth?: Maybe<Scalars['BigDecimal']>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Simple product's component items */
  componentItems?: Maybe<Array<Maybe<BomItemEntity>>>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sells */
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's discontinuation reason */
  discontinuationReason?: Maybe<DiscontinuationReasonType>;
  /** Simple product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Simple product's dropshipper profiles */
  dropshipperProfiles?: Maybe<Array<Maybe<DropshipperProfileEntity>>>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Simple product's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Simple product's inventory quantity caches */
  inventoryQuantityCaches?: Maybe<Array<Maybe<InventoryQuantityCacheEntity>>>;
  /** Simple product's inventory stats caches */
  inventoryStatsCaches?: Maybe<Array<Maybe<InventoryStatsCacheEntity>>>;
  /** Simple product's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Simple product's Magento vendor */
  magentoVendor?: Maybe<Vendor>;
  /** Simple product's MAP price */
  mapPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Simple product's minimum order quantity */
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
  /** Simple product's not ships alone */
  notShipsAlone?: Maybe<Scalars['Boolean']>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Simple product's requires prep */
  requiresPrep?: Maybe<Scalars['Boolean']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Simple product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Simple product's shipping restrictions */
  shippingRestrictions?: Maybe<Scalars['String']>;
  /** Simple product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Simple product's ships alone */
  shipsAlone?: Maybe<Scalars['Boolean']>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  /** Simple product's supplier */
  supplier?: Maybe<SupplierEntity>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Simple product's vendor reference */
  vendorRef?: Maybe<Scalars['String']>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Simple product's warehouses */
  warehouses?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Simple product's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's width */
  width?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's zones */
  zones?: Maybe<Array<Maybe<ZoneEntity>>>;
};

export type Stats = {
  __typename?: 'Stats';
  /** Stat's end date */
  endDate?: Maybe<Scalars['String']>;
  /** Stat's on-hold count */
  onHold?: Maybe<Scalars['Int']>;
  /** Stat's shipped late */
  shippedLate?: Maybe<Scalars['Int']>;
  /** Stat's shipped on-time */
  shippedOnTime?: Maybe<Scalars['Int']>;
  /** Stat's start date */
  startDate?: Maybe<Scalars['String']>;
  /** Stat's unshipped count */
  unshipped?: Maybe<Scalars['Int']>;
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential flag */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Subscription's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Subscription's delivery day of week */
  deliveryDay?: Maybe<DayOfWeek>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  paymentMethod?: Maybe<PaymentMethodDetail>;
  /** Subscription's phone */
  phone?: Maybe<Scalars['String']>;
  /** Subscription's sales orders */
  salesOrders?: Maybe<Array<Maybe<SubscriptionSalesOrderEntity>>>;
  /** Subscription's service type */
  serviceType?: Maybe<ServiceType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Subscription's items */
  subscriptionItems?: Maybe<Array<Maybe<SubscriptionItemEntity>>>;
  /** Subscription's tax-exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SubscriptionItemEntity = {
  __typename?: 'SubscriptionItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Subscriptions item's frequency */
  frequency?: Maybe<Scalars['Long']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Subscription item's last delivery date */
  lastDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Subscriptions item's next delivery date */
  nextDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Subscriptions item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Subscriptions item's product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SubscriptionSalesOrderEntity = {
  __typename?: 'SubscriptionSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type Summary = {
  __typename?: 'Summary';
  /** Summary's date */
  date?: Maybe<Scalars['String']>;
  /** Summary's on-hold count */
  onHold?: Maybe<Scalars['Long']>;
  /** Summary's shipped count */
  shipped?: Maybe<Scalars['Long']>;
  /** Summary's unshipped count */
  unshipped?: Maybe<Scalars['Long']>;
};

export type SupplierEntity = {
  __typename?: 'SupplierEntity';
  /** Supplier's validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Supplier's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Supplier's company */
  company?: Maybe<Scalars['String']>;
  /** Supplier's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Supplier's email address */
  email?: Maybe<Scalars['String']>;
  /** Supplier's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Supplier's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Supplier's lead time */
  leadTime?: Maybe<Scalars['Int']>;
  /** Supplier's first line */
  line1?: Maybe<Scalars['String']>;
  /** Supplier's last line */
  line2?: Maybe<Scalars['String']>;
  /** Supplier's name */
  name?: Maybe<Scalars['String']>;
  /** Supplier's order cycle */
  orderCycle?: Maybe<Scalars['Int']>;
  /** Supplier's payment terms */
  paymentTerms?: Maybe<Scalars['Int']>;
  /** Supplier's phone */
  phone?: Maybe<Scalars['String']>;
  /** Supplier's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Supplier's residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Supplier's simple products */
  simpleProducts?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** Supplier's slug */
  slug?: Maybe<Scalars['String']>;
  /** Supplier's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type TaxDetail = {
  __typename?: 'TaxDetail';
  /** Tax detail's rate */
  rate?: Maybe<Scalars['BigDecimal']>;
  /** Tax detail's shipping taxed */
  shippingTaxed?: Maybe<Scalars['Boolean']>;
  /** Tax detail's state */
  state?: Maybe<State>;
};

export type TeamEntity = {
  __typename?: 'TeamEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Team's name */
  name?: Maybe<Scalars['String']>;
  /** Team's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type TrackingEvent = {
  __typename?: 'TrackingEvent';
  /** Tracking event's comment */
  comment?: Maybe<Scalars['String']>;
  /** Tracking event's location */
  location?: Maybe<Scalars['String']>;
  /** Tracking event's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Tracking event's type */
  trackingEventType?: Maybe<TrackingEventType>;
};

export type TradeShowEntity = {
  __typename?: 'TradeShowEntity';
  carts?: Maybe<Array<Maybe<CartEntity>>>;
  /** Trade show's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Trade show's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Trade show's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Trade show's first line */
  line1?: Maybe<Scalars['String']>;
  /** Trade show's last line */
  line2?: Maybe<Scalars['String']>;
  /** Trade show's name */
  name?: Maybe<Scalars['String']>;
  posTerminals?: Maybe<Array<Maybe<PosTerminalEntity>>>;
  /** Trade show's number of POS units requested */
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  /** Trade show's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Trade show's pre-order end date */
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  /** Trade show's pre-order start date */
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  salesOrders?: Maybe<Array<Maybe<SalesOrderEntity>>>;
  /** Trade show's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
  /** Trade show's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Trade show's sales tax rate */
  taxRate?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Trade show's url */
  url?: Maybe<Scalars['String']>;
};

export type TransitTimeRestrictionEntity = {
  __typename?: 'TransitTimeRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Transit time rule's transit time type */
  transitTimeType?: Maybe<TransitTimeType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  /** User's addresses */
  addresses?: Maybe<Array<Maybe<AddressEntity>>>;
  /** User is an admin */
  admin?: Maybe<Scalars['Boolean']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** User's email address */
  email?: Maybe<Scalars['String']>;
  /** User's email is confirmed */
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  /** User's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** User's last name */
  lastName?: Maybe<Scalars['String']>;
  /** User's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** User's net terms */
  netTerms?: Maybe<Scalars['Int']>;
  /** User's roles */
  roles?: Maybe<Array<Maybe<RoleType>>>;
  /** User's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** User's type */
  userType?: Maybe<UserType>;
};

export type VariationSetEntity = {
  __typename?: 'VariationSetEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Variation set's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Variation set's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type WalmartProfileEntity = {
  __typename?: 'WalmartProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Walmart profile's MFN Seller SKU */
  sellerSku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Walmart profile's WFS Seller SKU */
  wfsSellerSku?: Maybe<Scalars['String']>;
};

export type WalmartSalesOrderEntity = {
  __typename?: 'WalmartSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Walmart customer number */
  customerOrderId?: Maybe<Scalars['String']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type WarehouseEntity = {
  __typename?: 'WarehouseEntity';
  /** Warehouse's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Warehouse' ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Warehouse's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Warehouse's latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Warehouse's first line */
  line1?: Maybe<Scalars['String']>;
  /** Warehouse's last line */
  line2?: Maybe<Scalars['String']>;
  /** Warehouse's longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Warehouse's name */
  name?: Maybe<Scalars['String']>;
  /** Warehouse's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Warehouse's slug */
  slug?: Maybe<Scalars['String']>;
  /** Warehouse's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Warehouse's type */
  warehouseType?: Maybe<WarehouseType>;
};

export type Weather = {
  __typename?: 'Weather';
  /** Weather's date */
  date?: Maybe<Scalars['LocalDate']>;
  /** Weather's high */
  high?: Maybe<Scalars['Float']>;
  /** Weather's low */
  low?: Maybe<Scalars['Float']>;
};

export type WorkstationEntity = {
  __typename?: 'WorkstationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Workstation's key */
  machineKey?: Maybe<Scalars['String']>;
  /** Workstation's name */
  name?: Maybe<Scalars['String']>;
  /** Workstation's printers */
  printers?: Maybe<Array<Maybe<PrinterEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Workstation's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ZoneEntity = {
  __typename?: 'ZoneEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Zone's name */
  name?: Maybe<Scalars['String']>;
  /** Zone's sequence */
  sequence?: Maybe<Scalars['Long']>;
  /** Zone's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Zone's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type IGenericProductEntity = ConfigurableProductEntity | KitProductEntity | ListedProductEntity | SimpleProductEntity;

export enum AddressValidationSource {
  FedEx = 'FedEx',
  SmartyStreets = 'SmartyStreets',
  Ups = 'UPS',
  Unverified = 'Unverified'
}

export enum AuthorizationScopeType {
  Admin = 'ADMIN',
  Partner = 'PARTNER',
  User = 'USER'
}

export enum CancellationReason {
  InsufficientInventory = 'INSUFFICIENT_INVENTORY',
  ShippingRestriction = 'SHIPPING_RESTRICTION',
  UndeliverableAddress = 'UNDELIVERABLE_ADDRESS',
  Unpaid = 'UNPAID'
}

export enum Carrier {
  Amazon = 'AMAZON',
  Dhl = 'DHL',
  Fedex = 'FEDEX',
  Generic = 'GENERIC',
  Globaltranz = 'GLOBALTRANZ',
  Lasership = 'LASERSHIP',
  Otd = 'OTD',
  Roadie = 'ROADIE',
  Ups = 'UPS',
  Usps = 'USPS'
}

export enum Comparison {
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export enum Country {
  Abw = 'ABW',
  Afg = 'AFG',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  Alb = 'ALB',
  And = 'AND',
  Are = 'ARE',
  Arg = 'ARG',
  Arm = 'ARM',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Aut = 'AUT',
  Aze = 'AZE',
  Bdi = 'BDI',
  Bel = 'BEL',
  Ben = 'BEN',
  Bes = 'BES',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bra = 'BRA',
  Brb = 'BRB',
  Brn = 'BRN',
  Btn = 'BTN',
  Bvt = 'BVT',
  Bwa = 'BWA',
  Caf = 'CAF',
  Can = 'CAN',
  Cck = 'CCK',
  Che = 'CHE',
  Chl = 'CHL',
  Chn = 'CHN',
  Civ = 'CIV',
  Cmr = 'CMR',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Com = 'COM',
  Cpv = 'CPV',
  Cri = 'CRI',
  Cub = 'CUB',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cze = 'CZE',
  Deu = 'DEU',
  Dji = 'DJI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dza = 'DZA',
  Ecu = 'ECU',
  Egy = 'EGY',
  Eri = 'ERI',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Eth = 'ETH',
  Fin = 'FIN',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gbr = 'GBR',
  Geo = 'GEO',
  Ggy = 'GGY',
  Gha = 'GHA',
  Gib = 'GIB',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guf = 'GUF',
  Gum = 'GUM',
  Guy = 'GUY',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  Iot = 'IOT',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  Isr = 'ISR',
  Ita = 'ITA',
  Jam = 'JAM',
  Jey = 'JEY',
  Jor = 'JOR',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Ken = 'KEN',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kna = 'KNA',
  Kor = 'KOR',
  Kwt = 'KWT',
  Lao = 'LAO',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lie = 'LIE',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Lva = 'LVA',
  Mac = 'MAC',
  Maf = 'MAF',
  Mar = 'MAR',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mhl = 'MHL',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Moz = 'MOZ',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Ncl = 'NCL',
  Ner = 'NER',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Niu = 'NIU',
  Nld = 'NLD',
  Nor = 'NOR',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Omn = 'OMN',
  Pak = 'PAK',
  Pan = 'PAN',
  Pcn = 'PCN',
  Per = 'PER',
  Phl = 'PHL',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  Pyf = 'PYF',
  Qat = 'QAT',
  Reu = 'REU',
  Rou = 'ROU',
  Rus = 'RUS',
  Rwa = 'RWA',
  Sau = 'SAU',
  Sdn = 'SDN',
  Sen = 'SEN',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Shn = 'SHN',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slv = 'SLV',
  Smr = 'SMR',
  Som = 'SOM',
  Spm = 'SPM',
  Srb = 'SRB',
  Stp = 'STP',
  Sur = 'SUR',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Ton = 'TON',
  Tto = 'TTO',
  Tun = 'TUN',
  Tur = 'TUR',
  Tuv = 'TUV',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Ukr = 'UKR',
  Umi = 'UMI',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Vgb = 'VGB',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Zaf = 'ZAF',
  Zmb = 'ZMB',
  Zwe = 'ZWE'
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DiscontinuationReasonType {
  ManufacturerDiscontinued = 'MANUFACTURER_DISCONTINUED',
  PoorQuality = 'POOR_QUALITY',
  RetiredSpecies = 'RETIRED_SPECIES',
  SlowMovement = 'SLOW_MOVEMENT'
}

export enum DiscountType {
  FixedDiscountOffCart = 'FIXED_DISCOUNT_OFF_CART',
  FreeShipping = 'FREE_SHIPPING',
  PercentageOff = 'PERCENTAGE_OFF'
}

export enum FulfillmentChannelType {
  DirectFulfillment = 'DirectFulfillment',
  ThirdPartyFulfillment = 'ThirdPartyFulfillment'
}

export enum GiftCardTransactionType {
  Create = 'CREATE',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Reload = 'RELOAD'
}

export enum InventoryChangeType {
  Final = 'Final',
  Initial = 'Initial',
  OrderPlaced = 'OrderPlaced',
  ReceivedAddition = 'ReceivedAddition',
  ShelfCount = 'ShelfCount',
  ShipmentShipped = 'ShipmentShipped'
}

export enum LiveArrivalGuaranteeState {
  Guaranteed = 'Guaranteed',
  NotApplicable = 'NotApplicable',
  NotGuaranteed = 'NotGuaranteed',
  UnableToDetermine = 'UnableToDetermine'
}

export enum MediaType {
  Image = 'Image',
  Panorama = 'Panorama',
  VimeoVideo = 'VimeoVideo',
  YouTubeVideo = 'YouTubeVideo'
}

export enum OrderType {
  GeneralMarketplace = 'GENERAL_MARKETPLACE',
  PointOfSale = 'POINT_OF_SALE',
  SpecificMarketplace = 'SPECIFIC_MARKETPLACE',
  Subscription = 'SUBSCRIPTION',
  Website = 'WEBSITE'
}

export enum Packaging {
  CardboardBox = 'CARDBOARD_BOX',
  FedexEnvelope = 'FEDEX_ENVELOPE',
  FlatRateEnvelope = 'FLAT_RATE_ENVELOPE',
  PolyBag_12X15 = 'POLY_BAG_12X15',
  RegionalBoxA = 'REGIONAL_BOX_A',
  RegionalBoxB = 'REGIONAL_BOX_B'
}

export enum PaymentStatus {
  AuthorizationExpired = 'AUTHORIZATION_EXPIRED',
  Authorized = 'AUTHORIZED',
  Authorizing = 'AUTHORIZING',
  Failed = 'FAILED',
  GatewayRejected = 'GATEWAY_REJECTED',
  ProcessorDeclined = 'PROCESSOR_DECLINED',
  Settled = 'SETTLED',
  SettlementDeclined = 'SETTLEMENT_DECLINED',
  SettlementPending = 'SETTLEMENT_PENDING',
  Settling = 'SETTLING',
  SubmittedForSettlement = 'SUBMITTED_FOR_SETTLEMENT',
  Voided = 'VOIDED'
}

export enum PrepType {
  LineItem = 'LINE_ITEM',
  Simple = 'SIMPLE'
}

export enum PromotionType {
  FixedDiscountOffEach = 'FIXED_DISCOUNT_OFF_EACH',
  PercentageDiscount = 'PERCENTAGE_DISCOUNT'
}

export enum PurchaseOrderStatus {
  Closed = 'Closed',
  Open = 'Open',
  PartiallyReceived = 'PartiallyReceived'
}

export enum PurchaseRequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  NeedsClarification = 'NEEDS_CLARIFICATION',
  Pending = 'PENDING',
  Purchased = 'PURCHASED',
  Received = 'RECEIVED'
}

export enum QueryCondition {
  Eq = 'eq',
  Ge = 'ge',
  Gt = 'gt',
  IsNull = 'isNull',
  Le = 'le',
  Lt = 'lt',
  Ne = 'ne',
  NotNull = 'notNull'
}

export enum RateQuoteFitness {
  Bad = 'BAD',
  Best = 'BEST',
  Good = 'GOOD',
  Neutral = 'NEUTRAL'
}

export enum RefundReason {
  CancelledPriorToShipment = 'CANCELLED_PRIOR_TO_SHIPMENT',
  DamagedInTransit = 'DAMAGED_IN_TRANSIT',
  DeadOnArrival = 'DEAD_ON_ARRIVAL',
  HealthIssue = 'HEALTH_ISSUE',
  ItemMissing = 'ITEM_MISSING',
  LostInTransit = 'LOST_IN_TRANSIT',
  Other = 'OTHER',
  WrongItem = 'WRONG_ITEM'
}

export enum Reseller {
  Amazon = 'AMAZON',
  Chewy = 'CHEWY',
  Dhl = 'DHL',
  Easypost = 'EASYPOST',
  Fedex = 'FEDEX',
  Generic = 'GENERIC',
  Lasership = 'LASERSHIP',
  Otd = 'OTD',
  PitneyExp = 'PITNEY_EXP',
  PitneyStd = 'PITNEY_STD',
  Sendle = 'SENDLE',
  Stamps = 'STAMPS',
  Ups = 'UPS',
  UpsAlt = 'UPS_ALT'
}

export enum ResellerShippingRateMode {
  FixedDomesticRate = 'FixedDomesticRate',
  FreeShipping = 'FreeShipping',
  RegionalRates = 'RegionalRates'
}

export enum ResellerTaxMode {
  MarketplaceFacilitator = 'MarketplaceFacilitator',
  Wholesale = 'Wholesale'
}

export enum RewardsTransactionType {
  Award = 'AWARD',
  Create = 'CREATE',
  Purchase = 'PURCHASE',
  Refund = 'REFUND'
}

export enum RoleType {
  RoleApplicationManage = 'ROLE_APPLICATION_MANAGE',
  RoleGiftCardManage = 'ROLE_GIFT_CARD_MANAGE',
  RoleProductSetDepartment = 'ROLE_PRODUCT_SET_DEPARTMENT',
  RoleProductSetThirdPartyProfiles = 'ROLE_PRODUCT_SET_THIRD_PARTY_PROFILES',
  RolePurchaseRequestApprove = 'ROLE_PURCHASE_REQUEST_APPROVE',
  RolePurchaseRequestOrder = 'ROLE_PURCHASE_REQUEST_ORDER',
  RolePurchaseRequestReceive = 'ROLE_PURCHASE_REQUEST_RECEIVE',
  RoleSalesOrderRefund = 'ROLE_SALES_ORDER_REFUND',
  RoleSalesOrderReleaseHold = 'ROLE_SALES_ORDER_RELEASE_HOLD',
  RoleShipmentCancel = 'ROLE_SHIPMENT_CANCEL',
  RoleShipmentSetShippingNeeds = 'ROLE_SHIPMENT_SET_SHIPPING_NEEDS',
  RoleUserRoleManage = 'ROLE_USER_ROLE_MANAGE'
}

export enum SalesOrderStatus {
  Cancelled = 'Cancelled',
  PartiallyShipped = 'PartiallyShipped',
  PendingPayment = 'PendingPayment',
  PendingReleaseOfHold = 'PendingReleaseOfHold',
  Shipped = 'Shipped',
  Unshipped = 'Unshipped'
}

export enum SalesOrderType {
  Employee = 'Employee',
  Retail = 'Retail',
  TaxExempt = 'TaxExempt',
  Wholesale = 'Wholesale'
}

export enum SalesTaxRules {
  NotTaxable = 'NOT_TAXABLE',
  Taxable = 'TAXABLE'
}

export enum SchedulingStatusType {
  NeedsApproval = 'NeedsApproval',
  NeedsPicked = 'NeedsPicked',
  NeedsShipped = 'NeedsShipped',
  ScheduledInFuture = 'ScheduledInFuture',
  ToReschedule = 'ToReschedule',
  Unscheduled = 'Unscheduled'
}

export enum Service {
  AmazonGround = 'AMAZON_GROUND',
  FedexExpressSaver = 'FEDEX_EXPRESS_SAVER',
  FedexFirstOvernight = 'FEDEX_FIRST_OVERNIGHT',
  FedexGround = 'FEDEX_GROUND',
  FedexHomeDelivery = 'FEDEX_HOME_DELIVERY',
  FedexInternationalEconomy = 'FEDEX_INTERNATIONAL_ECONOMY',
  FedexInternationalGround = 'FEDEX_INTERNATIONAL_GROUND',
  FedexPriorityOvernight = 'FEDEX_PRIORITY_OVERNIGHT',
  FedexSmartPost = 'FEDEX_SMART_POST',
  FedexStandardOvernight = 'FEDEX_STANDARD_OVERNIGHT',
  FedexTwoDay = 'FEDEX_TWO_DAY',
  GenericGround = 'GENERIC_GROUND',
  GenericInternational = 'GENERIC_INTERNATIONAL',
  GenericOvernight = 'GENERIC_OVERNIGHT',
  GenericTwoDay = 'GENERIC_TWO_DAY',
  GlobaltranzLtl = 'GLOBALTRANZ_LTL',
  GlobaltranzLtlLiftgate = 'GLOBALTRANZ_LTL_LIFTGATE',
  GlobaltranzLtlTradeshow = 'GLOBALTRANZ_LTL_TRADESHOW',
  LasershipGround = 'LASERSHIP_GROUND',
  OtdFloridaOvernight = 'OTD_FLORIDA_OVERNIGHT',
  RoadieNextDay = 'ROADIE_NEXT_DAY',
  RoadieSameDay = 'ROADIE_SAME_DAY',
  RoadieTwoDay = 'ROADIE_TWO_DAY',
  UpsGround = 'UPS_GROUND',
  UpsNextDayAir = 'UPS_NEXT_DAY_AIR',
  UpsNextDayAirSaver = 'UPS_NEXT_DAY_AIR_SAVER',
  UpsSecondDayAir = 'UPS_SECOND_DAY_AIR',
  UpsSurePost = 'UPS_SURE_POST',
  UpsThreeDaySelect = 'UPS_THREE_DAY_SELECT',
  UpsWorldwideExpedited = 'UPS_WORLDWIDE_EXPEDITED',
  UpsWorldwideExpress = 'UPS_WORLDWIDE_EXPRESS',
  UpsWorldwideExpressPlus = 'UPS_WORLDWIDE_EXPRESS_PLUS',
  UspsFirstClassMail = 'USPS_FIRST_CLASS_MAIL',
  UspsGroundAdvantage = 'USPS_GROUND_ADVANTAGE',
  UspsParcelSelect = 'USPS_PARCEL_SELECT',
  UspsParcelSelectLightweight = 'USPS_PARCEL_SELECT_LIGHTWEIGHT',
  UspsPriorityMail = 'USPS_PRIORITY_MAIL',
  UspsPriorityMailExpress = 'USPS_PRIORITY_MAIL_EXPRESS',
  UspsPriorityMailInternational = 'USPS_PRIORITY_MAIL_INTERNATIONAL'
}

export enum ServiceType {
  FreightLtl = 'FreightLTL',
  Ground = 'Ground',
  InternationalExpress = 'InternationalExpress',
  InternationalPriority = 'InternationalPriority',
  InternationalStandard = 'InternationalStandard',
  OvernightEarly = 'OvernightEarly',
  OvernightEvening = 'OvernightEvening',
  OvernightMorning = 'OvernightMorning',
  PostOfficeLastMile = 'PostOfficeLastMile',
  SameDay = 'SameDay',
  ThreeDay = 'ThreeDay',
  TwoDay = 'TwoDay'
}

export enum SexType {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export enum ShipmentStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  External = 'External',
  Labelled = 'Labelled',
  NeedsScheduling = 'NeedsScheduling',
  Pending = 'Pending',
  Shipped = 'Shipped',
  Unshipped = 'Unshipped'
}

export enum ShippingNeedsType {
  CourierDelivery = 'CourierDelivery',
  ItemNotShipped = 'ItemNotShipped',
  ScheduleWithCustomer = 'ScheduleWithCustomer',
  StoreShowPickupOnly = 'StoreShowPickupOnly'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum State {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum TrackingEventType {
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  Other = 'OTHER',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  ReceivedByCarrier = 'RECEIVED_BY_CARRIER'
}

export enum TransitTimeType {
  GroundService = 'GroundService',
  OneDayService = 'OneDayService',
  ThreeDayService = 'ThreeDayService',
  TwoDayService = 'TwoDayService'
}

export enum UserType {
  Employee = 'EMPLOYEE',
  Regular = 'REGULAR',
  Wholesale = 'WHOLESALE'
}

export enum Vendor {
  Arachnid = 'Arachnid',
  CricketFarm = 'CricketFarm',
  DryGoods = 'DryGoods',
  FreeShipping = 'FreeShipping',
  Frogs = 'Frogs',
  GiftCards = 'GiftCards',
  LiveInsects = 'LiveInsects',
  MiceDirect = 'MiceDirect'
}

export enum WarehouseType {
  DropShipper = 'DropShipper',
  OverstockStorage = 'OverstockStorage',
  Production = 'Production',
  ThirdPartyFulfillment = 'ThirdPartyFulfillment',
  TradeshowFulfillment = 'TradeshowFulfillment',
  WebFulfillment = 'WebFulfillment'
}

export type BomItemInput = {
  quantity: Scalars['BigDecimal'];
  sku: Scalars['String'];
};

export type GraphQlLikeQueryFilterInput = {
  /** Abbreviation for 'pattern'. */
  p?: Maybe<Scalars['String']>;
  /** Field pattern. */
  pattern?: Maybe<Scalars['String']>;
};

export type GraphQlPageableInput = {
  /** The number of the page to display. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** Sort conditions for the current view. */
  sort?: Maybe<Array<Maybe<GraphQlSortInput>>>;
};

export type GraphQlSingleValueFilter_BooleanInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['Boolean']>;
  /** Field value. */
  value?: Maybe<Scalars['Boolean']>;
};

export type GraphQlSingleValueFilter_LongInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['Long']>;
  /** Field value. */
  value?: Maybe<Scalars['Long']>;
};

export type GraphQlSingleValueFilter_SalesOrderStatusInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<SalesOrderStatus>;
  /** Field value. */
  value?: Maybe<SalesOrderStatus>;
};

export type GraphQlSingleValueFilter_SchedulingStatusTypeInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<SchedulingStatusType>;
  /** Field value. */
  value?: Maybe<SchedulingStatusType>;
};

export type GraphQlSingleValueFilter_ShipmentStatusInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<ShipmentStatus>;
  /** Field value. */
  value?: Maybe<ShipmentStatus>;
};

export type GraphQlSingleValueFilter_StringInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['String']>;
  /** Field value. */
  value?: Maybe<Scalars['String']>;
};

export type GraphQlSingleValueFilter_WarehouseTypeInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<WarehouseType>;
  /** Field value. */
  value?: Maybe<WarehouseType>;
};

export type GraphQlSortInput = {
  /** Abbreviation for 'direction'. */
  d?: Maybe<SortDirection>;
  /** Direction of sorting (default: asc). */
  direction?: Maybe<SortDirection>;
  /** Abbreviation for 'field'. */
  f?: Maybe<Scalars['String']>;
  /** Name of the field to sort by. */
  field?: Maybe<Scalars['String']>;
};

export type GraphQlValueRangeQueryFilter_BigDecimalInput = {
  /** Abbreviation for 'from'. */
  f?: Maybe<Scalars['BigDecimal']>;
  /** Value from (inclusive). */
  from?: Maybe<Scalars['BigDecimal']>;
  /** Abbreviation for 'to'. */
  t?: Maybe<Scalars['BigDecimal']>;
  /** Value to (inclusive). */
  to?: Maybe<Scalars['BigDecimal']>;
};

export type GraphQlValueRangeQueryFilter_DoubleInput = {
  /** Abbreviation for 'from'. */
  f?: Maybe<Scalars['Float']>;
  /** Value from (inclusive). */
  from?: Maybe<Scalars['Float']>;
  /** Abbreviation for 'to'. */
  t?: Maybe<Scalars['Float']>;
  /** Value to (inclusive). */
  to?: Maybe<Scalars['Float']>;
};

export type KitItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type PackageSizeInput = {
  height?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  weight: Scalars['BigDecimal'];
  width?: Maybe<Scalars['BigDecimal']>;
};

export type PurchaseRequestItemInput = {
  amount?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  quantity: Scalars['Long'];
};

export type SalesOrderLineItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type ShipmentLineItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type Cart_CheckoutFragment = (
  { __typename?: 'CartEntity' }
  & Pick<CartEntity, 'id' | 'email' | 'phone' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'couponCode' | 'taxExempt' | 'subtotal' | 'cartItemsCount' | 'cartBatchItemsCount'>
  & { tradeShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'url' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'startDate' | 'endDate'>
  )>, pickupWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )>, cartItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'CartItemGroupEntity' }
    & Pick<CartItemGroupEntity, 'id' | 'quantity' | 'price' | 'salePrice'>
    & { kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, cartItems?: Maybe<Array<Maybe<(
    { __typename?: 'CartItemEntity' }
    & Pick<CartItemEntity, 'id' | 'quantity' | 'price' | 'salePrice' | 'carrierPreference' | 'deliveryDatePreference' | 'serviceTypePreference'>
    & { cartItemGroup?: Maybe<(
      { __typename?: 'CartItemGroupEntity' }
      & Pick<CartItemGroupEntity, 'id'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price' | 'quantityAvailableForSale' | 'shippingNeeds'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, cartCustomItems?: Maybe<Array<Maybe<(
    { __typename?: 'CartCustomItemEntity' }
    & Pick<CartCustomItemEntity, 'id' | 'quantity' | 'productPrice' | 'title'>
  )>>> }
);

export type Admin_Simple_Product_EntityFragment = (
  { __typename?: 'SimpleProductEntity' }
  & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'upc' | 'title' | 'active' | 'visible' | 'featured' | 'quantityAvailableForSale' | 'caseQuantity' | 'cost' | 'height' | 'length' | 'price' | 'mapPrice' | 'specialPrice' | 'wholesalePrice' | 'weight' | 'width' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'minimumOrderQuantity' | 'averageRating' | 'countRating' | 'shippingNeeds' | 'shipsAlone' | 'requiresPrep' | 'discontinuationReason' | 'vendorRef' | 'shippingRestrictions'>
  & { publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, warehouses?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>>>, zones?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )> }
  )>>>, bins?: Maybe<Array<Maybe<(
    { __typename?: 'BinEntity' }
    & Pick<BinEntity, 'id' | 'binId'>
    & { zone?: Maybe<(
      { __typename?: 'ZoneEntity' }
      & Pick<ZoneEntity, 'id' | 'name'>
      & { warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, brand?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Pick<BrandEntity, 'id' | 'slug' | 'name'>
  )>, supplier?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
  )>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, inventoryQuantityCaches?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryQuantityCacheEntity' }
    & Pick<InventoryQuantityCacheEntity, 'id' | 'quantityAvailableForSale' | 'quantityOnShelf'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )> }
  )>>>, shippingRuleSet?: Maybe<(
    { __typename?: 'ShippingRuleSetEntity' }
    & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
    & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
      { __typename?: 'LiveArrivalGuaranteeEntity' }
      & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
    )>>>, destinationRestriction?: Maybe<(
      { __typename?: 'DestinationRestrictionEntity' }
      & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
    )> }
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, amazonProfile?: Maybe<(
    { __typename?: 'AmazonProfileEntity' }
    & Pick<AmazonProfileEntity, 'id' | 'asin' | 'fbaSellerSku' | 'sellerSku'>
  )>, petcoDropshipProfile?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )>, petcoStoreDirectProfile?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'description' | 'sku'>
  )>, chewyProfile?: Maybe<(
    { __typename?: 'ChewyProfileEntity' }
    & Pick<ChewyProfileEntity, 'id' | 'description' | 'sku' | 'cost'>
  )>, walmartProfile?: Maybe<(
    { __typename?: 'WalmartProfileEntity' }
    & Pick<WalmartProfileEntity, 'id' | 'sellerSku' | 'wfsSellerSku'>
  )>, resellerProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'price' | 'retail'>
    & { reseller?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, dropshipperProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'DropshipperProfileEntity' }
    & Pick<DropshipperProfileEntity, 'id' | 'dropshipperSku' | 'cost' | 'enabled'>
    & { dropshipper?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, crossSells?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'title' | 'sku' | 'price' | 'quantityAvailableForSale' | 'cls'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>>, priceTiers?: Maybe<Array<Maybe<(
    { __typename?: 'PriceTierEntity' }
    & Pick<PriceTierEntity, 'id' | 'price' | 'quantity' | 'userType'>
  )>>>, componentItems?: Maybe<Array<Maybe<(
    { __typename?: 'BomItemEntity' }
    & Pick<BomItemEntity, 'id' | 'quantity' | 'position'>
    & { component?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'cost' | 'title' | 'sku'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url' | 'mediaType'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_Kit_Product_EntityFragment = (
  { __typename?: 'KitProductEntity' }
  & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'cost' | 'price' | 'specialPrice' | 'wholesalePrice' | 'featured' | 'visible'>
  & { explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, kitItems?: Maybe<Array<Maybe<(
    { __typename?: 'KitItemEntity' }
    & Pick<KitItemEntity, 'id' | 'quantity' | 'position'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'price'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, amazonProfile?: Maybe<(
    { __typename?: 'AmazonProfileEntity' }
    & Pick<AmazonProfileEntity, 'id' | 'asin' | 'fbaSellerSku' | 'sellerSku'>
  )>, petcoDropshipProfile?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )>, petcoStoreDirectProfile?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'description' | 'sku'>
  )>, chewyProfile?: Maybe<(
    { __typename?: 'ChewyProfileEntity' }
    & Pick<ChewyProfileEntity, 'id' | 'description' | 'sku' | 'cost'>
  )>, walmartProfile?: Maybe<(
    { __typename?: 'WalmartProfileEntity' }
    & Pick<WalmartProfileEntity, 'id' | 'sellerSku' | 'wfsSellerSku'>
  )>, resellerProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'price' | 'retail'>
    & { reseller?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, crossSells?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'title' | 'sku' | 'price' | 'quantityAvailableForSale' | 'cls'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>>, priceTiers?: Maybe<Array<Maybe<(
    { __typename?: 'PriceTierEntity' }
    & Pick<PriceTierEntity, 'id' | 'price' | 'quantity' | 'userType'>
  )>>> }
);

export type Admin_Configurable_Product_EntityFragment = (
  { __typename?: 'ConfigurableProductEntity' }
  & Pick<ConfigurableProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'featured' | 'visible'>
  & { configurableItems?: Maybe<Array<Maybe<(
    { __typename?: 'ConfigurableItemEntity' }
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'price' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>> }
);

export type Customer_Sales_OrderFragment = (
  { __typename?: 'SalesOrderEntity' }
  & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime' | 'email' | 'phone' | 'status' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'subTotal' | 'discountTotal' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
  & { payments?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'id' | 'amount' | 'description' | 'placedAt'>
  )>>>, refunds?: Maybe<Array<Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'grandTotal' | 'placedTime' | 'refundNumber'>
    & { refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'name'>
      )> }
    )>>> }
  )>>>, salesOrderItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemEntity' }
    & Pick<SalesOrderItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )>, salesOrderItemGroup?: Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id'>
    )> }
  )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemGroupEntity' }
    & Pick<SalesOrderItemGroupEntity, 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'name' | 'quantity'>
    )>>>, kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )> }
  )>>>, shipments?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'carrier' | 'service' | 'trackingNos' | 'trackingUrls'>
    & { shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'name'>
      )> }
    )>>> }
  )>>> }
);

export type Supplier_EntityFragment = (
  { __typename?: 'SupplierEntity' }
  & Pick<SupplierEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'name' | 'phone' | 'email' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource' | 'createdAt' | 'leadTime' | 'orderCycle' | 'residential' | 'slug'>
  & { simpleProducts?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'active' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'url'>
    )>>> }
  )>>> }
);

export type Brand_EntityFragment = (
  { __typename?: 'BrandEntity' }
  & Pick<BrandEntity, 'id' | 'name' | 'slug'>
  & { products?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title' | 'active' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'url'>
    )>>> }
  )>>> }
);

export type Admin_Sales_OrderFragment = (
  { __typename?: 'SalesOrderEntity' }
  & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
  & { user?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName'>
  )>, salesOrderItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemGroupEntity' }
    & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'sku' | 'name' | 'unitAmount'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
    )>>>, kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'sku' | 'title'>
    )> }
  )>>>, salesOrderItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemEntity' }
    & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'quantityAvailableForSale'>
    )>, salesOrderItemGroup?: Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id'>
    )> }
  )>>>, shipments?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
    & { departingWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )>, originWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )>, shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
      )> }
    )>>> }
  )>>>, refunds?: Maybe<Array<Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
  )>>>, reships?: Maybe<Array<Maybe<(
    { __typename?: 'ReshipSalesOrderEntity' }
    & Pick<ReshipSalesOrderEntity, 'id' | 'status' | 'orderNumber'>
  )>>>, payments?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
  )>>>, comments?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderCommentsEntity' }
    & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
  )>>> }
);

export type Customer_DiscountFragment = (
  { __typename?: 'DiscountEntity' }
  & Pick<DiscountEntity, 'id' | 'discountAmount' | 'discountType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'htmlContent' | 'teaser'>
);

export type Admin_DiscountFragment = (
  { __typename?: 'DiscountEntity' }
  & Pick<DiscountEntity, 'id' | 'active' | 'discountAmount' | 'discountType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
  & { eligibleProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'price' | 'quantityAvailableForSale' | 'sku' | 'title'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )>>>, implicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, explicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
  )>>>, departments?: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'name'>
  )>>> }
);

export type Customer_PromotionFragment = (
  { __typename?: 'PromotionEntity' }
  & Pick<PromotionEntity, 'id' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'htmlContent' | 'teaser'>
);

export type Admin_PromotionFragment = (
  { __typename?: 'PromotionEntity' }
  & Pick<PromotionEntity, 'id' | 'active' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleItemCount' | 'eligibleItemValue' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
  & { implicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, explicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
  )>>>, departments?: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'name'>
  )>>> }
);

export type Admin_Shipment_EntityFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'cls' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'actualShippingCost' | 'estimatedShippingCost' | 'carrier' | 'carrierPreference' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'shippedAt' | 'shippedBy' | 'cancelledAt' | 'cancelledBy' | 'cancellationReason' | 'trackingNos' | 'trackingUrls' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressValidationSource' | 'downloadStart' | 'printedStart' | 'printedEnd' | 'reprint'>
  & { queue?: Maybe<(
    { __typename?: 'QueueEntity' }
    & Pick<QueueEntity, 'id' | 'name'>
  )>, originWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, departingWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'status'>
  )>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, shipmentAddons?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentAddonEntity' }
    & Pick<ShipmentAddonEntity, 'id' | 'quantity' | 'sku' | 'name'>
  )>>> }
);

export type Customer_ShipmentFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'carrier' | 'email' | 'scheduledAt' | 'estimatedDeliveryDate' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'scheduledBy' | 'finalApprovalBy' | 'trackingNos' | 'trackingUrls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressValidationSource' | 'shippingNeeds'>
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime'>
  )>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Customer_Shipment_PublicFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'carrier' | 'email' | 'scheduledAt' | 'estimatedDeliveryDate' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'scheduledBy' | 'trackingNos' | 'trackingUrls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressValidationSource' | 'shippingNeeds'>
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime'>
  )>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_User_EntityFragment = (
  { __typename?: 'UserEntity' }
  & Pick<UserEntity, 'id' | 'cls' | 'magentoId' | 'email' | 'firstName' | 'lastName' | 'admin' | 'roles' | 'emailConfirmed' | 'userType' | 'taxExempt' | 'netTerms' | 'createdAt' | 'updatedAt'>
  & { addresses?: Maybe<Array<Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'firstName' | 'lastName' | 'cls' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'createdAt' | 'latitude' | 'longitude' | 'magentoId' | 'residential' | 'addressValidationSource' | 'updatedAt'>
  )>>> }
);

export type SubscriptionFragment = (
  { __typename?: 'SubscriptionEntity' }
  & Pick<SubscriptionEntity, 'id' | 'phone' | 'deliveryDay' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'billingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'shippingAddressValidationSource' | 'taxExempt'>
  & { paymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'token' | 'type' | 'defaultMethod' | 'comment' | 'image'>
  )>, subscriptionItems?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionItemEntity' }
    & Pick<SubscriptionItemEntity, 'id' | 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'title' | 'price' | 'salePrice' | 'slug'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_SubscriptionFragment = (
  { __typename?: 'SubscriptionEntity' }
  & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'phone' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'billingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'shippingAddressValidationSource' | 'carrierPreference' | 'serviceType'>
  & { paymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'token' | 'type' | 'defaultMethod' | 'comment' | 'image'>
  )>, subscriptionItems?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionItemEntity' }
    & Pick<SubscriptionItemEntity, 'id' | 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'price' | 'sku' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_Partner_EntityFragment = (
  { __typename?: 'PartnerEntity' }
  & Pick<PartnerEntity, 'id' | 'affiliate' | 'affiliateAttributionPeriod' | 'affiliateBiography' | 'affiliateCommissionRate' | 'dropshipper' | 'email' | 'name' | 'reseller' | 'resellerShippingRateMode' | 'resellerTaxMode' | 'slug' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'residential' | 'addressValidationSource'>
  & { affiliateProductLists?: Maybe<Array<Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Pick<AffiliateProductListEntity, 'id' | 'name' | 'sequence'>
  )>>> }
);

export type Admin_Affiliate_Product_ListFragment = (
  { __typename?: 'AffiliateProductListEntity' }
  & Pick<AffiliateProductListEntity, 'id' | 'htmlContent' | 'name' | 'sequence' | 'slug'>
  & { affiliateProductListItems?: Maybe<Array<Maybe<(
    { __typename?: 'AffiliateProductListItemEntity' }
    & Pick<AffiliateProductListItemEntity, 'id' | 'position'>
    & { product?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'price' | 'title' | 'sku' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Shipment_EntityFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'placedAt' | 'cls' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'shippedAt' | 'trackingNo' | 'schedulingStatus' | 'scheduledAt' | 'scheduledBy' | 'finalApproval' | 'finalApprovalBy'>
  & { originWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, departingWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'salesOrderType'>
  )>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>> }
);

export type AddressCreateMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type AddressCreateMutation = (
  { __typename?: 'Mutation' }
  & { addressCreate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )> }
);

export type AddressDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addressDelete'>
);

export type AddressListQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressListQuery = (
  { __typename?: 'Query' }
  & { addressList?: Maybe<Array<Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource' | 'residential'>
  )>>> }
);

export type AddressUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type AddressUpdateMutation = (
  { __typename?: 'Mutation' }
  & { addressUpdate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )> }
);

export type AddressVerifyMutationVariables = Exact<{
  id: Scalars['UUID'];
  commit: Scalars['Boolean'];
}>;


export type AddressVerifyMutation = (
  { __typename?: 'Mutation' }
  & { addressVerify?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource'>
  )> }
);

export type AmazonSalesOrderRefreshMutationVariables = Exact<{
  amazonId: Scalars['String'];
}>;


export type AmazonSalesOrderRefreshMutation = (
  { __typename?: 'Mutation' }
  & { amazonSalesOrderRefresh?: Maybe<(
    { __typename?: 'AmazonSalesOrderEntity' }
    & Pick<AmazonSalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
    & { salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'unitAmount'>
      & { salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
      )>>>, kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'sku' | 'title'>
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
    )>>>, comments?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderCommentsEntity' }
      & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
    )>>> }
  )> }
);

export type AnimalListQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimalListQuery = (
  { __typename?: 'Query' }
  & { animalList?: Maybe<(
    { __typename?: 'GraphQLPage_AnimalEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type AssistedCartAddCustomProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  unitPrice: Scalars['BigDecimal'];
  quantity: Scalars['Long'];
  weight: Scalars['BigDecimal'];
  title: Scalars['String'];
}>;


export type AssistedCartAddCustomProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddCustomProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddKitProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartAddKitProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddProductsMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
  kitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type AssistedCartAddProductsMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddProducts?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddSimpleProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartAddSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartChangeQuantityKitProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartChangeQuantityKitProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartChangeQuantityKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartChangeQuantitySimpleProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartChangeQuantitySimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartChangeQuantitySimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartCheckoutMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  method: Scalars['String'];
  deviceData?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  skipAvs?: Maybe<Scalars['Boolean']>;
}>;


export type AssistedCartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartCheckout?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type AssistedCartClearBillingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearCarrierPreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearCouponCodeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearDeliveryDatePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearServiceTypePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearServiceTypePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearServiceTypePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearShippingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartGetEarliestDeliveryDateMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartGetEarliestDeliveryDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assistedCartGetEarliestDeliveryDate'>
);

export type AssistedCartInfoMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedCartInfoMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartInfo?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartLookupMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedCartLookupMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartLookup?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartRemoveCustomProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  cartCustomItemId: Scalars['UUID'];
}>;


export type AssistedCartRemoveCustomProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartRemoveCustomProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetBillingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedCartSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetBillingAddressByIdMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type AssistedCartSetBillingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetBillingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetCarrierPreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  carrier: Carrier;
}>;


export type AssistedCartSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetContactMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type AssistedCartSetContactMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetContact?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetCouponCodeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  couponCode: Scalars['String'];
}>;


export type AssistedCartSetCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetDeliveryDatePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
}>;


export type AssistedCartSetDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetPickupWarehouseMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  warehouseId: Scalars['UUID'];
}>;


export type AssistedCartSetPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetServiceTypePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  serviceType: ServiceType;
}>;


export type AssistedCartSetServiceTypePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetServiceTypePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
}>;


export type AssistedCartSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingAddressByIdMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type AssistedCartSetShippingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingZipMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  zip: Scalars['String'];
}>;


export type AssistedCartSetShippingZipMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingZip?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartSetTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetTradeshowMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  tradeshowId: Scalars['UUID'];
}>;


export type AssistedCartSetTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSummarizeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  customDiscount?: Maybe<Scalars['BigDecimal']>;
}>;


export type AssistedCartSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSummarize?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal' | 'couponCode'>
    & { salesOrderDiscounts?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderDiscountEntity' }
      & Pick<SalesOrderDiscountEntity, 'id' | 'name' | 'amount'>
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'id' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )>, simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'shippingNeeds' | 'carrier' | 'service' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'estimatedWeight' | 'estimatedShipDate' | 'estimatedDeliveryDate'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'id'>
        )>, salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'name'>
          & { salesOrderItemGroup?: Maybe<(
            { __typename?: 'SalesOrderItemGroupEntity' }
            & Pick<SalesOrderItemGroupEntity, 'id'>
            & { kitProduct?: Maybe<(
              { __typename?: 'KitProductEntity' }
              & Pick<KitProductEntity, 'id'>
            )> }
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AssistedPaymentMethodGetClientTokenMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedPaymentMethodGetClientTokenMutation = (
  { __typename?: 'Mutation' }
  & { assistedPaymentMethodGetClientToken?: Maybe<(
    { __typename?: 'ClientTokenDetail' }
    & Pick<ClientTokenDetail, 'clientToken'>
  )> }
);

export type AssistedPaymentMethodListMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedPaymentMethodListMutation = (
  { __typename?: 'Mutation' }
  & { assistedPaymentMethodList?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )>>> }
);

export type AssistedScheduledShipmentClearApprovalMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentClearApprovalMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentClearApproval?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Shipment_EntityFragment
  )> }
);

export type AssistedScheduledShipmentDashboardMutationVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
}>;


export type AssistedScheduledShipmentDashboardMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentDashboard?: Maybe<(
    { __typename?: 'SchedulingDashboard' }
    & Pick<SchedulingDashboard, 'needsApprovalCount' | 'needsPickedCount' | 'needsShippedCount' | 'nextOpenDate' | 'scheduledInFutureCount' | 'timezone' | 'toRescheduleCount' | 'today' | 'unscheduledCount' | 'warehouse'>
  )> }
);

export type AssistedScheduledShipmentFilterQueryVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
  salesOrderCls?: Maybe<Scalars['String']>;
  schedulingStatus?: Maybe<SchedulingStatusType>;
  shipmentNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type AssistedScheduledShipmentFilterQuery = (
  { __typename?: 'Query' }
  & { assistedScheduledShipmentFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ShipmentEntity' }
    & Pick<GraphQlPage_ShipmentEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'finalApproval'>
      & Shipment_EntityFragment
    )>>> }
  )> }
);

export type AssistedScheduledShipmentScheduleMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentScheduleMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentSchedule?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Shipment_EntityFragment
  )> }
);

export type AssistedScheduledShipmentSetApprovalMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentSetApprovalMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentSetApproval?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Shipment_EntityFragment
  )> }
);

export type AssistedSubscriptionClearCarrierPreferenceMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionClearCarrierPreference?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionClearServiceLevelMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionClearServiceLevelMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionClearServiceLevel?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetCarrierPreferenceMutationVariables = Exact<{
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetCarrierPreference?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetPaymentTokenMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  paymentToken: Scalars['String'];
}>;


export type AssistedSubscriptionSetPaymentTokenMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetPaymentToken?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetServiceLevelMutationVariables = Exact<{
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionSetServiceLevelMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetServiceLevel?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionAddItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionAddItemMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionAddItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  userId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  deliveryDay?: Maybe<DayOfWeek>;
  paymentToken?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingLastName: Scalars['String'];
  billingCompany?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingCity: Scalars['String'];
  billingState: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingLastName: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  shippingLine1: Scalars['String'];
  shippingLine2?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingState: Scalars['String'];
  shippingPostalCode: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
}>;


export type AssistedSubscriptionCreateMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionCreate?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { assistedSubscriptionInfo?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionListQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionListQuery = (
  { __typename?: 'Query' }
  & { assistedSubscriptionList?: Maybe<(
    { __typename?: 'GraphQLPage_SubscriptionEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionEntity' }
      & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'phone'>
      & { subscriptionItems?: Maybe<Array<Maybe<(
        { __typename?: 'SubscriptionItemEntity' }
        & Pick<SubscriptionItemEntity, 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'title'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AssistedSubscriptionProcessMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedSubscriptionProcessMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionProcess?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionRemoveItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionRemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionRemoveItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetItemFrequencyMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
}>;


export type AssistedSubscriptionSetItemFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetItemFrequency?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetItemQuantityMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedSubscriptionSetItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetItemQuantity?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AuthorizationAuthCodeMutationVariables = Exact<{
  scope: AuthorizationScopeType;
  clientId: Scalars['String'];
  redirectUri: Scalars['String'];
  codeChallenge: Scalars['String'];
  codeChallengeMethod: Scalars['String'];
}>;


export type AuthorizationAuthCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'authorizationAuthCode'>
);

export type AutoprintReprintMutationVariables = Exact<{
  shipment: Scalars['UUID'];
}>;


export type AutoprintReprintMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'autoprintReprint'>
);

export type BlockCreateMutationVariables = Exact<{
  htmlContent: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlockCreateMutation = (
  { __typename?: 'Mutation' }
  & { blockCreate?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type BlockDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlockDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockDelete'>
);

export type BlockFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type BlockFilterQuery = (
  { __typename?: 'Query' }
  & { blockFilter?: Maybe<(
    { __typename?: 'GraphQLPage_BlockEntity' }
    & Pick<GraphQlPage_BlockEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlockEntity' }
      & Pick<BlockEntity, 'id' | 'name' | 'slug'>
    )>>> }
  )> }
);

export type BlockInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlockInfoQuery = (
  { __typename?: 'Query' }
  & { blockInfo?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type BlockUpdateMutationVariables = Exact<{
  htmlContent: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlockUpdateMutation = (
  { __typename?: 'Mutation' }
  & { blockUpdate?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type Admin_Blog_EntityFragment = (
  { __typename?: 'BlogEntity' }
  & Pick<BlogEntity, 'id' | 'active' | 'author' | 'createdAt' | 'slug' | 'title'>
  & { publishedRevision?: Maybe<(
    { __typename?: 'ContentRevisionEntity' }
    & Pick<ContentRevisionEntity, 'htmlContent'>
  )> }
);

export type BlogBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type BlogBySlugQuery = (
  { __typename?: 'Query' }
  & { blogBySlug?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'countRating' | 'averageRating' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )>>>, blogTopics?: Maybe<Array<Maybe<(
      { __typename?: 'BlogTopicEntity' }
      & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
    )>>>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ContentMediaEntity' }
      & Pick<ContentMediaEntity, 'id' | 'sequence' | 'mediaType' | 'url'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ContentRevisionEntity' }
      & Pick<ContentRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type BlogDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlogDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blogDelete'>
);

export type BlogFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type BlogFilterQuery = (
  { __typename?: 'Query' }
  & { blogFilter?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'title' | 'slug' | 'active'>
    )>>> }
  )> }
);

export type BlogInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlogInfoQuery = (
  { __typename?: 'Query' }
  & { blogInfo?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogListQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
}>;


export type BlogListQuery = (
  { __typename?: 'Query' }
  & { blogList?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'active' | 'title' | 'author' | 'slug' | 'metaDescription'>
      & { blogTopics?: Maybe<Array<Maybe<(
        { __typename?: 'BlogTopicEntity' }
        & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
      )>>>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ContentMediaEntity' }
        & Pick<ContentMediaEntity, 'id' | 'cls' | 'sequence' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type BlogSearchQueryVariables = Exact<{
  query: Scalars['String'];
  pageable?: Maybe<GraphQlPageableInput>;
}>;


export type BlogSearchQuery = (
  { __typename?: 'Query' }
  & { blogSearch?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'active' | 'title' | 'author' | 'slug' | 'metaDescription'>
      & { blogTopics?: Maybe<Array<Maybe<(
        { __typename?: 'BlogTopicEntity' }
        & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
      )>>>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ContentMediaEntity' }
        & Pick<ContentMediaEntity, 'id' | 'cls' | 'sequence' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type BlogSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type BlogSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { blogSetActive?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type BlogSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { blogSetPublishedRevision?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BrandCreateMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type BrandCreateMutation = (
  { __typename?: 'Mutation' }
  & { brandCreate?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type BrandDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BrandDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'brandDelete'>
);

export type BrandInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BrandInfoQuery = (
  { __typename?: 'Query' }
  & { brandInfo?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type BrandListQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandListQuery = (
  { __typename?: 'Query' }
  & { brandList?: Maybe<(
    { __typename?: 'GraphQLPage_BrandEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BrandEntity' }
      & Pick<BrandEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type BrandSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type BrandSetNameMutation = (
  { __typename?: 'Mutation' }
  & { brandSetName?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type Admin_Care_Sheet_EntityFragment = (
  { __typename?: 'CareSheetEntity' }
  & Pick<CareSheetEntity, 'id' | 'active' | 'author' | 'createdAt' | 'slug' | 'title'>
  & { publishedRevision?: Maybe<(
    { __typename?: 'ContentRevisionEntity' }
    & Pick<ContentRevisionEntity, 'htmlContent'>
  )> }
);

export type CareSheetBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CareSheetBySlugQuery = (
  { __typename?: 'Query' }
  & { careSheetBySlug?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Pick<CareSheetEntity, 'id' | 'cls' | 'createdAt' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ContentMediaEntity' }
      & Pick<ContentMediaEntity, 'id' | 'sequence' | 'mediaType' | 'url'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ContentRevisionEntity' }
      & Pick<ContentRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type CareSheetDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CareSheetDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'careSheetDelete'>
);

export type CareSheetFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type CareSheetFilterQuery = (
  { __typename?: 'Query' }
  & { careSheetFilter?: Maybe<(
    { __typename?: 'GraphQLPage_CareSheetEntity' }
    & Pick<GraphQlPage_CareSheetEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'active' | 'title' | 'slug'>
    )>>> }
  )> }
);

export type CareSheetInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CareSheetInfoQuery = (
  { __typename?: 'Query' }
  & { careSheetInfo?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type CareSheetSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetActive?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type CareSheetSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetPublishedRevision?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CartAddPartialKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartAddPartialKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddPartialKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAuthorizeUrlMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
}>;


export type CartAuthorizeUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cartAuthorizeUrl'>
);

export type CartReorderMutationVariables = Exact<{
  orderId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartReorderMutation = (
  { __typename?: 'Mutation' }
  & { cartReorder?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetAffiliateMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  affiliateSlug: Scalars['String'];
}>;


export type CartSetAffiliateMutation = (
  { __typename?: 'Mutation' }
  & { cartSetAffiliate?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAddKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartAddKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAddSimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartAddSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartChangeQuantityKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartChangeQuantityKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartChangeQuantityKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartChangeQuantitySimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartChangeQuantitySimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartChangeQuantitySimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartCheckoutMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  method: Scalars['String'];
  deviceData?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
}>;


export type CartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { cartCheckout?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'couponCode'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'discountAmount'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'sku'>
      )> }
    )>>> }
    & Customer_Sales_OrderFragment
  )> }
);

export type CartClearBillingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartClearBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearCarrierPreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
}>;


export type CartClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartClearCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearCouponCodeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { cartClearCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearDeliveryDatePreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
}>;


export type CartClearDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartClearDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearEmailMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearEmailMutation = (
  { __typename?: 'Mutation' }
  & { cartClearEmail?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearPhoneMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearPhoneMutation = (
  { __typename?: 'Mutation' }
  & { cartClearPhone?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearPickupWarehouseMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { cartClearPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearShippingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartClearShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartClearTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { cartClearTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearTradeshowMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { cartClearTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartCrossSellsMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartCrossSellsMutation = (
  { __typename?: 'Mutation' }
  & { cartCrossSells?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>> }
);

export type CartGetEarliestDeliveryDateMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartGetEarliestDeliveryDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cartGetEarliestDeliveryDate'>
);

export type CartInfoMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartInfoMutation = (
  { __typename?: 'Mutation' }
  & { cartInfo?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartRemoveAllKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartRemoveAllKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartRemoveAllKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartRemoveAllSimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartRemoveAllSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartRemoveAllSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetAttributionTokenMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  attributionToken: Scalars['String'];
}>;


export type CartSetAttributionTokenMutation = (
  { __typename?: 'Mutation' }
  & { cartSetAttributionToken?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetBillingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type CartSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartSetBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetBillingAddressByIdMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
}>;


export type CartSetBillingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { cartSetBillingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetCarrierPreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  carrier: Carrier;
}>;


export type CartSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartSetCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetContactMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type CartSetContactMutation = (
  { __typename?: 'Mutation' }
  & { cartSetContact?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetCouponCodeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  couponCode: Scalars['String'];
}>;


export type CartSetCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { cartSetCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetDeliveryDatePreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
}>;


export type CartSetDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartSetDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetEmailMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
}>;


export type CartSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { cartSetEmail?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetPhoneMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  phone: Scalars['String'];
}>;


export type CartSetPhoneMutation = (
  { __typename?: 'Mutation' }
  & { cartSetPhone?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetPickupWarehouseMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  warehouseId: Scalars['UUID'];
}>;


export type CartSetPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { cartSetPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
}>;


export type CartSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingAddressByIdMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
}>;


export type CartSetShippingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingZipMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
}>;


export type CartSetShippingZipMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingZip?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartSetTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { cartSetTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetTradeshowMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  tradeshowId: Scalars['UUID'];
}>;


export type CartSetTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { cartSetTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSummarizeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
}>;


export type CartSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { cartSummarize?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal' | 'couponCode'>
    & { salesOrderDiscounts?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderDiscountEntity' }
      & Pick<SalesOrderDiscountEntity, 'id' | 'name' | 'amount'>
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )>, simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'shippingNeeds' | 'carrier' | 'service' | 'estimatedShipDate' | 'estimatedDeliveryDate'>
      & { shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'id'>
        )>, salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & { salesOrderItemGroup?: Maybe<(
            { __typename?: 'SalesOrderItemGroupEntity' }
            & Pick<SalesOrderItemGroupEntity, 'id'>
            & { kitProduct?: Maybe<(
              { __typename?: 'KitProductEntity' }
              & Pick<KitProductEntity, 'id'>
            )> }
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type CategoryByPathQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  path: Scalars['String'];
}>;


export type CategoryByPathQuery = (
  { __typename?: 'Query' }
  & { categoryByPath?: Maybe<(
    { __typename?: 'CategoryResults' }
    & { category?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
      & { publishedRevision?: Maybe<(
        { __typename?: 'CategoryRevisionEntity' }
        & Pick<CategoryRevisionEntity, 'htmlContent'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name'>
        & { thumbnailMedia?: Maybe<(
          { __typename?: 'MediaEntity' }
          & Pick<MediaEntity, 'id' | 'url'>
        )> }
      )>>>, ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'name' | 'path'>
      )>>> }
    )>, products?: Maybe<(
      { __typename?: 'GraphQLPage_ProductEntity' }
      & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'averageRating' | 'countRating'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type CategoryCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
  magentoIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type CategoryCreateMutation = (
  { __typename?: 'Mutation' }
  & { categoryCreate?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
    )> }
  )> }
);

export type CategoryDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CategoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'categoryDelete'>
);

export type CategoryFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<GraphQlSortInput>> | Maybe<GraphQlSortInput>>;
}>;


export type CategoryFilterQuery = (
  { __typename?: 'Query' }
  & { categoryFilter?: Maybe<(
    { __typename?: 'GraphQLPage_CategoryEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type CategoryInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CategoryInfoQuery = (
  { __typename?: 'Query' }
  & { categoryInfo?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'magentoIds'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'CategoryRevisionEntity' }
      & Pick<CategoryRevisionEntity, 'htmlContent'>
    )>, thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    )>>> }
  )> }
);

export type CategoryListQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<GraphQlSortInput>> | Maybe<GraphQlSortInput>>;
}>;


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { categoryList?: Maybe<(
    { __typename?: 'GraphQLPage_CategoryEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type CategorySetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type CategorySetActiveMutation = (
  { __typename?: 'Mutation' }
  & { categorySetActive?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'active'>
  )> }
);

export type CategorySetImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type CategorySetImageMutation = (
  { __typename?: 'Mutation' }
  & { categorySetImage?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )> }
  )> }
);

export type CategorySetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type CategorySetNameMutation = (
  { __typename?: 'Mutation' }
  & { categorySetName?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'name'>
  )> }
);

export type CategorySetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type CategorySetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { categorySetPublishedRevision?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'CategoryRevisionEntity' }
      & Pick<CategoryRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type CategoryUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type CategoryUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { categoryUploadImage?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )> }
  )> }
);

export type CheckoutCreatePaymentMethodMutationVariables = Exact<{
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type CheckoutCreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { checkoutCreatePaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )> }
);

export type CheckoutDeletePaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckoutDeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkoutDeletePaymentMethod'>
);

export type CheckoutGetClientTokenMutationVariables = Exact<{
  anonymous?: Maybe<Scalars['Boolean']>;
}>;


export type CheckoutGetClientTokenMutation = (
  { __typename?: 'Mutation' }
  & { checkoutGetClientToken?: Maybe<(
    { __typename?: 'ClientTokenDetail' }
    & Pick<ClientTokenDetail, 'clientToken'>
  )> }
);

export type CheckoutListPaymentMethodsMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckoutListPaymentMethodsMutation = (
  { __typename?: 'Mutation' }
  & { checkoutListPaymentMethods?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )>>> }
);

export type CheckoutSetDefaultPaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckoutSetDefaultPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkoutSetDefaultPaymentMethod'>
);

export type ConfigurableProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type ConfigurableProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductAddImage?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'configurableProductRemove'>
);

export type ConfigurableProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductRemoveProductMedia?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type ConfigurableProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetActive?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type ConfigurableProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetFeatured?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type ConfigurableProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetPublishedRevision?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type ConfigurableProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetSku?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type ConfigurableProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetSlug?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type ConfigurableProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetTitle?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetUpc?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type ConfigurableProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetVisible?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductUploadImage?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ConfigurableProductBySlugQuery = (
  { __typename?: 'Query' }
  & { configurableProductBySlug?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Pick<ConfigurableProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, configurableItems?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurableItemEntity' }
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'featured' | 'subscription' | 'quantityAvailableForSale' | 'averageRating' | 'countRating'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>>, shippingRuleSet?: Maybe<(
          { __typename?: 'ShippingRuleSetEntity' }
          & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
            { __typename?: 'LiveArrivalGuaranteeEntity' }
            & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
          )>>>, destinationRestriction?: Maybe<(
            { __typename?: 'DestinationRestrictionEntity' }
            & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type ConfigurableProductFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ConfigurableProductFilterQuery = (
  { __typename?: 'Query' }
  & { configurableProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ConfigurableProductEntity' }
    & Pick<GraphQlPage_ConfigurableProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurableProductEntity' }
      & Pick<ConfigurableProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'featured' | 'visible' | 'quantityAvailableForSale' | 'price' | 'specialPrice' | 'wholesalePrice' | 'cost'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ConfigurableProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ConfigurableProductInfoQuery = (
  { __typename?: 'Query' }
  & { configurableProductInfo?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ContentBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ContentBySlugQuery = (
  { __typename?: 'Query' }
  & { contentBySlug?: Maybe<(
    { __typename?: 'ContentEntity' }
    & Pick<ContentEntity, 'id' | 'cls' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
  )> }
);

export type DepartmentListQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentListQuery = (
  { __typename?: 'Query' }
  & { departmentList?: Maybe<(
    { __typename?: 'GraphQLPage_DepartmentEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type DiscountAddEligibleProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddEligibleProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountAddEligibleProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DiscountBySlugQuery = (
  { __typename?: 'Query' }
  & { discountBySlug?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Customer_DiscountFragment
  )> }
);

export type DiscountEvaluateImplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountEvaluateImplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountEvaluateImplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountRemoveEligibleProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveEligibleProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveEligibleProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountAddExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountAddExplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  discountType: DiscountType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
}>;


export type DiscountCreateMutation = (
  { __typename?: 'Mutation' }
  & { discountCreate?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'active' | 'discountAmount' | 'discountType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
  )> }
);

export type DiscountDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'discountDelete'>
);

export type DiscountInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountInfoQuery = (
  { __typename?: 'Query' }
  & { discountInfo?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountListQueryVariables = Exact<{
  pageable?: Maybe<GraphQlPageableInput>;
}>;


export type DiscountListQuery = (
  { __typename?: 'Query' }
  & { discountList?: Maybe<(
    { __typename?: 'GraphQLPage_DiscountEntity' }
    & Pick<GraphQlPage_DiscountEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DiscountEntity' }
      & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
    )>>> }
  )> }
);

export type DiscountRemoveExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveExplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type DiscountSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { discountSetActive?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  discountType: DiscountType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
}>;


export type DiscountUpdateMutation = (
  { __typename?: 'Mutation' }
  & { discountUpdate?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type FinancialResellerRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialResellerRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialResellerRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type FinancialWebsiteRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialWebsiteRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialWebsiteRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type GiftCardCreateMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
}>;


export type GiftCardCreateMutation = (
  { __typename?: 'Mutation' }
  & { giftCardCreate?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
  )> }
);

export type GiftCardFilterQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type GiftCardFilterQuery = (
  { __typename?: 'Query' }
  & { giftCardFilter?: Maybe<(
    { __typename?: 'GraphQLPage_GiftCardEntity' }
    & Pick<GraphQlPage_GiftCardEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardEntity' }
      & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    )>>> }
  )> }
);

export type GiftCardInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GiftCardInfoQuery = (
  { __typename?: 'Query' }
  & { giftCardInfo?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardTransactionEntity' }
      & Pick<GiftCardTransactionEntity, 'id' | 'createdAt' | 'updatedAt' | 'transactionType' | 'comment' | 'amount'>
    )>>> }
  )> }
);

export type GiftCardReloadMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
}>;


export type GiftCardReloadMutation = (
  { __typename?: 'Mutation' }
  & { giftCardReload?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardTransactionEntity' }
      & Pick<GiftCardTransactionEntity, 'id' | 'createdAt' | 'updatedAt' | 'transactionType' | 'comment' | 'amount'>
    )>>> }
  )> }
);

export type GiftCardBalanceQueryVariables = Exact<{
  code: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
}>;


export type GiftCardBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'giftCardBalance'>
);

export type InventoryGetHistoryMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
  days: Scalars['Long'];
}>;


export type InventoryGetHistoryMutation = (
  { __typename?: 'Mutation' }
  & { inventoryGetHistory?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryHistory' }
    & Pick<InventoryHistory, 'timestamp' | 'quantityOnShelf' | 'quantityAvailable' | 'changeQuantity' | 'changeType'>
  )>>> }
);

export type InventorySetDetailsMutationVariables = Exact<{
  warehouse: Scalars['String'];
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type InventorySetDetailsMutation = (
  { __typename?: 'Mutation' }
  & { inventorySetDetails?: Maybe<(
    { __typename?: 'InventoryDetails' }
    & { product?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Admin_Simple_Product_EntityFragment
    )> }
  )> }
);

export type KitProductAddCrossSellProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type KitProductAddCrossSellProductsMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddCrossSellProducts?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type KitProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddImage?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddKitItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type KitProductAddKitItemMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddKitItem?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddPriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  quantity: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
}>;


export type KitProductAddPriceTierMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddPriceTier?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type KitProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'kitProductRemove'>
);

export type KitProductRemoveCrossSellProductMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellProductId: Scalars['UUID'];
}>;


export type KitProductRemoveCrossSellProductMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveCrossSellProduct?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveKitItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
}>;


export type KitProductRemoveKitItemMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveKitItem?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemovePriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  priceTierId: Scalars['UUID'];
}>;


export type KitProductRemovePriceTierMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemovePriceTier?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type KitProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveProductMedia?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type KitProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetActive?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetCostMutationVariables = Exact<{
  id: Scalars['UUID'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetCostMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetCost?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type KitProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetFeatured?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetKitItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type KitProductSetKitItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetKitItemPosition?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type KitProductSetPriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetPrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type KitProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type KitProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetPublishedRevision?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type KitProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSku?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type KitProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSpecialPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetSpecialPriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSpecialPrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type KitProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetTitle?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type KitProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetUpc?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type KitProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetVisible?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetWholesalePriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetWholesalePriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetWholesalePrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type KitProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { kitProductUploadImage?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductWithCrossSellsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type KitProductWithCrossSellsQuery = (
  { __typename?: 'Query' }
  & { kitProductBySlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Pick<KitProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>> }
    )>, crossSells?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type KitProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type KitProductBySlugQuery = (
  { __typename?: 'Query' }
  & { kitProductBySlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating' | 'shippingNeeds'>
    & { kitItems?: Maybe<Array<Maybe<(
      { __typename?: 'KitItemEntity' }
      & Pick<KitItemEntity, 'id' | 'quantity'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'averageRating' | 'countRating' | 'quantityAvailableForSale'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )> }
    )>>>, tieredPrices?: Maybe<Array<Maybe<(
      { __typename?: 'PriceTierEntity' }
      & Pick<PriceTierEntity, 'id' | 'quantity' | 'price'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>>, destinationRestriction?: Maybe<(
        { __typename?: 'DestinationRestrictionEntity' }
        & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
      )> }
    )> }
  )> }
);

export type KitProductFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type KitProductFilterQuery = (
  { __typename?: 'Query' }
  & { kitProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_KitProductEntity' }
    & Pick<GraphQlPage_KitProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'featured' | 'visible' | 'quantityAvailableForSale' | 'price' | 'specialPrice' | 'wholesalePrice' | 'cost'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type KitProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type KitProductInfoQuery = (
  { __typename?: 'Query' }
  & { kitProductInfo?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type MakingStockStatusMutationVariables = Exact<{
  warehouse: Scalars['String'];
}>;


export type MakingStockStatusMutation = (
  { __typename?: 'Mutation' }
  & { makingStockStatus?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryDetails' }
    & Pick<InventoryDetails, 'warehouseQuantityAvailable' | 'warehouseWeeklyConsumptionRate' | 'warehouseWeeklyConsumptionVariance' | 'warehouseRunOutDays50' | 'warehouseRunOutDays85' | 'warehouseRunOutDays97'>
    & { product?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'title' | 'sku'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type MarketingSubscribeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type MarketingSubscribeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'marketingSubscribe'>
);

export type MediaFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
}>;


export type MediaFilterQuery = (
  { __typename?: 'Query' }
  & { mediaFilter?: Maybe<(
    { __typename?: 'GraphQLPage_MediaEntity' }
    & Pick<GraphQlPage_MediaEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
    )>>> }
  )> }
);

export type MediaInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MediaInfoQuery = (
  { __typename?: 'Query' }
  & { mediaInfo?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'name'>
      )>>> }
    )>>>, productMedias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id'>
      & { product?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type MediaUploadImageMutationVariables = Exact<{
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type MediaUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { mediaUploadImage?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  )> }
);

export type AffiliateAddProductListMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
}>;


export type AffiliateAddProductListMutation = (
  { __typename?: 'Mutation' }
  & { affiliateAddProductList?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateAddProductListItemsMutationVariables = Exact<{
  listId: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type AffiliateAddProductListItemsMutation = (
  { __typename?: 'Mutation' }
  & { affiliateAddProductListItems?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type AffiliateBySlugQuery = (
  { __typename?: 'Query' }
  & { affiliateBySlug?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'affiliateAttributionToken' | 'affiliateBiography' | 'name' | 'slug'>
    & { affiliateProductLists?: Maybe<Array<Maybe<(
      { __typename?: 'AffiliateProductListEntity' }
      & Pick<AffiliateProductListEntity, 'name' | 'slug'>
      & { affiliateProductListItems?: Maybe<Array<Maybe<(
        { __typename?: 'AffiliateProductListItemEntity' }
        & { product?: Maybe<(
          { __typename?: 'ProductEntity' }
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AffiliateDeleteProductListMutationVariables = Exact<{
  id: Scalars['UUID'];
  listId: Scalars['UUID'];
}>;


export type AffiliateDeleteProductListMutation = (
  { __typename?: 'Mutation' }
  & { affiliateDeleteProductList?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateGetProductListByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AffiliateGetProductListByIdQuery = (
  { __typename?: 'Query' }
  & { affiliateGetProductListById?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateGetProductListBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  affiliateSlug: Scalars['String'];
}>;


export type AffiliateGetProductListBySlugQuery = (
  { __typename?: 'Query' }
  & { affiliateGetProductListBySlug?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Pick<AffiliateProductListEntity, 'htmlContent' | 'name' | 'slug'>
    & { affiliateProductListItems?: Maybe<Array<Maybe<(
      { __typename?: 'AffiliateProductListItemEntity' }
      & { product?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'cls' | 'price' | 'salePrice' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type AffiliateRemoveProductListItemMutationVariables = Exact<{
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
}>;


export type AffiliateRemoveProductListItemMutation = (
  { __typename?: 'Mutation' }
  & { affiliateRemoveProductListItem?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetAffiliateBiographyMutationVariables = Exact<{
  id: Scalars['UUID'];
  affiliateBiography: Scalars['String'];
}>;


export type AffiliateSetAffiliateBiographyMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetAffiliateBiography?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetAttributionPeriodMutationVariables = Exact<{
  id: Scalars['UUID'];
  period: Scalars['Long'];
}>;


export type AffiliateSetAttributionPeriodMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetAttributionPeriod?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetCommissionMutationVariables = Exact<{
  id: Scalars['UUID'];
  commission: Scalars['BigDecimal'];
}>;


export type AffiliateSetCommissionMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetCommission?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetProductListHtmlContentMutationVariables = Exact<{
  listId: Scalars['UUID'];
  htmlContent: Scalars['String'];
}>;


export type AffiliateSetProductListHtmlContentMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListHtmlContent?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListItemPositionMutationVariables = Exact<{
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type AffiliateSetProductListItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListItemPosition?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListNameMutationVariables = Exact<{
  listId: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type AffiliateSetProductListNameMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListName?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  listId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type AffiliateSetProductListSequenceMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListSequence?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetProductListSlugMutationVariables = Exact<{
  listId: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type AffiliateSetProductListSlugMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListSlug?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type AffiliateSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetSlug?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerJoinMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
}>;


export type PartnerJoinMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'partnerJoin'>
);

export type PartnerCreateMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
}>;


export type PartnerCreateMutation = (
  { __typename?: 'Mutation' }
  & { partnerCreate?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'name' | 'reseller'>
  )> }
);

export type PartnerFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
}>;


export type PartnerFilterQuery = (
  { __typename?: 'Query' }
  & { partnerFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PartnerEntity' }
    & Pick<GraphQlPage_PartnerEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
    )>>> }
  )> }
);

export type PartnerInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PartnerInfoQuery = (
  { __typename?: 'Query' }
  & { partnerInfo?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerListDropshippersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerListDropshippersQuery = (
  { __typename?: 'Query' }
  & { partnerListDropshippers?: Maybe<Array<Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
  )>>> }
);

export type PartnerListResellersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerListResellersQuery = (
  { __typename?: 'Query' }
  & { partnerListResellers?: Maybe<Array<Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
  )>>> }
);

export type PartnerSetAffiliateMutationVariables = Exact<{
  id: Scalars['UUID'];
  affiliate: Scalars['Boolean'];
}>;


export type PartnerSetAffiliateMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetAffiliate?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetDropshipperMutationVariables = Exact<{
  id: Scalars['UUID'];
  dropshipper: Scalars['Boolean'];
}>;


export type PartnerSetDropshipperMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetDropshipper?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type PartnerSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetEmail?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type PartnerSetNameMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetName?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetResellerMutationVariables = Exact<{
  id: Scalars['UUID'];
  reseller: Scalars['Boolean'];
}>;


export type PartnerSetResellerMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetReseller?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerUpdateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type PartnerUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { partnerUpdateAddress?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type ResellerSetResellerShippingRateModeMutationVariables = Exact<{
  id: Scalars['UUID'];
  resellerShippingRateMode: ResellerShippingRateMode;
}>;


export type ResellerSetResellerShippingRateModeMutation = (
  { __typename?: 'Mutation' }
  & { resellerSetResellerShippingRateMode?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type ResellerSetResellerTaxModeMutationVariables = Exact<{
  id: Scalars['UUID'];
  resellerTaxMode: ResellerTaxMode;
}>;


export type ResellerSetResellerTaxModeMutation = (
  { __typename?: 'Mutation' }
  & { resellerSetResellerTaxMode?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type DropshipperProfileCreateMutationVariables = Exact<{
  simpleProductId: Scalars['UUID'];
  dropshipperId: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  cost: Scalars['BigDecimal'];
}>;


export type DropshipperProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { dropshipperProfileCreate?: Maybe<(
    { __typename?: 'DropshipperProfileEntity' }
    & Pick<DropshipperProfileEntity, 'id' | 'dropshipperSku' | 'title' | 'cost'>
  )> }
);

export type PetcoDsProfileCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  sku: Scalars['String'];
  description: Scalars['String'];
  surcharge: Scalars['BigDecimal'];
}>;


export type PetcoDsProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { petcoDsProfileCreate?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )> }
);

export type ResellerProfileCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  resellerId: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['BigDecimal'];
  retail?: Maybe<Scalars['BigDecimal']>;
}>;


export type ResellerProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { resellerProfileCreate?: Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'title' | 'price' | 'retail'>
  )> }
);

export type ProductAddCategoryMutationVariables = Exact<{
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
}>;


export type ProductAddCategoryMutation = (
  { __typename?: 'Mutation' }
  & { productAddCategory?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductBySlugQuery = (
  { __typename?: 'Query' }
  & { productBySlug?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'slug'>
  )> }
);

export type ProductFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  active?: Maybe<Scalars['Boolean']>;
  animal?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  minRating?: Maybe<Scalars['Long']>;
  minStock?: Maybe<Scalars['Long']>;
  sku?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
}>;


export type ProductFilterQuery = (
  { __typename?: 'Query' }
  & { productFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ProductEntity' }
    & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'title' | 'sku' | 'upc' | 'price' | 'quantityAvailableForSale' | 'subscription'>
      & { shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
          { __typename?: 'LiveArrivalGuaranteeEntity' }
          & Pick<LiveArrivalGuaranteeEntity, 'id'>
        )>>> }
      )>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type ProductListBySkusQueryVariables = Exact<{
  skus: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type ProductListBySkusQuery = (
  { __typename?: 'Query' }
  & { productListBySkus?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title' | 'slug' | 'price'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )>>> }
);

export type ProductRemoveCategoryMutationVariables = Exact<{
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
}>;


export type ProductRemoveCategoryMutation = (
  { __typename?: 'Mutation' }
  & { productRemoveCategory?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ProductRequestBackInStockNotificationMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type ProductRequestBackInStockNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'productRequestBackInStockNotification'>
);

export type ProductSearchQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  query?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  promotion?: Maybe<Scalars['String']>;
  animal?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  inStock?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['Boolean']>;
  minPrice?: Maybe<Scalars['BigDecimal']>;
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  minRating?: Maybe<Scalars['Float']>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ProductSearchQuery = (
  { __typename?: 'Query' }
  & { productSearch?: Maybe<(
    { __typename?: 'SearchResults' }
    & { category?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { children?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )>>> }
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name'>
      )>>> }
    )>, animal?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
        & { children?: Maybe<Array<Maybe<(
          { __typename?: 'AnimalEntity' }
          & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
        )>>> }
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )>>> }
    )>, brand?: Maybe<(
      { __typename?: 'BrandEntity' }
      & Pick<BrandEntity, 'id' | 'slug' | 'name'>
    )>, products?: Maybe<(
      { __typename?: 'GraphQLPage_ProductEntity' }
      & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'averageRating' | 'countRating' | 'featured'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type ProductSetDepartmentMutationVariables = Exact<{
  productId: Scalars['UUID'];
  departmentId: Scalars['UUID'];
}>;


export type ProductSetDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { productSetDepartment?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )> }
);

export type PromotionBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PromotionBySlugQuery = (
  { __typename?: 'Query' }
  & { promotionBySlug?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Customer_PromotionFragment
  )> }
);

export type PromotionEvaluateImplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionEvaluateImplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionEvaluateImplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionAddExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionAddExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionAddExplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  promotionType: PromotionType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
}>;


export type PromotionCreateMutation = (
  { __typename?: 'Mutation' }
  & { promotionCreate?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'active' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleItemCount' | 'eligibleItemValue' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
  )> }
);

export type PromotionDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'promotionDelete'>
);

export type PromotionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionInfoQuery = (
  { __typename?: 'Query' }
  & { promotionInfo?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionListQueryVariables = Exact<{
  pageable?: Maybe<GraphQlPageableInput>;
}>;


export type PromotionListQuery = (
  { __typename?: 'Query' }
  & { promotionList?: Maybe<(
    { __typename?: 'GraphQLPage_PromotionEntity' }
    & Pick<GraphQlPage_PromotionEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PromotionEntity' }
      & Pick<PromotionEntity, 'id' | 'active' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleItemCount' | 'eligibleItemValue' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
    )>>> }
  )> }
);

export type PromotionRemoveExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionRemoveExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionRemoveExplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type PromotionSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetActive?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  promotionType: PromotionType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['String']>;
}>;


export type PromotionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { promotionUpdate?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PurchaseOrderCloseMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderCloseMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderClose?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id' | 'status'>
  )> }
);

export type PurchaseOrderDeleteItemMutationVariables = Exact<{
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
}>;


export type PurchaseOrderDeleteItemMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderDeleteItem?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'id'>
    )>>> }
  )> }
);

export type PurchaseOrderEditItemQtyMutationVariables = Exact<{
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type PurchaseOrderEditItemQtyMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderEditItemQty?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'id' | 'total'>
    )>>> }
  )> }
);

export type PurchaseOrderFilterQueryVariables = Exact<{
  orderNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseOrderStatus>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type PurchaseOrderFilterQuery = (
  { __typename?: 'Query' }
  & { purchaseOrderFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PurchaseOrderEntity' }
    & Pick<GraphQlPage_PurchaseOrderEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'id' | 'cls' | 'placedTime' | 'orderNumber' | 'status'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'name'>
      )>, warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'name'>
      )> }
    )>>> }
  )> }
);

export type PurchaseOrderGenerateCsvMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderGenerateCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseOrderGenerateCSV'>
);

export type PurchaseOrderGeneratePdfMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderGeneratePdfMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseOrderGeneratePDF'>
);

export type PurchaseOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderInfoQuery = (
  { __typename?: 'Query' }
  & { purchaseOrderInfo?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'cls' | 'id' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'expectedTime' | 'status'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'cls' | 'id' | 'name' | 'sku' | 'received' | 'total'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id'>
      )> }
    )>>>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'phone' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'state' | 'postalCode' | 'country'>
    )>, warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'name' | 'line1' | 'line2' | 'state' | 'postalCode' | 'country'>
    )> }
  )> }
);

export type PurchaseOrderReOpenMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderReOpenMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderReOpen?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id' | 'status'>
  )> }
);

export type PurchaseRequestApproveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestApproveMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestApprove?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestCreateMutationVariables = Exact<{
  team: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<PurchaseRequestItemInput>> | Maybe<PurchaseRequestItemInput>>;
}>;


export type PurchaseRequestCreateMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestCreate?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestDenyMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestDenyMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestDeny?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestFilterQueryVariables = Exact<{
  requestNumber?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  team?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseRequestStatus>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type PurchaseRequestFilterQuery = (
  { __typename?: 'Query' }
  & { purchaseRequestFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PurchaseRequestEntity' }
    & Pick<GraphQlPage_PurchaseRequestEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestEntity' }
      & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
      & { team?: Maybe<(
        { __typename?: 'TeamEntity' }
        & Pick<TeamEntity, 'id' | 'name' | 'slug'>
      )>, purchaseRequestItems?: Maybe<Array<Maybe<(
        { __typename?: 'PurchaseRequestItemEntity' }
        & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
      )>>> }
    )>>> }
  )> }
);

export type PurchaseRequestInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestInfoQuery = (
  { __typename?: 'Query' }
  & { purchaseRequestInfo?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestOrderMutationVariables = Exact<{
  id: Scalars['UUID'];
  amount: Scalars['BigDecimal'];
}>;


export type PurchaseRequestOrderMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestOrder?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestReceiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestReceiveMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestReceive?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type RedirectFindByPathMutationVariables = Exact<{
  path: Scalars['String'];
}>;


export type RedirectFindByPathMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'redirectFindByPath'>
);

export type RefundInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefundInfoQuery = (
  { __typename?: 'Query' }
  & { refundInfo?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
    & { refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'unitAmount' | 'lineAmount'>
      )> }
    )>>> }
  )> }
);

export type ReviewListForProductQueryVariables = Exact<{
  productId: Scalars['UUID'];
  rating?: Maybe<Scalars['Long']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ReviewListForProductQuery = (
  { __typename?: 'Query' }
  & { reviewListForProduct?: Maybe<(
    { __typename?: 'ReviewResults' }
    & Pick<ReviewResults, 'count81To100' | 'count61To80' | 'count41To60' | 'count21To40' | 'count0To20' | 'averageRating'>
    & { reviews?: Maybe<(
      { __typename?: 'GraphQLPage_ReviewEntity' }
      & Pick<GraphQlPage_ReviewEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ReviewEntity' }
        & Pick<ReviewEntity, 'id' | 'reviewer' | 'title' | 'comments' | 'rating'>
      )>>> }
    )> }
  )> }
);

export type RewardPointsBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardPointsBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'rewardPointsBalance'>
);

export type RewardPointsGetRewardsAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardPointsGetRewardsAccountQuery = (
  { __typename?: 'Query' }
  & { rewardPointsGetRewardsAccount?: Maybe<(
    { __typename?: 'RewardsAccountEntity' }
    & Pick<RewardsAccountEntity, 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'RewardsTransactionEntity' }
      & Pick<RewardsTransactionEntity, 'amount' | 'comment' | 'timestamp' | 'transactionType'>
    )>>> }
  )> }
);

export type ReshipSalesOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ReshipSalesOrderInfoQuery = (
  { __typename?: 'Query' }
  & { reshipSalesOrderInfo?: Maybe<(
    { __typename?: 'ReshipSalesOrderEntity' }
    & { parentOrder?: Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber'>
    )> }
  )> }
);

export type SalesOrderAddBraintreePaymentMutationVariables = Exact<{
  orderId: Scalars['UUID'];
  transactionId: Scalars['String'];
}>;


export type SalesOrderAddBraintreePaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddBraintreePayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCashPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
}>;


export type SalesOrderAddCashPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddCashPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCheckPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  checkNumber?: Maybe<Scalars['String']>;
}>;


export type SalesOrderAddCheckPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddCheckPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCommentMutationVariables = Exact<{
  comment: Scalars['String'];
  id: Scalars['UUID'];
}>;


export type SalesOrderAddCommentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddComment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddGiftCardPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  giftCardCode?: Maybe<Scalars['String']>;
}>;


export type SalesOrderAddGiftCardPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddGiftCardPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddPaymentTermsPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
}>;


export type SalesOrderAddPaymentTermsPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddPaymentTermsPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderCancelMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderCancelMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCancel?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderCancelByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderCancelByHashMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCancelByHash?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderCustomRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  shipping: Scalars['BigDecimal'];
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderCustomRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCustomRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderCustomRefundSummarizeMutationVariables = Exact<{
  id: Scalars['UUID'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  shipping: Scalars['BigDecimal'];
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderCustomRefundSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCustomRefundSummarize?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
  )> }
);

export type SalesOrderGetRefundableAmountsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGetRefundableAmountsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderGetRefundableAmounts?: Maybe<(
    { __typename?: 'RefundableAmounts' }
    & Pick<RefundableAmounts, 'shipping' | 'handling' | 'shippingDiscount'>
  )> }
);

export type SalesOrderPartialRefundSummarizeMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderPartialRefundSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialRefundSummarize?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
    & { refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'unitAmount' | 'lineAmount'>
      )> }
    )>>> }
  )> }
);

export type SalesOrderSalesTaxRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: SalesOrderType;
}>;


export type SalesOrderSalesTaxRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSalesTaxRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderByHashQuery = (
  { __typename?: 'Query' }
  & { salesOrderByHash?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderFilterQueryVariables = Exact<{
  orderNumber?: Maybe<Scalars['String']>;
  alternateOrderNumber?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  billingFirstName?: Maybe<Scalars['String']>;
  billingLastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<SalesOrderStatus>;
  hold?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type SalesOrderFilterQuery = (
  { __typename?: 'Query' }
  & { salesOrderFilter?: Maybe<(
    { __typename?: 'GraphQLPage_SalesOrderEntity' }
    & Pick<GraphQlPage_SalesOrderEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'status' | 'billingFirstName' | 'billingLastName' | 'email' | 'placedTime' | 'subTotal' | 'discountTotal' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
      & { salesOrderItemGroups?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'lineAmount' | 'name' | 'sku'>
        & { kitProduct?: Maybe<(
          { __typename?: 'KitProductEntity' }
          & Pick<KitProductEntity, 'sku' | 'title'>
        )> }
      )>>>, salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
        & { salesOrderItemGroup?: Maybe<(
          { __typename?: 'SalesOrderItemGroupEntity' }
          & Pick<SalesOrderItemGroupEntity, 'id'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SalesOrderGenerateInvoiceMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGenerateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salesOrderGenerateInvoice'>
);

export type SalesOrderGenerateInvoiceByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderGenerateInvoiceByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salesOrderGenerateInvoiceByHash'>
);

export type SalesOrderGetRefundableItemsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGetRefundableItemsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderGetRefundableItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderLineItem' }
    & Pick<SalesOrderLineItem, 'id' | 'quantity'>
  )>>> }
);

export type SalesOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderInfoQuery = (
  { __typename?: 'Query' }
  & { salesOrderInfo?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderInfoAdminQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderInfoAdminQuery = (
  { __typename?: 'Query' }
  & { salesOrderInfo?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderListQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
}>;


export type SalesOrderListQuery = (
  { __typename?: 'Query' }
  & { salesOrderList?: Maybe<(
    { __typename?: 'GraphQLPage_SalesOrderEntity' }
    & Pick<GraphQlPage_SalesOrderEntity, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'status' | 'placedTime' | 'grandTotal'>
      & { salesOrderItemGroups?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'quantity' | 'unitAmount' | 'lineAmount' | 'name'>
        & { kitProduct?: Maybe<(
          { __typename?: 'KitProductEntity' }
          & Pick<KitProductEntity, 'slug' | 'title'>
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )>, salesOrderItems?: Maybe<Array<Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'quantity' | 'name'>
        )>>> }
      )>>>, salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
        & { salesOrderItemGroup?: Maybe<(
          { __typename?: 'SalesOrderItemGroupEntity' }
          & Pick<SalesOrderItemGroupEntity, 'id'>
        )>, simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'slug' | 'title'>
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SalesOrderOfflineRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderOfflineRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderOfflineRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderOnlineRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderOnlineRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderOnlineRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderPartialRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderPartialRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderPartialReshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
  carrierPreference?: Maybe<Carrier>;
  serviceType?: Maybe<ServiceType>;
}>;


export type SalesOrderPartialReshipMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialReship?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type SalesOrderSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSetEmail?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderSetHoldMutationVariables = Exact<{
  id: Scalars['UUID'];
  hold: Scalars['Boolean'];
}>;


export type SalesOrderSetHoldMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSetHold?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderUpdatePaymentsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderUpdatePaymentsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderUpdatePayments?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderValidateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderValidateAddressMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderValidateAddress?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type ScheduledShipmentScheduleMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
}>;


export type ScheduledShipmentScheduleMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentSchedule?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ScheduledShipmentScheduleByHashMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  hash: Scalars['String'];
}>;


export type ScheduledShipmentScheduleByHashMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentScheduleByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_Shipment_PublicFragment
  )> }
);

export type ScheduledShipmentUpdateAddressByHashFullMutationVariables = Exact<{
  hash: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type ScheduledShipmentUpdateAddressByHashFullMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentUpdateAddressByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ScheduledShipmentHoldLocationsQueryVariables = Exact<{
  postalCode: Scalars['String'];
}>;


export type ScheduledShipmentHoldLocationsQuery = (
  { __typename?: 'Query' }
  & { scheduledShipmentHoldLocations?: Maybe<Array<Maybe<(
    { __typename?: 'HoldLocation' }
    & Pick<HoldLocation, 'city' | 'company' | 'country' | 'line1' | 'line2' | 'postalCode' | 'state'>
  )>>> }
);

export type ShipmentByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ShipmentByHashQuery = (
  { __typename?: 'Query' }
  & { shipmentByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_Shipment_PublicFragment
  )> }
);

export type ShipmentFilterQueryVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
  salesOrderCls?: Maybe<Scalars['String']>;
  shipmentNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  late?: Maybe<Scalars['Boolean']>;
  shipmentStatus?: Maybe<ShipmentStatus>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ShipmentFilterQuery = (
  { __typename?: 'Query' }
  & { shipmentFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ShipmentEntity' }
    & Pick<GraphQlPage_ShipmentEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'cls' | 'email' | 'firstName' | 'lastName' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'shippedAt' | 'trackingNo'>
      & { originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, salesOrder?: Maybe<(
        { __typename?: 'SalesOrderEntity' }
        & Pick<SalesOrderEntity, 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'salesOrderType'>
      )>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type ShipmentInfoAdminQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentInfoAdminQuery = (
  { __typename?: 'Query' }
  & { shipmentInfo?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentTrackMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentTrackMutation = (
  { __typename?: 'Mutation' }
  & { shipmentTrack?: Maybe<Array<Maybe<(
    { __typename?: 'TrackingEvent' }
    & Pick<TrackingEvent, 'comment' | 'location' | 'timestamp' | 'trackingEventType'>
  )>>> }
);

export type ShipmentTrackByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ShipmentTrackByHashMutation = (
  { __typename?: 'Mutation' }
  & { shipmentTrackByHash?: Maybe<Array<Maybe<(
    { __typename?: 'TrackingEvent' }
    & Pick<TrackingEvent, 'comment' | 'location' | 'timestamp' | 'trackingEventType'>
  )>>> }
);

export type ShipmentCancelAndRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<CancellationReason>;
  sendNotification: Scalars['Boolean'];
  refund: Scalars['Boolean'];
}>;


export type ShipmentCancelAndRefundMutation = (
  { __typename?: 'Mutation' }
  & { shipmentCancelAndRefund?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentDashboardMutationVariables = Exact<{
  warehouse: Scalars['String'];
}>;


export type ShipmentDashboardMutation = (
  { __typename?: 'Mutation' }
  & { shipmentDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'warehouse' | 'timezone' | 'today' | 'nextOpenDate' | 'lateCount' | 'onHoldCount' | 'pendingCount' | 'needsSchedulingCount'>
    & { summaries?: Maybe<Array<Maybe<(
      { __typename?: 'Summary' }
      & Pick<Summary, 'date' | 'onHold' | 'unshipped' | 'shipped'>
    )>>> }
  )> }
);

export type ShipmentDeliverOfflineMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentDeliverOfflineMutation = (
  { __typename?: 'Mutation' }
  & { shipmentDeliverOffline?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentStatus'>
  )> }
);

export type ShipmentGenerateCommercialInvoiceMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentGenerateCommercialInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipmentGenerateCommercialInvoice'>
);

export type ShipmentGeneratePackingListMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentGeneratePackingListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipmentGeneratePackingList'>
);

export type ShipmentInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentInfoQuery = (
  { __typename?: 'Query' }
  & { shipmentInfo?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ShipmentLookupWeatherMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type ShipmentLookupWeatherMutation = (
  { __typename?: 'Mutation' }
  & { shipmentLookupWeather?: Maybe<Array<Maybe<(
    { __typename?: 'Weather' }
    & Pick<Weather, 'date' | 'high' | 'low'>
  )>>> }
);

export type ShipmentLookupWeatherByHashMutationVariables = Exact<{
  shipmentIdHash: Scalars['String'];
}>;


export type ShipmentLookupWeatherByHashMutation = (
  { __typename?: 'Mutation' }
  & { shipmentLookupWeatherByHash?: Maybe<Array<Maybe<(
    { __typename?: 'Weather' }
    & Pick<Weather, 'date' | 'high' | 'low'>
  )>>> }
);

export type ShipmentRerouteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentRerouteMutation = (
  { __typename?: 'Mutation' }
  & { shipmentReroute?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentSetShippingNeedsMutationVariables = Exact<{
  id: Scalars['UUID'];
  shippingNeeds: ShippingNeedsType;
}>;


export type ShipmentSetShippingNeedsMutation = (
  { __typename?: 'Mutation' }
  & { shipmentSetShippingNeeds?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentShipOfflineMutationVariables = Exact<{
  id: Scalars['UUID'];
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  service?: Maybe<Service>;
}>;


export type ShipmentShipOfflineMutation = (
  { __typename?: 'Mutation' }
  & { shipmentShipOffline?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentSplitMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<ShipmentLineItemInput>> | Maybe<ShipmentLineItemInput>;
  reroute: Scalars['Boolean'];
}>;


export type ShipmentSplitMutation = (
  { __typename?: 'Mutation' }
  & { shipmentSplit?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'cls' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'shippedAt' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressValidationSource' | 'downloadStart' | 'printedStart' | 'printedEnd' | 'reprint'>
    & { salesOrder?: Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'status'>
    )>, shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
      )> }
    )>>>, shipmentAddons?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentAddonEntity' }
      & Pick<ShipmentAddonEntity, 'id' | 'cls' | 'quantity' | 'sku' | 'name'>
    )>>> }
  )> }
);

export type ShipmentUpdateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type ShipmentUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { shipmentUpdateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentUpdateAddressFullMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential: Scalars['Boolean'];
}>;


export type ShipmentUpdateAddressFullMutation = (
  { __typename?: 'Mutation' }
  & { shipmentUpdateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ShipmentValidateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentValidateAddressMutation = (
  { __typename?: 'Mutation' }
  & { shipmentValidateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type SimpleProductAddComponentItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['BigDecimal'];
}>;


export type SimpleProductAddComponentItemMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddComponentItem?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddCrossSellProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type SimpleProductAddCrossSellProductsMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddCrossSellProducts?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type SimpleProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddImage?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddPriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  quantity: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
}>;


export type SimpleProductAddPriceTierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddPriceTier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductAddWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddWarehouse?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type SimpleProductAddZoneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddZone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type SimpleProductBySlugQuery = (
  { __typename?: 'Query' }
  & { simpleProductBySlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'featured' | 'subscription' | 'quantityAvailableForSale' | 'discontinuationReason' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating' | 'shippingNeeds'>
    & { tieredPrices?: Maybe<Array<Maybe<(
      { __typename?: 'PriceTierEntity' }
      & Pick<PriceTierEntity, 'id' | 'quantity' | 'price'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>>, destinationRestriction?: Maybe<(
        { __typename?: 'DestinationRestrictionEntity' }
        & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
      )> }
    )> }
  )> }
);

export type SimpleProductClearBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductClearBinMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearBin?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
}>;


export type SimpleProductClearBrandMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearBrand?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearDiscontinuationReasonMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductClearDiscontinuationReasonMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearDiscontinuationReason?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearRequiresPrepMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductClearRequiresPrepMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearRequiresPrep?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearShipsAloneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductClearShipsAloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearShipsAlone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearSupplierMutationVariables = Exact<{
  productId: Scalars['UUID'];
}>;


export type SimpleProductClearSupplierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearSupplier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
  upc?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductCloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductCreateMutationVariables = Exact<{
  sku: Scalars['String'];
  upc?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductCreate?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'simpleProductRemove'>
);

export type SimpleProductFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  inStock?: Maybe<Scalars['Boolean']>;
}>;


export type SimpleProductFilterQuery = (
  { __typename?: 'Query' }
  & { simpleProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_SimpleProductEntity' }
    & Pick<GraphQlPage_SimpleProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'averageRating' | 'countRating' | 'price' | 'salePrice' | 'shippingNeeds'>
      & { explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'BrandEntity' }
        & Pick<BrandEntity, 'id' | 'slug' | 'name'>
      )>, supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
      )>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
      )> }
    )>>> }
  )> }
);

export type SimpleProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductInfoQuery = (
  { __typename?: 'Query' }
  & { simpleProductInfo?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveComponentItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
}>;


export type SimpleProductRemoveComponentItemMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveComponentItem?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveCrossSellProductMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellProductId: Scalars['UUID'];
}>;


export type SimpleProductRemoveCrossSellProductMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveCrossSellProduct?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemovePriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  priceTierId: Scalars['UUID'];
}>;


export type SimpleProductRemovePriceTierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemovePriceTier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type SimpleProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveProductMedia?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductRemoveWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveWarehouse?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type SimpleProductRemoveZoneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveZone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type SimpleProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetActive?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  bin: Scalars['String'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductSetBinMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetBin?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
}>;


export type SimpleProductSetBrandMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetBrand?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCaseQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  caseQuantity?: Maybe<Scalars['Long']>;
}>;


export type SimpleProductSetCaseQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCaseQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetComponentItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type SimpleProductSetComponentItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetComponentItemPosition?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetComponentItemQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
  quantity: Scalars['BigDecimal'];
}>;


export type SimpleProductSetComponentItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetComponentItemQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCostMutationVariables = Exact<{
  id: Scalars['UUID'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetCostMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCost?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetDimensionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  length: Scalars['BigDecimal'];
  width: Scalars['BigDecimal'];
  height: Scalars['BigDecimal'];
}>;


export type SimpleProductSetDimensionsMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetDimensions?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetDiscontinuationReasonMutationVariables = Exact<{
  reason: DiscontinuationReasonType;
  id: Scalars['UUID'];
}>;


export type SimpleProductSetDiscontinuationReasonMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetDiscontinuationReason?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type SimpleProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetFeatured?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetHeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetHeightMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetHeight?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetLengthMutationVariables = Exact<{
  id: Scalars['UUID'];
  length?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetLengthMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetLength?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMapPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  mapPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetMapPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMapPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMinimumOrderQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
}>;


export type SimpleProductSetMinimumOrderQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMinimumOrderQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type SimpleProductSetPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type SimpleProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetPublishedRevision?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetRequiresPrepMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductSetRequiresPrepMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetRequiresPrep?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetShipsAloneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductSetShipsAloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetShipsAlone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type SimpleProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSku?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type SimpleProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSpecialPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetSpecialPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSpecialPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSupplierMutationVariables = Exact<{
  productId: Scalars['UUID'];
  supplierId: Scalars['UUID'];
}>;


export type SimpleProductSetSupplierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSupplier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type SimpleProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetTitle?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetUpc?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetVendorRefMutationVariables = Exact<{
  id: Scalars['UUID'];
  vendorRef?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetVendorRefMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetVendorRef?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type SimpleProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetVisible?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  weight?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetWeightMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWeight?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWholesalePriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetWholesalePriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWholesalePrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWidthMutationVariables = Exact<{
  id: Scalars['UUID'];
  width?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetWidthMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWidth?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type SimpleProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductUploadImage?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductWithCrossSellsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type SimpleProductWithCrossSellsQuery = (
  { __typename?: 'Query' }
  & { simpleProductBySlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>> }
    )>, crossSells?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type SubscriptionAddItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
}>;


export type SubscriptionAddItemMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionAddItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  deliveryDay?: Maybe<DayOfWeek>;
  phone: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingLastName: Scalars['String'];
  billingCompany?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingCity: Scalars['String'];
  billingState: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingLastName: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  shippingLine1: Scalars['String'];
  shippingLine2?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingState: Scalars['String'];
  shippingPostalCode: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
  paymentToken?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
}>;


export type SubscriptionCreateMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionCreate?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { subscriptionInfo?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionListQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionListQuery = (
  { __typename?: 'Query' }
  & { subscriptionList?: Maybe<(
    { __typename?: 'GraphQLPage_SubscriptionEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionEntity' }
      & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'shippingAddressValidationSource'>
      & { subscriptionItems?: Maybe<Array<Maybe<(
        { __typename?: 'SubscriptionItemEntity' }
        & Pick<SubscriptionItemEntity, 'frequency' | 'quantity'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'title' | 'price' | 'salePrice'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SubscriptionRemoveItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
}>;


export type SubscriptionRemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionRemoveItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetPaymentTokenMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  paymentToken: Scalars['String'];
}>;


export type SubscriptionSetPaymentTokenMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetPaymentToken?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SupplierClearEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierClearEmailMutation = (
  { __typename?: 'Mutation' }
  & { supplierClearEmail?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierClearPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierClearPhoneMutation = (
  { __typename?: 'Mutation' }
  & { supplierClearPhone?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierCreateMutationVariables = Exact<{
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
}>;


export type SupplierCreateMutation = (
  { __typename?: 'Mutation' }
  & { supplierCreate?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'supplierDelete'>
);

export type SupplierInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierInfoQuery = (
  { __typename?: 'Query' }
  & { supplierInfo?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplierListQuery = (
  { __typename?: 'Query' }
  & { supplierList?: Maybe<(
    { __typename?: 'GraphQLPage_SupplierEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type SupplierSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type SupplierSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetEmail?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetLeadTimeMutationVariables = Exact<{
  id: Scalars['UUID'];
  leadTime: Scalars['Int'];
}>;


export type SupplierSetLeadTimeMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetLeadTime?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type SupplierSetNameMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetName?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetOrderCycleMutationVariables = Exact<{
  id: Scalars['UUID'];
  orderCycle: Scalars['Int'];
}>;


export type SupplierSetOrderCycleMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetOrderCycle?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  phone: Scalars['String'];
}>;


export type SupplierSetPhoneMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetPhone?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierUpdateAddressMutationVariables = Exact<{
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  id: Scalars['UUID'];
  residential: Scalars['Boolean'];
}>;


export type SupplierUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { supplierUpdateAddress?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type TeamListQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamListQuery = (
  { __typename?: 'Query' }
  & { teamList?: Maybe<(
    { __typename?: 'GraphQLPage_TeamEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type TradeShowAssignTerminalMutationVariables = Exact<{
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
}>;


export type TradeShowAssignTerminalMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowAssignTerminal?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1'>
  )> }
);

export type TradeShowCreateShowMutationVariables = Exact<{
  name: Scalars['String'];
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  posTerminalsRequested?: Maybe<Scalars['Long']>;
}>;


export type TradeShowCreateShowMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowCreateShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderStartDate' | 'preOrderEndDate' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1' | 'url' | 'posTerminalsRequested'>
  )> }
);

export type TradeShowDeleteShowMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowDeleteShowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'tradeShowDeleteShow'>
);

export type TradeShowFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type TradeShowFilterQuery = (
  { __typename?: 'Query' }
  & { tradeShowFilter?: Maybe<(
    { __typename?: 'GraphQLPage_TradeShowEntity' }
    & Pick<GraphQlPage_TradeShowEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TradeShowEntity' }
      & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderEndDate' | 'preOrderStartDate' | 'cls' | 'name' | 'taxRate' | 'line1' | 'line2' | 'state'>
    )>>> }
  )> }
);

export type TradeShowInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowInfoQuery = (
  { __typename?: 'Query' }
  & { tradeShowInfo?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'cls' | 'name' | 'taxRate' | 'startDate' | 'endDate' | 'preOrderEndDate' | 'preOrderStartDate' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'url' | 'posTerminalsRequested'>
    & { posTerminals?: Maybe<Array<Maybe<(
      { __typename?: 'PosTerminalEntity' }
      & Pick<PosTerminalEntity, 'id' | 'cls' | 'assetTag'>
    )>>>, salesOrders?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'alternateOrderNumber' | 'billingAddressValidationSource' | 'billingCity' | 'billingCompany' | 'billingCountry' | 'billingFirstName' | 'billingLastName' | 'billingLine1' | 'billingLine2' | 'billingPostalCode' | 'billingState' | 'cls' | 'couponCode' | 'createdAt' | 'discountTotal' | 'email' | 'fulfillmentChannelType' | 'grandTotal' | 'handlingTotal' | 'hold' | 'ipAddress' | 'orderNumber' | 'phone' | 'placedTime' | 'salesOrderType' | 'shippingAddressValidationSource' | 'shippingCity' | 'shippingCompany' | 'shippingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingLine1' | 'shippingLine2' | 'shippingMethod' | 'shippingPostalCode' | 'shippingResidential' | 'shippingState' | 'shippingTotal' | 'status' | 'subTotal' | 'taxCode' | 'taxTotal' | 'updatedAt'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderCommentsEntity' }
        & Pick<SalesOrderCommentsEntity, 'id'>
      )>>>, payments?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'id'>
      )>>>, shipments?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentEntity' }
        & Pick<ShipmentEntity, 'id' | 'actualHeight' | 'actualLength' | 'actualShippingCost' | 'actualWeight' | 'actualWidth' | 'addressValidationSource' | 'carrier' | 'carrierPreference' | 'city' | 'cls' | 'company' | 'contentsMd5' | 'country' | 'createdAt' | 'downloadStart' | 'email' | 'estimatedDeliveryDate' | 'estimatedHeight' | 'estimatedLength' | 'estimatedShipDate' | 'estimatedWeight' | 'estimatedWidth' | 'finalApproval' | 'firstName' | 'lastName' | 'line1' | 'line2' | 'options' | 'packaging' | 'placedAt' | 'postalCode' | 'printedEnd' | 'printedStart' | 'reprint' | 'reseller' | 'residential' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'shippedBy' | 'shippingNeeds' | 'state' | 'trackingNo' | 'trackingNos' | 'updatedAt' | 'zplContent' | 'zplContents'>
        & { shipmentItems?: Maybe<Array<Maybe<(
          { __typename?: 'ShipmentItemEntity' }
          & Pick<ShipmentItemEntity, 'id'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type TradeShowListInPreOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListInPreOrderQuery = (
  { __typename?: 'Query' }
  & { tradeShowListInPreOrder?: Maybe<Array<Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'url' | 'city' | 'state' | 'startDate' | 'endDate'>
  )>>> }
);

export type TradeShowListTerminalsMutationVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListTerminalsMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowListTerminals?: Maybe<Array<Maybe<(
    { __typename?: 'PosTerminalEntity' }
    & Pick<PosTerminalEntity, 'urn' | 'cls' | 'assetTag' | 'id'>
  )>>> }
);

export type TradeShowListUpcomingQueryVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListUpcomingQuery = (
  { __typename?: 'Query' }
  & { tradeShowListUpcoming?: Maybe<Array<Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'city' | 'state' | 'startDate' | 'endDate' | 'preOrderEndDate' | 'preOrderStartDate' | 'url'>
  )>>> }
);

export type TradeShowReportMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowReportMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowReport?: Maybe<(
    { __typename?: 'ShowReport' }
    & Pick<ShowReport, 'showName' | 'startDate' | 'endDate' | 'city' | 'state' | 'subTotal' | 'discountTotal' | 'handlingTotal' | 'shippingTotal' | 'taxTotal' | 'grandTotal'>
    & { lineItems?: Maybe<Array<Maybe<(
      { __typename?: 'LineItem' }
      & Pick<LineItem, 'orderNumber' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    )>>> }
  )> }
);

export type TradeShowSendReportMutationVariables = Exact<{
  id: Scalars['UUID'];
  emails: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type TradeShowSendReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'tradeShowSendReport'>
);

export type TradeShowUnassignTerminalMutationVariables = Exact<{
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
}>;


export type TradeShowUnassignTerminalMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowUnassignTerminal?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1'>
  )> }
);

export type TradeShowUpdateShowMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  posTerminalsRequested?: Maybe<Scalars['Long']>;
}>;


export type TradeShowUpdateShowMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowUpdateShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderStartDate' | 'preOrderEndDate' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1' | 'url' | 'posTerminalsRequested'>
  )> }
);

export type UserChangePasswordByHashMutationVariables = Exact<{
  hash: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserChangePasswordByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userChangePasswordByHash'>
);

export type UserConfirmEmailByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type UserConfirmEmailByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userConfirmEmail'>
);

export type UserFilterQueryVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type UserFilterQuery = (
  { __typename?: 'Query' }
  & { userFilter?: Maybe<(
    { __typename?: 'GraphQLPage_UserEntity' }
    & Pick<GraphQlPage_UserEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'id' | 'firstName' | 'lastName' | 'email' | 'admin' | 'userType' | 'taxExempt' | 'netTerms'>
    )>>> }
  )> }
);

export type UserInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSelfQuery = (
  { __typename?: 'Query' }
  & { userSelf?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName' | 'admin' | 'roles' | 'emailConfirmed' | 'taxExempt' | 'netTerms' | 'userType'>
  )> }
);

export type UserSendPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserSendPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userSendPasswordReset'>
);

export type UserUpdateAdminMutationVariables = Exact<{
  id: Scalars['UUID'];
  admin: Scalars['Boolean'];
}>;


export type UserUpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateAdmin?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type UserUpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateEmail?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type UserUpdateNameMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateName?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateNetTermsMutationVariables = Exact<{
  id: Scalars['UUID'];
  netTerms: Scalars['Int'];
}>;


export type UserUpdateNetTermsMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateNetTerms?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdatePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserUpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { userUpdatePassword?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName' | 'admin' | 'emailConfirmed'>
  )> }
);

export type UserUpdateTaxExemptMutationVariables = Exact<{
  taxExempt: Scalars['Boolean'];
  id: Scalars['UUID'];
}>;


export type UserUpdateTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateTaxExempt?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: UserType;
}>;


export type UserUpdateTypeMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateType?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type WalmartSalesOrderRefreshMutationVariables = Exact<{
  purchaseOrderId: Scalars['String'];
}>;


export type WalmartSalesOrderRefreshMutation = (
  { __typename?: 'Mutation' }
  & { walmartSalesOrderRefresh?: Maybe<(
    { __typename?: 'WalmartSalesOrderEntity' }
    & Pick<WalmartSalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
    & { salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'unitAmount'>
      & { salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
      )>>>, kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'sku' | 'title'>
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDate' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
    )>>>, comments?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderCommentsEntity' }
      & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
    )>>> }
  )> }
);

export type WarehouseFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  warehouseType?: Maybe<WarehouseType>;
  enabled?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type WarehouseFilterQuery = (
  { __typename?: 'Query' }
  & { warehouseFilter?: Maybe<(
    { __typename?: 'GraphQLPage_WarehouseEntity' }
    & Pick<GraphQlPage_WarehouseEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'cls' | 'warehouseType' | 'name' | 'slug' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude' | 'enabled'>
    )>>> }
  )> }
);

export type WarehouseListQueryVariables = Exact<{ [key: string]: never; }>;


export type WarehouseListQuery = (
  { __typename?: 'Query' }
  & { warehouseList?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'cls' | 'warehouseType' | 'name' | 'slug' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude' | 'enabled'>
  )>>> }
);

export type ZoneListQueryVariables = Exact<{ [key: string]: never; }>;


export type ZoneListQuery = (
  { __typename?: 'Query' }
  & { zoneList?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude'>
    )> }
  )>>> }
);

export const Cart_CheckoutFragmentDoc = gql`
    fragment CART_CHECKOUT on CartEntity {
  id
  email
  phone
  billingAddressValidationSource
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingAddressValidationSource
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingResidential
  couponCode
  taxExempt
  subtotal
  cartItemsCount
  cartBatchItemsCount
  tradeShow {
    id
    name
    url
    line1
    line2
    city
    state
    postalCode
    country
    startDate
    endDate
  }
  pickupWarehouse {
    id
    line1
    line2
    city
    state
    postalCode
    country
  }
  cartItemGroups {
    id
    quantity
    kitProduct {
      id
      cls
      slug
      sku
      title
      price
      quantityAvailableForSale
      medias {
        id
        mediaType
        url
      }
    }
    price
    salePrice
  }
  cartItems {
    id
    cartItemGroup {
      id
    }
    simpleProduct {
      id
      cls
      slug
      sku
      title
      price
      quantityAvailableForSale
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
    }
    quantity
    price
    salePrice
    carrierPreference
    deliveryDatePreference
    serviceTypePreference
  }
  cartCustomItems {
    id
    quantity
    productPrice
    title
  }
}
    `;
export const Admin_Simple_Product_EntityFragmentDoc = gql`
    fragment ADMIN_SIMPLE_PRODUCT_ENTITY on SimpleProductEntity {
  id
  cls
  slug
  sku
  magentoId
  upc
  title
  active
  visible
  featured
  quantityAvailableForSale
  caseQuantity
  cost
  height
  length
  price
  mapPrice
  specialPrice
  wholesalePrice
  weight
  width
  metaTitle
  metaDescription
  metaKeywords
  minimumOrderQuantity
  averageRating
  countRating
  shippingNeeds
  shipsAlone
  requiresPrep
  discontinuationReason
  vendorRef
  publishedRevision {
    htmlContent
  }
  shippingRestrictions
  warehouses {
    id
    name
  }
  zones {
    id
    name
    warehouse {
      id
      name
    }
  }
  bins {
    id
    binId
    zone {
      id
      name
      warehouse {
        id
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  brand {
    id
    slug
    name
  }
  supplier {
    id
    slug
    name
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  inventoryQuantityCaches {
    id
    warehouse {
      id
      name
    }
    quantityAvailableForSale
    quantityOnShelf
  }
  shippingRuleSet {
    id
    slug
    name
    liveArrivalGuarantees {
      minTemp
      maxTemp
      transitTimeType
    }
    destinationRestriction {
      domesticOnly
      prohibitedStates
    }
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
  amazonProfile {
    id
    asin
    fbaSellerSku
    sellerSku
  }
  petcoDropshipProfile {
    id
    description
    sku
    surcharge
  }
  petcoStoreDirectProfile {
    id
    description
    sku
  }
  chewyProfile {
    id
    description
    sku
    cost
  }
  walmartProfile {
    id
    sellerSku
    wfsSellerSku
  }
  resellerProfiles {
    id
    resellerSku
    price
    retail
    reseller {
      id
      name
    }
  }
  dropshipperProfiles {
    id
    dropshipperSku
    cost
    enabled
    dropshipper {
      id
      name
    }
  }
  crossSells {
    id
    title
    sku
    price
    quantityAvailableForSale
    cls
    medias {
      id
      mediaType
      url
    }
  }
  priceTiers {
    id
    price
    quantity
    userType
  }
  componentItems {
    id
    quantity
    position
    component {
      id
      cost
      title
      sku
      medias {
        url
        mediaType
      }
    }
  }
}
    `;
export const Admin_Kit_Product_EntityFragmentDoc = gql`
    fragment ADMIN_KIT_PRODUCT_ENTITY on KitProductEntity {
  id
  cls
  slug
  sku
  magentoId
  upc
  title
  active
  quantityAvailableForSale
  cost
  price
  specialPrice
  wholesalePrice
  featured
  visible
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  kitItems {
    id
    quantity
    position
    simpleProduct {
      id
      sku
      title
      price
      medias {
        id
        mediaType
        url
      }
    }
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  publishedRevision {
    htmlContent
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
  amazonProfile {
    id
    asin
    fbaSellerSku
    sellerSku
  }
  petcoDropshipProfile {
    id
    description
    sku
    surcharge
  }
  petcoStoreDirectProfile {
    id
    description
    sku
  }
  chewyProfile {
    id
    description
    sku
    cost
  }
  walmartProfile {
    id
    sellerSku
    wfsSellerSku
  }
  resellerProfiles {
    id
    resellerSku
    price
    retail
    reseller {
      id
      name
    }
  }
  crossSells {
    id
    title
    sku
    price
    quantityAvailableForSale
    cls
    medias {
      id
      mediaType
      url
    }
  }
  priceTiers {
    id
    price
    quantity
    userType
  }
}
    `;
export const Admin_Configurable_Product_EntityFragmentDoc = gql`
    fragment ADMIN_CONFIGURABLE_PRODUCT_ENTITY on ConfigurableProductEntity {
  id
  cls
  slug
  sku
  magentoId
  upc
  title
  active
  quantityAvailableForSale
  featured
  visible
  configurableItems {
    simpleProduct {
      id
      sku
      title
      price
      quantityAvailableForSale
      medias {
        id
        mediaType
        url
      }
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  publishedRevision {
    htmlContent
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
}
    `;
export const Customer_Sales_OrderFragmentDoc = gql`
    fragment CUSTOMER_SALES_ORDER on SalesOrderEntity {
  id
  orderNumber
  placedTime
  email
  phone
  status
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  subTotal
  discountTotal
  taxTotal
  shippingTotal
  handlingTotal
  grandTotal
  payments {
    id
    amount
    description
    placedAt
  }
  refunds {
    id
    grandTotal
    placedTime
    refundNumber
    refundItems {
      id
      quantity
      salesOrderItem {
        id
        name
      }
    }
  }
  salesOrderItems {
    id
    name
    quantity
    unitAmount
    lineAmount
    simpleProduct {
      slug
      title
      medias {
        url
      }
    }
    salesOrderItemGroup {
      id
    }
  }
  salesOrderItemGroups {
    name
    quantity
    unitAmount
    lineAmount
    salesOrderItems {
      name
      quantity
    }
    kitProduct {
      slug
      title
      medias {
        url
      }
    }
  }
  shipments {
    id
    shipmentNumber
    shipmentStatus
    carrier
    service
    trackingNos
    trackingUrls
    shipmentItems {
      quantity
      salesOrderItem {
        name
      }
    }
  }
}
    `;
export const Supplier_EntityFragmentDoc = gql`
    fragment SUPPLIER_ENTITY on SupplierEntity {
  id
  firstName
  lastName
  company
  name
  phone
  email
  line1
  line2
  city
  state
  postalCode
  country
  addressValidationSource
  createdAt
  leadTime
  orderCycle
  residential
  slug
  simpleProducts {
    id
    sku
    title
    medias {
      id
      url
    }
    active
    price
    quantityAvailableForSale
  }
}
    `;
export const Brand_EntityFragmentDoc = gql`
    fragment BRAND_ENTITY on BrandEntity {
  id
  name
  products {
    id
    cls
    sku
    title
    medias {
      id
      url
    }
    active
    price
    quantityAvailableForSale
  }
  slug
}
    `;
export const Admin_Sales_OrderFragmentDoc = gql`
    fragment ADMIN_SALES_ORDER on SalesOrderEntity {
  id
  cls
  orderNumber
  alternateOrderNumber
  placedTime
  email
  phone
  ipAddress
  status
  hold
  couponCode
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingAddressValidationSource
  salesOrderType
  subTotal
  discountTotal
  taxCode
  taxTotal
  shippingTotal
  handlingTotal
  grandTotal
  user {
    id
    email
    firstName
    lastName
  }
  salesOrderItemGroups {
    id
    lineAmount
    quantity
    sku
    name
    unitAmount
    salesOrderItems {
      sku
      name
      quantity
    }
    kitProduct {
      sku
      title
    }
  }
  salesOrderItems {
    id
    sku
    name
    quantity
    unitAmount
    discountAmount
    lineAmount
    simpleProduct {
      id
      quantityAvailableForSale
    }
    salesOrderItemGroup {
      id
    }
  }
  shipments {
    id
    shipmentNumber
    shipmentStatus
    estimatedShipDate
    estimatedDeliveryDate
    estimatedWeight
    estimatedLength
    estimatedWidth
    estimatedHeight
    actualWeight
    actualLength
    actualWidth
    actualHeight
    placedAt
    printedStart
    printedEnd
    shippedAt
    carrier
    service
    departingWarehouse {
      id
      name
    }
    originWarehouse {
      id
      name
    }
    shippingNeeds
    shipmentItems {
      id
      quantity
      salesOrderItem {
        id
        sku
        name
      }
    }
  }
  refunds {
    id
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
  }
  reships {
    id
    status
    orderNumber
  }
  payments {
    id
    transactionId
    paymentStatus
    placedAt
    amount
    description
  }
  comments {
    id
    time
    name
    comment
  }
}
    `;
export const Customer_DiscountFragmentDoc = gql`
    fragment CUSTOMER_DISCOUNT on DiscountEntity {
  id
  discountAmount
  discountType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  htmlContent
  teaser
}
    `;
export const Admin_DiscountFragmentDoc = gql`
    fragment ADMIN_DISCOUNT on DiscountEntity {
  id
  active
  discountAmount
  discountType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  eligibleProducts {
    id
    price
    quantityAvailableForSale
    sku
    title
    medias {
      url
    }
  }
  implicitProducts {
    id
    sku
    title
  }
  explicitProducts {
    id
    sku
    title
  }
  categories {
    id
    slug
    path
    name
  }
  departments {
    id
    name
  }
}
    `;
export const Customer_PromotionFragmentDoc = gql`
    fragment CUSTOMER_PROMOTION on PromotionEntity {
  id
  discountAmount
  promotionType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  htmlContent
  teaser
}
    `;
export const Admin_PromotionFragmentDoc = gql`
    fragment ADMIN_PROMOTION on PromotionEntity {
  id
  active
  discountAmount
  promotionType
  eligibleCartValue
  eligibleCouponCode
  eligibleItemCount
  eligibleItemValue
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  implicitProducts {
    id
    sku
    title
  }
  explicitProducts {
    id
    sku
    title
  }
  categories {
    id
    slug
    path
    name
  }
  departments {
    id
    name
  }
}
    `;
export const Admin_Shipment_EntityFragmentDoc = gql`
    fragment ADMIN_SHIPMENT_ENTITY on ShipmentEntity {
  id
  cls
  shipmentNumber
  shipmentStatus
  shippingNeeds
  actualShippingCost
  estimatedShippingCost
  carrier
  carrierPreference
  service
  estimatedWeight
  actualWeight
  estimatedLength
  estimatedWidth
  estimatedHeight
  actualLength
  actualWidth
  actualHeight
  placedAt
  estimatedShipDate
  estimatedDeliveryDate
  shippedAt
  shippedBy
  cancelledAt
  cancelledBy
  cancellationReason
  trackingNos
  trackingUrls
  email
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressValidationSource
  downloadStart
  printedStart
  printedEnd
  reprint
  queue {
    id
    name
  }
  originWarehouse {
    id
    name
  }
  departingWarehouse {
    id
    name
  }
  salesOrder {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    status
  }
  shipmentItems {
    id
    cls
    quantity
    salesOrderItem {
      id
      cls
      sku
      name
    }
  }
  shipmentAddons {
    id
    quantity
    sku
    name
  }
}
    `;
export const Customer_ShipmentFragmentDoc = gql`
    fragment CUSTOMER_SHIPMENT on ShipmentEntity {
  id
  carrier
  email
  scheduledAt
  estimatedDeliveryDate
  service
  shipmentNumber
  shipmentStatus
  shippedAt
  scheduledAt
  scheduledBy
  finalApprovalBy
  trackingNos
  trackingUrls
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressValidationSource
  shippingNeeds
  salesOrder {
    id
    orderNumber
    placedTime
  }
  shipmentItems {
    id
    quantity
    salesOrderItem {
      id
      name
    }
    simpleProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
}
    `;
export const Customer_Shipment_PublicFragmentDoc = gql`
    fragment CUSTOMER_SHIPMENT_PUBLIC on ShipmentEntity {
  id
  carrier
  email
  scheduledAt
  estimatedDeliveryDate
  service
  shipmentNumber
  shipmentStatus
  shippedAt
  scheduledAt
  scheduledBy
  trackingNos
  trackingUrls
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressValidationSource
  shippingNeeds
  salesOrder {
    id
    orderNumber
    placedTime
  }
  shipmentItems {
    id
    quantity
    salesOrderItem {
      id
      name
    }
    simpleProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
}
    `;
export const Admin_User_EntityFragmentDoc = gql`
    fragment ADMIN_USER_ENTITY on UserEntity {
  id
  cls
  magentoId
  email
  firstName
  lastName
  admin
  roles
  emailConfirmed
  userType
  taxExempt
  netTerms
  createdAt
  updatedAt
  addresses {
    id
    firstName
    lastName
    cls
    company
    line1
    line2
    city
    state
    postalCode
    country
    createdAt
    latitude
    longitude
    magentoId
    residential
    addressValidationSource
    updatedAt
  }
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment SUBSCRIPTION on SubscriptionEntity {
  id
  phone
  deliveryDay
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  billingAddressValidationSource
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingResidential
  shippingAddressValidationSource
  taxExempt
  paymentMethod {
    token
    type
    defaultMethod
    comment
    image
  }
  subscriptionItems {
    id
    frequency
    quantity
    lastDeliveryDate
    nextDeliveryDate
    simpleProduct {
      id
      title
      price
      salePrice
      slug
      medias {
        id
        mediaType
        url
      }
    }
  }
}
    `;
export const Admin_SubscriptionFragmentDoc = gql`
    fragment ADMIN_SUBSCRIPTION on SubscriptionEntity {
  id
  deliveryDay
  phone
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  billingAddressValidationSource
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingResidential
  shippingAddressValidationSource
  carrierPreference
  serviceType
  paymentMethod {
    token
    type
    defaultMethod
    comment
    image
  }
  subscriptionItems {
    id
    frequency
    quantity
    lastDeliveryDate
    nextDeliveryDate
    simpleProduct {
      id
      price
      sku
      title
      medias {
        id
        mediaType
        url
      }
    }
  }
}
    `;
export const Admin_Partner_EntityFragmentDoc = gql`
    fragment ADMIN_PARTNER_ENTITY on PartnerEntity {
  id
  affiliate
  affiliateAttributionPeriod
  affiliateBiography
  affiliateCommissionRate
  dropshipper
  email
  name
  reseller
  resellerShippingRateMode
  resellerTaxMode
  slug
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  residential
  addressValidationSource
  affiliateProductLists {
    id
    name
    sequence
  }
}
    `;
export const Admin_Affiliate_Product_ListFragmentDoc = gql`
    fragment ADMIN_AFFILIATE_PRODUCT_LIST on AffiliateProductListEntity {
  id
  htmlContent
  name
  sequence
  slug
  affiliateProductListItems {
    id
    position
    product {
      id
      cls
      price
      title
      sku
      quantityAvailableForSale
      medias {
        url
      }
    }
  }
}
    `;
export const Shipment_EntityFragmentDoc = gql`
    fragment SHIPMENT_ENTITY on ShipmentEntity {
  id
  placedAt
  cls
  email
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  addressValidationSource
  shipmentNumber
  shipmentStatus
  shippingNeeds
  carrier
  service
  estimatedWeight
  actualWeight
  estimatedLength
  estimatedWidth
  estimatedHeight
  actualLength
  actualWidth
  actualHeight
  estimatedShipDate
  estimatedDeliveryDate
  shippedAt
  trackingNo
  schedulingStatus
  scheduledAt
  scheduledBy
  finalApproval
  finalApprovalBy
  originWarehouse {
    id
    name
  }
  departingWarehouse {
    id
    name
  }
  salesOrder {
    cls
    orderNumber
    alternateOrderNumber
    salesOrderType
  }
  shipmentItems {
    id
    cls
    quantity
    salesOrderItem {
      id
      cls
      sku
      name
    }
  }
}
    `;
export const Admin_Blog_EntityFragmentDoc = gql`
    fragment ADMIN_BLOG_ENTITY on BlogEntity {
  id
  active
  author
  createdAt
  slug
  title
  publishedRevision {
    htmlContent
  }
}
    `;
export const Admin_Care_Sheet_EntityFragmentDoc = gql`
    fragment ADMIN_CARE_SHEET_ENTITY on CareSheetEntity {
  id
  active
  author
  createdAt
  slug
  title
  publishedRevision {
    htmlContent
  }
}
    `;
export const AddressCreateDocument = gql`
    mutation addressCreate($firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  addressCreate(
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressCreateGQL extends Apollo.Mutation<AddressCreateMutation, AddressCreateMutationVariables> {
    document = AddressCreateDocument;
    
  }
export const AddressDeleteDocument = gql`
    mutation addressDelete($id: UUID!) {
  addressDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressDeleteGQL extends Apollo.Mutation<AddressDeleteMutation, AddressDeleteMutationVariables> {
    document = AddressDeleteDocument;
    
  }
export const AddressListDocument = gql`
    query addressList {
  addressList {
    id
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressValidationSource
    residential
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressListGQL extends Apollo.Query<AddressListQuery, AddressListQueryVariables> {
    document = AddressListDocument;
    
  }
export const AddressUpdateDocument = gql`
    mutation addressUpdate($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  addressUpdate(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressUpdateGQL extends Apollo.Mutation<AddressUpdateMutation, AddressUpdateMutationVariables> {
    document = AddressUpdateDocument;
    
  }
export const AddressVerifyDocument = gql`
    mutation addressVerify($id: UUID!, $commit: Boolean!) {
  addressVerify(id: $id, commit: $commit) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressValidationSource
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressVerifyGQL extends Apollo.Mutation<AddressVerifyMutation, AddressVerifyMutationVariables> {
    document = AddressVerifyDocument;
    
  }
export const AmazonSalesOrderRefreshDocument = gql`
    mutation amazonSalesOrderRefresh($amazonId: String!) {
  amazonSalesOrderRefresh(amazonId: $amazonId) {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    email
    phone
    ipAddress
    status
    hold
    couponCode
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingAddressValidationSource
    salesOrderType
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    salesOrderItemGroups {
      id
      lineAmount
      quantity
      unitAmount
      salesOrderItems {
        sku
        name
        quantity
      }
      kitProduct {
        sku
        title
      }
    }
    salesOrderItems {
      id
      sku
      name
      quantity
      unitAmount
      discountAmount
      lineAmount
      salesOrderItemGroup {
        id
      }
    }
    shipments {
      id
      shipmentNumber
      shipmentStatus
      estimatedShipDate
      estimatedDeliveryDate
      estimatedWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualWeight
      actualLength
      actualWidth
      actualHeight
      placedAt
      printedStart
      printedEnd
      shippedAt
      carrier
      service
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shippingNeeds
      shipmentItems {
        id
        quantity
        salesOrderItem {
          id
          sku
          name
        }
      }
    }
    payments {
      id
      transactionId
      paymentStatus
      placedAt
      amount
      description
    }
    comments {
      id
      time
      name
      comment
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AmazonSalesOrderRefreshGQL extends Apollo.Mutation<AmazonSalesOrderRefreshMutation, AmazonSalesOrderRefreshMutationVariables> {
    document = AmazonSalesOrderRefreshDocument;
    
  }
export const AnimalListDocument = gql`
    query animalList {
  animalList(page: {page: null, pageSize: null}) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AnimalListGQL extends Apollo.Query<AnimalListQuery, AnimalListQueryVariables> {
    document = AnimalListDocument;
    
  }
export const AssistedCartAddCustomProductDocument = gql`
    mutation assistedCartAddCustomProduct($cartId: UUID!, $unitPrice: BigDecimal!, $quantity: Long!, $weight: BigDecimal!, $title: String!) {
  assistedCartAddCustomProduct(
    cartId: $cartId
    unitPrice: $unitPrice
    quantity: $quantity
    weight: $weight
    title: $title
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddCustomProductGQL extends Apollo.Mutation<AssistedCartAddCustomProductMutation, AssistedCartAddCustomProductMutationVariables> {
    document = AssistedCartAddCustomProductDocument;
    
  }
export const AssistedCartAddKitProductDocument = gql`
    mutation assistedCartAddKitProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartAddKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddKitProductGQL extends Apollo.Mutation<AssistedCartAddKitProductMutation, AssistedCartAddKitProductMutationVariables> {
    document = AssistedCartAddKitProductDocument;
    
  }
export const AssistedCartAddProductsDocument = gql`
    mutation assistedCartAddProducts($cartId: UUID!, $simpleProductIds: [UUID]!, $kitProductIds: [UUID]!) {
  assistedCartAddProducts(
    cartId: $cartId
    simpleProductIds: $simpleProductIds
    kitProductIds: $kitProductIds
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddProductsGQL extends Apollo.Mutation<AssistedCartAddProductsMutation, AssistedCartAddProductsMutationVariables> {
    document = AssistedCartAddProductsDocument;
    
  }
export const AssistedCartAddSimpleProductDocument = gql`
    mutation assistedCartAddSimpleProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartAddSimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddSimpleProductGQL extends Apollo.Mutation<AssistedCartAddSimpleProductMutation, AssistedCartAddSimpleProductMutationVariables> {
    document = AssistedCartAddSimpleProductDocument;
    
  }
export const AssistedCartChangeQuantityKitProductDocument = gql`
    mutation assistedCartChangeQuantityKitProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartChangeQuantityKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartChangeQuantityKitProductGQL extends Apollo.Mutation<AssistedCartChangeQuantityKitProductMutation, AssistedCartChangeQuantityKitProductMutationVariables> {
    document = AssistedCartChangeQuantityKitProductDocument;
    
  }
export const AssistedCartChangeQuantitySimpleProductDocument = gql`
    mutation assistedCartChangeQuantitySimpleProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartChangeQuantitySimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartChangeQuantitySimpleProductGQL extends Apollo.Mutation<AssistedCartChangeQuantitySimpleProductMutation, AssistedCartChangeQuantitySimpleProductMutationVariables> {
    document = AssistedCartChangeQuantitySimpleProductDocument;
    
  }
export const AssistedCartCheckoutDocument = gql`
    mutation assistedCartCheckout($cartId: UUID!, $method: String!, $deviceData: String, $nonce: String, $useRewardPoints: Boolean, $customDiscount: BigDecimal, $skipAvs: Boolean) {
  assistedCartCheckout(
    cartId: $cartId
    method: $method
    deviceData: $deviceData
    nonce: $nonce
    useRewardPoints: $useRewardPoints
    customDiscount: $customDiscount
    skipAvs: $skipAvs
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartCheckoutGQL extends Apollo.Mutation<AssistedCartCheckoutMutation, AssistedCartCheckoutMutationVariables> {
    document = AssistedCartCheckoutDocument;
    
  }
export const AssistedCartClearBillingAddressDocument = gql`
    mutation assistedCartClearBillingAddress($cartId: UUID!) {
  assistedCartClearBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearBillingAddressGQL extends Apollo.Mutation<AssistedCartClearBillingAddressMutation, AssistedCartClearBillingAddressMutationVariables> {
    document = AssistedCartClearBillingAddressDocument;
    
  }
export const AssistedCartClearCarrierPreferenceDocument = gql`
    mutation assistedCartClearCarrierPreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearCarrierPreferenceGQL extends Apollo.Mutation<AssistedCartClearCarrierPreferenceMutation, AssistedCartClearCarrierPreferenceMutationVariables> {
    document = AssistedCartClearCarrierPreferenceDocument;
    
  }
export const AssistedCartClearCouponCodeDocument = gql`
    mutation assistedCartClearCouponCode($cartId: UUID!) {
  assistedCartClearCouponCode(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearCouponCodeGQL extends Apollo.Mutation<AssistedCartClearCouponCodeMutation, AssistedCartClearCouponCodeMutationVariables> {
    document = AssistedCartClearCouponCodeDocument;
    
  }
export const AssistedCartClearDeliveryDatePreferenceDocument = gql`
    mutation assistedCartClearDeliveryDatePreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearDeliveryDatePreferenceGQL extends Apollo.Mutation<AssistedCartClearDeliveryDatePreferenceMutation, AssistedCartClearDeliveryDatePreferenceMutationVariables> {
    document = AssistedCartClearDeliveryDatePreferenceDocument;
    
  }
export const AssistedCartClearServiceTypePreferenceDocument = gql`
    mutation assistedCartClearServiceTypePreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearServiceTypePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearServiceTypePreferenceGQL extends Apollo.Mutation<AssistedCartClearServiceTypePreferenceMutation, AssistedCartClearServiceTypePreferenceMutationVariables> {
    document = AssistedCartClearServiceTypePreferenceDocument;
    
  }
export const AssistedCartClearShippingAddressDocument = gql`
    mutation assistedCartClearShippingAddress($cartId: UUID!) {
  assistedCartClearShippingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearShippingAddressGQL extends Apollo.Mutation<AssistedCartClearShippingAddressMutation, AssistedCartClearShippingAddressMutationVariables> {
    document = AssistedCartClearShippingAddressDocument;
    
  }
export const AssistedCartClearTaxExemptDocument = gql`
    mutation assistedCartClearTaxExempt($cartId: UUID!) {
  assistedCartClearTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearTaxExemptGQL extends Apollo.Mutation<AssistedCartClearTaxExemptMutation, AssistedCartClearTaxExemptMutationVariables> {
    document = AssistedCartClearTaxExemptDocument;
    
  }
export const AssistedCartGetEarliestDeliveryDateDocument = gql`
    mutation assistedCartGetEarliestDeliveryDate($cartId: UUID!, $simpleProductId: UUID!, $quantity: Long!) {
  assistedCartGetEarliestDeliveryDate(
    cartId: $cartId
    simpleProductId: $simpleProductId
    quantity: $quantity
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartGetEarliestDeliveryDateGQL extends Apollo.Mutation<AssistedCartGetEarliestDeliveryDateMutation, AssistedCartGetEarliestDeliveryDateMutationVariables> {
    document = AssistedCartGetEarliestDeliveryDateDocument;
    
  }
export const AssistedCartInfoDocument = gql`
    mutation assistedCartInfo($cartId: UUID) {
  assistedCartInfo(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartInfoGQL extends Apollo.Mutation<AssistedCartInfoMutation, AssistedCartInfoMutationVariables> {
    document = AssistedCartInfoDocument;
    
  }
export const AssistedCartLookupDocument = gql`
    mutation assistedCartLookup($userId: UUID) {
  assistedCartLookup(lookup: $userId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartLookupGQL extends Apollo.Mutation<AssistedCartLookupMutation, AssistedCartLookupMutationVariables> {
    document = AssistedCartLookupDocument;
    
  }
export const AssistedCartRemoveCustomProductDocument = gql`
    mutation assistedCartRemoveCustomProduct($cartId: UUID!, $cartCustomItemId: UUID!) {
  assistedCartRemoveCustomProduct(
    cartId: $cartId
    cartCustomItemId: $cartCustomItemId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartRemoveCustomProductGQL extends Apollo.Mutation<AssistedCartRemoveCustomProductMutation, AssistedCartRemoveCustomProductMutationVariables> {
    document = AssistedCartRemoveCustomProductDocument;
    
  }
export const AssistedCartSetBillingAddressDocument = gql`
    mutation assistedCartSetBillingAddress($cartId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedCartSetBillingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetBillingAddressGQL extends Apollo.Mutation<AssistedCartSetBillingAddressMutation, AssistedCartSetBillingAddressMutationVariables> {
    document = AssistedCartSetBillingAddressDocument;
    
  }
export const AssistedCartSetBillingAddressByIdDocument = gql`
    mutation assistedCartSetBillingAddressById($cartId: UUID!, $addressId: UUID!) {
  assistedCartSetBillingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetBillingAddressByIdGQL extends Apollo.Mutation<AssistedCartSetBillingAddressByIdMutation, AssistedCartSetBillingAddressByIdMutationVariables> {
    document = AssistedCartSetBillingAddressByIdDocument;
    
  }
export const AssistedCartSetCarrierPreferenceDocument = gql`
    mutation assistedCartSetCarrierPreference($cartId: UUID!, $simpleProductId: UUID!, $carrier: Carrier!) {
  assistedCartSetCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    carrierPreference: $carrier
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetCarrierPreferenceGQL extends Apollo.Mutation<AssistedCartSetCarrierPreferenceMutation, AssistedCartSetCarrierPreferenceMutationVariables> {
    document = AssistedCartSetCarrierPreferenceDocument;
    
  }
export const AssistedCartSetContactDocument = gql`
    mutation assistedCartSetContact($cartId: UUID!, $email: String, $phone: String) {
  assistedCartSetContact(cartId: $cartId, email: $email, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetContactGQL extends Apollo.Mutation<AssistedCartSetContactMutation, AssistedCartSetContactMutationVariables> {
    document = AssistedCartSetContactDocument;
    
  }
export const AssistedCartSetCouponCodeDocument = gql`
    mutation assistedCartSetCouponCode($cartId: UUID!, $couponCode: String!) {
  assistedCartSetCouponCode(cartId: $cartId, couponCode: $couponCode) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetCouponCodeGQL extends Apollo.Mutation<AssistedCartSetCouponCodeMutation, AssistedCartSetCouponCodeMutationVariables> {
    document = AssistedCartSetCouponCodeDocument;
    
  }
export const AssistedCartSetDeliveryDatePreferenceDocument = gql`
    mutation assistedCartSetDeliveryDatePreference($cartId: UUID!, $simpleProductId: UUID!, $deliveryDatePreference: LocalDate!) {
  assistedCartSetDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    deliveryDatePreference: $deliveryDatePreference
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetDeliveryDatePreferenceGQL extends Apollo.Mutation<AssistedCartSetDeliveryDatePreferenceMutation, AssistedCartSetDeliveryDatePreferenceMutationVariables> {
    document = AssistedCartSetDeliveryDatePreferenceDocument;
    
  }
export const AssistedCartSetPickupWarehouseDocument = gql`
    mutation assistedCartSetPickupWarehouse($cartId: UUID!, $warehouseId: UUID!) {
  assistedCartSetPickupWarehouse(cartId: $cartId, warehouseId: $warehouseId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetPickupWarehouseGQL extends Apollo.Mutation<AssistedCartSetPickupWarehouseMutation, AssistedCartSetPickupWarehouseMutationVariables> {
    document = AssistedCartSetPickupWarehouseDocument;
    
  }
export const AssistedCartSetServiceTypePreferenceDocument = gql`
    mutation assistedCartSetServiceTypePreference($cartId: UUID!, $simpleProductId: UUID!, $serviceType: ServiceType!) {
  assistedCartSetServiceTypePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    serviceType: $serviceType
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetServiceTypePreferenceGQL extends Apollo.Mutation<AssistedCartSetServiceTypePreferenceMutation, AssistedCartSetServiceTypePreferenceMutationVariables> {
    document = AssistedCartSetServiceTypePreferenceDocument;
    
  }
export const AssistedCartSetShippingAddressDocument = gql`
    mutation assistedCartSetShippingAddress($cartId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean) {
  assistedCartSetShippingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingAddressGQL extends Apollo.Mutation<AssistedCartSetShippingAddressMutation, AssistedCartSetShippingAddressMutationVariables> {
    document = AssistedCartSetShippingAddressDocument;
    
  }
export const AssistedCartSetShippingAddressByIdDocument = gql`
    mutation assistedCartSetShippingAddressById($cartId: UUID!, $addressId: UUID!) {
  assistedCartSetShippingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingAddressByIdGQL extends Apollo.Mutation<AssistedCartSetShippingAddressByIdMutation, AssistedCartSetShippingAddressByIdMutationVariables> {
    document = AssistedCartSetShippingAddressByIdDocument;
    
  }
export const AssistedCartSetShippingZipDocument = gql`
    mutation assistedCartSetShippingZip($cartId: UUID!, $zip: String!) {
  assistedCartSetShippingZip(cartId: $cartId, zip: $zip) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingZipGQL extends Apollo.Mutation<AssistedCartSetShippingZipMutation, AssistedCartSetShippingZipMutationVariables> {
    document = AssistedCartSetShippingZipDocument;
    
  }
export const AssistedCartSetTaxExemptDocument = gql`
    mutation assistedCartSetTaxExempt($cartId: UUID!) {
  assistedCartSetTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetTaxExemptGQL extends Apollo.Mutation<AssistedCartSetTaxExemptMutation, AssistedCartSetTaxExemptMutationVariables> {
    document = AssistedCartSetTaxExemptDocument;
    
  }
export const AssistedCartSetTradeshowDocument = gql`
    mutation assistedCartSetTradeshow($cartId: UUID!, $tradeshowId: UUID!) {
  assistedCartSetTradeshow(cartId: $cartId, tradeshowId: $tradeshowId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetTradeshowGQL extends Apollo.Mutation<AssistedCartSetTradeshowMutation, AssistedCartSetTradeshowMutationVariables> {
    document = AssistedCartSetTradeshowDocument;
    
  }
export const AssistedCartSummarizeDocument = gql`
    mutation assistedCartSummarize($cartId: UUID!, $useRewardPoints: Boolean, $customDiscount: BigDecimal) {
  assistedCartSummarize(
    cartId: $cartId
    useRewardPoints: $useRewardPoints
    customDiscount: $customDiscount
  ) {
    id
    billingAddressValidationSource
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingAddressValidationSource
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingResidential
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    couponCode
    salesOrderDiscounts {
      id
      name
      amount
    }
    salesOrderItemGroups {
      id
      quantity
      unitAmount
      discountAmount
      kitProduct {
        id
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    salesOrderItems {
      id
      quantity
      unitAmount
      discountAmount
      salesOrderItemGroup {
        id
      }
      simpleProduct {
        id
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    shipments {
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shipmentItems {
        simpleProduct {
          id
        }
        salesOrderItem {
          name
          salesOrderItemGroup {
            id
            kitProduct {
              id
            }
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shippingNeeds
      carrier
      service
      estimatedLength
      estimatedWidth
      estimatedHeight
      estimatedWeight
      estimatedShipDate
      estimatedDeliveryDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSummarizeGQL extends Apollo.Mutation<AssistedCartSummarizeMutation, AssistedCartSummarizeMutationVariables> {
    document = AssistedCartSummarizeDocument;
    
  }
export const AssistedPaymentMethodGetClientTokenDocument = gql`
    mutation assistedPaymentMethodGetClientToken($userId: UUID!) {
  assistedPaymentMethodGetClientToken(userId: $userId) {
    clientToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentMethodGetClientTokenGQL extends Apollo.Mutation<AssistedPaymentMethodGetClientTokenMutation, AssistedPaymentMethodGetClientTokenMutationVariables> {
    document = AssistedPaymentMethodGetClientTokenDocument;
    
  }
export const AssistedPaymentMethodListDocument = gql`
    mutation assistedPaymentMethodList($id: UUID!) {
  assistedPaymentMethodList(id: $id) {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentMethodListGQL extends Apollo.Mutation<AssistedPaymentMethodListMutation, AssistedPaymentMethodListMutationVariables> {
    document = AssistedPaymentMethodListDocument;
    
  }
export const AssistedScheduledShipmentClearApprovalDocument = gql`
    mutation assistedScheduledShipmentClearApproval($shipmentId: UUID!) {
  assistedScheduledShipmentClearApproval(id: $shipmentId) {
    ...SHIPMENT_ENTITY
  }
}
    ${Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentClearApprovalGQL extends Apollo.Mutation<AssistedScheduledShipmentClearApprovalMutation, AssistedScheduledShipmentClearApprovalMutationVariables> {
    document = AssistedScheduledShipmentClearApprovalDocument;
    
  }
export const AssistedScheduledShipmentDashboardDocument = gql`
    mutation assistedScheduledShipmentDashboard($warehouse: String) {
  assistedScheduledShipmentDashboard(warehouse: $warehouse) {
    needsApprovalCount
    needsPickedCount
    needsShippedCount
    nextOpenDate
    scheduledInFutureCount
    timezone
    toRescheduleCount
    today
    unscheduledCount
    warehouse
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentDashboardGQL extends Apollo.Mutation<AssistedScheduledShipmentDashboardMutation, AssistedScheduledShipmentDashboardMutationVariables> {
    document = AssistedScheduledShipmentDashboardDocument;
    
  }
export const AssistedScheduledShipmentFilterDocument = gql`
    query assistedScheduledShipmentFilter($warehouse: String, $salesOrderCls: String, $schedulingStatus: SchedulingStatusType, $shipmentNumber: String, $firstName: String, $lastName: String, $email: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  assistedScheduledShipmentFilter(
    warehouse: $warehouse
    salesOrderCls: {value: $salesOrderCls}
    schedulingStatus: {value: $schedulingStatus}
    shipmentNumber: {pattern: $shipmentNumber}
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    page: $pageable
    sort: $sort
  ) {
    count
    page
    pageSize
    data {
      ...SHIPMENT_ENTITY
      finalApproval
    }
  }
}
    ${Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentFilterGQL extends Apollo.Query<AssistedScheduledShipmentFilterQuery, AssistedScheduledShipmentFilterQueryVariables> {
    document = AssistedScheduledShipmentFilterDocument;
    
  }
export const AssistedScheduledShipmentScheduleDocument = gql`
    mutation assistedScheduledShipmentSchedule($date: LocalDate!, $shipmentId: UUID!) {
  assistedScheduledShipmentSchedule(date: $date, id: $shipmentId) {
    ...SHIPMENT_ENTITY
  }
}
    ${Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentScheduleGQL extends Apollo.Mutation<AssistedScheduledShipmentScheduleMutation, AssistedScheduledShipmentScheduleMutationVariables> {
    document = AssistedScheduledShipmentScheduleDocument;
    
  }
export const AssistedScheduledShipmentSetApprovalDocument = gql`
    mutation assistedScheduledShipmentSetApproval($shipmentId: UUID!) {
  assistedScheduledShipmentSetApproval(id: $shipmentId) {
    ...SHIPMENT_ENTITY
  }
}
    ${Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentSetApprovalGQL extends Apollo.Mutation<AssistedScheduledShipmentSetApprovalMutation, AssistedScheduledShipmentSetApprovalMutationVariables> {
    document = AssistedScheduledShipmentSetApprovalDocument;
    
  }
export const AssistedSubscriptionClearCarrierPreferenceDocument = gql`
    mutation AssistedSubscriptionClearCarrierPreference($subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionClearCarrierPreference(
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionClearCarrierPreferenceGQL extends Apollo.Mutation<AssistedSubscriptionClearCarrierPreferenceMutation, AssistedSubscriptionClearCarrierPreferenceMutationVariables> {
    document = AssistedSubscriptionClearCarrierPreferenceDocument;
    
  }
export const AssistedSubscriptionClearServiceLevelDocument = gql`
    mutation AssistedSubscriptionClearServiceLevel($subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionClearServiceLevel(
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionClearServiceLevelGQL extends Apollo.Mutation<AssistedSubscriptionClearServiceLevelMutation, AssistedSubscriptionClearServiceLevelMutationVariables> {
    document = AssistedSubscriptionClearServiceLevelDocument;
    
  }
export const AssistedSubscriptionSetCarrierPreferenceDocument = gql`
    mutation AssistedSubscriptionSetCarrierPreference($carrierPreference: Carrier!, $subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionSetCarrierPreference(
    carrierPreference: $carrierPreference
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetCarrierPreferenceGQL extends Apollo.Mutation<AssistedSubscriptionSetCarrierPreferenceMutation, AssistedSubscriptionSetCarrierPreferenceMutationVariables> {
    document = AssistedSubscriptionSetCarrierPreferenceDocument;
    
  }
export const AssistedSubscriptionSetPaymentTokenDocument = gql`
    mutation assistedSubscriptionSetPaymentToken($subscriptionId: UUID!, $userId: UUID!, $paymentToken: String!) {
  assistedSubscriptionSetPaymentToken(
    subscriptionId: $subscriptionId
    userId: $userId
    paymentToken: $paymentToken
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetPaymentTokenGQL extends Apollo.Mutation<AssistedSubscriptionSetPaymentTokenMutation, AssistedSubscriptionSetPaymentTokenMutationVariables> {
    document = AssistedSubscriptionSetPaymentTokenDocument;
    
  }
export const AssistedSubscriptionSetServiceLevelDocument = gql`
    mutation AssistedSubscriptionSetServiceLevel($serviceLevel: ServiceType!, $subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionSetServiceLevel(
    serviceLevel: $serviceLevel
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetServiceLevelGQL extends Apollo.Mutation<AssistedSubscriptionSetServiceLevelMutation, AssistedSubscriptionSetServiceLevelMutationVariables> {
    document = AssistedSubscriptionSetServiceLevelDocument;
    
  }
export const AssistedSubscriptionAddItemDocument = gql`
    mutation assistedSubscriptionAddItem($subscriptionId: UUID!, $productId: UUID!, $quantity: Long!, $frequency: Long!, $userId: UUID!) {
  assistedSubscriptionAddItem(
    subscriptionId: $subscriptionId
    productId: $productId
    quantity: $quantity
    frequency: $frequency
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionAddItemGQL extends Apollo.Mutation<AssistedSubscriptionAddItemMutation, AssistedSubscriptionAddItemMutationVariables> {
    document = AssistedSubscriptionAddItemDocument;
    
  }
export const AssistedSubscriptionCreateDocument = gql`
    mutation assistedSubscriptionCreate($productId: UUID!, $userId: UUID!, $quantity: Long!, $frequency: Long!, $deliveryDay: DayOfWeek, $paymentToken: String, $phone: String!, $billingFirstName: String!, $billingLastName: String!, $billingCompany: String, $billingLine1: String!, $billingLine2: String, $billingCity: String!, $billingState: String!, $billingPostalCode: String!, $billingCountry: String!, $shippingFirstName: String!, $shippingLastName: String!, $shippingCompany: String, $shippingLine1: String!, $shippingLine2: String, $shippingCity: String!, $shippingState: String!, $shippingPostalCode: String!, $shippingCountry: String!, $shippingResidential: Boolean) {
  assistedSubscriptionCreate(
    productId: $productId
    userId: $userId
    quantity: $quantity
    frequency: $frequency
    deliveryDay: $deliveryDay
    paymentToken: $paymentToken
    phone: $phone
    billingFirstName: $billingFirstName
    billingLastName: $billingLastName
    billingCompany: $billingCompany
    billingLine1: $billingLine1
    billingLine2: $billingLine2
    billingCity: $billingCity
    billingState: $billingState
    billingPostalCode: $billingPostalCode
    billingCountry: $billingCountry
    shippingFirstName: $shippingFirstName
    shippingLastName: $shippingLastName
    shippingCompany: $shippingCompany
    shippingLine1: $shippingLine1
    shippingLine2: $shippingLine2
    shippingCity: $shippingCity
    shippingState: $shippingState
    shippingPostalCode: $shippingPostalCode
    shippingCountry: $shippingCountry
    shippingResidential: $shippingResidential
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionCreateGQL extends Apollo.Mutation<AssistedSubscriptionCreateMutation, AssistedSubscriptionCreateMutationVariables> {
    document = AssistedSubscriptionCreateDocument;
    
  }
export const AssistedSubscriptionInfoDocument = gql`
    query assistedSubscriptionInfo($id: UUID!, $userId: UUID!) {
  assistedSubscriptionInfo(id: $id, userId: $userId) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionInfoGQL extends Apollo.Query<AssistedSubscriptionInfoQuery, AssistedSubscriptionInfoQueryVariables> {
    document = AssistedSubscriptionInfoDocument;
    
  }
export const AssistedSubscriptionListDocument = gql`
    query assistedSubscriptionList($userId: UUID!) {
  assistedSubscriptionList(userId: $userId, page: {page: null, pageSize: null}) {
    data {
      id
      deliveryDay
      phone
      subscriptionItems {
        frequency
        quantity
        lastDeliveryDate
        nextDeliveryDate
        simpleProduct {
          title
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionListGQL extends Apollo.Query<AssistedSubscriptionListQuery, AssistedSubscriptionListQueryVariables> {
    document = AssistedSubscriptionListDocument;
    
  }
export const AssistedSubscriptionProcessDocument = gql`
    mutation assistedSubscriptionProcess($id: UUID!) {
  assistedSubscriptionProcess(id: $id) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionProcessGQL extends Apollo.Mutation<AssistedSubscriptionProcessMutation, AssistedSubscriptionProcessMutationVariables> {
    document = AssistedSubscriptionProcessDocument;
    
  }
export const AssistedSubscriptionRemoveItemDocument = gql`
    mutation assistedSubscriptionRemoveItem($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!) {
  assistedSubscriptionRemoveItem(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionRemoveItemGQL extends Apollo.Mutation<AssistedSubscriptionRemoveItemMutation, AssistedSubscriptionRemoveItemMutationVariables> {
    document = AssistedSubscriptionRemoveItemDocument;
    
  }
export const AssistedSubscriptionSetItemFrequencyDocument = gql`
    mutation assistedSubscriptionSetItemFrequency($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!, $frequency: Long!) {
  assistedSubscriptionSetItemFrequency(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
    frequency: $frequency
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetItemFrequencyGQL extends Apollo.Mutation<AssistedSubscriptionSetItemFrequencyMutation, AssistedSubscriptionSetItemFrequencyMutationVariables> {
    document = AssistedSubscriptionSetItemFrequencyDocument;
    
  }
export const AssistedSubscriptionSetItemQuantityDocument = gql`
    mutation assistedSubscriptionSetItemQuantity($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!, $quantity: Long!) {
  assistedSubscriptionSetItemQuantity(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
    quantity: $quantity
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetItemQuantityGQL extends Apollo.Mutation<AssistedSubscriptionSetItemQuantityMutation, AssistedSubscriptionSetItemQuantityMutationVariables> {
    document = AssistedSubscriptionSetItemQuantityDocument;
    
  }
export const AuthorizationAuthCodeDocument = gql`
    mutation authorizationAuthCode($scope: AuthorizationScopeType!, $clientId: String!, $redirectUri: String!, $codeChallenge: String!, $codeChallengeMethod: String!) {
  authorizationAuthCode(
    scope: $scope
    clientId: $clientId
    redirectUri: $redirectUri
    codeChallenge: $codeChallenge
    codeChallengeMethod: $codeChallengeMethod
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthorizationAuthCodeGQL extends Apollo.Mutation<AuthorizationAuthCodeMutation, AuthorizationAuthCodeMutationVariables> {
    document = AuthorizationAuthCodeDocument;
    
  }
export const AutoprintReprintDocument = gql`
    mutation autoprintReprint($shipment: UUID!) {
  autoprintReprint(shipment: $shipment)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoprintReprintGQL extends Apollo.Mutation<AutoprintReprintMutation, AutoprintReprintMutationVariables> {
    document = AutoprintReprintDocument;
    
  }
export const BlockCreateDocument = gql`
    mutation blockCreate($htmlContent: String!, $name: String!, $slug: String!) {
  blockCreate(htmlContent: $htmlContent, name: $name, slug: $slug) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockCreateGQL extends Apollo.Mutation<BlockCreateMutation, BlockCreateMutationVariables> {
    document = BlockCreateDocument;
    
  }
export const BlockDeleteDocument = gql`
    mutation blockDelete($id: UUID!) {
  blockDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockDeleteGQL extends Apollo.Mutation<BlockDeleteMutation, BlockDeleteMutationVariables> {
    document = BlockDeleteDocument;
    
  }
export const BlockFilterDocument = gql`
    query blockFilter($name: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  blockFilter(name: {pattern: $name}, page: $pageable, sort: $sort) {
    data {
      id
      name
      slug
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockFilterGQL extends Apollo.Query<BlockFilterQuery, BlockFilterQueryVariables> {
    document = BlockFilterDocument;
    
  }
export const BlockInfoDocument = gql`
    query blockInfo($id: UUID!) {
  blockInfo(id: $id) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockInfoGQL extends Apollo.Query<BlockInfoQuery, BlockInfoQueryVariables> {
    document = BlockInfoDocument;
    
  }
export const BlockUpdateDocument = gql`
    mutation blockUpdate($htmlContent: String!, $id: UUID!, $name: String!, $slug: String!) {
  blockUpdate(htmlContent: $htmlContent, id: $id, name: $name, slug: $slug) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockUpdateGQL extends Apollo.Mutation<BlockUpdateMutation, BlockUpdateMutationVariables> {
    document = BlockUpdateDocument;
    
  }
export const BlogBySlugDocument = gql`
    query blogBySlug($slug: String!) {
  blogBySlug(slug: $slug) {
    id
    cls
    createdAt
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
    products {
      id
      cls
      sku
      slug
      title
      price
      salePrice
      countRating
      averageRating
      quantityAvailableForSale
      medias {
        url
      }
    }
    blogTopics {
      id
      cls
      slug
      name
    }
    medias {
      id
      sequence
      mediaType
      url
    }
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogBySlugGQL extends Apollo.Query<BlogBySlugQuery, BlogBySlugQueryVariables> {
    document = BlogBySlugDocument;
    
  }
export const BlogDeleteDocument = gql`
    mutation blogDelete($id: UUID!) {
  blogDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogDeleteGQL extends Apollo.Mutation<BlogDeleteMutation, BlogDeleteMutationVariables> {
    document = BlogDeleteDocument;
    
  }
export const BlogFilterDocument = gql`
    query blogFilter($title: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  blogFilter(title: {pattern: $title}, page: $pageable, sort: $sort) {
    data {
      id
      title
      slug
      active
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogFilterGQL extends Apollo.Query<BlogFilterQuery, BlogFilterQueryVariables> {
    document = BlogFilterDocument;
    
  }
export const BlogInfoDocument = gql`
    query blogInfo($id: UUID!) {
  blogInfo(id: $id) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogInfoGQL extends Apollo.Query<BlogInfoQuery, BlogInfoQueryVariables> {
    document = BlogInfoDocument;
    
  }
export const BlogListDocument = gql`
    query blogList($pageable: GraphQLPageableInput!) {
  blogList(page: $pageable) {
    count
    page
    pageSize
    data {
      id
      cls
      createdAt
      active
      title
      author
      slug
      metaDescription
      blogTopics {
        id
        cls
        slug
        name
      }
      medias {
        id
        cls
        sequence
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogListGQL extends Apollo.Query<BlogListQuery, BlogListQueryVariables> {
    document = BlogListDocument;
    
  }
export const BlogSearchDocument = gql`
    query blogSearch($query: String!, $pageable: GraphQLPageableInput) {
  blogSearch(query: $query, page: $pageable) {
    count
    page
    pageSize
    data {
      id
      cls
      createdAt
      active
      title
      author
      slug
      metaDescription
      blogTopics {
        id
        cls
        slug
        name
      }
      medias {
        id
        cls
        sequence
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSearchGQL extends Apollo.Query<BlogSearchQuery, BlogSearchQueryVariables> {
    document = BlogSearchDocument;
    
  }
export const BlogSetActiveDocument = gql`
    mutation blogSetActive($id: UUID!, $active: Boolean!) {
  blogSetActive(id: $id, active: $active) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetActiveGQL extends Apollo.Mutation<BlogSetActiveMutation, BlogSetActiveMutationVariables> {
    document = BlogSetActiveDocument;
    
  }
export const BlogSetPublishedRevisionDocument = gql`
    mutation blogSetPublishedRevision($id: UUID!, $htmlContent: String) {
  blogSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetPublishedRevisionGQL extends Apollo.Mutation<BlogSetPublishedRevisionMutation, BlogSetPublishedRevisionMutationVariables> {
    document = BlogSetPublishedRevisionDocument;
    
  }
export const BrandCreateDocument = gql`
    mutation brandCreate($name: String!) {
  brandCreate(name: $name) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandCreateGQL extends Apollo.Mutation<BrandCreateMutation, BrandCreateMutationVariables> {
    document = BrandCreateDocument;
    
  }
export const BrandDeleteDocument = gql`
    mutation brandDelete($id: UUID!) {
  brandDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandDeleteGQL extends Apollo.Mutation<BrandDeleteMutation, BrandDeleteMutationVariables> {
    document = BrandDeleteDocument;
    
  }
export const BrandInfoDocument = gql`
    query brandInfo($id: UUID!) {
  brandInfo(id: $id) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandInfoGQL extends Apollo.Query<BrandInfoQuery, BrandInfoQueryVariables> {
    document = BrandInfoDocument;
    
  }
export const BrandListDocument = gql`
    query brandList {
  brandList(page: {page: null, pageSize: null}) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandListGQL extends Apollo.Query<BrandListQuery, BrandListQueryVariables> {
    document = BrandListDocument;
    
  }
export const BrandSetNameDocument = gql`
    mutation brandSetName($id: UUID!, $name: String!) {
  brandSetName(id: $id, name: $name) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandSetNameGQL extends Apollo.Mutation<BrandSetNameMutation, BrandSetNameMutationVariables> {
    document = BrandSetNameDocument;
    
  }
export const CareSheetBySlugDocument = gql`
    query careSheetBySlug($slug: String!) {
  careSheetBySlug(slug: $slug) {
    id
    cls
    createdAt
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
    medias {
      id
      sequence
      mediaType
      url
    }
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetBySlugGQL extends Apollo.Query<CareSheetBySlugQuery, CareSheetBySlugQueryVariables> {
    document = CareSheetBySlugDocument;
    
  }
export const CareSheetDeleteDocument = gql`
    mutation careSheetDelete($id: UUID!) {
  careSheetDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetDeleteGQL extends Apollo.Mutation<CareSheetDeleteMutation, CareSheetDeleteMutationVariables> {
    document = CareSheetDeleteDocument;
    
  }
export const CareSheetFilterDocument = gql`
    query careSheetFilter($title: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  careSheetFilter(title: {pattern: $title}, page: $pageable, sort: $sort) {
    data {
      id
      active
      title
      slug
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetFilterGQL extends Apollo.Query<CareSheetFilterQuery, CareSheetFilterQueryVariables> {
    document = CareSheetFilterDocument;
    
  }
export const CareSheetInfoDocument = gql`
    query careSheetInfo($id: UUID!) {
  careSheetInfo(id: $id) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetInfoGQL extends Apollo.Query<CareSheetInfoQuery, CareSheetInfoQueryVariables> {
    document = CareSheetInfoDocument;
    
  }
export const CareSheetSetActiveDocument = gql`
    mutation careSheetSetActive($id: UUID!, $active: Boolean!) {
  careSheetSetActive(id: $id, active: $active) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetActiveGQL extends Apollo.Mutation<CareSheetSetActiveMutation, CareSheetSetActiveMutationVariables> {
    document = CareSheetSetActiveDocument;
    
  }
export const CareSheetSetPublishedRevisionDocument = gql`
    mutation careSheetSetPublishedRevision($id: UUID!, $htmlContent: String) {
  careSheetSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetPublishedRevisionGQL extends Apollo.Mutation<CareSheetSetPublishedRevisionMutation, CareSheetSetPublishedRevisionMutationVariables> {
    document = CareSheetSetPublishedRevisionDocument;
    
  }
export const CartAddPartialKitProductDocument = gql`
    mutation cartAddPartialKitProduct($cartId: UUID, $productId: UUID!) {
  cartAddPartialKitProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddPartialKitProductGQL extends Apollo.Mutation<CartAddPartialKitProductMutation, CartAddPartialKitProductMutationVariables> {
    document = CartAddPartialKitProductDocument;
    
  }
export const CartAuthorizeUrlDocument = gql`
    mutation cartAuthorizeUrl($cartId: UUID, $useRewardPoints: Boolean, $giftCardCode: String, $giftCardPin: String) {
  cartAuthorizeUrl(
    cartId: $cartId
    useRewardPoints: $useRewardPoints
    giftCardCode: $giftCardCode
    giftCardPin: $giftCardPin
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAuthorizeUrlGQL extends Apollo.Mutation<CartAuthorizeUrlMutation, CartAuthorizeUrlMutationVariables> {
    document = CartAuthorizeUrlDocument;
    
  }
export const CartReorderDocument = gql`
    mutation cartReorder($orderId: UUID!, $cartId: UUID) {
  cartReorder(orderId: $orderId, cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartReorderGQL extends Apollo.Mutation<CartReorderMutation, CartReorderMutationVariables> {
    document = CartReorderDocument;
    
  }
export const CartSetAffiliateDocument = gql`
    mutation cartSetAffiliate($cartId: UUID, $affiliateSlug: String!) {
  cartSetAffiliate(cartId: $cartId, affiliateSlug: $affiliateSlug) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetAffiliateGQL extends Apollo.Mutation<CartSetAffiliateMutation, CartSetAffiliateMutationVariables> {
    document = CartSetAffiliateDocument;
    
  }
export const CartAddKitProductDocument = gql`
    mutation cartAddKitProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartAddKitProduct(cartId: $cartId, productId: $productId, quantity: $quantity) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddKitProductGQL extends Apollo.Mutation<CartAddKitProductMutation, CartAddKitProductMutationVariables> {
    document = CartAddKitProductDocument;
    
  }
export const CartAddSimpleProductDocument = gql`
    mutation cartAddSimpleProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartAddSimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddSimpleProductGQL extends Apollo.Mutation<CartAddSimpleProductMutation, CartAddSimpleProductMutationVariables> {
    document = CartAddSimpleProductDocument;
    
  }
export const CartChangeQuantityKitProductDocument = gql`
    mutation cartChangeQuantityKitProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartChangeQuantityKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartChangeQuantityKitProductGQL extends Apollo.Mutation<CartChangeQuantityKitProductMutation, CartChangeQuantityKitProductMutationVariables> {
    document = CartChangeQuantityKitProductDocument;
    
  }
export const CartChangeQuantitySimpleProductDocument = gql`
    mutation cartChangeQuantitySimpleProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartChangeQuantitySimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartChangeQuantitySimpleProductGQL extends Apollo.Mutation<CartChangeQuantitySimpleProductMutation, CartChangeQuantitySimpleProductMutationVariables> {
    document = CartChangeQuantitySimpleProductDocument;
    
  }
export const CartCheckoutDocument = gql`
    mutation cartCheckout($cartId: UUID!, $method: String!, $deviceData: String, $nonce: String, $useRewardPoints: Boolean, $giftCardCode: String, $giftCardPin: String) {
  cartCheckout(
    cartId: $cartId
    method: $method
    deviceData: $deviceData
    nonce: $nonce
    useRewardPoints: $useRewardPoints
    giftCardCode: $giftCardCode
    giftCardPin: $giftCardPin
  ) {
    ...CUSTOMER_SALES_ORDER
    couponCode
    salesOrderItems {
      discountAmount
      simpleProduct {
        sku
      }
    }
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCheckoutGQL extends Apollo.Mutation<CartCheckoutMutation, CartCheckoutMutationVariables> {
    document = CartCheckoutDocument;
    
  }
export const CartClearBillingAddressDocument = gql`
    mutation cartClearBillingAddress($cartId: UUID) {
  cartClearBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearBillingAddressGQL extends Apollo.Mutation<CartClearBillingAddressMutation, CartClearBillingAddressMutationVariables> {
    document = CartClearBillingAddressDocument;
    
  }
export const CartClearCarrierPreferenceDocument = gql`
    mutation cartClearCarrierPreference($cartId: UUID, $simpleProductId: UUID!) {
  cartClearCarrierPreference(cartId: $cartId, simpleProductId: $simpleProductId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearCarrierPreferenceGQL extends Apollo.Mutation<CartClearCarrierPreferenceMutation, CartClearCarrierPreferenceMutationVariables> {
    document = CartClearCarrierPreferenceDocument;
    
  }
export const CartClearCouponCodeDocument = gql`
    mutation cartClearCouponCode($cartId: UUID) {
  cartClearCouponCode(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearCouponCodeGQL extends Apollo.Mutation<CartClearCouponCodeMutation, CartClearCouponCodeMutationVariables> {
    document = CartClearCouponCodeDocument;
    
  }
export const CartClearDeliveryDatePreferenceDocument = gql`
    mutation cartClearDeliveryDatePreference($cartId: UUID, $simpleProductId: UUID!) {
  cartClearDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearDeliveryDatePreferenceGQL extends Apollo.Mutation<CartClearDeliveryDatePreferenceMutation, CartClearDeliveryDatePreferenceMutationVariables> {
    document = CartClearDeliveryDatePreferenceDocument;
    
  }
export const CartClearEmailDocument = gql`
    mutation cartClearEmail($cartId: UUID) {
  cartClearEmail(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearEmailGQL extends Apollo.Mutation<CartClearEmailMutation, CartClearEmailMutationVariables> {
    document = CartClearEmailDocument;
    
  }
export const CartClearPhoneDocument = gql`
    mutation cartClearPhone($cartId: UUID) {
  cartClearPhone(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearPhoneGQL extends Apollo.Mutation<CartClearPhoneMutation, CartClearPhoneMutationVariables> {
    document = CartClearPhoneDocument;
    
  }
export const CartClearPickupWarehouseDocument = gql`
    mutation cartClearPickupWarehouse($cartId: UUID) {
  cartClearPickupWarehouse(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearPickupWarehouseGQL extends Apollo.Mutation<CartClearPickupWarehouseMutation, CartClearPickupWarehouseMutationVariables> {
    document = CartClearPickupWarehouseDocument;
    
  }
export const CartClearShippingAddressDocument = gql`
    mutation cartClearShippingAddress($cartId: UUID) {
  cartClearShippingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearShippingAddressGQL extends Apollo.Mutation<CartClearShippingAddressMutation, CartClearShippingAddressMutationVariables> {
    document = CartClearShippingAddressDocument;
    
  }
export const CartClearTaxExemptDocument = gql`
    mutation cartClearTaxExempt($cartId: UUID!) {
  cartClearTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearTaxExemptGQL extends Apollo.Mutation<CartClearTaxExemptMutation, CartClearTaxExemptMutationVariables> {
    document = CartClearTaxExemptDocument;
    
  }
export const CartClearTradeshowDocument = gql`
    mutation cartClearTradeshow($cartId: UUID) {
  cartClearTradeshow(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearTradeshowGQL extends Apollo.Mutation<CartClearTradeshowMutation, CartClearTradeshowMutationVariables> {
    document = CartClearTradeshowDocument;
    
  }
export const CartCrossSellsDocument = gql`
    mutation cartCrossSells($cartId: UUID!) {
  cartCrossSells(cartId: $cartId) {
    id
    cls
    title
    sku
    slug
    countRating
    averageRating
    quantityAvailableForSale
    price
    salePrice
    variationKey
    featured
    medias {
      id
      mediaType
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCrossSellsGQL extends Apollo.Mutation<CartCrossSellsMutation, CartCrossSellsMutationVariables> {
    document = CartCrossSellsDocument;
    
  }
export const CartGetEarliestDeliveryDateDocument = gql`
    mutation cartGetEarliestDeliveryDate($cartId: UUID, $simpleProductId: UUID!, $quantity: Long!) {
  cartGetEarliestDeliveryDate(
    cartId: $cartId
    simpleProductId: $simpleProductId
    quantity: $quantity
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartGetEarliestDeliveryDateGQL extends Apollo.Mutation<CartGetEarliestDeliveryDateMutation, CartGetEarliestDeliveryDateMutationVariables> {
    document = CartGetEarliestDeliveryDateDocument;
    
  }
export const CartInfoDocument = gql`
    mutation cartInfo($cartId: UUID) {
  cartInfo(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartInfoGQL extends Apollo.Mutation<CartInfoMutation, CartInfoMutationVariables> {
    document = CartInfoDocument;
    
  }
export const CartRemoveAllKitProductDocument = gql`
    mutation cartRemoveAllKitProduct($cartId: UUID, $productId: UUID!) {
  cartRemoveAllKitProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveAllKitProductGQL extends Apollo.Mutation<CartRemoveAllKitProductMutation, CartRemoveAllKitProductMutationVariables> {
    document = CartRemoveAllKitProductDocument;
    
  }
export const CartRemoveAllSimpleProductDocument = gql`
    mutation cartRemoveAllSimpleProduct($cartId: UUID, $productId: UUID!) {
  cartRemoveAllSimpleProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveAllSimpleProductGQL extends Apollo.Mutation<CartRemoveAllSimpleProductMutation, CartRemoveAllSimpleProductMutationVariables> {
    document = CartRemoveAllSimpleProductDocument;
    
  }
export const CartSetAttributionTokenDocument = gql`
    mutation cartSetAttributionToken($cartId: UUID, $attributionToken: String!) {
  cartSetAttributionToken(cartId: $cartId, attributionToken: $attributionToken) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetAttributionTokenGQL extends Apollo.Mutation<CartSetAttributionTokenMutation, CartSetAttributionTokenMutationVariables> {
    document = CartSetAttributionTokenDocument;
    
  }
export const CartSetBillingAddressDocument = gql`
    mutation cartSetBillingAddress($cartId: UUID, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  cartSetBillingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetBillingAddressGQL extends Apollo.Mutation<CartSetBillingAddressMutation, CartSetBillingAddressMutationVariables> {
    document = CartSetBillingAddressDocument;
    
  }
export const CartSetBillingAddressByIdDocument = gql`
    mutation cartSetBillingAddressById($cartId: UUID, $addressId: UUID!) {
  cartSetBillingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetBillingAddressByIdGQL extends Apollo.Mutation<CartSetBillingAddressByIdMutation, CartSetBillingAddressByIdMutationVariables> {
    document = CartSetBillingAddressByIdDocument;
    
  }
export const CartSetCarrierPreferenceDocument = gql`
    mutation cartSetCarrierPreference($cartId: UUID, $simpleProductId: UUID!, $carrier: Carrier!) {
  cartSetCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    carrierPreference: $carrier
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetCarrierPreferenceGQL extends Apollo.Mutation<CartSetCarrierPreferenceMutation, CartSetCarrierPreferenceMutationVariables> {
    document = CartSetCarrierPreferenceDocument;
    
  }
export const CartSetContactDocument = gql`
    mutation cartSetContact($cartId: UUID, $email: String, $phone: String) {
  cartSetContact(cartId: $cartId, email: $email, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetContactGQL extends Apollo.Mutation<CartSetContactMutation, CartSetContactMutationVariables> {
    document = CartSetContactDocument;
    
  }
export const CartSetCouponCodeDocument = gql`
    mutation cartSetCouponCode($cartId: UUID, $couponCode: String!) {
  cartSetCouponCode(cartId: $cartId, couponCode: $couponCode) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetCouponCodeGQL extends Apollo.Mutation<CartSetCouponCodeMutation, CartSetCouponCodeMutationVariables> {
    document = CartSetCouponCodeDocument;
    
  }
export const CartSetDeliveryDatePreferenceDocument = gql`
    mutation cartSetDeliveryDatePreference($cartId: UUID, $simpleProductId: UUID!, $deliveryDatePreference: LocalDate!) {
  cartSetDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    deliveryDatePreference: $deliveryDatePreference
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetDeliveryDatePreferenceGQL extends Apollo.Mutation<CartSetDeliveryDatePreferenceMutation, CartSetDeliveryDatePreferenceMutationVariables> {
    document = CartSetDeliveryDatePreferenceDocument;
    
  }
export const CartSetEmailDocument = gql`
    mutation cartSetEmail($cartId: UUID, $email: String!) {
  cartSetEmail(cartId: $cartId, email: $email) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetEmailGQL extends Apollo.Mutation<CartSetEmailMutation, CartSetEmailMutationVariables> {
    document = CartSetEmailDocument;
    
  }
export const CartSetPhoneDocument = gql`
    mutation cartSetPhone($cartId: UUID, $phone: String!) {
  cartSetPhone(cartId: $cartId, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetPhoneGQL extends Apollo.Mutation<CartSetPhoneMutation, CartSetPhoneMutationVariables> {
    document = CartSetPhoneDocument;
    
  }
export const CartSetPickupWarehouseDocument = gql`
    mutation cartSetPickupWarehouse($cartId: UUID, $warehouseId: UUID!) {
  cartSetPickupWarehouse(cartId: $cartId, warehouseId: $warehouseId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetPickupWarehouseGQL extends Apollo.Mutation<CartSetPickupWarehouseMutation, CartSetPickupWarehouseMutationVariables> {
    document = CartSetPickupWarehouseDocument;
    
  }
export const CartSetShippingAddressDocument = gql`
    mutation cartSetShippingAddress($cartId: UUID, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean) {
  cartSetShippingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingAddressGQL extends Apollo.Mutation<CartSetShippingAddressMutation, CartSetShippingAddressMutationVariables> {
    document = CartSetShippingAddressDocument;
    
  }
export const CartSetShippingAddressByIdDocument = gql`
    mutation cartSetShippingAddressById($cartId: UUID, $addressId: UUID!) {
  cartSetShippingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingAddressByIdGQL extends Apollo.Mutation<CartSetShippingAddressByIdMutation, CartSetShippingAddressByIdMutationVariables> {
    document = CartSetShippingAddressByIdDocument;
    
  }
export const CartSetShippingZipDocument = gql`
    mutation cartSetShippingZip($cartId: UUID, $zip: String!) {
  cartSetShippingZip(cartId: $cartId, zip: $zip) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingZipGQL extends Apollo.Mutation<CartSetShippingZipMutation, CartSetShippingZipMutationVariables> {
    document = CartSetShippingZipDocument;
    
  }
export const CartSetTaxExemptDocument = gql`
    mutation cartSetTaxExempt($cartId: UUID!) {
  cartSetTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetTaxExemptGQL extends Apollo.Mutation<CartSetTaxExemptMutation, CartSetTaxExemptMutationVariables> {
    document = CartSetTaxExemptDocument;
    
  }
export const CartSetTradeshowDocument = gql`
    mutation cartSetTradeshow($cartId: UUID, $tradeshowId: UUID!) {
  cartSetTradeshow(cartId: $cartId, tradeshowId: $tradeshowId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetTradeshowGQL extends Apollo.Mutation<CartSetTradeshowMutation, CartSetTradeshowMutationVariables> {
    document = CartSetTradeshowDocument;
    
  }
export const CartSummarizeDocument = gql`
    mutation cartSummarize($cartId: UUID, $useRewardPoints: Boolean) {
  cartSummarize(cartId: $cartId, useRewardPoints: $useRewardPoints) {
    id
    billingAddressValidationSource
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingAddressValidationSource
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingResidential
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    couponCode
    salesOrderDiscounts {
      id
      name
      amount
    }
    salesOrderItemGroups {
      id
      quantity
      unitAmount
      discountAmount
      kitProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    salesOrderItems {
      id
      quantity
      unitAmount
      discountAmount
      salesOrderItemGroup {
        id
      }
      simpleProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    shipments {
      shipmentItems {
        simpleProduct {
          id
        }
        salesOrderItem {
          salesOrderItemGroup {
            id
            kitProduct {
              id
            }
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shippingNeeds
      carrier
      service
      estimatedShipDate
      estimatedDeliveryDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSummarizeGQL extends Apollo.Mutation<CartSummarizeMutation, CartSummarizeMutationVariables> {
    document = CartSummarizeDocument;
    
  }
export const CategoryByPathDocument = gql`
    query categoryByPath($pageable: GraphQLPageableInput!, $path: String!) {
  categoryByPath(page: $pageable, path: $path) {
    category {
      id
      slug
      path
      name
      metaTitle
      metaDescription
      metaKeywords
      publishedRevision {
        htmlContent
      }
      children {
        id
        active
        slug
        path
        name
        thumbnailMedia {
          id
          url
        }
      }
      ancestors {
        name
        path
      }
    }
    products {
      data {
        id
        cls
        slug
        sku
        title
        quantityAvailableForSale
        price
        salePrice
        averageRating
        countRating
        medias {
          id
          mediaType
          url
        }
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryByPathGQL extends Apollo.Query<CategoryByPathQuery, CategoryByPathQueryVariables> {
    document = CategoryByPathDocument;
    
  }
export const CategoryCreateDocument = gql`
    mutation categoryCreate($name: String!, $slug: String!, $parentId: UUID!, $magentoIds: [String]!) {
  categoryCreate(
    name: $name
    slug: $slug
    parentId: $parentId
    magentoIds: $magentoIds
  ) {
    id
    slug
    path
    name
    active
    routePath
    parent {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryCreateGQL extends Apollo.Mutation<CategoryCreateMutation, CategoryCreateMutationVariables> {
    document = CategoryCreateDocument;
    
  }
export const CategoryDeleteDocument = gql`
    mutation categoryDelete($id: UUID!) {
  categoryDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryDeleteGQL extends Apollo.Mutation<CategoryDeleteMutation, CategoryDeleteMutationVariables> {
    document = CategoryDeleteDocument;
    
  }
export const CategoryFilterDocument = gql`
    query categoryFilter($active: Boolean, $sort: [GraphQLSortInput]) {
  categoryFilter(active: $active, page: {page: null, pageSize: null, sort: $sort}) {
    data {
      id
      slug
      path
      name
      active
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryFilterGQL extends Apollo.Query<CategoryFilterQuery, CategoryFilterQueryVariables> {
    document = CategoryFilterDocument;
    
  }
export const CategoryInfoDocument = gql`
    query categoryInfo($id: UUID!) {
  categoryInfo(id: $id) {
    id
    active
    slug
    path
    name
    metaTitle
    metaDescription
    metaKeywords
    magentoIds
    publishedRevision {
      htmlContent
    }
    thumbnailMedia {
      id
      title
      url
    }
    children {
      id
      slug
      path
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryInfoGQL extends Apollo.Query<CategoryInfoQuery, CategoryInfoQueryVariables> {
    document = CategoryInfoDocument;
    
  }
export const CategoryListDocument = gql`
    query categoryList($sort: [GraphQLSortInput]) {
  categoryList(page: {page: null, pageSize: null, sort: $sort}) {
    data {
      id
      slug
      path
      name
      active
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryListGQL extends Apollo.Query<CategoryListQuery, CategoryListQueryVariables> {
    document = CategoryListDocument;
    
  }
export const CategorySetActiveDocument = gql`
    mutation categorySetActive($id: UUID!, $active: Boolean!) {
  categorySetActive(id: $id, active: $active) {
    id
    active
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetActiveGQL extends Apollo.Mutation<CategorySetActiveMutation, CategorySetActiveMutationVariables> {
    document = CategorySetActiveDocument;
    
  }
export const CategorySetImageDocument = gql`
    mutation categorySetImage($id: UUID!, $mediaId: UUID!) {
  categorySetImage(id: $id, mediaId: $mediaId) {
    id
    thumbnailMedia {
      id
      title
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetImageGQL extends Apollo.Mutation<CategorySetImageMutation, CategorySetImageMutationVariables> {
    document = CategorySetImageDocument;
    
  }
export const CategorySetNameDocument = gql`
    mutation categorySetName($id: UUID!, $name: String!) {
  categorySetName(id: $id, name: $name) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetNameGQL extends Apollo.Mutation<CategorySetNameMutation, CategorySetNameMutationVariables> {
    document = CategorySetNameDocument;
    
  }
export const CategorySetPublishedRevisionDocument = gql`
    mutation categorySetPublishedRevision($id: UUID!, $htmlContent: String) {
  categorySetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    id
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetPublishedRevisionGQL extends Apollo.Mutation<CategorySetPublishedRevisionMutation, CategorySetPublishedRevisionMutationVariables> {
    document = CategorySetPublishedRevisionDocument;
    
  }
export const CategoryUploadImageDocument = gql`
    mutation categoryUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  categoryUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    id
    thumbnailMedia {
      id
      title
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryUploadImageGQL extends Apollo.Mutation<CategoryUploadImageMutation, CategoryUploadImageMutationVariables> {
    document = CategoryUploadImageDocument;
    
  }
export const CheckoutCreatePaymentMethodDocument = gql`
    mutation checkoutCreatePaymentMethod($deviceData: String!, $nonce: String!) {
  checkoutCreatePaymentMethod(deviceData: $deviceData, nonce: $nonce) {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutCreatePaymentMethodGQL extends Apollo.Mutation<CheckoutCreatePaymentMethodMutation, CheckoutCreatePaymentMethodMutationVariables> {
    document = CheckoutCreatePaymentMethodDocument;
    
  }
export const CheckoutDeletePaymentMethodDocument = gql`
    mutation checkoutDeletePaymentMethod($token: String!) {
  checkoutDeletePaymentMethod(token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutDeletePaymentMethodGQL extends Apollo.Mutation<CheckoutDeletePaymentMethodMutation, CheckoutDeletePaymentMethodMutationVariables> {
    document = CheckoutDeletePaymentMethodDocument;
    
  }
export const CheckoutGetClientTokenDocument = gql`
    mutation checkoutGetClientToken($anonymous: Boolean) {
  checkoutGetClientToken(anonymous: $anonymous) {
    clientToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutGetClientTokenGQL extends Apollo.Mutation<CheckoutGetClientTokenMutation, CheckoutGetClientTokenMutationVariables> {
    document = CheckoutGetClientTokenDocument;
    
  }
export const CheckoutListPaymentMethodsDocument = gql`
    mutation checkoutListPaymentMethods {
  checkoutListPaymentMethods {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutListPaymentMethodsGQL extends Apollo.Mutation<CheckoutListPaymentMethodsMutation, CheckoutListPaymentMethodsMutationVariables> {
    document = CheckoutListPaymentMethodsDocument;
    
  }
export const CheckoutSetDefaultPaymentMethodDocument = gql`
    mutation checkoutSetDefaultPaymentMethod($token: String!) {
  checkoutSetDefaultPaymentMethod(token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutSetDefaultPaymentMethodGQL extends Apollo.Mutation<CheckoutSetDefaultPaymentMethodMutation, CheckoutSetDefaultPaymentMethodMutationVariables> {
    document = CheckoutSetDefaultPaymentMethodDocument;
    
  }
export const ConfigurableProductAddImageDocument = gql`
    mutation configurableProductAddImage($id: UUID!, $mediaId: UUID!) {
  configurableProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductAddImageGQL extends Apollo.Mutation<ConfigurableProductAddImageMutation, ConfigurableProductAddImageMutationVariables> {
    document = ConfigurableProductAddImageDocument;
    
  }
export const ConfigurableProductRemoveDocument = gql`
    mutation configurableProductRemove($id: UUID!) {
  configurableProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveGQL extends Apollo.Mutation<ConfigurableProductRemoveMutation, ConfigurableProductRemoveMutationVariables> {
    document = ConfigurableProductRemoveDocument;
    
  }
export const ConfigurableProductRemoveProductMediaDocument = gql`
    mutation configurableProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  configurableProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveProductMediaGQL extends Apollo.Mutation<ConfigurableProductRemoveProductMediaMutation, ConfigurableProductRemoveProductMediaMutationVariables> {
    document = ConfigurableProductRemoveProductMediaDocument;
    
  }
export const ConfigurableProductSetActiveDocument = gql`
    mutation configurableProductSetActive($id: UUID!, $active: Boolean!) {
  configurableProductSetActive(id: $id, active: $active) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetActiveGQL extends Apollo.Mutation<ConfigurableProductSetActiveMutation, ConfigurableProductSetActiveMutationVariables> {
    document = ConfigurableProductSetActiveDocument;
    
  }
export const ConfigurableProductSetFeaturedDocument = gql`
    mutation configurableProductSetFeatured($id: UUID!, $featured: Boolean!) {
  configurableProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetFeaturedGQL extends Apollo.Mutation<ConfigurableProductSetFeaturedMutation, ConfigurableProductSetFeaturedMutationVariables> {
    document = ConfigurableProductSetFeaturedDocument;
    
  }
export const ConfigurableProductSetProductMediaSequenceDocument = gql`
    mutation configurableProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  configurableProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetProductMediaSequenceGQL extends Apollo.Mutation<ConfigurableProductSetProductMediaSequenceMutation, ConfigurableProductSetProductMediaSequenceMutationVariables> {
    document = ConfigurableProductSetProductMediaSequenceDocument;
    
  }
export const ConfigurableProductSetPublishedRevisionDocument = gql`
    mutation configurableProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  configurableProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetPublishedRevisionGQL extends Apollo.Mutation<ConfigurableProductSetPublishedRevisionMutation, ConfigurableProductSetPublishedRevisionMutationVariables> {
    document = ConfigurableProductSetPublishedRevisionDocument;
    
  }
export const ConfigurableProductSetSkuDocument = gql`
    mutation configurableProductSetSku($id: UUID!, $sku: String!) {
  configurableProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetSkuGQL extends Apollo.Mutation<ConfigurableProductSetSkuMutation, ConfigurableProductSetSkuMutationVariables> {
    document = ConfigurableProductSetSkuDocument;
    
  }
export const ConfigurableProductSetSlugDocument = gql`
    mutation configurableProductSetSlug($id: UUID!, $slug: String!) {
  configurableProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetSlugGQL extends Apollo.Mutation<ConfigurableProductSetSlugMutation, ConfigurableProductSetSlugMutationVariables> {
    document = ConfigurableProductSetSlugDocument;
    
  }
export const ConfigurableProductSetTitleDocument = gql`
    mutation configurableProductSetTitle($id: UUID!, $title: String!) {
  configurableProductSetTitle(id: $id, title: $title) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetTitleGQL extends Apollo.Mutation<ConfigurableProductSetTitleMutation, ConfigurableProductSetTitleMutationVariables> {
    document = ConfigurableProductSetTitleDocument;
    
  }
export const ConfigurableProductSetUpcDocument = gql`
    mutation configurableProductSetUpc($id: UUID!, $upc: String) {
  configurableProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetUpcGQL extends Apollo.Mutation<ConfigurableProductSetUpcMutation, ConfigurableProductSetUpcMutationVariables> {
    document = ConfigurableProductSetUpcDocument;
    
  }
export const ConfigurableProductSetVisibleDocument = gql`
    mutation configurableProductSetVisible($id: UUID!, $visible: Boolean!) {
  configurableProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetVisibleGQL extends Apollo.Mutation<ConfigurableProductSetVisibleMutation, ConfigurableProductSetVisibleMutationVariables> {
    document = ConfigurableProductSetVisibleDocument;
    
  }
export const ConfigurableProductUploadImageDocument = gql`
    mutation configurableProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  configurableProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductUploadImageGQL extends Apollo.Mutation<ConfigurableProductUploadImageMutation, ConfigurableProductUploadImageMutationVariables> {
    document = ConfigurableProductUploadImageDocument;
    
  }
export const ConfigurableProductBySlugDocument = gql`
    query configurableProductBySlug($slug: String!) {
  configurableProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    quantityAvailableForSale
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    configurableItems {
      simpleProduct {
        id
        cls
        sku
        slug
        title
        price
        salePrice
        featured
        subscription
        quantityAvailableForSale
        averageRating
        countRating
        medias {
          id
          mediaType
          url
        }
        shippingRuleSet {
          liveArrivalGuarantees {
            minTemp
            maxTemp
            transitTimeType
          }
          destinationRestriction {
            domesticOnly
            prohibitedStates
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductBySlugGQL extends Apollo.Query<ConfigurableProductBySlugQuery, ConfigurableProductBySlugQueryVariables> {
    document = ConfigurableProductBySlugDocument;
    
  }
export const ConfigurableProductFilterDocument = gql`
    query configurableProductFilter($active: Boolean, $pageable: GraphQLPageableInput!, $category: String, $sku: String, $title: String, $sort: GraphQLSortInput) {
  configurableProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    category: $category
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      featured
      visible
      quantityAvailableForSale
      price
      specialPrice
      wholesalePrice
      cost
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductFilterGQL extends Apollo.Query<ConfigurableProductFilterQuery, ConfigurableProductFilterQueryVariables> {
    document = ConfigurableProductFilterDocument;
    
  }
export const ConfigurableProductInfoDocument = gql`
    query configurableProductInfo($id: UUID!) {
  configurableProductInfo(id: $id) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductInfoGQL extends Apollo.Query<ConfigurableProductInfoQuery, ConfigurableProductInfoQueryVariables> {
    document = ConfigurableProductInfoDocument;
    
  }
export const ContentBySlugDocument = gql`
    query contentBySlug($slug: String!) {
  contentBySlug(slug: $slug) {
    id
    cls
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentBySlugGQL extends Apollo.Query<ContentBySlugQuery, ContentBySlugQueryVariables> {
    document = ContentBySlugDocument;
    
  }
export const DepartmentListDocument = gql`
    query departmentList {
  departmentList(
    page: {page: null, pageSize: null, sort: [{field: "slug", direction: ASC}]}
  ) {
    data {
      id
      slug
      name
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepartmentListGQL extends Apollo.Query<DepartmentListQuery, DepartmentListQueryVariables> {
    document = DepartmentListDocument;
    
  }
export const DiscountAddEligibleProductsDocument = gql`
    mutation discountAddEligibleProducts($id: UUID!, $eligibleProductIds: [UUID]!) {
  discountAddEligibleProducts(id: $id, eligibleProductIds: $eligibleProductIds) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddEligibleProductsGQL extends Apollo.Mutation<DiscountAddEligibleProductsMutation, DiscountAddEligibleProductsMutationVariables> {
    document = DiscountAddEligibleProductsDocument;
    
  }
export const DiscountBySlugDocument = gql`
    query discountBySlug($slug: String!) {
  discountBySlug(slug: $slug) {
    ...CUSTOMER_DISCOUNT
  }
}
    ${Customer_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountBySlugGQL extends Apollo.Query<DiscountBySlugQuery, DiscountBySlugQueryVariables> {
    document = DiscountBySlugDocument;
    
  }
export const DiscountEvaluateImplicitProductsDocument = gql`
    mutation discountEvaluateImplicitProducts($id: UUID!) {
  discountEvaluateImplicitProducts(id: $id) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountEvaluateImplicitProductsGQL extends Apollo.Mutation<DiscountEvaluateImplicitProductsMutation, DiscountEvaluateImplicitProductsMutationVariables> {
    document = DiscountEvaluateImplicitProductsDocument;
    
  }
export const DiscountRemoveEligibleProductsDocument = gql`
    mutation discountRemoveEligibleProducts($id: UUID!, $eligibleProductIds: [UUID]!) {
  discountRemoveEligibleProducts(id: $id, eligibleProductIds: $eligibleProductIds) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveEligibleProductsGQL extends Apollo.Mutation<DiscountRemoveEligibleProductsMutation, DiscountRemoveEligibleProductsMutationVariables> {
    document = DiscountRemoveEligibleProductsDocument;
    
  }
export const DiscountAddExplicitProductsDocument = gql`
    mutation discountAddExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  discountAddExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddExplicitProductsGQL extends Apollo.Mutation<DiscountAddExplicitProductsMutation, DiscountAddExplicitProductsMutationVariables> {
    document = DiscountAddExplicitProductsDocument;
    
  }
export const DiscountCreateDocument = gql`
    mutation discountCreate($name: String!, $slug: String!, $discountType: DiscountType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $discountAmount: BigDecimal) {
  discountCreate(
    name: $name
    slug: $slug
    discountType: $discountType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    discountAmount: $discountAmount
  ) {
    id
    active
    discountAmount
    discountType
    eligibleCartValue
    eligibleCouponCode
    eligibleUserType
    endDate
    name
    permitStacking
    slug
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountCreateGQL extends Apollo.Mutation<DiscountCreateMutation, DiscountCreateMutationVariables> {
    document = DiscountCreateDocument;
    
  }
export const DiscountDeleteDocument = gql`
    mutation discountDelete($id: UUID!) {
  discountDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountDeleteGQL extends Apollo.Mutation<DiscountDeleteMutation, DiscountDeleteMutationVariables> {
    document = DiscountDeleteDocument;
    
  }
export const DiscountInfoDocument = gql`
    query discountInfo($id: UUID!) {
  discountInfo(id: $id) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountInfoGQL extends Apollo.Query<DiscountInfoQuery, DiscountInfoQueryVariables> {
    document = DiscountInfoDocument;
    
  }
export const DiscountListDocument = gql`
    query discountList($pageable: GraphQLPageableInput) {
  discountList(page: $pageable) {
    count
    page
    pageSize
    data {
      id
      name
      slug
      discountType
      active
      startDate
      endDate
      permitStacking
      eligibleUserType
      eligibleCouponCode
      eligibleCartValue
      discountAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountListGQL extends Apollo.Query<DiscountListQuery, DiscountListQueryVariables> {
    document = DiscountListDocument;
    
  }
export const DiscountRemoveExplicitProductsDocument = gql`
    mutation discountRemoveExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  discountRemoveExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveExplicitProductsGQL extends Apollo.Mutation<DiscountRemoveExplicitProductsMutation, DiscountRemoveExplicitProductsMutationVariables> {
    document = DiscountRemoveExplicitProductsDocument;
    
  }
export const DiscountSetActiveDocument = gql`
    mutation discountSetActive($id: UUID!, $active: Boolean!) {
  discountSetActive(id: $id, active: $active) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetActiveGQL extends Apollo.Mutation<DiscountSetActiveMutation, DiscountSetActiveMutationVariables> {
    document = DiscountSetActiveDocument;
    
  }
export const DiscountUpdateDocument = gql`
    mutation discountUpdate($id: UUID!, $name: String!, $slug: String!, $discountType: DiscountType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $discountAmount: BigDecimal, $htmlContent: String, $teaser: String) {
  discountUpdate(
    id: $id
    name: $name
    slug: $slug
    discountType: $discountType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    discountAmount: $discountAmount
    htmlContent: $htmlContent
    teaser: $teaser
  ) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountUpdateGQL extends Apollo.Mutation<DiscountUpdateMutation, DiscountUpdateMutationVariables> {
    document = DiscountUpdateDocument;
    
  }
export const FinancialResellerRevenueJournalDocument = gql`
    query financialResellerRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialResellerRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialResellerRevenueJournalGQL extends Apollo.Query<FinancialResellerRevenueJournalQuery, FinancialResellerRevenueJournalQueryVariables> {
    document = FinancialResellerRevenueJournalDocument;
    
  }
export const FinancialWebsiteRevenueJournalDocument = gql`
    query financialWebsiteRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialWebsiteRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialWebsiteRevenueJournalGQL extends Apollo.Query<FinancialWebsiteRevenueJournalQuery, FinancialWebsiteRevenueJournalQueryVariables> {
    document = FinancialWebsiteRevenueJournalDocument;
    
  }
export const GiftCardCreateDocument = gql`
    mutation giftCardCreate($amount: BigDecimal!) {
  giftCardCreate(amount: $amount) {
    id
    createdAt
    activatedBy
    code
    balance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardCreateGQL extends Apollo.Mutation<GiftCardCreateMutation, GiftCardCreateMutationVariables> {
    document = GiftCardCreateDocument;
    
  }
export const GiftCardFilterDocument = gql`
    query giftCardFilter($code: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  giftCardFilter(code: {pattern: $code}, page: $pageable, sort: $sort) {
    data {
      id
      createdAt
      activatedBy
      code
      balance
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardFilterGQL extends Apollo.Query<GiftCardFilterQuery, GiftCardFilterQueryVariables> {
    document = GiftCardFilterDocument;
    
  }
export const GiftCardInfoDocument = gql`
    query giftCardInfo($id: UUID!) {
  giftCardInfo(id: $id) {
    id
    createdAt
    activatedBy
    code
    balance
    transactions {
      id
      createdAt
      updatedAt
      transactionType
      comment
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardInfoGQL extends Apollo.Query<GiftCardInfoQuery, GiftCardInfoQueryVariables> {
    document = GiftCardInfoDocument;
    
  }
export const GiftCardReloadDocument = gql`
    mutation GiftCardReload($amount: BigDecimal!, $id: UUID!) {
  giftCardReload(amount: $amount, id: $id) {
    id
    createdAt
    activatedBy
    code
    balance
    transactions {
      id
      createdAt
      updatedAt
      transactionType
      comment
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardReloadGQL extends Apollo.Mutation<GiftCardReloadMutation, GiftCardReloadMutationVariables> {
    document = GiftCardReloadDocument;
    
  }
export const GiftCardBalanceDocument = gql`
    query giftCardBalance($code: String!, $pin: String) {
  giftCardBalance(code: $code, pin: $pin)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardBalanceGQL extends Apollo.Query<GiftCardBalanceQuery, GiftCardBalanceQueryVariables> {
    document = GiftCardBalanceDocument;
    
  }
export const InventoryGetHistoryDocument = gql`
    mutation inventoryGetHistory($id: UUID!, $warehouse: String!, $days: Long!) {
  inventoryGetHistory(warehouse: $warehouse, id: $id, days: $days) {
    timestamp
    quantityOnShelf
    quantityAvailable
    changeQuantity
    changeType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InventoryGetHistoryGQL extends Apollo.Mutation<InventoryGetHistoryMutation, InventoryGetHistoryMutationVariables> {
    document = InventoryGetHistoryDocument;
    
  }
export const InventorySetDetailsDocument = gql`
    mutation inventorySetDetails($warehouse: String!, $id: UUID!, $quantity: Long!) {
  inventorySetDetails(warehouse: $warehouse, id: $id, quantity: $quantity) {
    product {
      ...ADMIN_SIMPLE_PRODUCT_ENTITY
    }
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InventorySetDetailsGQL extends Apollo.Mutation<InventorySetDetailsMutation, InventorySetDetailsMutationVariables> {
    document = InventorySetDetailsDocument;
    
  }
export const KitProductAddCrossSellProductsDocument = gql`
    mutation kitProductAddCrossSellProducts($id: UUID!, $productIds: [UUID]!) {
  kitProductAddCrossSellProducts(id: $id, productIds: $productIds) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddCrossSellProductsGQL extends Apollo.Mutation<KitProductAddCrossSellProductsMutation, KitProductAddCrossSellProductsMutationVariables> {
    document = KitProductAddCrossSellProductsDocument;
    
  }
export const KitProductAddImageDocument = gql`
    mutation kitProductAddImage($id: UUID!, $mediaId: UUID!) {
  kitProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddImageGQL extends Apollo.Mutation<KitProductAddImageMutation, KitProductAddImageMutationVariables> {
    document = KitProductAddImageDocument;
    
  }
export const KitProductAddKitItemDocument = gql`
    mutation kitProductAddKitItem($id: UUID!, $productId: UUID!, $quantity: Long!) {
  kitProductAddKitItem(id: $id, productId: $productId, quantity: $quantity) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddKitItemGQL extends Apollo.Mutation<KitProductAddKitItemMutation, KitProductAddKitItemMutationVariables> {
    document = KitProductAddKitItemDocument;
    
  }
export const KitProductAddPriceTierDocument = gql`
    mutation kitProductAddPriceTier($id: UUID!, $price: BigDecimal!, $quantity: BigDecimal!, $userType: UserType) {
  kitProductAddPriceTier(
    id: $id
    price: $price
    quantity: $quantity
    userType: $userType
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddPriceTierGQL extends Apollo.Mutation<KitProductAddPriceTierMutation, KitProductAddPriceTierMutationVariables> {
    document = KitProductAddPriceTierDocument;
    
  }
export const KitProductRemoveDocument = gql`
    mutation kitProductRemove($id: UUID!) {
  kitProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveGQL extends Apollo.Mutation<KitProductRemoveMutation, KitProductRemoveMutationVariables> {
    document = KitProductRemoveDocument;
    
  }
export const KitProductRemoveCrossSellProductDocument = gql`
    mutation kitProductRemoveCrossSellProduct($id: UUID!, $crossSellProductId: UUID!) {
  kitProductRemoveCrossSellProduct(
    id: $id
    crossSellProductId: $crossSellProductId
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveCrossSellProductGQL extends Apollo.Mutation<KitProductRemoveCrossSellProductMutation, KitProductRemoveCrossSellProductMutationVariables> {
    document = KitProductRemoveCrossSellProductDocument;
    
  }
export const KitProductRemoveKitItemDocument = gql`
    mutation kitProductRemoveKitItem($id: UUID!, $kitItemId: UUID!) {
  kitProductRemoveKitItem(id: $id, kitItemId: $kitItemId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveKitItemGQL extends Apollo.Mutation<KitProductRemoveKitItemMutation, KitProductRemoveKitItemMutationVariables> {
    document = KitProductRemoveKitItemDocument;
    
  }
export const KitProductRemovePriceTierDocument = gql`
    mutation kitProductRemovePriceTier($id: UUID!, $priceTierId: UUID!) {
  kitProductRemovePriceTier(id: $id, priceTierId: $priceTierId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemovePriceTierGQL extends Apollo.Mutation<KitProductRemovePriceTierMutation, KitProductRemovePriceTierMutationVariables> {
    document = KitProductRemovePriceTierDocument;
    
  }
export const KitProductRemoveProductMediaDocument = gql`
    mutation kitProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  kitProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveProductMediaGQL extends Apollo.Mutation<KitProductRemoveProductMediaMutation, KitProductRemoveProductMediaMutationVariables> {
    document = KitProductRemoveProductMediaDocument;
    
  }
export const KitProductSetActiveDocument = gql`
    mutation kitProductSetActive($id: UUID!, $active: Boolean!) {
  kitProductSetActive(id: $id, active: $active) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetActiveGQL extends Apollo.Mutation<KitProductSetActiveMutation, KitProductSetActiveMutationVariables> {
    document = KitProductSetActiveDocument;
    
  }
export const KitProductSetCostDocument = gql`
    mutation kitProductSetCost($id: UUID!, $cost: BigDecimal) {
  kitProductSetCost(id: $id, cost: $cost) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetCostGQL extends Apollo.Mutation<KitProductSetCostMutation, KitProductSetCostMutationVariables> {
    document = KitProductSetCostDocument;
    
  }
export const KitProductSetFeaturedDocument = gql`
    mutation kitProductSetFeatured($id: UUID!, $featured: Boolean!) {
  kitProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetFeaturedGQL extends Apollo.Mutation<KitProductSetFeaturedMutation, KitProductSetFeaturedMutationVariables> {
    document = KitProductSetFeaturedDocument;
    
  }
export const KitProductSetKitItemPositionDocument = gql`
    mutation kitProductSetKitItemPosition($id: UUID!, $kitItemId: UUID!, $position: Int!) {
  kitProductSetKitItemPosition(
    id: $id
    kitItemId: $kitItemId
    position: $position
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetKitItemPositionGQL extends Apollo.Mutation<KitProductSetKitItemPositionMutation, KitProductSetKitItemPositionMutationVariables> {
    document = KitProductSetKitItemPositionDocument;
    
  }
export const KitProductSetPriceDocument = gql`
    mutation kitProductSetPrice($id: UUID!, $price: BigDecimal!) {
  kitProductSetPrice(id: $id, price: $price) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetPriceGQL extends Apollo.Mutation<KitProductSetPriceMutation, KitProductSetPriceMutationVariables> {
    document = KitProductSetPriceDocument;
    
  }
export const KitProductSetProductMediaSequenceDocument = gql`
    mutation kitProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  kitProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetProductMediaSequenceGQL extends Apollo.Mutation<KitProductSetProductMediaSequenceMutation, KitProductSetProductMediaSequenceMutationVariables> {
    document = KitProductSetProductMediaSequenceDocument;
    
  }
export const KitProductSetPublishedRevisionDocument = gql`
    mutation kitProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  kitProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetPublishedRevisionGQL extends Apollo.Mutation<KitProductSetPublishedRevisionMutation, KitProductSetPublishedRevisionMutationVariables> {
    document = KitProductSetPublishedRevisionDocument;
    
  }
export const KitProductSetSkuDocument = gql`
    mutation kitProductSetSku($id: UUID!, $sku: String!) {
  kitProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSkuGQL extends Apollo.Mutation<KitProductSetSkuMutation, KitProductSetSkuMutationVariables> {
    document = KitProductSetSkuDocument;
    
  }
export const KitProductSetSlugDocument = gql`
    mutation kitProductSetSlug($id: UUID!, $slug: String!) {
  kitProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSlugGQL extends Apollo.Mutation<KitProductSetSlugMutation, KitProductSetSlugMutationVariables> {
    document = KitProductSetSlugDocument;
    
  }
export const KitProductSetSpecialPriceDocument = gql`
    mutation kitProductSetSpecialPrice($id: UUID!, $specialPrice: BigDecimal) {
  kitProductSetSpecialPrice(id: $id, specialPrice: $specialPrice) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSpecialPriceGQL extends Apollo.Mutation<KitProductSetSpecialPriceMutation, KitProductSetSpecialPriceMutationVariables> {
    document = KitProductSetSpecialPriceDocument;
    
  }
export const KitProductSetTitleDocument = gql`
    mutation kitProductSetTitle($id: UUID!, $title: String!) {
  kitProductSetTitle(id: $id, title: $title) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetTitleGQL extends Apollo.Mutation<KitProductSetTitleMutation, KitProductSetTitleMutationVariables> {
    document = KitProductSetTitleDocument;
    
  }
export const KitProductSetUpcDocument = gql`
    mutation kitProductSetUpc($id: UUID!, $upc: String) {
  kitProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetUpcGQL extends Apollo.Mutation<KitProductSetUpcMutation, KitProductSetUpcMutationVariables> {
    document = KitProductSetUpcDocument;
    
  }
export const KitProductSetVisibleDocument = gql`
    mutation kitProductSetVisible($id: UUID!, $visible: Boolean!) {
  kitProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetVisibleGQL extends Apollo.Mutation<KitProductSetVisibleMutation, KitProductSetVisibleMutationVariables> {
    document = KitProductSetVisibleDocument;
    
  }
export const KitProductSetWholesalePriceDocument = gql`
    mutation kitProductSetWholesalePrice($id: UUID!, $wholesalePrice: BigDecimal) {
  kitProductSetWholesalePrice(id: $id, wholesalePrice: $wholesalePrice) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetWholesalePriceGQL extends Apollo.Mutation<KitProductSetWholesalePriceMutation, KitProductSetWholesalePriceMutationVariables> {
    document = KitProductSetWholesalePriceDocument;
    
  }
export const KitProductUploadImageDocument = gql`
    mutation kitProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  kitProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductUploadImageGQL extends Apollo.Mutation<KitProductUploadImageMutation, KitProductUploadImageMutationVariables> {
    document = KitProductUploadImageDocument;
    
  }
export const KitProductWithCrossSellsDocument = gql`
    query kitProductWithCrossSells($slug: String!) {
  kitProductBySlug(slug: $slug) {
    id
    cls
    sku
    slug
    title
    quantityAvailableForSale
    price
    salePrice
    medias {
      id
      mediaType
      url
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
    }
    crossSells {
      id
      cls
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductWithCrossSellsGQL extends Apollo.Query<KitProductWithCrossSellsQuery, KitProductWithCrossSellsQueryVariables> {
    document = KitProductWithCrossSellsDocument;
    
  }
export const KitProductBySlugDocument = gql`
    query kitProductBySlug($slug: String!) {
  kitProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    quantityAvailableForSale
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    shippingNeeds
    kitItems {
      id
      quantity
      simpleProduct {
        id
        cls
        sku
        slug
        title
        price
        salePrice
        averageRating
        countRating
        quantityAvailableForSale
        medias {
          url
        }
      }
    }
    tieredPrices {
      id
      quantity
      price
    }
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
      destinationRestriction {
        domesticOnly
        prohibitedStates
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductBySlugGQL extends Apollo.Query<KitProductBySlugQuery, KitProductBySlugQueryVariables> {
    document = KitProductBySlugDocument;
    
  }
export const KitProductFilterDocument = gql`
    query kitProductFilter($active: Boolean, $pageable: GraphQLPageableInput!, $category: String, $sku: String, $title: String, $sort: GraphQLSortInput) {
  kitProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    category: $category
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      featured
      visible
      quantityAvailableForSale
      price
      specialPrice
      wholesalePrice
      cost
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductFilterGQL extends Apollo.Query<KitProductFilterQuery, KitProductFilterQueryVariables> {
    document = KitProductFilterDocument;
    
  }
export const KitProductInfoDocument = gql`
    query kitProductInfo($id: UUID!) {
  kitProductInfo(id: $id) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductInfoGQL extends Apollo.Query<KitProductInfoQuery, KitProductInfoQueryVariables> {
    document = KitProductInfoDocument;
    
  }
export const MakingStockStatusDocument = gql`
    mutation makingStockStatus($warehouse: String!) {
  makingStockStatus(warehouse: $warehouse) {
    product {
      id
      title
      sku
      medias {
        id
        mediaType
        url
      }
    }
    warehouseQuantityAvailable
    warehouseWeeklyConsumptionRate
    warehouseWeeklyConsumptionVariance
    warehouseRunOutDays50
    warehouseRunOutDays85
    warehouseRunOutDays97
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MakingStockStatusGQL extends Apollo.Mutation<MakingStockStatusMutation, MakingStockStatusMutationVariables> {
    document = MakingStockStatusDocument;
    
  }
export const MarketingSubscribeDocument = gql`
    mutation marketingSubscribe($email: String!) {
  marketingSubscribe(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarketingSubscribeGQL extends Apollo.Mutation<MarketingSubscribeMutation, MarketingSubscribeMutationVariables> {
    document = MarketingSubscribeDocument;
    
  }
export const MediaFilterDocument = gql`
    query mediaFilter($title: String, $keywords: String, $pageable: GraphQLPageableInput!) {
  mediaFilter(title: $title, page: $pageable, keywords: $keywords) {
    data {
      id
      keywords
      title
      url
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaFilterGQL extends Apollo.Query<MediaFilterQuery, MediaFilterQueryVariables> {
    document = MediaFilterDocument;
    
  }
export const MediaInfoDocument = gql`
    query mediaInfo($id: UUID!) {
  mediaInfo(id: $id) {
    id
    keywords
    title
    url
    categories {
      id
      name
      ancestors {
        id
        name
      }
    }
    productMedias {
      id
      product {
        id
        cls
        sku
        title
        medias {
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaInfoGQL extends Apollo.Query<MediaInfoQuery, MediaInfoQueryVariables> {
    document = MediaInfoDocument;
    
  }
export const MediaUploadImageDocument = gql`
    mutation mediaUploadImage($dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  mediaUploadImage(
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    id
    keywords
    title
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaUploadImageGQL extends Apollo.Mutation<MediaUploadImageMutation, MediaUploadImageMutationVariables> {
    document = MediaUploadImageDocument;
    
  }
export const AffiliateAddProductListDocument = gql`
    mutation affiliateAddProductList($id: UUID!, $name: String!, $slug: String!, $htmlContent: String!) {
  affiliateAddProductList(
    id: $id
    name: $name
    slug: $slug
    htmlContent: $htmlContent
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateAddProductListGQL extends Apollo.Mutation<AffiliateAddProductListMutation, AffiliateAddProductListMutationVariables> {
    document = AffiliateAddProductListDocument;
    
  }
export const AffiliateAddProductListItemsDocument = gql`
    mutation affiliateAddProductListItems($listId: UUID!, $productIds: [UUID]!) {
  affiliateAddProductListItems(listId: $listId, productIds: $productIds) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateAddProductListItemsGQL extends Apollo.Mutation<AffiliateAddProductListItemsMutation, AffiliateAddProductListItemsMutationVariables> {
    document = AffiliateAddProductListItemsDocument;
    
  }
export const AffiliateBySlugDocument = gql`
    query affiliateBySlug($slug: String!) {
  affiliateBySlug(slug: $slug) {
    affiliateAttributionToken
    affiliateBiography
    name
    slug
    affiliateProductLists {
      name
      slug
      affiliateProductListItems {
        product {
          medias {
            url
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateBySlugGQL extends Apollo.Query<AffiliateBySlugQuery, AffiliateBySlugQueryVariables> {
    document = AffiliateBySlugDocument;
    
  }
export const AffiliateDeleteProductListDocument = gql`
    mutation affiliateDeleteProductList($id: UUID!, $listId: UUID!) {
  affiliateDeleteProductList(id: $id, listId: $listId) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateDeleteProductListGQL extends Apollo.Mutation<AffiliateDeleteProductListMutation, AffiliateDeleteProductListMutationVariables> {
    document = AffiliateDeleteProductListDocument;
    
  }
export const AffiliateGetProductListByIdDocument = gql`
    query affiliateGetProductListById($id: UUID!) {
  affiliateGetProductListById(id: $id) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateGetProductListByIdGQL extends Apollo.Query<AffiliateGetProductListByIdQuery, AffiliateGetProductListByIdQueryVariables> {
    document = AffiliateGetProductListByIdDocument;
    
  }
export const AffiliateGetProductListBySlugDocument = gql`
    query affiliateGetProductListBySlug($slug: String!, $affiliateSlug: String!) {
  affiliateGetProductListBySlug(slug: $slug, affiliateSlug: $affiliateSlug) {
    htmlContent
    name
    slug
    affiliateProductListItems {
      product {
        cls
        price
        salePrice
        sku
        slug
        title
        quantityAvailableForSale
        medias {
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateGetProductListBySlugGQL extends Apollo.Query<AffiliateGetProductListBySlugQuery, AffiliateGetProductListBySlugQueryVariables> {
    document = AffiliateGetProductListBySlugDocument;
    
  }
export const AffiliateRemoveProductListItemDocument = gql`
    mutation affiliateRemoveProductListItem($listId: UUID!, $affiliateProductListItemId: UUID!) {
  affiliateRemoveProductListItem(
    listId: $listId
    affiliateProductListItemId: $affiliateProductListItemId
  ) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateRemoveProductListItemGQL extends Apollo.Mutation<AffiliateRemoveProductListItemMutation, AffiliateRemoveProductListItemMutationVariables> {
    document = AffiliateRemoveProductListItemDocument;
    
  }
export const AffiliateSetAffiliateBiographyDocument = gql`
    mutation affiliateSetAffiliateBiography($id: UUID!, $affiliateBiography: String!) {
  affiliateSetAffiliateBiography(id: $id, affiliateBiography: $affiliateBiography) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetAffiliateBiographyGQL extends Apollo.Mutation<AffiliateSetAffiliateBiographyMutation, AffiliateSetAffiliateBiographyMutationVariables> {
    document = AffiliateSetAffiliateBiographyDocument;
    
  }
export const AffiliateSetAttributionPeriodDocument = gql`
    mutation affiliateSetAttributionPeriod($id: UUID!, $period: Long!) {
  affiliateSetAttributionPeriod(id: $id, period: $period) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetAttributionPeriodGQL extends Apollo.Mutation<AffiliateSetAttributionPeriodMutation, AffiliateSetAttributionPeriodMutationVariables> {
    document = AffiliateSetAttributionPeriodDocument;
    
  }
export const AffiliateSetCommissionDocument = gql`
    mutation affiliateSetCommission($id: UUID!, $commission: BigDecimal!) {
  affiliateSetCommission(id: $id, commission: $commission) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetCommissionGQL extends Apollo.Mutation<AffiliateSetCommissionMutation, AffiliateSetCommissionMutationVariables> {
    document = AffiliateSetCommissionDocument;
    
  }
export const AffiliateSetProductListHtmlContentDocument = gql`
    mutation affiliateSetProductListHtmlContent($listId: UUID!, $htmlContent: String!) {
  affiliateSetProductListHtmlContent(listId: $listId, htmlContent: $htmlContent) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListHtmlContentGQL extends Apollo.Mutation<AffiliateSetProductListHtmlContentMutation, AffiliateSetProductListHtmlContentMutationVariables> {
    document = AffiliateSetProductListHtmlContentDocument;
    
  }
export const AffiliateSetProductListItemPositionDocument = gql`
    mutation affiliateSetProductListItemPosition($listId: UUID!, $affiliateProductListItemId: UUID!, $position: Int!) {
  affiliateSetProductListItemPosition(
    listId: $listId
    affiliateProductListItemId: $affiliateProductListItemId
    position: $position
  ) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListItemPositionGQL extends Apollo.Mutation<AffiliateSetProductListItemPositionMutation, AffiliateSetProductListItemPositionMutationVariables> {
    document = AffiliateSetProductListItemPositionDocument;
    
  }
export const AffiliateSetProductListNameDocument = gql`
    mutation affiliateSetProductListName($listId: UUID!, $name: String!) {
  affiliateSetProductListName(listId: $listId, name: $name) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListNameGQL extends Apollo.Mutation<AffiliateSetProductListNameMutation, AffiliateSetProductListNameMutationVariables> {
    document = AffiliateSetProductListNameDocument;
    
  }
export const AffiliateSetProductListSequenceDocument = gql`
    mutation affiliateSetProductListSequence($id: UUID!, $listId: UUID!, $sequence: Int!) {
  affiliateSetProductListSequence(id: $id, listId: $listId, sequence: $sequence) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListSequenceGQL extends Apollo.Mutation<AffiliateSetProductListSequenceMutation, AffiliateSetProductListSequenceMutationVariables> {
    document = AffiliateSetProductListSequenceDocument;
    
  }
export const AffiliateSetProductListSlugDocument = gql`
    mutation affiliateSetProductListSlug($listId: UUID!, $slug: String!) {
  affiliateSetProductListSlug(listId: $listId, slug: $slug) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListSlugGQL extends Apollo.Mutation<AffiliateSetProductListSlugMutation, AffiliateSetProductListSlugMutationVariables> {
    document = AffiliateSetProductListSlugDocument;
    
  }
export const AffiliateSetSlugDocument = gql`
    mutation affiliateSetSlug($id: UUID!, $slug: String!) {
  affiliateSetSlug(id: $id, slug: $slug) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetSlugGQL extends Apollo.Mutation<AffiliateSetSlugMutation, AffiliateSetSlugMutationVariables> {
    document = AffiliateSetSlugDocument;
    
  }
export const PartnerJoinDocument = gql`
    mutation partnerJoin($firstName: String, $lastName: String, $company: String, $email: String!, $phone: String, $message: String) {
  partnerJoin(
    firstName: $firstName
    lastName: $lastName
    company: $company
    email: $email
    phone: $phone
    message: $message
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerJoinGQL extends Apollo.Mutation<PartnerJoinMutation, PartnerJoinMutationVariables> {
    document = PartnerJoinDocument;
    
  }
export const PartnerCreateDocument = gql`
    mutation partnerCreate($email: String!, $name: String!) {
  partnerCreate(email: $email, name: $name) {
    id
    affiliate
    dropshipper
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerCreateGQL extends Apollo.Mutation<PartnerCreateMutation, PartnerCreateMutationVariables> {
    document = PartnerCreateDocument;
    
  }
export const PartnerFilterDocument = gql`
    query partnerFilter($pageable: GraphQLPageableInput!) {
  partnerFilter(page: $pageable) {
    data {
      id
      affiliate
      dropshipper
      email
      name
      reseller
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerFilterGQL extends Apollo.Query<PartnerFilterQuery, PartnerFilterQueryVariables> {
    document = PartnerFilterDocument;
    
  }
export const PartnerInfoDocument = gql`
    query partnerInfo($id: UUID!) {
  partnerInfo(id: $id) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerInfoGQL extends Apollo.Query<PartnerInfoQuery, PartnerInfoQueryVariables> {
    document = PartnerInfoDocument;
    
  }
export const PartnerListDropshippersDocument = gql`
    query partnerListDropshippers {
  partnerListDropshippers {
    id
    affiliate
    dropshipper
    email
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerListDropshippersGQL extends Apollo.Query<PartnerListDropshippersQuery, PartnerListDropshippersQueryVariables> {
    document = PartnerListDropshippersDocument;
    
  }
export const PartnerListResellersDocument = gql`
    query partnerListResellers {
  partnerListResellers {
    id
    affiliate
    dropshipper
    email
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerListResellersGQL extends Apollo.Query<PartnerListResellersQuery, PartnerListResellersQueryVariables> {
    document = PartnerListResellersDocument;
    
  }
export const PartnerSetAffiliateDocument = gql`
    mutation partnerSetAffiliate($id: UUID!, $affiliate: Boolean!) {
  partnerSetAffiliate(id: $id, affiliate: $affiliate) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetAffiliateGQL extends Apollo.Mutation<PartnerSetAffiliateMutation, PartnerSetAffiliateMutationVariables> {
    document = PartnerSetAffiliateDocument;
    
  }
export const PartnerSetDropshipperDocument = gql`
    mutation partnerSetDropshipper($id: UUID!, $dropshipper: Boolean!) {
  partnerSetDropshipper(id: $id, dropshipper: $dropshipper) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetDropshipperGQL extends Apollo.Mutation<PartnerSetDropshipperMutation, PartnerSetDropshipperMutationVariables> {
    document = PartnerSetDropshipperDocument;
    
  }
export const PartnerSetEmailDocument = gql`
    mutation partnerSetEmail($id: UUID!, $email: String!) {
  partnerSetEmail(id: $id, email: $email) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetEmailGQL extends Apollo.Mutation<PartnerSetEmailMutation, PartnerSetEmailMutationVariables> {
    document = PartnerSetEmailDocument;
    
  }
export const PartnerSetNameDocument = gql`
    mutation partnerSetName($id: UUID!, $name: String!) {
  partnerSetName(id: $id, name: $name) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetNameGQL extends Apollo.Mutation<PartnerSetNameMutation, PartnerSetNameMutationVariables> {
    document = PartnerSetNameDocument;
    
  }
export const PartnerSetResellerDocument = gql`
    mutation partnerSetReseller($id: UUID!, $reseller: Boolean!) {
  partnerSetReseller(id: $id, reseller: $reseller) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetResellerGQL extends Apollo.Mutation<PartnerSetResellerMutation, PartnerSetResellerMutationVariables> {
    document = PartnerSetResellerDocument;
    
  }
export const PartnerUpdateAddressDocument = gql`
    mutation partnerUpdateAddress($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  partnerUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateAddressGQL extends Apollo.Mutation<PartnerUpdateAddressMutation, PartnerUpdateAddressMutationVariables> {
    document = PartnerUpdateAddressDocument;
    
  }
export const ResellerSetResellerShippingRateModeDocument = gql`
    mutation resellerSetResellerShippingRateMode($id: UUID!, $resellerShippingRateMode: ResellerShippingRateMode!) {
  resellerSetResellerShippingRateMode(
    id: $id
    resellerShippingRateMode: $resellerShippingRateMode
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerSetResellerShippingRateModeGQL extends Apollo.Mutation<ResellerSetResellerShippingRateModeMutation, ResellerSetResellerShippingRateModeMutationVariables> {
    document = ResellerSetResellerShippingRateModeDocument;
    
  }
export const ResellerSetResellerTaxModeDocument = gql`
    mutation resellerSetResellerTaxMode($id: UUID!, $resellerTaxMode: ResellerTaxMode!) {
  resellerSetResellerTaxMode(id: $id, resellerTaxMode: $resellerTaxMode) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerSetResellerTaxModeGQL extends Apollo.Mutation<ResellerSetResellerTaxModeMutation, ResellerSetResellerTaxModeMutationVariables> {
    document = ResellerSetResellerTaxModeDocument;
    
  }
export const DropshipperProfileCreateDocument = gql`
    mutation dropshipperProfileCreate($simpleProductId: UUID!, $dropshipperId: UUID!, $sku: String!, $title: String!, $cost: BigDecimal!) {
  dropshipperProfileCreate(
    simpleProductId: $simpleProductId
    dropshipperId: $dropshipperId
    sku: $sku
    title: $title
    cost: $cost
  ) {
    id
    dropshipperSku
    title
    cost
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DropshipperProfileCreateGQL extends Apollo.Mutation<DropshipperProfileCreateMutation, DropshipperProfileCreateMutationVariables> {
    document = DropshipperProfileCreateDocument;
    
  }
export const PetcoDsProfileCreateDocument = gql`
    mutation petcoDsProfileCreate($productId: UUID!, $sku: String!, $description: String!, $surcharge: BigDecimal!) {
  petcoDsProfileCreate(
    productId: $productId
    sku: $sku
    description: $description
    surcharge: $surcharge
  ) {
    id
    description
    sku
    surcharge
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PetcoDsProfileCreateGQL extends Apollo.Mutation<PetcoDsProfileCreateMutation, PetcoDsProfileCreateMutationVariables> {
    document = PetcoDsProfileCreateDocument;
    
  }
export const ResellerProfileCreateDocument = gql`
    mutation resellerProfileCreate($productId: UUID!, $resellerId: UUID!, $sku: String!, $title: String!, $price: BigDecimal!, $retail: BigDecimal) {
  resellerProfileCreate(
    productId: $productId
    resellerId: $resellerId
    sku: $sku
    title: $title
    price: $price
    retail: $retail
  ) {
    id
    resellerSku
    title
    price
    retail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerProfileCreateGQL extends Apollo.Mutation<ResellerProfileCreateMutation, ResellerProfileCreateMutationVariables> {
    document = ResellerProfileCreateDocument;
    
  }
export const ProductAddCategoryDocument = gql`
    mutation productAddCategory($productId: UUID!, $categoryId: UUID!) {
  productAddCategory(productId: $productId, categoryId: $categoryId) {
    id
    explicitCategories {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAddCategoryGQL extends Apollo.Mutation<ProductAddCategoryMutation, ProductAddCategoryMutationVariables> {
    document = ProductAddCategoryDocument;
    
  }
export const ProductBySlugDocument = gql`
    query productBySlug($slug: String!) {
  productBySlug(slug: $slug) {
    id
    cls
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductBySlugGQL extends Apollo.Query<ProductBySlugQuery, ProductBySlugQueryVariables> {
    document = ProductBySlugDocument;
    
  }
export const ProductFilterDocument = gql`
    query productFilter($pageable: GraphQLPageableInput!, $active: Boolean, $animal: String, $category: String, $department: String, $minRating: Long, $minStock: Long, $sku: String, $subscription: Boolean, $title: String, $upc: String, $visible: Boolean) {
  productFilter(
    page: $pageable
    active: $active
    animal: $animal
    category: $category
    department: $department
    minRating: $minRating
    minStock: $minStock
    sku: $sku
    subscription: $subscription
    title: $title
    upc: $upc
    visible: $visible
  ) {
    data {
      id
      cls
      slug
      title
      sku
      upc
      price
      quantityAvailableForSale
      subscription
      shippingRuleSet {
        liveArrivalGuarantees {
          id
        }
      }
      medias {
        id
        mediaType
        url
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductFilterGQL extends Apollo.Query<ProductFilterQuery, ProductFilterQueryVariables> {
    document = ProductFilterDocument;
    
  }
export const ProductListBySkusDocument = gql`
    query productListBySkus($skus: [String]!) {
  productListBySkus(skus: $skus) {
    id
    cls
    sku
    title
    slug
    price
    medias {
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductListBySkusGQL extends Apollo.Query<ProductListBySkusQuery, ProductListBySkusQueryVariables> {
    document = ProductListBySkusDocument;
    
  }
export const ProductRemoveCategoryDocument = gql`
    mutation productRemoveCategory($productId: UUID!, $categoryId: UUID!) {
  productRemoveCategory(productId: $productId, categoryId: $categoryId) {
    id
    explicitCategories {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductRemoveCategoryGQL extends Apollo.Mutation<ProductRemoveCategoryMutation, ProductRemoveCategoryMutationVariables> {
    document = ProductRemoveCategoryDocument;
    
  }
export const ProductRequestBackInStockNotificationDocument = gql`
    mutation productRequestBackInStockNotification($id: UUID!, $email: String!) {
  productRequestBackInStockNotification(id: $id, email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductRequestBackInStockNotificationGQL extends Apollo.Mutation<ProductRequestBackInStockNotificationMutation, ProductRequestBackInStockNotificationMutationVariables> {
    document = ProductRequestBackInStockNotificationDocument;
    
  }
export const ProductSearchDocument = gql`
    query productSearch($pageable: GraphQLPageableInput!, $query: String, $category: String, $discount: String, $promotion: String, $animal: String, $brand: String, $featured: Boolean, $inStock: Boolean, $subscription: Boolean, $minPrice: BigDecimal, $maxPrice: BigDecimal, $minRating: Float, $sort: GraphQLSortInput) {
  productSearch(
    page: $pageable
    query: $query
    category: $category
    discount: $discount
    promotion: $promotion
    animal: $animal
    brand: $brand
    featured: {value: $featured}
    inStock: {value: $inStock}
    subscription: {value: $subscription}
    price: {from: $minPrice, to: $maxPrice}
    rating: {from: $minRating, to: null}
    sort: $sort
  ) {
    category {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        children {
          id
          slug
          path
          name
        }
      }
      children {
        id
        active
        slug
        path
        name
      }
    }
    animal {
      id
      slug
      name
      parent {
        id
        slug
        name
        children {
          id
          slug
          name
        }
      }
      children {
        id
        slug
        name
      }
    }
    brand {
      id
      slug
      name
    }
    products {
      data {
        id
        cls
        slug
        sku
        title
        quantityAvailableForSale
        price
        salePrice
        averageRating
        countRating
        featured
        medias {
          id
          mediaType
          url
        }
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductSearchGQL extends Apollo.Query<ProductSearchQuery, ProductSearchQueryVariables> {
    document = ProductSearchDocument;
    
  }
export const ProductSetDepartmentDocument = gql`
    mutation productSetDepartment($productId: UUID!, $departmentId: UUID!) {
  productSetDepartment(productId: $productId, departmentId: $departmentId) {
    id
    explicitDepartment {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductSetDepartmentGQL extends Apollo.Mutation<ProductSetDepartmentMutation, ProductSetDepartmentMutationVariables> {
    document = ProductSetDepartmentDocument;
    
  }
export const PromotionBySlugDocument = gql`
    query promotionBySlug($slug: String!) {
  promotionBySlug(slug: $slug) {
    ...CUSTOMER_PROMOTION
  }
}
    ${Customer_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionBySlugGQL extends Apollo.Query<PromotionBySlugQuery, PromotionBySlugQueryVariables> {
    document = PromotionBySlugDocument;
    
  }
export const PromotionEvaluateImplicitProductsDocument = gql`
    mutation promotionEvaluateImplicitProducts($id: UUID!) {
  promotionEvaluateImplicitProducts(id: $id) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionEvaluateImplicitProductsGQL extends Apollo.Mutation<PromotionEvaluateImplicitProductsMutation, PromotionEvaluateImplicitProductsMutationVariables> {
    document = PromotionEvaluateImplicitProductsDocument;
    
  }
export const PromotionAddExplicitProductsDocument = gql`
    mutation promotionAddExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  promotionAddExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionAddExplicitProductsGQL extends Apollo.Mutation<PromotionAddExplicitProductsMutation, PromotionAddExplicitProductsMutationVariables> {
    document = PromotionAddExplicitProductsDocument;
    
  }
export const PromotionCreateDocument = gql`
    mutation promotionCreate($name: String!, $slug: String!, $promotionType: PromotionType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $eligibleItemValue: BigDecimal, $eligibleItemCount: Long, $discountAmount: BigDecimal) {
  promotionCreate(
    name: $name
    slug: $slug
    promotionType: $promotionType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    eligibleItemValue: $eligibleItemValue
    eligibleItemCount: $eligibleItemCount
    discountAmount: $discountAmount
  ) {
    id
    active
    discountAmount
    promotionType
    eligibleCartValue
    eligibleCouponCode
    eligibleItemCount
    eligibleItemValue
    eligibleUserType
    endDate
    name
    permitStacking
    slug
    startDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionCreateGQL extends Apollo.Mutation<PromotionCreateMutation, PromotionCreateMutationVariables> {
    document = PromotionCreateDocument;
    
  }
export const PromotionDeleteDocument = gql`
    mutation promotionDelete($id: UUID!) {
  promotionDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionDeleteGQL extends Apollo.Mutation<PromotionDeleteMutation, PromotionDeleteMutationVariables> {
    document = PromotionDeleteDocument;
    
  }
export const PromotionInfoDocument = gql`
    query promotionInfo($id: UUID!) {
  promotionInfo(id: $id) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionInfoGQL extends Apollo.Query<PromotionInfoQuery, PromotionInfoQueryVariables> {
    document = PromotionInfoDocument;
    
  }
export const PromotionListDocument = gql`
    query promotionList($pageable: GraphQLPageableInput) {
  promotionList(page: $pageable) {
    count
    page
    pageSize
    data {
      id
      active
      discountAmount
      promotionType
      eligibleCartValue
      eligibleCouponCode
      eligibleItemCount
      eligibleItemValue
      eligibleUserType
      endDate
      name
      permitStacking
      slug
      startDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionListGQL extends Apollo.Query<PromotionListQuery, PromotionListQueryVariables> {
    document = PromotionListDocument;
    
  }
export const PromotionRemoveExplicitProductsDocument = gql`
    mutation promotionRemoveExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  promotionRemoveExplicitProducts(
    id: $id
    explicitProductIds: $explicitProductIds
  ) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRemoveExplicitProductsGQL extends Apollo.Mutation<PromotionRemoveExplicitProductsMutation, PromotionRemoveExplicitProductsMutationVariables> {
    document = PromotionRemoveExplicitProductsDocument;
    
  }
export const PromotionSetActiveDocument = gql`
    mutation promotionSetActive($id: UUID!, $active: Boolean!) {
  promotionSetActive(id: $id, active: $active) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetActiveGQL extends Apollo.Mutation<PromotionSetActiveMutation, PromotionSetActiveMutationVariables> {
    document = PromotionSetActiveDocument;
    
  }
export const PromotionUpdateDocument = gql`
    mutation promotionUpdate($id: UUID!, $name: String!, $slug: String!, $promotionType: PromotionType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $eligibleItemValue: BigDecimal, $eligibleItemCount: Long, $discountAmount: BigDecimal, $htmlContent: String, $teaser: String) {
  promotionUpdate(
    id: $id
    name: $name
    slug: $slug
    promotionType: $promotionType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    eligibleItemValue: $eligibleItemValue
    eligibleItemCount: $eligibleItemCount
    discountAmount: $discountAmount
    htmlContent: $htmlContent
    teaser: $teaser
  ) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionUpdateGQL extends Apollo.Mutation<PromotionUpdateMutation, PromotionUpdateMutationVariables> {
    document = PromotionUpdateDocument;
    
  }
export const PurchaseOrderCloseDocument = gql`
    mutation purchaseOrderClose($id: UUID!) {
  purchaseOrderClose(id: $id) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderCloseGQL extends Apollo.Mutation<PurchaseOrderCloseMutation, PurchaseOrderCloseMutationVariables> {
    document = PurchaseOrderCloseDocument;
    
  }
export const PurchaseOrderDeleteItemDocument = gql`
    mutation purchaseOrderDeleteItem($purchaseOrderId: UUID!, $purchaseOrderItemId: UUID!) {
  purchaseOrderDeleteItem(
    purchaseOrderId: $purchaseOrderId
    purchaseOrderItemId: $purchaseOrderItemId
  ) {
    id
    purchaseOrderItems {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderDeleteItemGQL extends Apollo.Mutation<PurchaseOrderDeleteItemMutation, PurchaseOrderDeleteItemMutationVariables> {
    document = PurchaseOrderDeleteItemDocument;
    
  }
export const PurchaseOrderEditItemQtyDocument = gql`
    mutation purchaseOrderEditItemQty($purchaseOrderId: UUID!, $purchaseOrderItemId: UUID!, $quantity: Long!) {
  purchaseOrderEditItemQty(
    purchaseOrderId: $purchaseOrderId
    purchaseOrderItemId: $purchaseOrderItemId
    quantity: $quantity
  ) {
    id
    purchaseOrderItems {
      id
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderEditItemQtyGQL extends Apollo.Mutation<PurchaseOrderEditItemQtyMutation, PurchaseOrderEditItemQtyMutationVariables> {
    document = PurchaseOrderEditItemQtyDocument;
    
  }
export const PurchaseOrderFilterDocument = gql`
    query purchaseOrderFilter($orderNumber: String, $supplier: String, $status: PurchaseOrderStatus, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  purchaseOrderFilter(
    orderNumber: {pattern: $orderNumber}
    status: $status
    supplier: $supplier
    sort: $sort
    page: $pageable
  ) {
    count
    page
    pageSize
    data {
      id
      cls
      placedTime
      orderNumber
      status
      supplier {
        name
      }
      warehouse {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderFilterGQL extends Apollo.Query<PurchaseOrderFilterQuery, PurchaseOrderFilterQueryVariables> {
    document = PurchaseOrderFilterDocument;
    
  }
export const PurchaseOrderGenerateCsvDocument = gql`
    mutation purchaseOrderGenerateCSV($id: UUID!) {
  purchaseOrderGenerateCSV(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderGenerateCsvGQL extends Apollo.Mutation<PurchaseOrderGenerateCsvMutation, PurchaseOrderGenerateCsvMutationVariables> {
    document = PurchaseOrderGenerateCsvDocument;
    
  }
export const PurchaseOrderGeneratePdfDocument = gql`
    mutation purchaseOrderGeneratePDF($id: UUID!) {
  purchaseOrderGeneratePDF(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderGeneratePdfGQL extends Apollo.Mutation<PurchaseOrderGeneratePdfMutation, PurchaseOrderGeneratePdfMutationVariables> {
    document = PurchaseOrderGeneratePdfDocument;
    
  }
export const PurchaseOrderInfoDocument = gql`
    query purchaseOrderInfo($id: UUID!) {
  purchaseOrderInfo(id: $id) {
    cls
    id
    orderNumber
    alternateOrderNumber
    placedTime
    expectedTime
    status
    purchaseOrderItems {
      cls
      id
      name
      sku
      received
      total
      simpleProduct {
        id
      }
    }
    supplier {
      name
      phone
      email
      firstName
      lastName
      company
      line1
      line2
      state
      postalCode
      country
    }
    warehouse {
      name
      line1
      line2
      state
      postalCode
      country
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderInfoGQL extends Apollo.Query<PurchaseOrderInfoQuery, PurchaseOrderInfoQueryVariables> {
    document = PurchaseOrderInfoDocument;
    
  }
export const PurchaseOrderReOpenDocument = gql`
    mutation purchaseOrderReOpen($id: UUID!) {
  purchaseOrderReOpen(id: $id) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderReOpenGQL extends Apollo.Mutation<PurchaseOrderReOpenMutation, PurchaseOrderReOpenMutationVariables> {
    document = PurchaseOrderReOpenDocument;
    
  }
export const PurchaseRequestApproveDocument = gql`
    mutation purchaseRequestApprove($id: UUID!) {
  purchaseRequestApprove(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestApproveGQL extends Apollo.Mutation<PurchaseRequestApproveMutation, PurchaseRequestApproveMutationVariables> {
    document = PurchaseRequestApproveDocument;
    
  }
export const PurchaseRequestCreateDocument = gql`
    mutation purchaseRequestCreate($team: String!, $supplier: String, $comments: String, $items: [PurchaseRequestItemInput]) {
  purchaseRequestCreate(
    team: $team
    supplier: $supplier
    comments: $comments
    items: $items
  ) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestCreateGQL extends Apollo.Mutation<PurchaseRequestCreateMutation, PurchaseRequestCreateMutationVariables> {
    document = PurchaseRequestCreateDocument;
    
  }
export const PurchaseRequestDenyDocument = gql`
    mutation purchaseRequestDeny($id: UUID!) {
  purchaseRequestDeny(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestDenyGQL extends Apollo.Mutation<PurchaseRequestDenyMutation, PurchaseRequestDenyMutationVariables> {
    document = PurchaseRequestDenyDocument;
    
  }
export const PurchaseRequestFilterDocument = gql`
    query purchaseRequestFilter($requestNumber: String, $pageable: GraphQLPageableInput!, $team: String, $status: PurchaseRequestStatus, $sort: GraphQLSortInput) {
  purchaseRequestFilter(
    requestNumber: {pattern: $requestNumber}
    team: $team
    status: $status
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      requestNumber
      purchaseRequestStatus
      team {
        id
        name
        slug
      }
      requester
      approver
      orderer
      receiver
      supplier
      requestedDate
      orderedDate
      receivedDate
      estimatedAmount
      finalAmount
      comments
      purchaseRequestItems {
        id
        quantity
        name
        amount
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestFilterGQL extends Apollo.Query<PurchaseRequestFilterQuery, PurchaseRequestFilterQueryVariables> {
    document = PurchaseRequestFilterDocument;
    
  }
export const PurchaseRequestInfoDocument = gql`
    query purchaseRequestInfo($id: UUID!) {
  purchaseRequestInfo(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestInfoGQL extends Apollo.Query<PurchaseRequestInfoQuery, PurchaseRequestInfoQueryVariables> {
    document = PurchaseRequestInfoDocument;
    
  }
export const PurchaseRequestOrderDocument = gql`
    mutation purchaseRequestOrder($id: UUID!, $amount: BigDecimal!) {
  purchaseRequestOrder(id: $id, amount: $amount) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestOrderGQL extends Apollo.Mutation<PurchaseRequestOrderMutation, PurchaseRequestOrderMutationVariables> {
    document = PurchaseRequestOrderDocument;
    
  }
export const PurchaseRequestReceiveDocument = gql`
    mutation purchaseRequestReceive($id: UUID!) {
  purchaseRequestReceive(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestReceiveGQL extends Apollo.Mutation<PurchaseRequestReceiveMutation, PurchaseRequestReceiveMutationVariables> {
    document = PurchaseRequestReceiveDocument;
    
  }
export const RedirectFindByPathDocument = gql`
    mutation redirectFindByPath($path: String!) {
  redirectFindByPath(path: $path)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RedirectFindByPathGQL extends Apollo.Mutation<RedirectFindByPathMutation, RedirectFindByPathMutationVariables> {
    document = RedirectFindByPathDocument;
    
  }
export const RefundInfoDocument = gql`
    query refundInfo($id: UUID!) {
  refundInfo(id: $id) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
    refundItems {
      id
      quantity
      salesOrderItem {
        sku
        name
        unitAmount
        lineAmount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefundInfoGQL extends Apollo.Query<RefundInfoQuery, RefundInfoQueryVariables> {
    document = RefundInfoDocument;
    
  }
export const ReviewListForProductDocument = gql`
    query reviewListForProduct($productId: UUID!, $rating: Long, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  reviewListForProduct(
    productId: $productId
    rating: $rating
    page: $pageable
    sort: $sort
  ) {
    count81To100
    count61To80
    count41To60
    count21To40
    count0To20
    averageRating
    reviews {
      data {
        id
        reviewer
        title
        comments
        rating
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewListForProductGQL extends Apollo.Query<ReviewListForProductQuery, ReviewListForProductQueryVariables> {
    document = ReviewListForProductDocument;
    
  }
export const RewardPointsBalanceDocument = gql`
    query rewardPointsBalance {
  rewardPointsBalance
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardPointsBalanceGQL extends Apollo.Query<RewardPointsBalanceQuery, RewardPointsBalanceQueryVariables> {
    document = RewardPointsBalanceDocument;
    
  }
export const RewardPointsGetRewardsAccountDocument = gql`
    query rewardPointsGetRewardsAccount {
  rewardPointsGetRewardsAccount {
    balance
    transactions {
      amount
      comment
      timestamp
      transactionType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardPointsGetRewardsAccountGQL extends Apollo.Query<RewardPointsGetRewardsAccountQuery, RewardPointsGetRewardsAccountQueryVariables> {
    document = RewardPointsGetRewardsAccountDocument;
    
  }
export const ReshipSalesOrderInfoDocument = gql`
    query reshipSalesOrderInfo($id: UUID!) {
  reshipSalesOrderInfo(id: $id) {
    parentOrder {
      id
      cls
      orderNumber
      alternateOrderNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReshipSalesOrderInfoGQL extends Apollo.Query<ReshipSalesOrderInfoQuery, ReshipSalesOrderInfoQueryVariables> {
    document = ReshipSalesOrderInfoDocument;
    
  }
export const SalesOrderAddBraintreePaymentDocument = gql`
    mutation salesOrderAddBraintreePayment($orderId: UUID!, $transactionId: String!) {
  salesOrderAddBraintreePayment(transactionId: $transactionId, orderId: $orderId) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddBraintreePaymentGQL extends Apollo.Mutation<SalesOrderAddBraintreePaymentMutation, SalesOrderAddBraintreePaymentMutationVariables> {
    document = SalesOrderAddBraintreePaymentDocument;
    
  }
export const SalesOrderAddCashPaymentDocument = gql`
    mutation salesOrderAddCashPayment($amount: BigDecimal!, $orderId: UUID!) {
  salesOrderAddCashPayment(amount: $amount, orderId: $orderId) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCashPaymentGQL extends Apollo.Mutation<SalesOrderAddCashPaymentMutation, SalesOrderAddCashPaymentMutationVariables> {
    document = SalesOrderAddCashPaymentDocument;
    
  }
export const SalesOrderAddCheckPaymentDocument = gql`
    mutation salesOrderAddCheckPayment($amount: BigDecimal!, $orderId: UUID!, $checkNumber: String) {
  salesOrderAddCheckPayment(
    amount: $amount
    orderId: $orderId
    checkNumber: $checkNumber
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCheckPaymentGQL extends Apollo.Mutation<SalesOrderAddCheckPaymentMutation, SalesOrderAddCheckPaymentMutationVariables> {
    document = SalesOrderAddCheckPaymentDocument;
    
  }
export const SalesOrderAddCommentDocument = gql`
    mutation SalesOrderAddComment($comment: String!, $id: UUID!) {
  salesOrderAddComment(comment: $comment, id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCommentGQL extends Apollo.Mutation<SalesOrderAddCommentMutation, SalesOrderAddCommentMutationVariables> {
    document = SalesOrderAddCommentDocument;
    
  }
export const SalesOrderAddGiftCardPaymentDocument = gql`
    mutation salesOrderAddGiftCardPayment($amount: BigDecimal!, $orderId: UUID!, $giftCardCode: String) {
  salesOrderAddGiftCardPayment(
    amount: $amount
    orderId: $orderId
    giftCardCode: $giftCardCode
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddGiftCardPaymentGQL extends Apollo.Mutation<SalesOrderAddGiftCardPaymentMutation, SalesOrderAddGiftCardPaymentMutationVariables> {
    document = SalesOrderAddGiftCardPaymentDocument;
    
  }
export const SalesOrderAddPaymentTermsPaymentDocument = gql`
    mutation salesOrderAddPaymentTermsPayment($amount: BigDecimal!, $orderId: UUID!) {
  salesOrderAddPaymentTermsPayment(amount: $amount, orderId: $orderId) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddPaymentTermsPaymentGQL extends Apollo.Mutation<SalesOrderAddPaymentTermsPaymentMutation, SalesOrderAddPaymentTermsPaymentMutationVariables> {
    document = SalesOrderAddPaymentTermsPaymentDocument;
    
  }
export const SalesOrderCancelDocument = gql`
    mutation salesOrderCancel($id: UUID!) {
  salesOrderCancel(id: $id) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCancelGQL extends Apollo.Mutation<SalesOrderCancelMutation, SalesOrderCancelMutationVariables> {
    document = SalesOrderCancelDocument;
    
  }
export const SalesOrderCancelByHashDocument = gql`
    mutation salesOrderCancelByHash($hash: String!) {
  salesOrderCancelByHash(hash: $hash) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCancelByHashGQL extends Apollo.Mutation<SalesOrderCancelByHashMutation, SalesOrderCancelByHashMutationVariables> {
    document = SalesOrderCancelByHashDocument;
    
  }
export const SalesOrderCustomRefundDocument = gql`
    mutation salesOrderCustomRefund($id: UUID!, $subTotal: BigDecimal, $productDiscount: BigDecimal, $shipping: BigDecimal!, $handling: BigDecimal!, $shippingDiscount: BigDecimal!, $reason: RefundReason) {
  salesOrderCustomRefund(
    id: $id
    subTotal: $subTotal
    productDiscount: $productDiscount
    shipping: $shipping
    handling: $handling
    shippingDiscount: $shippingDiscount
    refundReason: $reason
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCustomRefundGQL extends Apollo.Mutation<SalesOrderCustomRefundMutation, SalesOrderCustomRefundMutationVariables> {
    document = SalesOrderCustomRefundDocument;
    
  }
export const SalesOrderCustomRefundSummarizeDocument = gql`
    mutation salesOrderCustomRefundSummarize($id: UUID!, $subTotal: BigDecimal, $productDiscount: BigDecimal, $shipping: BigDecimal!, $handling: BigDecimal!, $shippingDiscount: BigDecimal!, $reason: RefundReason) {
  salesOrderCustomRefundSummarize(
    id: $id
    subTotal: $subTotal
    productDiscount: $productDiscount
    shipping: $shipping
    handling: $handling
    shippingDiscount: $shippingDiscount
    reason: $reason
  ) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCustomRefundSummarizeGQL extends Apollo.Mutation<SalesOrderCustomRefundSummarizeMutation, SalesOrderCustomRefundSummarizeMutationVariables> {
    document = SalesOrderCustomRefundSummarizeDocument;
    
  }
export const SalesOrderGetRefundableAmountsDocument = gql`
    mutation salesOrderGetRefundableAmounts($id: UUID!) {
  salesOrderGetRefundableAmounts(id: $id) {
    shipping
    handling
    shippingDiscount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGetRefundableAmountsGQL extends Apollo.Mutation<SalesOrderGetRefundableAmountsMutation, SalesOrderGetRefundableAmountsMutationVariables> {
    document = SalesOrderGetRefundableAmountsDocument;
    
  }
export const SalesOrderPartialRefundSummarizeDocument = gql`
    mutation salesOrderPartialRefundSummarize($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason) {
  salesOrderPartialRefundSummarize(
    id: $id
    lineItems: $lineItems
    reason: $reason
  ) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
    refundItems {
      id
      quantity
      salesOrderItem {
        sku
        name
        unitAmount
        lineAmount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialRefundSummarizeGQL extends Apollo.Mutation<SalesOrderPartialRefundSummarizeMutation, SalesOrderPartialRefundSummarizeMutationVariables> {
    document = SalesOrderPartialRefundSummarizeDocument;
    
  }
export const SalesOrderSalesTaxRefundDocument = gql`
    mutation salesOrderSalesTaxRefund($id: UUID!, $type: SalesOrderType!) {
  salesOrderSalesTaxRefund(id: $id, type: $type) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSalesTaxRefundGQL extends Apollo.Mutation<SalesOrderSalesTaxRefundMutation, SalesOrderSalesTaxRefundMutationVariables> {
    document = SalesOrderSalesTaxRefundDocument;
    
  }
export const SalesOrderByHashDocument = gql`
    query salesOrderByHash($hash: String!) {
  salesOrderByHash(hash: $hash) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderByHashGQL extends Apollo.Query<SalesOrderByHashQuery, SalesOrderByHashQueryVariables> {
    document = SalesOrderByHashDocument;
    
  }
export const SalesOrderFilterDocument = gql`
    query salesOrderFilter($orderNumber: String, $alternateOrderNumber: String, $orderType: String, $billingFirstName: String, $billingLastName: String, $email: String, $status: SalesOrderStatus, $hold: Boolean, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  salesOrderFilter(
    orderNumber: {pattern: $orderNumber}
    alternateOrderNumber: {pattern: $alternateOrderNumber}
    orderType: {value: $orderType}
    billingFirstName: {pattern: $billingFirstName}
    billingLastName: {pattern: $billingLastName}
    email: {pattern: $email}
    status: {value: $status}
    hold: {value: $hold}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      orderNumber
      alternateOrderNumber
      status
      billingFirstName
      billingLastName
      email
      placedTime
      subTotal
      discountTotal
      taxTotal
      shippingTotal
      handlingTotal
      grandTotal
      salesOrderItemGroups {
        id
        quantity
        unitAmount
        lineAmount
        name
        sku
        kitProduct {
          sku
          title
        }
      }
      salesOrderItems {
        id
        sku
        name
        quantity
        unitAmount
        discountAmount
        lineAmount
        salesOrderItemGroup {
          id
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderFilterGQL extends Apollo.Query<SalesOrderFilterQuery, SalesOrderFilterQueryVariables> {
    document = SalesOrderFilterDocument;
    
  }
export const SalesOrderGenerateInvoiceDocument = gql`
    mutation salesOrderGenerateInvoice($id: UUID!) {
  salesOrderGenerateInvoice(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGenerateInvoiceGQL extends Apollo.Mutation<SalesOrderGenerateInvoiceMutation, SalesOrderGenerateInvoiceMutationVariables> {
    document = SalesOrderGenerateInvoiceDocument;
    
  }
export const SalesOrderGenerateInvoiceByHashDocument = gql`
    mutation salesOrderGenerateInvoiceByHash($hash: String!) {
  salesOrderGenerateInvoiceByHash(hash: $hash)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGenerateInvoiceByHashGQL extends Apollo.Mutation<SalesOrderGenerateInvoiceByHashMutation, SalesOrderGenerateInvoiceByHashMutationVariables> {
    document = SalesOrderGenerateInvoiceByHashDocument;
    
  }
export const SalesOrderGetRefundableItemsDocument = gql`
    mutation salesOrderGetRefundableItems($id: UUID!) {
  salesOrderGetRefundableItems(id: $id) {
    id
    quantity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGetRefundableItemsGQL extends Apollo.Mutation<SalesOrderGetRefundableItemsMutation, SalesOrderGetRefundableItemsMutationVariables> {
    document = SalesOrderGetRefundableItemsDocument;
    
  }
export const SalesOrderInfoDocument = gql`
    query salesOrderInfo($id: UUID!) {
  salesOrderInfo(id: $id) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderInfoGQL extends Apollo.Query<SalesOrderInfoQuery, SalesOrderInfoQueryVariables> {
    document = SalesOrderInfoDocument;
    
  }
export const SalesOrderInfoAdminDocument = gql`
    query salesOrderInfoAdmin($id: UUID!) {
  salesOrderInfo(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderInfoAdminGQL extends Apollo.Query<SalesOrderInfoAdminQuery, SalesOrderInfoAdminQueryVariables> {
    document = SalesOrderInfoAdminDocument;
    
  }
export const SalesOrderListDocument = gql`
    query salesOrderList($pageable: GraphQLPageableInput!) {
  salesOrderList(page: $pageable, sort: {field: "placedTime", direction: DESC}) {
    count
    data {
      id
      orderNumber
      status
      placedTime
      grandTotal
      salesOrderItemGroups {
        quantity
        unitAmount
        lineAmount
        name
        kitProduct {
          slug
          title
          medias {
            url
          }
        }
        salesOrderItems {
          quantity
          name
        }
      }
      salesOrderItems {
        name
        quantity
        unitAmount
        lineAmount
        salesOrderItemGroup {
          id
        }
        simpleProduct {
          slug
          title
          medias {
            url
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderListGQL extends Apollo.Query<SalesOrderListQuery, SalesOrderListQueryVariables> {
    document = SalesOrderListDocument;
    
  }
export const SalesOrderOfflineRefundDocument = gql`
    mutation salesOrderOfflineRefund($id: UUID!, $reason: RefundReason) {
  salesOrderOfflineRefund(id: $id, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderOfflineRefundGQL extends Apollo.Mutation<SalesOrderOfflineRefundMutation, SalesOrderOfflineRefundMutationVariables> {
    document = SalesOrderOfflineRefundDocument;
    
  }
export const SalesOrderOnlineRefundDocument = gql`
    mutation salesOrderOnlineRefund($id: UUID!, $reason: RefundReason) {
  salesOrderOnlineRefund(id: $id, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderOnlineRefundGQL extends Apollo.Mutation<SalesOrderOnlineRefundMutation, SalesOrderOnlineRefundMutationVariables> {
    document = SalesOrderOnlineRefundDocument;
    
  }
export const SalesOrderPartialRefundDocument = gql`
    mutation salesOrderPartialRefund($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason) {
  salesOrderPartialRefund(id: $id, lineItems: $lineItems, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialRefundGQL extends Apollo.Mutation<SalesOrderPartialRefundMutation, SalesOrderPartialRefundMutationVariables> {
    document = SalesOrderPartialRefundDocument;
    
  }
export const SalesOrderPartialReshipDocument = gql`
    mutation salesOrderPartialReship($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason, $carrierPreference: Carrier, $serviceType: ServiceType) {
  salesOrderPartialReship(
    id: $id
    lineItems: $lineItems
    reason: $reason
    carrierPreference: $carrierPreference
    serviceType: $serviceType
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialReshipGQL extends Apollo.Mutation<SalesOrderPartialReshipMutation, SalesOrderPartialReshipMutationVariables> {
    document = SalesOrderPartialReshipDocument;
    
  }
export const SalesOrderSetEmailDocument = gql`
    mutation salesOrderSetEmail($id: UUID!, $email: String!) {
  salesOrderSetEmail(id: $id, email: $email) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSetEmailGQL extends Apollo.Mutation<SalesOrderSetEmailMutation, SalesOrderSetEmailMutationVariables> {
    document = SalesOrderSetEmailDocument;
    
  }
export const SalesOrderSetHoldDocument = gql`
    mutation salesOrderSetHold($id: UUID!, $hold: Boolean!) {
  salesOrderSetHold(id: $id, hold: $hold) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSetHoldGQL extends Apollo.Mutation<SalesOrderSetHoldMutation, SalesOrderSetHoldMutationVariables> {
    document = SalesOrderSetHoldDocument;
    
  }
export const SalesOrderUpdatePaymentsDocument = gql`
    mutation salesOrderUpdatePayments($id: UUID!) {
  salesOrderUpdatePayments(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderUpdatePaymentsGQL extends Apollo.Mutation<SalesOrderUpdatePaymentsMutation, SalesOrderUpdatePaymentsMutationVariables> {
    document = SalesOrderUpdatePaymentsDocument;
    
  }
export const SalesOrderValidateAddressDocument = gql`
    mutation salesOrderValidateAddress($id: UUID!) {
  salesOrderValidateAddress(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderValidateAddressGQL extends Apollo.Mutation<SalesOrderValidateAddressMutation, SalesOrderValidateAddressMutationVariables> {
    document = SalesOrderValidateAddressDocument;
    
  }
export const ScheduledShipmentScheduleDocument = gql`
    mutation scheduledShipmentSchedule($date: LocalDate!, $id: UUID!) {
  scheduledShipmentSchedule(date: $date, id: $id) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentScheduleGQL extends Apollo.Mutation<ScheduledShipmentScheduleMutation, ScheduledShipmentScheduleMutationVariables> {
    document = ScheduledShipmentScheduleDocument;
    
  }
export const ScheduledShipmentScheduleByHashDocument = gql`
    mutation scheduledShipmentScheduleByHash($date: LocalDate!, $hash: String!) {
  scheduledShipmentScheduleByHash(date: $date, hash: $hash) {
    ...CUSTOMER_SHIPMENT_PUBLIC
  }
}
    ${Customer_Shipment_PublicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentScheduleByHashGQL extends Apollo.Mutation<ScheduledShipmentScheduleByHashMutation, ScheduledShipmentScheduleByHashMutationVariables> {
    document = ScheduledShipmentScheduleByHashDocument;
    
  }
export const ScheduledShipmentUpdateAddressByHashFullDocument = gql`
    mutation scheduledShipmentUpdateAddressByHashFull($hash: String!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  scheduledShipmentUpdateAddressByHash(
    hash: $hash
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentUpdateAddressByHashFullGQL extends Apollo.Mutation<ScheduledShipmentUpdateAddressByHashFullMutation, ScheduledShipmentUpdateAddressByHashFullMutationVariables> {
    document = ScheduledShipmentUpdateAddressByHashFullDocument;
    
  }
export const ScheduledShipmentHoldLocationsDocument = gql`
    query scheduledShipmentHoldLocations($postalCode: String!) {
  scheduledShipmentHoldLocations(postalCode: $postalCode) {
    city
    company
    country
    line1
    line2
    postalCode
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentHoldLocationsGQL extends Apollo.Query<ScheduledShipmentHoldLocationsQuery, ScheduledShipmentHoldLocationsQueryVariables> {
    document = ScheduledShipmentHoldLocationsDocument;
    
  }
export const ShipmentByHashDocument = gql`
    query shipmentByHash($hash: String!) {
  shipmentByHash(hash: $hash) {
    ...CUSTOMER_SHIPMENT_PUBLIC
  }
}
    ${Customer_Shipment_PublicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentByHashGQL extends Apollo.Query<ShipmentByHashQuery, ShipmentByHashQueryVariables> {
    document = ShipmentByHashDocument;
    
  }
export const ShipmentFilterDocument = gql`
    query shipmentFilter($warehouse: String, $salesOrderCls: String, $shipmentNumber: String, $firstName: String, $lastName: String, $email: String, $late: Boolean, $shipmentStatus: ShipmentStatus, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  shipmentFilter(
    warehouse: {value: $warehouse}
    salesOrderCls: {value: $salesOrderCls}
    shipmentNumber: {pattern: $shipmentNumber}
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    late: {value: $late}
    shipmentStatus: {value: $shipmentStatus}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      email
      firstName
      lastName
      shipmentNumber
      shipmentStatus
      shippingNeeds
      carrier
      service
      estimatedWeight
      actualWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualLength
      actualWidth
      actualHeight
      estimatedShipDate
      estimatedDeliveryDate
      shippedAt
      trackingNo
      originWarehouse {
        id
        name
      }
      departingWarehouse {
        id
        name
      }
      salesOrder {
        cls
        orderNumber
        alternateOrderNumber
        salesOrderType
      }
      shipmentItems {
        id
        cls
        quantity
        salesOrderItem {
          id
          cls
          sku
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentFilterGQL extends Apollo.Query<ShipmentFilterQuery, ShipmentFilterQueryVariables> {
    document = ShipmentFilterDocument;
    
  }
export const ShipmentInfoAdminDocument = gql`
    query shipmentInfoAdmin($id: UUID!) {
  shipmentInfo(id: $id) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentInfoAdminGQL extends Apollo.Query<ShipmentInfoAdminQuery, ShipmentInfoAdminQueryVariables> {
    document = ShipmentInfoAdminDocument;
    
  }
export const ShipmentTrackDocument = gql`
    mutation shipmentTrack($id: UUID!) {
  shipmentTrack(id: $id) {
    comment
    location
    timestamp
    trackingEventType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentTrackGQL extends Apollo.Mutation<ShipmentTrackMutation, ShipmentTrackMutationVariables> {
    document = ShipmentTrackDocument;
    
  }
export const ShipmentTrackByHashDocument = gql`
    mutation shipmentTrackByHash($hash: String!) {
  shipmentTrackByHash(hash: $hash) {
    comment
    location
    timestamp
    trackingEventType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentTrackByHashGQL extends Apollo.Mutation<ShipmentTrackByHashMutation, ShipmentTrackByHashMutationVariables> {
    document = ShipmentTrackByHashDocument;
    
  }
export const ShipmentCancelAndRefundDocument = gql`
    mutation shipmentCancelAndRefund($id: UUID!, $reason: CancellationReason, $sendNotification: Boolean!, $refund: Boolean!) {
  shipmentCancelAndRefund(
    id: $id
    cancellationReason: $reason
    sendNotification: $sendNotification
    refund: $refund
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentCancelAndRefundGQL extends Apollo.Mutation<ShipmentCancelAndRefundMutation, ShipmentCancelAndRefundMutationVariables> {
    document = ShipmentCancelAndRefundDocument;
    
  }
export const ShipmentDashboardDocument = gql`
    mutation shipmentDashboard($warehouse: String!) {
  shipmentDashboard(warehouse: $warehouse) {
    warehouse
    timezone
    today
    nextOpenDate
    lateCount
    onHoldCount
    pendingCount
    needsSchedulingCount
    summaries {
      date
      onHold
      unshipped
      shipped
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentDashboardGQL extends Apollo.Mutation<ShipmentDashboardMutation, ShipmentDashboardMutationVariables> {
    document = ShipmentDashboardDocument;
    
  }
export const ShipmentDeliverOfflineDocument = gql`
    mutation shipmentDeliverOffline($id: UUID!) {
  shipmentDeliverOffline(id: $id) {
    id
    shipmentStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentDeliverOfflineGQL extends Apollo.Mutation<ShipmentDeliverOfflineMutation, ShipmentDeliverOfflineMutationVariables> {
    document = ShipmentDeliverOfflineDocument;
    
  }
export const ShipmentGenerateCommercialInvoiceDocument = gql`
    mutation shipmentGenerateCommercialInvoice($id: UUID!) {
  shipmentGenerateCommercialInvoice(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentGenerateCommercialInvoiceGQL extends Apollo.Mutation<ShipmentGenerateCommercialInvoiceMutation, ShipmentGenerateCommercialInvoiceMutationVariables> {
    document = ShipmentGenerateCommercialInvoiceDocument;
    
  }
export const ShipmentGeneratePackingListDocument = gql`
    mutation shipmentGeneratePackingList($id: UUID!) {
  shipmentGeneratePackingList(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentGeneratePackingListGQL extends Apollo.Mutation<ShipmentGeneratePackingListMutation, ShipmentGeneratePackingListMutationVariables> {
    document = ShipmentGeneratePackingListDocument;
    
  }
export const ShipmentInfoDocument = gql`
    query shipmentInfo($id: UUID!) {
  shipmentInfo(id: $id) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentInfoGQL extends Apollo.Query<ShipmentInfoQuery, ShipmentInfoQueryVariables> {
    document = ShipmentInfoDocument;
    
  }
export const ShipmentLookupWeatherDocument = gql`
    mutation shipmentLookupWeather($shipmentId: UUID!) {
  shipmentLookupWeather(id: $shipmentId) {
    date
    high
    low
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLookupWeatherGQL extends Apollo.Mutation<ShipmentLookupWeatherMutation, ShipmentLookupWeatherMutationVariables> {
    document = ShipmentLookupWeatherDocument;
    
  }
export const ShipmentLookupWeatherByHashDocument = gql`
    mutation shipmentLookupWeatherByHash($shipmentIdHash: String!) {
  shipmentLookupWeatherByHash(hash: $shipmentIdHash) {
    date
    high
    low
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLookupWeatherByHashGQL extends Apollo.Mutation<ShipmentLookupWeatherByHashMutation, ShipmentLookupWeatherByHashMutationVariables> {
    document = ShipmentLookupWeatherByHashDocument;
    
  }
export const ShipmentRerouteDocument = gql`
    mutation shipmentReroute($id: UUID!) {
  shipmentReroute(id: $id) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentRerouteGQL extends Apollo.Mutation<ShipmentRerouteMutation, ShipmentRerouteMutationVariables> {
    document = ShipmentRerouteDocument;
    
  }
export const ShipmentSetShippingNeedsDocument = gql`
    mutation shipmentSetShippingNeeds($id: UUID!, $shippingNeeds: ShippingNeedsType!) {
  shipmentSetShippingNeeds(id: $id, shippingNeeds: $shippingNeeds) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentSetShippingNeedsGQL extends Apollo.Mutation<ShipmentSetShippingNeedsMutation, ShipmentSetShippingNeedsMutationVariables> {
    document = ShipmentSetShippingNeedsDocument;
    
  }
export const ShipmentShipOfflineDocument = gql`
    mutation shipmentShipOffline($id: UUID!, $carrier: Carrier, $trackingNos: [String], $service: Service) {
  shipmentShipOffline(
    id: $id
    carrier: $carrier
    trackingNos: $trackingNos
    service: $service
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentShipOfflineGQL extends Apollo.Mutation<ShipmentShipOfflineMutation, ShipmentShipOfflineMutationVariables> {
    document = ShipmentShipOfflineDocument;
    
  }
export const ShipmentSplitDocument = gql`
    mutation shipmentSplit($id: UUID!, $lineItems: [ShipmentLineItemInput]!, $reroute: Boolean!) {
  shipmentSplit(id: $id, lineItems: $lineItems, reroute: $reroute) {
    id
    cls
    shipmentNumber
    shipmentStatus
    shippingNeeds
    carrier
    service
    estimatedWeight
    actualWeight
    estimatedLength
    estimatedWidth
    estimatedHeight
    actualLength
    actualWidth
    actualHeight
    placedAt
    estimatedShipDate
    estimatedDeliveryDate
    shippedAt
    email
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    residential
    addressValidationSource
    downloadStart
    printedStart
    printedEnd
    reprint
    salesOrder {
      id
      cls
      orderNumber
      alternateOrderNumber
      placedTime
      status
    }
    shipmentItems {
      id
      cls
      quantity
      salesOrderItem {
        id
        cls
        sku
        name
      }
    }
    shipmentAddons {
      id
      cls
      quantity
      sku
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentSplitGQL extends Apollo.Mutation<ShipmentSplitMutation, ShipmentSplitMutationVariables> {
    document = ShipmentSplitDocument;
    
  }
export const ShipmentUpdateAddressDocument = gql`
    mutation shipmentUpdateAddress($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  shipmentUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentUpdateAddressGQL extends Apollo.Mutation<ShipmentUpdateAddressMutation, ShipmentUpdateAddressMutationVariables> {
    document = ShipmentUpdateAddressDocument;
    
  }
export const ShipmentUpdateAddressFullDocument = gql`
    mutation shipmentUpdateAddressFull($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean!) {
  shipmentUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
  ) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentUpdateAddressFullGQL extends Apollo.Mutation<ShipmentUpdateAddressFullMutation, ShipmentUpdateAddressFullMutationVariables> {
    document = ShipmentUpdateAddressFullDocument;
    
  }
export const ShipmentValidateAddressDocument = gql`
    mutation shipmentValidateAddress($id: UUID!) {
  shipmentValidateAddress(id: $id) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentValidateAddressGQL extends Apollo.Mutation<ShipmentValidateAddressMutation, ShipmentValidateAddressMutationVariables> {
    document = ShipmentValidateAddressDocument;
    
  }
export const SimpleProductAddComponentItemDocument = gql`
    mutation simpleProductAddComponentItem($id: UUID!, $productId: UUID!, $quantity: BigDecimal!) {
  simpleProductAddComponentItem(
    id: $id
    productId: $productId
    quantity: $quantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddComponentItemGQL extends Apollo.Mutation<SimpleProductAddComponentItemMutation, SimpleProductAddComponentItemMutationVariables> {
    document = SimpleProductAddComponentItemDocument;
    
  }
export const SimpleProductAddCrossSellProductsDocument = gql`
    mutation simpleProductAddCrossSellProducts($id: UUID!, $productIds: [UUID]!) {
  simpleProductAddCrossSellProducts(id: $id, productIds: $productIds) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddCrossSellProductsGQL extends Apollo.Mutation<SimpleProductAddCrossSellProductsMutation, SimpleProductAddCrossSellProductsMutationVariables> {
    document = SimpleProductAddCrossSellProductsDocument;
    
  }
export const SimpleProductAddImageDocument = gql`
    mutation simpleProductAddImage($id: UUID!, $mediaId: UUID!) {
  simpleProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddImageGQL extends Apollo.Mutation<SimpleProductAddImageMutation, SimpleProductAddImageMutationVariables> {
    document = SimpleProductAddImageDocument;
    
  }
export const SimpleProductAddPriceTierDocument = gql`
    mutation simpleProductAddPriceTier($id: UUID!, $price: BigDecimal!, $quantity: BigDecimal!, $userType: UserType) {
  simpleProductAddPriceTier(
    id: $id
    price: $price
    quantity: $quantity
    userType: $userType
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddPriceTierGQL extends Apollo.Mutation<SimpleProductAddPriceTierMutation, SimpleProductAddPriceTierMutationVariables> {
    document = SimpleProductAddPriceTierDocument;
    
  }
export const SimpleProductAddWarehouseDocument = gql`
    mutation simpleProductAddWarehouse($id: UUID!, $warehouse: String!) {
  simpleProductAddWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddWarehouseGQL extends Apollo.Mutation<SimpleProductAddWarehouseMutation, SimpleProductAddWarehouseMutationVariables> {
    document = SimpleProductAddWarehouseDocument;
    
  }
export const SimpleProductAddZoneDocument = gql`
    mutation simpleProductAddZone($id: UUID!, $zone: UUID!) {
  simpleProductAddZone(productId: $id, zoneId: $zone) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddZoneGQL extends Apollo.Mutation<SimpleProductAddZoneMutation, SimpleProductAddZoneMutationVariables> {
    document = SimpleProductAddZoneDocument;
    
  }
export const SimpleProductBySlugDocument = gql`
    query simpleProductBySlug($slug: String!) {
  simpleProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    featured
    subscription
    quantityAvailableForSale
    discontinuationReason
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    shippingNeeds
    tieredPrices {
      id
      quantity
      price
    }
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
      destinationRestriction {
        domesticOnly
        prohibitedStates
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductBySlugGQL extends Apollo.Query<SimpleProductBySlugQuery, SimpleProductBySlugQueryVariables> {
    document = SimpleProductBySlugDocument;
    
  }
export const SimpleProductClearBinDocument = gql`
    mutation simpleProductClearBin($id: UUID!, $warehouse: String!) {
  simpleProductClearBin(id: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearBinGQL extends Apollo.Mutation<SimpleProductClearBinMutation, SimpleProductClearBinMutationVariables> {
    document = SimpleProductClearBinDocument;
    
  }
export const SimpleProductClearBrandDocument = gql`
    mutation simpleProductClearBrand($productId: UUID!) {
  simpleProductClearBrand(productId: $productId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearBrandGQL extends Apollo.Mutation<SimpleProductClearBrandMutation, SimpleProductClearBrandMutationVariables> {
    document = SimpleProductClearBrandDocument;
    
  }
export const SimpleProductClearDiscontinuationReasonDocument = gql`
    mutation simpleProductClearDiscontinuationReason($id: UUID!) {
  simpleProductClearDiscontinuationReason(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearDiscontinuationReasonGQL extends Apollo.Mutation<SimpleProductClearDiscontinuationReasonMutation, SimpleProductClearDiscontinuationReasonMutationVariables> {
    document = SimpleProductClearDiscontinuationReasonDocument;
    
  }
export const SimpleProductClearRequiresPrepDocument = gql`
    mutation simpleProductClearRequiresPrep($id: UUID!) {
  simpleProductClearRequiresPrep(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearRequiresPrepGQL extends Apollo.Mutation<SimpleProductClearRequiresPrepMutation, SimpleProductClearRequiresPrepMutationVariables> {
    document = SimpleProductClearRequiresPrepDocument;
    
  }
export const SimpleProductClearShipsAloneDocument = gql`
    mutation simpleProductClearShipsAlone($id: UUID!) {
  simpleProductClearShipsAlone(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearShipsAloneGQL extends Apollo.Mutation<SimpleProductClearShipsAloneMutation, SimpleProductClearShipsAloneMutationVariables> {
    document = SimpleProductClearShipsAloneDocument;
    
  }
export const SimpleProductClearSupplierDocument = gql`
    mutation simpleProductClearSupplier($productId: UUID!) {
  simpleProductClearSupplier(productId: $productId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearSupplierGQL extends Apollo.Mutation<SimpleProductClearSupplierMutation, SimpleProductClearSupplierMutationVariables> {
    document = SimpleProductClearSupplierDocument;
    
  }
export const SimpleProductCloneDocument = gql`
    mutation simpleProductClone($id: UUID!, $sku: String!, $upc: String, $vendorRef: String, $title: String!, $slug: String, $price: BigDecimal!, $cost: BigDecimal) {
  simpleProductClone(
    id: $id
    sku: $sku
    upc: $upc
    vendorRef: $vendorRef
    title: $title
    slug: $slug
    price: $price
    cost: $cost
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductCloneGQL extends Apollo.Mutation<SimpleProductCloneMutation, SimpleProductCloneMutationVariables> {
    document = SimpleProductCloneDocument;
    
  }
export const SimpleProductCreateDocument = gql`
    mutation simpleProductCreate($sku: String!, $upc: String, $vendorRef: String, $title: String!, $slug: String, $price: BigDecimal!, $cost: BigDecimal) {
  simpleProductCreate(
    sku: $sku
    upc: $upc
    vendorRef: $vendorRef
    title: $title
    slug: $slug
    price: $price
    cost: $cost
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductCreateGQL extends Apollo.Mutation<SimpleProductCreateMutation, SimpleProductCreateMutationVariables> {
    document = SimpleProductCreateDocument;
    
  }
export const SimpleProductRemoveDocument = gql`
    mutation simpleProductRemove($id: UUID!) {
  simpleProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveGQL extends Apollo.Mutation<SimpleProductRemoveMutation, SimpleProductRemoveMutationVariables> {
    document = SimpleProductRemoveDocument;
    
  }
export const SimpleProductFilterDocument = gql`
    query simpleProductFilter($pageable: GraphQLPageableInput!, $title: String, $sku: String, $active: Boolean, $brand: String, $supplier: String, $department: String, $category: String, $sort: GraphQLSortInput, $inStock: Boolean) {
  simpleProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    brand: $brand
    supplier: $supplier
    department: $department
    category: $category
    page: $pageable
    sort: $sort
    inStock: {value: $inStock}
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      quantityAvailableForSale
      averageRating
      countRating
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
      brand {
        id
        slug
        name
      }
      supplier {
        id
        slug
        name
      }
      price
      salePrice
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      shippingRuleSet {
        id
        slug
        name
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductFilterGQL extends Apollo.Query<SimpleProductFilterQuery, SimpleProductFilterQueryVariables> {
    document = SimpleProductFilterDocument;
    
  }
export const SimpleProductInfoDocument = gql`
    query simpleProductInfo($id: UUID!) {
  simpleProductInfo(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductInfoGQL extends Apollo.Query<SimpleProductInfoQuery, SimpleProductInfoQueryVariables> {
    document = SimpleProductInfoDocument;
    
  }
export const SimpleProductRemoveComponentItemDocument = gql`
    mutation simpleProductRemoveComponentItem($id: UUID!, $componentItemId: UUID!) {
  simpleProductRemoveComponentItem(id: $id, componentItemId: $componentItemId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveComponentItemGQL extends Apollo.Mutation<SimpleProductRemoveComponentItemMutation, SimpleProductRemoveComponentItemMutationVariables> {
    document = SimpleProductRemoveComponentItemDocument;
    
  }
export const SimpleProductRemoveCrossSellProductDocument = gql`
    mutation simpleProductRemoveCrossSellProduct($id: UUID!, $crossSellProductId: UUID!) {
  simpleProductRemoveCrossSellProduct(
    id: $id
    crossSellProductId: $crossSellProductId
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveCrossSellProductGQL extends Apollo.Mutation<SimpleProductRemoveCrossSellProductMutation, SimpleProductRemoveCrossSellProductMutationVariables> {
    document = SimpleProductRemoveCrossSellProductDocument;
    
  }
export const SimpleProductRemovePriceTierDocument = gql`
    mutation simpleProductRemovePriceTier($id: UUID!, $priceTierId: UUID!) {
  simpleProductRemovePriceTier(id: $id, priceTierId: $priceTierId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemovePriceTierGQL extends Apollo.Mutation<SimpleProductRemovePriceTierMutation, SimpleProductRemovePriceTierMutationVariables> {
    document = SimpleProductRemovePriceTierDocument;
    
  }
export const SimpleProductRemoveProductMediaDocument = gql`
    mutation simpleProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  simpleProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveProductMediaGQL extends Apollo.Mutation<SimpleProductRemoveProductMediaMutation, SimpleProductRemoveProductMediaMutationVariables> {
    document = SimpleProductRemoveProductMediaDocument;
    
  }
export const SimpleProductRemoveWarehouseDocument = gql`
    mutation simpleProductRemoveWarehouse($id: UUID!, $warehouse: String!) {
  simpleProductRemoveWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveWarehouseGQL extends Apollo.Mutation<SimpleProductRemoveWarehouseMutation, SimpleProductRemoveWarehouseMutationVariables> {
    document = SimpleProductRemoveWarehouseDocument;
    
  }
export const SimpleProductRemoveZoneDocument = gql`
    mutation simpleProductRemoveZone($id: UUID!, $zone: UUID!) {
  simpleProductRemoveZone(productId: $id, zoneId: $zone) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveZoneGQL extends Apollo.Mutation<SimpleProductRemoveZoneMutation, SimpleProductRemoveZoneMutationVariables> {
    document = SimpleProductRemoveZoneDocument;
    
  }
export const SimpleProductSetActiveDocument = gql`
    mutation simpleProductSetActive($id: UUID!, $active: Boolean!) {
  simpleProductSetActive(id: $id, active: $active) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetActiveGQL extends Apollo.Mutation<SimpleProductSetActiveMutation, SimpleProductSetActiveMutationVariables> {
    document = SimpleProductSetActiveDocument;
    
  }
export const SimpleProductSetBinDocument = gql`
    mutation simpleProductSetBin($id: UUID!, $bin: String!, $warehouse: String!) {
  simpleProductSetBin(id: $id, bin: $bin, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetBinGQL extends Apollo.Mutation<SimpleProductSetBinMutation, SimpleProductSetBinMutationVariables> {
    document = SimpleProductSetBinDocument;
    
  }
export const SimpleProductSetBrandDocument = gql`
    mutation simpleProductSetBrand($productId: UUID!, $brandId: UUID!) {
  simpleProductSetBrand(productId: $productId, brandId: $brandId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetBrandGQL extends Apollo.Mutation<SimpleProductSetBrandMutation, SimpleProductSetBrandMutationVariables> {
    document = SimpleProductSetBrandDocument;
    
  }
export const SimpleProductSetCaseQuantityDocument = gql`
    mutation simpleProductSetCaseQuantity($id: UUID!, $caseQuantity: Long) {
  simpleProductSetCaseQuantity(id: $id, caseQuantity: $caseQuantity) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCaseQuantityGQL extends Apollo.Mutation<SimpleProductSetCaseQuantityMutation, SimpleProductSetCaseQuantityMutationVariables> {
    document = SimpleProductSetCaseQuantityDocument;
    
  }
export const SimpleProductSetComponentItemPositionDocument = gql`
    mutation simpleProductSetComponentItemPosition($id: UUID!, $componentItemId: UUID!, $position: Int!) {
  simpleProductSetComponentItemPosition(
    id: $id
    componentItemId: $componentItemId
    position: $position
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetComponentItemPositionGQL extends Apollo.Mutation<SimpleProductSetComponentItemPositionMutation, SimpleProductSetComponentItemPositionMutationVariables> {
    document = SimpleProductSetComponentItemPositionDocument;
    
  }
export const SimpleProductSetComponentItemQuantityDocument = gql`
    mutation simpleProductSetComponentItemQuantity($id: UUID!, $componentItemId: UUID!, $quantity: BigDecimal!) {
  simpleProductSetComponentItemQuantity(
    id: $id
    componentItemId: $componentItemId
    quantity: $quantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetComponentItemQuantityGQL extends Apollo.Mutation<SimpleProductSetComponentItemQuantityMutation, SimpleProductSetComponentItemQuantityMutationVariables> {
    document = SimpleProductSetComponentItemQuantityDocument;
    
  }
export const SimpleProductSetCostDocument = gql`
    mutation simpleProductSetCost($id: UUID!, $cost: BigDecimal) {
  simpleProductSetCost(id: $id, cost: $cost) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCostGQL extends Apollo.Mutation<SimpleProductSetCostMutation, SimpleProductSetCostMutationVariables> {
    document = SimpleProductSetCostDocument;
    
  }
export const SimpleProductSetDimensionsDocument = gql`
    mutation simpleProductSetDimensions($id: UUID!, $length: BigDecimal!, $width: BigDecimal!, $height: BigDecimal!) {
  simpleProductSetDimensions(
    id: $id
    length: $length
    width: $width
    height: $height
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetDimensionsGQL extends Apollo.Mutation<SimpleProductSetDimensionsMutation, SimpleProductSetDimensionsMutationVariables> {
    document = SimpleProductSetDimensionsDocument;
    
  }
export const SimpleProductSetDiscontinuationReasonDocument = gql`
    mutation simpleProductSetDiscontinuationReason($reason: DiscontinuationReasonType!, $id: UUID!) {
  simpleProductSetDiscontinuationReason(reason: $reason, id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetDiscontinuationReasonGQL extends Apollo.Mutation<SimpleProductSetDiscontinuationReasonMutation, SimpleProductSetDiscontinuationReasonMutationVariables> {
    document = SimpleProductSetDiscontinuationReasonDocument;
    
  }
export const SimpleProductSetFeaturedDocument = gql`
    mutation simpleProductSetFeatured($id: UUID!, $featured: Boolean!) {
  simpleProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetFeaturedGQL extends Apollo.Mutation<SimpleProductSetFeaturedMutation, SimpleProductSetFeaturedMutationVariables> {
    document = SimpleProductSetFeaturedDocument;
    
  }
export const SimpleProductSetHeightDocument = gql`
    mutation simpleProductSetHeight($id: UUID!, $height: BigDecimal) {
  simpleProductSetHeight(id: $id, height: $height) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetHeightGQL extends Apollo.Mutation<SimpleProductSetHeightMutation, SimpleProductSetHeightMutationVariables> {
    document = SimpleProductSetHeightDocument;
    
  }
export const SimpleProductSetLengthDocument = gql`
    mutation simpleProductSetLength($id: UUID!, $length: BigDecimal) {
  simpleProductSetLength(id: $id, length: $length) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetLengthGQL extends Apollo.Mutation<SimpleProductSetLengthMutation, SimpleProductSetLengthMutationVariables> {
    document = SimpleProductSetLengthDocument;
    
  }
export const SimpleProductSetMapPriceDocument = gql`
    mutation simpleProductSetMapPrice($id: UUID!, $mapPrice: BigDecimal) {
  simpleProductSetMapPrice(id: $id, mapPrice: $mapPrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMapPriceGQL extends Apollo.Mutation<SimpleProductSetMapPriceMutation, SimpleProductSetMapPriceMutationVariables> {
    document = SimpleProductSetMapPriceDocument;
    
  }
export const SimpleProductSetMinimumOrderQuantityDocument = gql`
    mutation simpleProductSetMinimumOrderQuantity($id: UUID!, $minimumOrderQuantity: Long) {
  simpleProductSetMinimumOrderQuantity(
    id: $id
    minimumOrderQuantity: $minimumOrderQuantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMinimumOrderQuantityGQL extends Apollo.Mutation<SimpleProductSetMinimumOrderQuantityMutation, SimpleProductSetMinimumOrderQuantityMutationVariables> {
    document = SimpleProductSetMinimumOrderQuantityDocument;
    
  }
export const SimpleProductSetPriceDocument = gql`
    mutation simpleProductSetPrice($id: UUID!, $price: BigDecimal!) {
  simpleProductSetPrice(id: $id, price: $price) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetPriceGQL extends Apollo.Mutation<SimpleProductSetPriceMutation, SimpleProductSetPriceMutationVariables> {
    document = SimpleProductSetPriceDocument;
    
  }
export const SimpleProductSetProductMediaSequenceDocument = gql`
    mutation simpleProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  simpleProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetProductMediaSequenceGQL extends Apollo.Mutation<SimpleProductSetProductMediaSequenceMutation, SimpleProductSetProductMediaSequenceMutationVariables> {
    document = SimpleProductSetProductMediaSequenceDocument;
    
  }
export const SimpleProductSetPublishedRevisionDocument = gql`
    mutation simpleProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  simpleProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetPublishedRevisionGQL extends Apollo.Mutation<SimpleProductSetPublishedRevisionMutation, SimpleProductSetPublishedRevisionMutationVariables> {
    document = SimpleProductSetPublishedRevisionDocument;
    
  }
export const SimpleProductSetRequiresPrepDocument = gql`
    mutation simpleProductSetRequiresPrep($id: UUID!) {
  simpleProductSetRequiresPrep(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetRequiresPrepGQL extends Apollo.Mutation<SimpleProductSetRequiresPrepMutation, SimpleProductSetRequiresPrepMutationVariables> {
    document = SimpleProductSetRequiresPrepDocument;
    
  }
export const SimpleProductSetShipsAloneDocument = gql`
    mutation simpleProductSetShipsAlone($id: UUID!) {
  simpleProductSetShipsAlone(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetShipsAloneGQL extends Apollo.Mutation<SimpleProductSetShipsAloneMutation, SimpleProductSetShipsAloneMutationVariables> {
    document = SimpleProductSetShipsAloneDocument;
    
  }
export const SimpleProductSetSkuDocument = gql`
    mutation simpleProductSetSku($id: UUID!, $sku: String!) {
  simpleProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSkuGQL extends Apollo.Mutation<SimpleProductSetSkuMutation, SimpleProductSetSkuMutationVariables> {
    document = SimpleProductSetSkuDocument;
    
  }
export const SimpleProductSetSlugDocument = gql`
    mutation simpleProductSetSlug($id: UUID!, $slug: String!) {
  simpleProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSlugGQL extends Apollo.Mutation<SimpleProductSetSlugMutation, SimpleProductSetSlugMutationVariables> {
    document = SimpleProductSetSlugDocument;
    
  }
export const SimpleProductSetSpecialPriceDocument = gql`
    mutation simpleProductSetSpecialPrice($id: UUID!, $specialPrice: BigDecimal) {
  simpleProductSetSpecialPrice(id: $id, specialPrice: $specialPrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSpecialPriceGQL extends Apollo.Mutation<SimpleProductSetSpecialPriceMutation, SimpleProductSetSpecialPriceMutationVariables> {
    document = SimpleProductSetSpecialPriceDocument;
    
  }
export const SimpleProductSetSupplierDocument = gql`
    mutation simpleProductSetSupplier($productId: UUID!, $supplierId: UUID!) {
  simpleProductSetSupplier(productId: $productId, supplierId: $supplierId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSupplierGQL extends Apollo.Mutation<SimpleProductSetSupplierMutation, SimpleProductSetSupplierMutationVariables> {
    document = SimpleProductSetSupplierDocument;
    
  }
export const SimpleProductSetTitleDocument = gql`
    mutation simpleProductSetTitle($id: UUID!, $title: String!) {
  simpleProductSetTitle(id: $id, title: $title) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetTitleGQL extends Apollo.Mutation<SimpleProductSetTitleMutation, SimpleProductSetTitleMutationVariables> {
    document = SimpleProductSetTitleDocument;
    
  }
export const SimpleProductSetUpcDocument = gql`
    mutation simpleProductSetUpc($id: UUID!, $upc: String) {
  simpleProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetUpcGQL extends Apollo.Mutation<SimpleProductSetUpcMutation, SimpleProductSetUpcMutationVariables> {
    document = SimpleProductSetUpcDocument;
    
  }
export const SimpleProductSetVendorRefDocument = gql`
    mutation simpleProductSetVendorRef($id: UUID!, $vendorRef: String) {
  simpleProductSetVendorRef(id: $id, vendorRef: $vendorRef) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetVendorRefGQL extends Apollo.Mutation<SimpleProductSetVendorRefMutation, SimpleProductSetVendorRefMutationVariables> {
    document = SimpleProductSetVendorRefDocument;
    
  }
export const SimpleProductSetVisibleDocument = gql`
    mutation simpleProductSetVisible($id: UUID!, $visible: Boolean!) {
  simpleProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetVisibleGQL extends Apollo.Mutation<SimpleProductSetVisibleMutation, SimpleProductSetVisibleMutationVariables> {
    document = SimpleProductSetVisibleDocument;
    
  }
export const SimpleProductSetWeightDocument = gql`
    mutation simpleProductSetWeight($id: UUID!, $weight: BigDecimal) {
  simpleProductSetWeight(id: $id, weight: $weight) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWeightGQL extends Apollo.Mutation<SimpleProductSetWeightMutation, SimpleProductSetWeightMutationVariables> {
    document = SimpleProductSetWeightDocument;
    
  }
export const SimpleProductSetWholesalePriceDocument = gql`
    mutation simpleProductSetWholesalePrice($id: UUID!, $wholesalePrice: BigDecimal) {
  simpleProductSetWholesalePrice(id: $id, wholesalePrice: $wholesalePrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWholesalePriceGQL extends Apollo.Mutation<SimpleProductSetWholesalePriceMutation, SimpleProductSetWholesalePriceMutationVariables> {
    document = SimpleProductSetWholesalePriceDocument;
    
  }
export const SimpleProductSetWidthDocument = gql`
    mutation simpleProductSetWidth($id: UUID!, $width: BigDecimal) {
  simpleProductSetWidth(id: $id, width: $width) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWidthGQL extends Apollo.Mutation<SimpleProductSetWidthMutation, SimpleProductSetWidthMutationVariables> {
    document = SimpleProductSetWidthDocument;
    
  }
export const SimpleProductUploadImageDocument = gql`
    mutation simpleProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  simpleProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductUploadImageGQL extends Apollo.Mutation<SimpleProductUploadImageMutation, SimpleProductUploadImageMutationVariables> {
    document = SimpleProductUploadImageDocument;
    
  }
export const SimpleProductWithCrossSellsDocument = gql`
    query simpleProductWithCrossSells($slug: String!) {
  simpleProductBySlug(slug: $slug) {
    id
    cls
    sku
    slug
    title
    quantityAvailableForSale
    price
    salePrice
    medias {
      id
      mediaType
      url
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
    }
    crossSells {
      id
      cls
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductWithCrossSellsGQL extends Apollo.Query<SimpleProductWithCrossSellsQuery, SimpleProductWithCrossSellsQueryVariables> {
    document = SimpleProductWithCrossSellsDocument;
    
  }
export const SubscriptionAddItemDocument = gql`
    mutation subscriptionAddItem($subscriptionId: UUID!, $productId: UUID!, $quantity: Long!, $frequency: Long!) {
  subscriptionAddItem(
    subscriptionId: $subscriptionId
    productId: $productId
    quantity: $quantity
    frequency: $frequency
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionAddItemGQL extends Apollo.Mutation<SubscriptionAddItemMutation, SubscriptionAddItemMutationVariables> {
    document = SubscriptionAddItemDocument;
    
  }
export const SubscriptionCreateDocument = gql`
    mutation subscriptionCreate($productId: UUID!, $quantity: Long!, $frequency: Long!, $deliveryDay: DayOfWeek, $phone: String!, $billingFirstName: String!, $billingLastName: String!, $billingCompany: String, $billingLine1: String!, $billingLine2: String, $billingCity: String!, $billingState: String!, $billingPostalCode: String!, $billingCountry: String!, $shippingFirstName: String!, $shippingLastName: String!, $shippingCompany: String, $shippingLine1: String!, $shippingLine2: String, $shippingCity: String!, $shippingState: String!, $shippingPostalCode: String!, $shippingCountry: String!, $shippingResidential: Boolean, $paymentToken: String, $taxExempt: Boolean) {
  subscriptionCreate(
    productId: $productId
    quantity: $quantity
    frequency: $frequency
    deliveryDay: $deliveryDay
    phone: $phone
    billingFirstName: $billingFirstName
    billingLastName: $billingLastName
    billingCompany: $billingCompany
    billingLine1: $billingLine1
    billingLine2: $billingLine2
    billingCity: $billingCity
    billingState: $billingState
    billingPostalCode: $billingPostalCode
    billingCountry: $billingCountry
    shippingFirstName: $shippingFirstName
    shippingLastName: $shippingLastName
    shippingCompany: $shippingCompany
    shippingLine1: $shippingLine1
    shippingLine2: $shippingLine2
    shippingCity: $shippingCity
    shippingState: $shippingState
    shippingPostalCode: $shippingPostalCode
    shippingCountry: $shippingCountry
    shippingResidential: $shippingResidential
    paymentToken: $paymentToken
    taxExempt: $taxExempt
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionCreateGQL extends Apollo.Mutation<SubscriptionCreateMutation, SubscriptionCreateMutationVariables> {
    document = SubscriptionCreateDocument;
    
  }
export const SubscriptionInfoDocument = gql`
    query subscriptionInfo($id: UUID!) {
  subscriptionInfo(id: $id) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionInfoGQL extends Apollo.Query<SubscriptionInfoQuery, SubscriptionInfoQueryVariables> {
    document = SubscriptionInfoDocument;
    
  }
export const SubscriptionListDocument = gql`
    query subscriptionList {
  subscriptionList(page: {page: null, pageSize: null}) {
    data {
      id
      deliveryDay
      shippingFirstName
      shippingLastName
      shippingCompany
      shippingLine1
      shippingLine2
      shippingCity
      shippingState
      shippingPostalCode
      shippingCountry
      shippingResidential
      shippingAddressValidationSource
      subscriptionItems {
        frequency
        quantity
        simpleProduct {
          title
          price
          salePrice
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionListGQL extends Apollo.Query<SubscriptionListQuery, SubscriptionListQueryVariables> {
    document = SubscriptionListDocument;
    
  }
export const SubscriptionRemoveItemDocument = gql`
    mutation subscriptionRemoveItem($subscriptionId: UUID!, $subscriptionItemId: UUID!) {
  subscriptionRemoveItem(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRemoveItemGQL extends Apollo.Mutation<SubscriptionRemoveItemMutation, SubscriptionRemoveItemMutationVariables> {
    document = SubscriptionRemoveItemDocument;
    
  }
export const SubscriptionSetPaymentTokenDocument = gql`
    mutation subscriptionSetPaymentToken($subscriptionId: UUID!, $paymentToken: String!) {
  subscriptionSetPaymentToken(
    subscriptionId: $subscriptionId
    paymentToken: $paymentToken
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetPaymentTokenGQL extends Apollo.Mutation<SubscriptionSetPaymentTokenMutation, SubscriptionSetPaymentTokenMutationVariables> {
    document = SubscriptionSetPaymentTokenDocument;
    
  }
export const SupplierClearEmailDocument = gql`
    mutation supplierClearEmail($id: UUID!) {
  supplierClearEmail(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierClearEmailGQL extends Apollo.Mutation<SupplierClearEmailMutation, SupplierClearEmailMutationVariables> {
    document = SupplierClearEmailDocument;
    
  }
export const SupplierClearPhoneDocument = gql`
    mutation supplierClearPhone($id: UUID!) {
  supplierClearPhone(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierClearPhoneGQL extends Apollo.Mutation<SupplierClearPhoneMutation, SupplierClearPhoneMutationVariables> {
    document = SupplierClearPhoneDocument;
    
  }
export const SupplierCreateDocument = gql`
    mutation supplierCreate($country: String!, $lastName: String!, $firstName: String!, $city: String!, $postalCode: String!, $name: String!, $company: String, $state: String!, $line2: String, $line1: String!) {
  supplierCreate(
    country: $country
    lastName: $lastName
    firstName: $firstName
    city: $city
    postalCode: $postalCode
    name: $name
    company: $company
    state: $state
    line2: $line2
    line1: $line1
  ) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierCreateGQL extends Apollo.Mutation<SupplierCreateMutation, SupplierCreateMutationVariables> {
    document = SupplierCreateDocument;
    
  }
export const SupplierDeleteDocument = gql`
    mutation supplierDelete($id: UUID!) {
  supplierDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierDeleteGQL extends Apollo.Mutation<SupplierDeleteMutation, SupplierDeleteMutationVariables> {
    document = SupplierDeleteDocument;
    
  }
export const SupplierInfoDocument = gql`
    query supplierInfo($id: UUID!) {
  supplierInfo(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierInfoGQL extends Apollo.Query<SupplierInfoQuery, SupplierInfoQueryVariables> {
    document = SupplierInfoDocument;
    
  }
export const SupplierListDocument = gql`
    query supplierList {
  supplierList(page: {page: null, pageSize: null}) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierListGQL extends Apollo.Query<SupplierListQuery, SupplierListQueryVariables> {
    document = SupplierListDocument;
    
  }
export const SupplierSetEmailDocument = gql`
    mutation supplierSetEmail($id: UUID!, $email: String!) {
  supplierSetEmail(id: $id, email: $email) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetEmailGQL extends Apollo.Mutation<SupplierSetEmailMutation, SupplierSetEmailMutationVariables> {
    document = SupplierSetEmailDocument;
    
  }
export const SupplierSetLeadTimeDocument = gql`
    mutation supplierSetLeadTime($id: UUID!, $leadTime: Int!) {
  supplierSetLeadTime(id: $id, leadTime: $leadTime) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetLeadTimeGQL extends Apollo.Mutation<SupplierSetLeadTimeMutation, SupplierSetLeadTimeMutationVariables> {
    document = SupplierSetLeadTimeDocument;
    
  }
export const SupplierSetNameDocument = gql`
    mutation supplierSetName($id: UUID!, $name: String!) {
  supplierSetName(id: $id, name: $name) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetNameGQL extends Apollo.Mutation<SupplierSetNameMutation, SupplierSetNameMutationVariables> {
    document = SupplierSetNameDocument;
    
  }
export const SupplierSetOrderCycleDocument = gql`
    mutation supplierSetOrderCycle($id: UUID!, $orderCycle: Int!) {
  supplierSetOrderCycle(id: $id, orderCycle: $orderCycle) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetOrderCycleGQL extends Apollo.Mutation<SupplierSetOrderCycleMutation, SupplierSetOrderCycleMutationVariables> {
    document = SupplierSetOrderCycleDocument;
    
  }
export const SupplierSetPhoneDocument = gql`
    mutation supplierSetPhone($id: UUID!, $phone: String!) {
  supplierSetPhone(id: $id, phone: $phone) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetPhoneGQL extends Apollo.Mutation<SupplierSetPhoneMutation, SupplierSetPhoneMutationVariables> {
    document = SupplierSetPhoneDocument;
    
  }
export const SupplierUpdateAddressDocument = gql`
    mutation supplierUpdateAddress($country: String!, $lastName: String!, $firstName: String!, $city: String!, $postalCode: String!, $company: String, $state: String!, $line2: String, $line1: String!, $id: UUID!, $residential: Boolean!) {
  supplierUpdateAddress(
    country: $country
    lastName: $lastName
    firstName: $firstName
    city: $city
    postalCode: $postalCode
    company: $company
    state: $state
    line2: $line2
    line1: $line1
    id: $id
    residential: $residential
  ) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierUpdateAddressGQL extends Apollo.Mutation<SupplierUpdateAddressMutation, SupplierUpdateAddressMutationVariables> {
    document = SupplierUpdateAddressDocument;
    
  }
export const TeamListDocument = gql`
    query teamList {
  teamList(
    page: {page: null, pageSize: null, sort: [{field: "name", direction: ASC}]}
  ) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TeamListGQL extends Apollo.Query<TeamListQuery, TeamListQueryVariables> {
    document = TeamListDocument;
    
  }
export const TradeShowAssignTerminalDocument = gql`
    mutation tradeShowAssignTerminal($tradeshowId: UUID!, $terminalId: UUID!) {
  tradeShowAssignTerminal(tradeshowId: $tradeshowId, terminalId: $terminalId) {
    id
    country
    city
    endDate
    startDate
    postalCode
    cls
    taxRate
    name
    state
    line2
    line1
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowAssignTerminalGQL extends Apollo.Mutation<TradeShowAssignTerminalMutation, TradeShowAssignTerminalMutationVariables> {
    document = TradeShowAssignTerminalDocument;
    
  }
export const TradeShowCreateShowDocument = gql`
    mutation tradeShowCreateShow($name: String!, $preOrderStartDate: LocalDate, $preOrderEndDate: LocalDate, $startDate: LocalDate!, $endDate: LocalDate!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $url: String, $posTerminalsRequested: Long) {
  tradeShowCreateShow(
    name: $name
    preOrderStartDate: $preOrderStartDate
    preOrderEndDate: $preOrderEndDate
    startDate: $startDate
    endDate: $endDate
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    url: $url
    posTerminalsRequested: $posTerminalsRequested
  ) {
    id
    country
    city
    endDate
    startDate
    postalCode
    preOrderStartDate
    preOrderEndDate
    cls
    taxRate
    name
    state
    line2
    line1
    url
    posTerminalsRequested
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowCreateShowGQL extends Apollo.Mutation<TradeShowCreateShowMutation, TradeShowCreateShowMutationVariables> {
    document = TradeShowCreateShowDocument;
    
  }
export const TradeShowDeleteShowDocument = gql`
    mutation tradeShowDeleteShow($id: UUID!) {
  tradeShowDeleteShow(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowDeleteShowGQL extends Apollo.Mutation<TradeShowDeleteShowMutation, TradeShowDeleteShowMutationVariables> {
    document = TradeShowDeleteShowDocument;
    
  }
export const TradeShowFilterDocument = gql`
    query tradeShowFilter($name: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  tradeShowFilter(name: {pattern: $name}, sort: $sort, page: $pageable) {
    data {
      id
      country
      city
      endDate
      startDate
      postalCode
      preOrderEndDate
      preOrderStartDate
      cls
      name
      taxRate
      startDate
      endDate
      line1
      line2
      city
      state
      postalCode
      country
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowFilterGQL extends Apollo.Query<TradeShowFilterQuery, TradeShowFilterQueryVariables> {
    document = TradeShowFilterDocument;
    
  }
export const TradeShowInfoDocument = gql`
    query tradeShowInfo($id: UUID!) {
  tradeShowInfo(id: $id) {
    id
    cls
    name
    taxRate
    startDate
    endDate
    preOrderEndDate
    preOrderStartDate
    line1
    line2
    city
    state
    postalCode
    country
    url
    posTerminalsRequested
    posTerminals {
      id
      cls
      assetTag
    }
    salesOrders {
      id
      alternateOrderNumber
      billingAddressValidationSource
      billingCity
      billingCompany
      billingCountry
      billingFirstName
      billingLastName
      billingLine1
      billingLine2
      billingPostalCode
      billingState
      cls
      comments {
        id
      }
      couponCode
      createdAt
      discountTotal
      email
      fulfillmentChannelType
      grandTotal
      handlingTotal
      hold
      ipAddress
      orderNumber
      payments {
        id
      }
      phone
      placedTime
      salesOrderType
      shippingAddressValidationSource
      shippingCity
      shippingCompany
      shippingCountry
      shippingFirstName
      shippingLastName
      shippingLine1
      shippingLine2
      shippingMethod
      shippingPostalCode
      shippingResidential
      shippingState
      shippingTotal
      status
      subTotal
      taxCode
      taxTotal
      updatedAt
      shipments {
        id
        actualHeight
        actualLength
        actualShippingCost
        actualWeight
        actualWidth
        addressValidationSource
        carrier
        carrierPreference
        city
        cls
        company
        contentsMd5
        country
        createdAt
        downloadStart
        email
        estimatedDeliveryDate
        estimatedHeight
        estimatedLength
        estimatedShipDate
        estimatedWeight
        estimatedWidth
        finalApproval
        firstName
        lastName
        line1
        line2
        options
        packaging
        placedAt
        postalCode
        printedEnd
        printedStart
        reprint
        reseller
        residential
        service
        shipmentItems {
          id
        }
        shipmentNumber
        shipmentStatus
        shippedAt
        shippedBy
        shippingNeeds
        state
        trackingNo
        trackingNos
        updatedAt
        zplContent
        zplContents
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowInfoGQL extends Apollo.Query<TradeShowInfoQuery, TradeShowInfoQueryVariables> {
    document = TradeShowInfoDocument;
    
  }
export const TradeShowListInPreOrderDocument = gql`
    query tradeShowListInPreOrder {
  tradeShowListInPreOrder {
    id
    name
    url
    city
    state
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListInPreOrderGQL extends Apollo.Query<TradeShowListInPreOrderQuery, TradeShowListInPreOrderQueryVariables> {
    document = TradeShowListInPreOrderDocument;
    
  }
export const TradeShowListTerminalsDocument = gql`
    mutation tradeShowListTerminals {
  tradeShowListTerminals {
    urn
    cls
    assetTag
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListTerminalsGQL extends Apollo.Mutation<TradeShowListTerminalsMutation, TradeShowListTerminalsMutationVariables> {
    document = TradeShowListTerminalsDocument;
    
  }
export const TradeShowListUpcomingDocument = gql`
    query tradeShowListUpcoming {
  tradeShowListUpcoming {
    id
    name
    city
    state
    startDate
    endDate
    preOrderEndDate
    preOrderStartDate
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListUpcomingGQL extends Apollo.Query<TradeShowListUpcomingQuery, TradeShowListUpcomingQueryVariables> {
    document = TradeShowListUpcomingDocument;
    
  }
export const TradeShowReportDocument = gql`
    mutation tradeShowReport($id: UUID!) {
  tradeShowReport(id: $id) {
    showName
    startDate
    endDate
    city
    state
    subTotal
    discountTotal
    handlingTotal
    shippingTotal
    taxTotal
    grandTotal
    lineItems {
      orderNumber
      sku
      name
      quantity
      unitAmount
      lineAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowReportGQL extends Apollo.Mutation<TradeShowReportMutation, TradeShowReportMutationVariables> {
    document = TradeShowReportDocument;
    
  }
export const TradeShowSendReportDocument = gql`
    mutation tradeShowSendReport($id: UUID!, $emails: [String]!) {
  tradeShowSendReport(id: $id, emails: $emails)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowSendReportGQL extends Apollo.Mutation<TradeShowSendReportMutation, TradeShowSendReportMutationVariables> {
    document = TradeShowSendReportDocument;
    
  }
export const TradeShowUnassignTerminalDocument = gql`
    mutation tradeShowUnassignTerminal($tradeshowId: UUID!, $terminalId: UUID!) {
  tradeShowUnassignTerminal(tradeshowId: $tradeshowId, terminalId: $terminalId) {
    id
    country
    city
    endDate
    startDate
    postalCode
    cls
    taxRate
    name
    state
    line2
    line1
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowUnassignTerminalGQL extends Apollo.Mutation<TradeShowUnassignTerminalMutation, TradeShowUnassignTerminalMutationVariables> {
    document = TradeShowUnassignTerminalDocument;
    
  }
export const TradeShowUpdateShowDocument = gql`
    mutation tradeShowUpdateShow($id: UUID!, $name: String!, $preOrderStartDate: LocalDate, $preOrderEndDate: LocalDate, $startDate: LocalDate!, $endDate: LocalDate!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $url: String, $posTerminalsRequested: Long) {
  tradeShowUpdateShow(
    id: $id
    name: $name
    preOrderStartDate: $preOrderStartDate
    preOrderEndDate: $preOrderEndDate
    startDate: $startDate
    endDate: $endDate
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    url: $url
    posTerminalsRequested: $posTerminalsRequested
  ) {
    id
    country
    city
    endDate
    startDate
    postalCode
    preOrderStartDate
    preOrderEndDate
    cls
    taxRate
    name
    state
    line2
    line1
    url
    posTerminalsRequested
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowUpdateShowGQL extends Apollo.Mutation<TradeShowUpdateShowMutation, TradeShowUpdateShowMutationVariables> {
    document = TradeShowUpdateShowDocument;
    
  }
export const UserChangePasswordByHashDocument = gql`
    mutation userChangePasswordByHash($hash: String!, $password: String!) {
  userChangePasswordByHash(hash: $hash, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserChangePasswordByHashGQL extends Apollo.Mutation<UserChangePasswordByHashMutation, UserChangePasswordByHashMutationVariables> {
    document = UserChangePasswordByHashDocument;
    
  }
export const UserConfirmEmailByHashDocument = gql`
    mutation userConfirmEmailByHash($hash: String!) {
  userConfirmEmail(hash: $hash)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserConfirmEmailByHashGQL extends Apollo.Mutation<UserConfirmEmailByHashMutation, UserConfirmEmailByHashMutationVariables> {
    document = UserConfirmEmailByHashDocument;
    
  }
export const UserFilterDocument = gql`
    query userFilter($firstName: String, $lastName: String, $email: String, $admin: Boolean, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  userFilter(
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    admin: {value: $admin}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      firstName
      lastName
      email
      admin
      userType
      taxExempt
      netTerms
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFilterGQL extends Apollo.Query<UserFilterQuery, UserFilterQueryVariables> {
    document = UserFilterDocument;
    
  }
export const UserInfoDocument = gql`
    query userInfo($id: UUID!) {
  userInfo(id: $id) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInfoGQL extends Apollo.Query<UserInfoQuery, UserInfoQueryVariables> {
    document = UserInfoDocument;
    
  }
export const UserSelfDocument = gql`
    query userSelf {
  userSelf {
    id
    email
    firstName
    lastName
    admin
    roles
    emailConfirmed
    taxExempt
    netTerms
    userType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSelfGQL extends Apollo.Query<UserSelfQuery, UserSelfQueryVariables> {
    document = UserSelfDocument;
    
  }
export const UserSendPasswordResetDocument = gql`
    mutation userSendPasswordReset($email: String!) {
  userSendPasswordReset(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSendPasswordResetGQL extends Apollo.Mutation<UserSendPasswordResetMutation, UserSendPasswordResetMutationVariables> {
    document = UserSendPasswordResetDocument;
    
  }
export const UserUpdateAdminDocument = gql`
    mutation userUpdateAdmin($id: UUID!, $admin: Boolean!) {
  userUpdateAdmin(id: $id, admin: $admin) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateAdminGQL extends Apollo.Mutation<UserUpdateAdminMutation, UserUpdateAdminMutationVariables> {
    document = UserUpdateAdminDocument;
    
  }
export const UserUpdateEmailDocument = gql`
    mutation userUpdateEmail($id: UUID!, $email: String!) {
  userUpdateEmail(id: $id, email: $email) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateEmailGQL extends Apollo.Mutation<UserUpdateEmailMutation, UserUpdateEmailMutationVariables> {
    document = UserUpdateEmailDocument;
    
  }
export const UserUpdateNameDocument = gql`
    mutation userUpdateName($id: UUID!, $firstName: String!, $lastName: String!) {
  userUpdateName(id: $id, firstName: $firstName, lastName: $lastName) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateNameGQL extends Apollo.Mutation<UserUpdateNameMutation, UserUpdateNameMutationVariables> {
    document = UserUpdateNameDocument;
    
  }
export const UserUpdateNetTermsDocument = gql`
    mutation userUpdateNetTerms($id: UUID!, $netTerms: Int!) {
  userUpdateNetTerms(id: $id, netTerms: $netTerms) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateNetTermsGQL extends Apollo.Mutation<UserUpdateNetTermsMutation, UserUpdateNetTermsMutationVariables> {
    document = UserUpdateNetTermsDocument;
    
  }
export const UserUpdatePasswordDocument = gql`
    mutation userUpdatePassword($email: String!, $password: String!) {
  userUpdatePassword(email: $email, password: $password) {
    id
    email
    firstName
    lastName
    admin
    emailConfirmed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdatePasswordGQL extends Apollo.Mutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables> {
    document = UserUpdatePasswordDocument;
    
  }
export const UserUpdateTaxExemptDocument = gql`
    mutation userUpdateTaxExempt($taxExempt: Boolean!, $id: UUID!) {
  userUpdateTaxExempt(taxExempt: $taxExempt, id: $id) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateTaxExemptGQL extends Apollo.Mutation<UserUpdateTaxExemptMutation, UserUpdateTaxExemptMutationVariables> {
    document = UserUpdateTaxExemptDocument;
    
  }
export const UserUpdateTypeDocument = gql`
    mutation userUpdateType($id: UUID!, $type: UserType!) {
  userUpdateType(id: $id, type: $type) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateTypeGQL extends Apollo.Mutation<UserUpdateTypeMutation, UserUpdateTypeMutationVariables> {
    document = UserUpdateTypeDocument;
    
  }
export const WalmartSalesOrderRefreshDocument = gql`
    mutation walmartSalesOrderRefresh($purchaseOrderId: String!) {
  walmartSalesOrderRefresh(purchaseOrderId: $purchaseOrderId) {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    email
    phone
    ipAddress
    status
    hold
    couponCode
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingAddressValidationSource
    salesOrderType
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    salesOrderItemGroups {
      id
      lineAmount
      quantity
      unitAmount
      salesOrderItems {
        sku
        name
        quantity
      }
      kitProduct {
        sku
        title
      }
    }
    salesOrderItems {
      id
      sku
      name
      quantity
      unitAmount
      discountAmount
      lineAmount
      salesOrderItemGroup {
        id
      }
    }
    shipments {
      id
      shipmentNumber
      shipmentStatus
      estimatedShipDate
      estimatedDeliveryDate
      estimatedWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualWeight
      actualLength
      actualWidth
      actualHeight
      placedAt
      printedStart
      printedEnd
      shippedAt
      carrier
      service
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shippingNeeds
      shipmentItems {
        id
        quantity
        salesOrderItem {
          id
          sku
          name
        }
      }
    }
    payments {
      id
      transactionId
      paymentStatus
      placedAt
      amount
      description
    }
    comments {
      id
      time
      name
      comment
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WalmartSalesOrderRefreshGQL extends Apollo.Mutation<WalmartSalesOrderRefreshMutation, WalmartSalesOrderRefreshMutationVariables> {
    document = WalmartSalesOrderRefreshDocument;
    
  }
export const WarehouseFilterDocument = gql`
    query warehouseFilter($name: String, $warehouseType: WarehouseType, $enabled: Boolean, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  warehouseFilter(
    name: {pattern: $name}
    warehouseType: {value: $warehouseType}
    enabled: {value: $enabled}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      warehouseType
      name
      slug
      line1
      line2
      city
      state
      postalCode
      country
      latitude
      longitude
      enabled
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseFilterGQL extends Apollo.Query<WarehouseFilterQuery, WarehouseFilterQueryVariables> {
    document = WarehouseFilterDocument;
    
  }
export const WarehouseListDocument = gql`
    query warehouseList {
  warehouseList {
    id
    cls
    warehouseType
    name
    slug
    line1
    line2
    city
    state
    postalCode
    country
    latitude
    longitude
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseListGQL extends Apollo.Query<WarehouseListQuery, WarehouseListQueryVariables> {
    document = WarehouseListDocument;
    
  }
export const ZoneListDocument = gql`
    query zoneList {
  zoneList {
    id
    name
    warehouse {
      id
      name
      line1
      line2
      city
      state
      postalCode
      country
      latitude
      longitude
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ZoneListGQL extends Apollo.Query<ZoneListQuery, ZoneListQueryVariables> {
    document = ZoneListDocument;
    
  }