import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { QuillService } from 'ngx-quill';
import { MediaEntity } from '../../../../generated/graphql';
import { AddMediaComponent } from '../../backend/modules/catalog/dialogs/add-media/add-media.component';
import { DialogBoxOptions } from '../components/dialog/dialog.component';
import { HostnamePipe } from '../pipes/hostname.pipe';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class CustomizeQuillService {
  hostnamePipe = new HostnamePipe();
  private isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private quillService: QuillService,
    private dialogService: DialogService
  ) {}

  // This should only be called in app.module.ts
  public setupQuillCustomizations() {
    if (this.isBrowser) {
      this.quillService.getQuill().then((Quill) => {
        import('../../../../scripts/image-resize.min.js').then((module) =>
          Quill.register('modules/imageResize', module.default)
        );

        const font = Quill.import('formats/font');
        font.whitelist = ['bello_script', 'serif', 'monospace'];
        Quill.register(font, true);

        const size = Quill.import('formats/size');
        size.whitelist = ['xs', 'small', 'large', 'xl', '2xl', '3xl', '4xl', '5xl'];
        Quill.register(size, true);

        // Switch to using DIV tags instead of P tags
        // const block = Quill.import('blots/block');
        // block.tagName = 'DIV';
        // Quill.register(block, true);

        // The next two lines change the color options from being inline styles to classes
        Quill.register(Quill.import('attributors/class/color'), true);
        Quill.register(Quill.import('attributors/class/background'), true);

        const Embed = Quill.import('blots/embed');
        const Break = Quill.import('blots/break');
        // SoftBreak is used to shift enter to add a new line. It's used by our keyboard bindings.
        // Since the quill keyboard isn't defined here yet, we add the bindings in CustomizeQuillService,
        class SoftBreak extends Break {
          static blotName = 'softBreak';
          static tagName = 'br';

          length() {
            return 1;
          }

          value() {
            return '\n';
          }

          insertInto(parent, ref) {
            Embed.prototype.insertInto.call(this, parent, ref);
          }
        }

        Quill.register(SoftBreak);

        // Update image format to support class changes
        const BaseImageFormat = Quill.import('formats/image');
        const ImageFormatAttributesList = [
          'alt',
          'height',
          'width',
          'class'
        ];

        class ImageFormat extends BaseImageFormat {
          static formats(domNode) {
            return ImageFormatAttributesList.reduce(function(formats, attribute) {
              if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
              }
              return formats;
            }, {});
          }

          format(name, value) {
            if (ImageFormatAttributesList.indexOf(name) > -1) {
              if (value) {
                this.domNode.setAttribute(name, value);
              } else {
                this.domNode.removeAttribute(name);
              }
            } else {
              super.format(name, value);
            }
          }
        }

        Quill.register(ImageFormat, true);
      });
    }
  }

  public quillEditorCreated(quillEditor): void {
    quillEditor.container.childNodes[0].classList.add('prose');
    quillEditor.container.childNodes[0].classList.add('prose-jfgreen');
    quillEditor.container.childNodes[0].setAttribute('data-gramm_editor', 'false');

    const onImage = () => {
      const options = new DialogBoxOptions();
      options.component = AddMediaComponent;
      options.title = 'Upload Media';
      options.cancelText = 'Cancel';
      options.okText = '';
      const callback = (media: MediaEntity) => {
        quillEditor.insertEmbed(
          quillEditor.selection.savedRange.index,
          'image',
          this.hostnamePipe.transform(media.url),
          'user'
        );
      };
      options.inputs = { callback };

      this.dialogService.showDialog(options);
    };
    const toolbar = quillEditor.getModule('toolbar');
    toolbar.addHandler('image', onImage);

    if (this.isBrowser) {
      this.quillService.getQuill().then((Quill) => {
        const Delta = Quill.import('delta');

        function lineBreakMatcher() {
          const newDelta = new Delta();
          newDelta.insert({ softBreak: '' });
          return newDelta;
        }

        quillEditor.clipboard.addMatcher('BR', lineBreakMatcher);

        // We unshift the bindings rather than use quill.keyboard.addBinding so ours take precedence.
        quillEditor.keyboard.bindings[13].unshift({
          key: 13,
          shiftKey: true,
          handler: (range) => {
            const currentLeaf = quillEditor.getLeaf(range.index)[0];
            const nextLeaf = quillEditor.getLeaf(range.index + 1)[0];
            let count = 1;
            quillEditor.insertEmbed(range.index, 'softBreak', true, 'user');
            // Insert another break if:
            //  1. We're not currently at a SoftBreak and
            //  2. We're at the end of the editor or the next leaf has a different parent
            if ((currentLeaf.constructor.name !== 'SoftBreak') && (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent))) {
              count += 1;
              quillEditor.insertEmbed(range.index, 'softBreak', true, 'user');
            }

            // Move the cursor forward
            quillEditor.setSelection(range.index + count, 'silent');
            quillEditor.scrollIntoView();
          }
        });
        quillEditor.keyboard.bindings[8].unshift({
          key: 8,
          handler: (range) => {
            const currentLeaf = quillEditor.getLeaf(range.index)[0];
            const nextLeaf = quillEditor.getLeaf(range.index + 1)[0];
            const previousLeaf = quillEditor.getLeaf(range.index - 1)[0];
            const previousLeaf2 = quillEditor.getLeaf(range.index - 2)[0];
            // Todo: There's still an issue: if a user has new lines and then arrow keys up and back down,
            //  the cursor will be before the final <br> and deleting can hide the <br>
            if (previousLeaf != null) {
              if ((previousLeaf.constructor.name === 'SoftBreak') && (currentLeaf.constructor.name !== 'SoftBreak') && ((nextLeaf === null) || (currentLeaf.parent !== nextLeaf.parent))) {
                // Add another soft break when we have removed everything from this line to ensure the new line is visible to the user
                quillEditor.insertEmbed(range.index, 'softBreak', true, 'silent');
                quillEditor.setSelection(range.index + 1, 'silent');
              } else if ((previousLeaf2 != null) && (previousLeaf2.constructor.name !== 'SoftBreak') && (previousLeaf.constructor.name === 'SoftBreak') && (currentLeaf.constructor.name === 'SoftBreak') && ((nextLeaf === null) || (nextLeaf.constructor.name !== 'SoftBreak'))) {
                // If there is only one more soft break before this one, it means we are removing the new line
                // Which requires us to remove both soft breaks
                // return false to prevent default handler
                quillEditor.deleteText(range.index - 2, 2, Quill.sources.USER);
                return false;
              }
            }
            return true;
          }
        });
      });
    }
  }
}

const QUILL_TOOLBAR = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote'], // Todo:, 'code-block'],
  [{ header: 1 }, { header: 2 }],               // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction
  [{ size: ['xs', 'small', false, 'large', 'xl', '2xl', '3xl', '4xl', '5xl'] }],  // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [
    { color: ['', 'white', 'jfgreen', 'jfyellow', 'gray', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'] },
    { background: ['', 'black', 'jfgreen', 'jfyellow', 'gray', 'red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'] }
  ],
  [{ font: ['', 'bello_script', 'serif', 'monospace'] }],
  [{ align: [] }],
  ['clean'],                                         // remove formatting button
  ['link', 'image'] // Todo: 'video']
];

export const QUILL_MODULES = {
  toolbar: QUILL_TOOLBAR,
  imageResize: {
    modules: ['Resize', 'DisplaySize', 'Toolbar']
  }
};

